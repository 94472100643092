import React, { useState } from 'react';
import '../css/EnquiryForm.css'; // Custom styles (you can add this CSS file)

const EnquiryForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false); // State for showing success message
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    enquiryType: '',
    message: ''
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    const object = { ...formData, access_key: '45746f61-c95b-4799-ba54-85b08023a630' };
    const json = JSON.stringify(object);

    try {
      const res = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: json
      });

      const result = await res.json();

      if (result.success) {
        setIsSubmitted(true); // Show success message
        setFormData({ name: '', email: '', phone: '', enquiryType: '', message: '' }); // Reset form fields
        console.log('Success', result);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h1 className="mb-3"> Enquiry</h1>
            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>

      <section className="enquiry-form pt-6 pb-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="enquiry-form-box bg-white p-5 rounded shadow">
                <h2 className="text-center mb-4">Enquire About Shinde Agri Tourism</h2>
                <p className="text-center mb-4">
                  Fill out the form below to get more information or make a booking enquiry.
                </p>

                {isSubmitted && (
                  <div className="alert alert-success text-center mb-4">
                    Your enquiry has been submitted successfully! We will get back to you shortly.
                  </div>
                )}

                <form onSubmit={onSubmit}>
                  {/* Name */}
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Email */}
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Phone */}
                  <div className="form-group mb-3">
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Your Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Enquiry Type */}
                  <div className="form-group mb-3">
                    <select
                      name="enquiryType"
                      className="form-control"
                      value={formData.enquiryType}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Enquiry Type</option>
                      <option value="booking">Booking Inquiry</option>
                      <option value="services">Tour Services</option>
                      <option value="events">Events and Special Offers</option>
                      <option value="other">Family Picnic</option>
                      <option value="other">Get Together</option>
                      <option value="other">Corporate Picnic</option>
                      <option value="other">School Picnic</option>
                      <option value="other">Other Enquiry</option>
                    </select>
                  </div>

                  {/* Message */}
                  <div className="form-group mb-3">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="5"
                      placeholder="Enter your message or enquiry here..."
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  {/* Submit Button */}
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Submit Enquiry
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <h3 className='text-white'>Address Scanner for Accurate Location Capture</h3>
                <img className='' src='/images/qr.png'/>
            </div>
          </div>
        </div>
      </section>


      <section className='container pt-2'>
      <div className="contact-info-content row mb-1">
                
                {/* Phone Number */}
                <div className="col-lg-4 col-md-6 mb-4 borderrr">
                  <div className="info-item bg-lgrey px-4 py-5  text-center rounded">
                    <div className="info-icon mb-2">
                      <i className="fa fa-phone theme"></i>
                    </div>
                    <div className="info-content">
                      <h3>Phone Number</h3>
                      <a href="tel:+919356007002">
                   <p className="m-0 darkcolor">+91 9356007002</p>
                    </a>
                      <a href="tel:+919075990202">
                      <p className="m-0 darkcolor">+91 9075990202</p>
                      </a>

                    </div>
                  </div>
                </div>
                {/* Email Address */}
                <div className="col-lg-4 col-md-12 mb-4 borderrr">
                  <div className="info-item bg-lgrey px-4 py-5 text-center rounded">
                    <div className="info-icon mb-2">
                      <i className="fa fa-envelope theme"></i>
                    </div>
                    <div className="info-content ">
                      <h3>Email </h3>
                      <p className="m-0 darkcolor">
                        <a href="booking@shindekrushiparyatan.com" className='darkcolor'>booking@shindekrushiparyatan.com</a>
                      </p>
                     
                    </div>
                  </div>
                </div>
                  {/* Office Location */}
                  <div className="col-lg-4 col-md-6 mb-4 borderrr">
                  <div className="info-item bg-lgrey px-4 py-5   text-center rounded">
                    <div className="info-icon mb-2">
                      <i className="fa fa-map-marker-alt theme"></i>
                    </div>
                    <div className="info-content darkcolor">
                      <h3>Address</h3>
                      At post - Malthan (Pabal Road) <br />
                          Taluka Shirur
                          District Pune,
                          <br /> Maharashtra - 412218
                    </div>
                  </div>
                </div>

              </div>

      </section>
    </div>
  );
};

export default EnquiryForm;
