import React from 'react';
import AboutUsSection from './AboutUsSection';
import CoreFeaturesSection from './CoreFeaturesSection';
import TestimonialSection from './TestimonialSection';
import Guid from './Guid';

const About = () => {
  return (
<div>


<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
      <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">About Us</h1>

          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


<AboutUsSection/>
{/* <CoreFeaturesSection/> */}
<Guid/>
<TestimonialSection/>

</div>   

);
};

export default About;
