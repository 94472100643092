



import React, { useState, useEffect } from 'react';

const Counter = () => {
  // Initialize state for each counter
  const [experience, setExperience] = useState(0);
  const [tourPackages, setTourPackages] = useState(0);
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [awards, setAwards] = useState(0);

  // Function to animate counter increment
  const animateCounter = (targetValue, setState, delay = 50) => {
    let startValue = 0;
    const increment = Math.ceil(targetValue / 100); // Increment per animation step
    const interval = setInterval(() => {
      startValue += increment;
      if (startValue >= targetValue) {
        clearInterval(interval);
        setState(`${targetValue}+`); // Set final value with "+"
      } else {
        setState(startValue); // Update state with current value
      }
    }, delay);
  };

  useEffect(() => {
    animateCounter(30, setExperience);
    animateCounter(25330, setTourPackages);
    animateCounter(60050, setHappyCustomers);
    animateCounter(40, setAwards);
  }, []); 

  return (
    <section className="counter-main pt-6">
      <div className="container">
        <div className="counter p-4 pb-0 box-shadow bg-white rounded m-0">
          <div className="row">
            {/* Counter Item 1 */}
            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
              <div className="counter-item border-end pe-4 d-flex align-items-center">
                <i className="fas fa-swimmer bg-theme p-3 rounded me-3 white fs-4"></i>
                <div className="counter-content">
                  <h2 className="value mb-0">{experience}</h2>
                  <span className="m-0">Activities</span>
                </div>
              </div>    
            </div>

            {/* Counter Item 2 */}
            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
              <div className="counter-item border-end pe-4 d-flex align-items-center">
                <i className="fas fa-sync-alt bg-theme p-3 rounded me-3 white fs-4"></i>
                <div className="counter-content">
                  <h2 className="value mb-0">{tourPackages}</h2>
                  <span className="m-0">Repeat Customers</span>
                </div>
              </div>    
            </div>

            {/* Counter Item 3 */}
            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
              <div className="counter-item border-end pe-4 d-flex align-items-center">
                <i className="icon-user-following bg-theme p-3 rounded me-3 white fs-4"></i>
                <div className="counter-content">
                  <h2 className="value mb-0">{happyCustomers}</h2>
                  <span className="m-0">Happy Customers</span>
                </div>
              </div>    
            </div>

            {/* Counter Item 4 */}
            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
              <div className="counter-item d-flex align-items-center">
                <i className="fas fa-utensils bg-theme p-3 rounded me-3 white fs-4"></i>
                <div className="counter-content">
                  <h2 className="value mb-0">{awards}</h2>
                  <span className="m-0">Food Options </span>
                </div>
              </div>    
            </div>
          </div>
        </div> 
      </div>
    </section>
  );
};

export default Counter;
















// import React, { useState, useEffect } from 'react';

// const Counter = () => {
//   // Initialize state for each counter
//   const [experience, setExperience] = useState(0);
//   const [tourPackages, setTourPackages] = useState(0);
//   const [happyCustomers, setHappyCustomers] = useState(0);
//   const [awards, setAwards] = useState(0);

//   // Function to animate counter increment
//   const animateCounter = (targetValue, setState, delay = 50) => {
//     let startValue = 0;
//     const increment = Math.ceil(targetValue / 100); // Increment per animation step
//     const interval = setInterval(() => {
//       startValue += increment;
//       if (startValue >= targetValue) {
//         clearInterval(interval);
//         setState(targetValue);  // Set final value
//       } else {
//         setState(startValue);  // Update state with current value
//       }
//     }, delay);
//   };

//   useEffect(() => {
//     animateCounter(30, setExperience);
//     animateCounter(530, setTourPackages);
//     animateCounter(850, setHappyCustomers);
//     animateCounter(19, setAwards);
//   }, []); 

//   return (
//     <section className="counter-main pt-6">
//       <div className="container">
//         <div className="counter p-4 pb-0 box-shadow bg-white rounded m-0">
//           <div className="row">
//             {/* Counter Item 1 */}
//             <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
//               <div className="counter-item border-end pe-4 d-flex align-items-center">
//                 <i className="fas fa-swimmer bg-theme p-3 rounded me-3 white fs-4"></i>
//                 <div className="counter-content">
//                   <h2 className="value mb-0">{experience}</h2>
//                   <span className="m-0">Activities</span>
//                 </div>
//               </div>    
//             </div>

//             {/* Counter Item 2 */}
//             <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
//               <div className="counter-item border-end pe-4 d-flex align-items-center">
//                 <i className="fas fa-sync-alt bg-theme p-3 rounded me-3 white fs-4"></i>
//                 <div className="counter-content">
//                   <h2 className="value mb-0">{tourPackages}</h2>
//                   <span className="m-0">Repeat Customers</span>
//                 </div>
//               </div>    
//             </div>

//             {/* Counter Item 3 */}
//             <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
//               <div className="counter-item border-end pe-4 d-flex align-items-center">
//                 <i className="icon-user-following bg-theme p-3 rounded me-3 white fs-4"></i>
//                 <div className="counter-content">
//                   <h2 className="value mb-0">{happyCustomers}</h2>
//                   <span className="m-0">Happy Customers</span>
//                 </div>
//               </div>    
//             </div>

//             {/* Counter Item 4 */}
//             <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
//               <div className="counter-item d-flex align-items-center">
//                 <i className="fas fa-heart bg-theme p-3 rounded me-3 white fs-4"></i>
//                 <div className="counter-content">
//                   <h2 className="value mb-0">{awards}</h2>
//                   <span className="m-0">Engagement </span>
//                 </div>
//               </div>    
//             </div>
//           </div>
//         </div> 
//       </div>
//     </section>
//   );
// };

// export default Counter;
