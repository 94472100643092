
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Onedaypicnic() {



  const cardData =
  {
    keyword: ' One Day Picnic Spot Near Pune for Family',
    heading: 'One Day Picnic Spot Near Pune for Family: Shinde Agri Tourism',
    headingDescription: 'If you are searching for the ideal one-day picnic spot near Pune for family outings, look no further than Shinde Agri Tourism. Just a short drive from Pune, this lush retreat combines the serenity of rural life with exciting activities that the entire family can enjoy. Known for its beautiful natural surroundings, rich agricultural heritage, and family-friendly atmosphere, Shinde Agri Tourism offers a perfect escape from the hustle and bustle of city life, making it a top choice for a refreshing getaway.',

    top: 'Top Activities at Shinde Agri Tourism	',
    topPlaces: [
      {
        "title": "Pre-Wedding Photoshoots in Scenic Surroundings",
        "description": "Shinde Agri Tourism is an excellent spot for pre-wedding photoshoots. With its lush green fields, traditional farmhouses, vibrant gardens, and rustic charm, couples can capture beautiful, natural moments. The rural backdrop creates a romantic and serene setting, ideal for timeless photos that reflect nature’s beauty and simplicity."
      },
      {
        "title": "Birthday Party Celebrations in a Natural Setting",
        "description": "Celebrate your special day with a birthday party at Shinde Agri Tourism! Enjoy a private picnic area, play games, and indulge in delicious homemade food while surrounded by nature. The open spaces are perfect for organizing games, setting up decorations, and hosting a memorable birthday event, especially for those who prefer a relaxed and scenic environment."
      },
      {
        "title": "Interactive Farming Experience for the Whole Family",
        "description": "Learn about traditional farming with hands-on activities such as planting seeds, harvesting crops, and milking cows. It’s a fun and educational experience for both kids and adults. Families can enjoy bonding while experiencing the rural way of life firsthand."
      },
      {
        "title": "Nature Walks and Bird Watching",
        "description": "Take a peaceful nature walk through the farm, breathing in fresh air and soaking up the beauty of the surroundings. Shinde Agri Tourism is home to diverse plant and animal life, making it a great spot for bird watching. The tranquil environment offers a perfect escape from the city, allowing families to reconnect with nature."
      },
      {
        "title": "Outdoor Games and Relaxing Picnic Areas",
        "description": "There’s no shortage of space for outdoor fun at Shinde Agri Tourism. Enjoy cricket, badminton, and volleyball with your family, or simply relax in the lush, well-maintained picnic areas. Whether you're playing games or lounging under the shade, the vast open areas offer plenty of room for everyone to enjoy."
      }
    ],

    "services": [
      {
        "name": "One Day Trip Near Pune with Family",
        "description": "Shinde Agritourism is an excellent destination for a one-day trip near Pune with family. Located amidst nature, it offers a range of activities for the whole family, from farm tours to animal interactions. Children can learn about farming practices, enjoy bullock cart rides, and take part in fun outdoor games. It’s a wonderful way for families to bond, relax, and learn together in a beautiful rural setting."
      },
      {
        "name": "One Day Trip Near Pune for Couples",
        "description": "For couples looking for a peaceful and romantic escape, Shinde Agritourism provides a serene environment perfect for relaxation. Enjoy a peaceful walk through the farm, enjoy scenic views, and indulge in a quiet picnic surrounded by nature. It’s the perfect getaway to unwind, connect with nature, and enjoy each other's company away from the hustle and bustle of city life."
      },
      {
        "name": "One Day Trip Near Pune for Family",
        "description": "Shinde Agritourism offers a delightful one-day trip near Pune for families. With activities like organic farming demonstrations, animal feeding, and bullock cart rides, the farm provides a unique opportunity to learn about rural life while having fun. It’s a great place for children to engage in educational activities and for the whole family to relax in the countryside."
      },
      {
        "name": "1 Day Trip Near Pune for Couple",
        "description": "Shinde Agritourism is an ideal destination for couples seeking a one-day trip near Pune. The farm’s tranquil environment, beautiful landscapes, and range of activities like nature walks and farm tours make it a perfect place for a relaxing and romantic outing. You can spend quality time together exploring the farm, enjoying a picnic, and experiencing rural life in a serene setting."
      },
      {
        "name": "One Day Picnic Spot Near Pune for Family",
        "description": "Shinde Agritourism is one of the best picnic spots near Pune for families. The farm offers a peaceful atmosphere and a variety of activities that families can enjoy together. From learning about organic farming to participating in farm games, it’s an educational yet fun way to spend the day. The kids will love feeding the animals, and the adults can enjoy a picnic with fresh, organic meals from the farm."
      },
      {
        "name": "1 Day Trip Near Pune for Family",
        "description": "If you’re looking for a one-day trip near Pune for your family, Shinde Agritourism is the perfect choice. The farm offers a range of interactive and fun activities for kids and adults alike, including animal interactions, organic farming tours, and outdoor games. With its peaceful rural setting, it’s an ideal place for families to relax and enjoy nature."
      },
      {
        "name": "Best Family Picnic Spot Near Pune",
        "description": "For a memorable family outing, Shinde Agritourism is one of the best family picnic spots near Pune. The farm provides a tranquil setting where families can bond over outdoor activities, explore the farm, and enjoy traditional rural games. There’s plenty of space for a picnic, and the kids will love the opportunity to interact with animals and learn about farm life."
      },
      {
        "name": "Family One Day Picnic Spot in Pune",
        "description": "If you prefer staying within Pune, Shinde Agritourism offers a great family-friendly picnic spot just outside the city. The farm offers a unique rural experience where you can engage in organic farming activities, enjoy traditional games, and savor fresh meals. It's a perfect way to introduce kids to farm life and nature in a relaxed, fun environment."
      },
      {
        "name": "Family One Day Trip Near Pune",
        "description": "Shinde Agritourism is a fantastic place for a one-day family trip near Pune. The farm offers a variety of activities for all ages, from feeding animals to exploring organic farming. The peaceful setting and fresh air make it an ideal place for families to spend quality time together, learning new things and enjoying nature."
      },
      {
        "name": "Family Picnic Spot in Pune",
        "description": "If you're looking for a family picnic spot within Pune, Shinde Agritourism offers a great option. Located on the outskirts of Pune, the farm’s peaceful setting provides an opportunity for families to engage in educational farm activities, play games, and enjoy delicious meals made with farm-fresh ingredients. It’s a wonderful escape from the city for families to relax and reconnect with nature."
      },
      {
        "name": "Family Picnic Spot Near Pune",
        "description": "For a perfect family picnic spot near Pune, look no further than Shinde Agritourism. The farm offers a wide range of activities for all ages, from nature walks to animal feeding and farm tours. It’s an ideal spot for families to relax, play, and learn about farming and rural life, all while enjoying fresh organic food."
      },
      {
        "name": "Family Picnic Spot Pune",
        "description": "If you’re seeking a family picnic spot in Pune, Shinde Agritourism is a great choice. It offers a unique experience where families can spend the day exploring a farm, feeding animals, and participating in fun rural activities. It’s an enriching experience that combines learning and relaxation in a beautiful rural setting."
      },
      {
        "name": "One Day Family Picnic Spot Near Pune",
        "description": "Shinde Agritourism is the ideal one-day family picnic spot near Pune. With its relaxing atmosphere and educational farm tours, it’s the perfect place to spend a day in nature. Families can enjoy a variety of activities, such as organic farming demonstrations, bullock cart rides, and games, while also enjoying delicious, freshly prepared meals."
      },
      {
        "name": "One Day Picnic Near Pune for Family",
        "description": "If you're looking for a fun-filled and educational one-day picnic near Pune for your family, Shinde Agritourism offers a unique experience. Spend the day learning about organic farming, interacting with farm animals, and enjoying a picnic amidst nature. It’s the perfect place to relax and bond with your loved ones while experiencing rural life."
      },
      {
        "name": "One Day Picnic Spot in Pune for Family",
        "description": "For those who prefer to stay closer to Pune, Shinde Agritourism offers an ideal one-day picnic spot. The farm provides a peaceful setting for families to explore, enjoy nature, and participate in fun activities. It’s the perfect location for a relaxing day out with the family, learning about farming and rural life."
      },
      {
        "name": "One Day Picnic Spot for Family Near Pune",
        "description": "Shinde Agritourism is an excellent one-day picnic spot for families near Pune. The farm offers a blend of fun and education, with opportunities to learn about sustainable farming, interact with animals, and enjoy the outdoors. Families can also enjoy a traditional picnic in the open air, making it a memorable experience for everyone."
      },
      {
        "name": "One Day Picnic Spot Near Pune with Family",
        "description": "Looking for a one-day picnic spot near Pune with the family? Shinde Agritourism offers a fantastic opportunity to enjoy a peaceful day in the countryside. With activities like bullock cart rides, nature walks, and farm-based games, it's the perfect place for families to relax and enjoy the outdoors."
      },
      {
        "name": "One Day Trip Near Pune in Winter with Family",
        "description": "Winter is the ideal time to visit Shinde Agritourism for a one-day trip with your family. The cool weather and beautiful natural surroundings provide the perfect backdrop for outdoor activities like farm tours, animal feeding, and nature walks. It's an ideal family getaway during the cooler months."
      },
      {
        "name": "One Day Trip Near Pune with Family in Summer",
        "description": "During the summer months, Shinde Agritourism offers a cool and refreshing escape from the heat. Enjoy a fun-filled day with your family, exploring the farm, participating in activities like bullock cart rides, and learning about organic farming. The fresh air and natural surroundings make it an ideal summer destination."
      },
      {
        "name": "One Day Trip with Family Near Pune",
        "description": "Shinde Agritourism is a great choice for a one-day trip with your family near Pune. From educational farm tours to fun activities like bullock cart rides and traditional games, the farm offers a variety of experiences for everyone. It's a peaceful, fun, and educational way to spend the day together as a family."
      },
      {
        "name": "Pune Family Picnic Spot",
        "description": "For a memorable family outing near Pune, Shinde Agritourism is a top choice. With its wide range of activities, including farm tours, nature walks, and animal interactions, it's an ideal place to relax, learn, and enjoy quality time together. The farm’s tranquil setting adds to the charm of the experience."
      },
      {
        "name": "Family One Day Picnic Spot in Pune",
        "description": "Shinde Agritourism provides a wonderful family-friendly picnic spot in Pune. Located just outside the city, the farm offers a variety of activities, such as nature walks, animal interactions, and organic farming tours. It's a great place to relax, play, and enjoy a peaceful day in the countryside."
      },
      {
        "name": "Family Picnic Spot Near Pune",
        "description": "Shinde Agritourism is one of the best family picnic spots near Pune. The farm offers a mix of educational and fun activities for families, from learning about farming to engaging in outdoor games. It’s a great place to relax and enjoy fresh, organic food while surrounded by nature."
      },
      {
        "name": "Shinde Agritourism One Day Picnic Spot Near Pune for Family Contact Number",
        "description": "Escape to nature with Shinde Agri Tourism for a perfect One Day Picnic Spot Near Pune for Family. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
      }
    ],
    tableData: [
      ["- One Day Trip Near Pune for Family", "- One Day Picnic Spot Near Pune for Family"],
      ["- One Day Trip Near Pune for Couples", "- One Day Picnic Spot for Family Near Pune"],
      ["- Best Family Picnic Spot Near Pune", "- Family One Day Picnic Spot in Pune"],
      ["- Family Picnic Spot Near Pune", "- Family Picnic Spot in Pune"],
      ["- One Day Picnic Spot in Pune for Family", "- One Day Picnic Near Pune for Family"],
      ["- One Day Trip Near Pune in Winter with Family", "- One Day Trip Near Pune with Family in Summer"],
      ["- Pune Family Picnic Spot", "- Family One Day Trip Near Pune"],
      ["- Shinde Agri Tourism - One Day Picnic Spot Near Pune", "- Shinde Agri Tourism Family Picnic Spot Near Pune"]
    ],

    whychoose: [
      {
        "WhyChooseheading": "Scenic & Serene Location",
        "WhyChoosedescription": "Nestled amidst lush greenery, Shinde Agri Tourism offers a peaceful environment perfect for a relaxing getaway from the hustle and bustle of city life. It’s a beautiful place to unwind and connect with nature."
      },
      {
        "WhyChooseheading": "Family-Friendly Activities",
        "WhyChoosedescription": "From nature trails and farming activities to outdoor games, there’s something for every family member. Kids can learn about sustainable farming while adults relax in a serene setting."
      },
      {
        "WhyChooseheading": "Delicious Local Cuisine",
        "WhyChoosedescription": "Enjoy authentic Maharashtrian dishes made with fresh, organic ingredients at our in-house restaurant. It’s a food lover's paradise, offering wholesome and traditional food for the whole family."
      },
      {
        "WhyChooseheading": "Safe & Clean Environment",
        "WhyChoosedescription": "We prioritize the safety and cleanliness of our environment. Our high standards ensure that every family member enjoys a safe and hygienic outing while having fun."
      },
      {
        "WhyChooseheading": "Affordable Packages",
        "WhyChoosedescription": "Our competitive pricing ensures you get a memorable experience without breaking the bank. With packages that cater to different needs and budgets, we offer an enriching outing for families at affordable rates."
      },
      {
        "WhyChooseheading": "Easy Accessibility from Pune",
        "WhyChoosedescription": "Just a short drive from Pune, Shinde Agri Tourism is a convenient and easy-to-reach location for families looking to escape the city for a day. A quick getaway that doesn’t require much travel time."
      }
    ]





  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which translates to a 2 to 2.5-hour drive, depending on traffic conditions."
    },
    {
      question: "How can I book a cab from Pune to Panvel?",
      answer: "You can book a cab directly through us. Simply contact our customer service team, and we will arrange a comfortable and hassle-free ride for you from Pune to Panvel and back."
    },
    {
      question: "What are the types of cabs available for the journey?",
      answer: "We offer a variety of vehicles, from sedans to SUVs, ensuring that you have a comfortable ride depending on the number of people in your group and your preferences."
    },
    {
      question: "Is the cab service available for a round trip?",
      answer: "Yes, we offer both one-way and round-trip cab services. If you're planning to stay for the day, you can book a round-trip service with flexible timings to suit your schedule."
    },
    {
      question: "Are the cab rides safe and comfortable?",
      answer: "Absolutely! Our cabs are well-maintained, regularly sanitized, and driven by experienced and professional drivers who prioritize your safety and comfort throughout the journey."
    },
    {
      question: "Can I make a last-minute booking for a cab?",
      answer: "While we recommend booking in advance to secure availability, we do our best to accommodate last-minute requests based on availability. It’s always best to book as early as possible to ensure a smooth experience."
    },
    {
      question: "What is the cost of the Pune to Panvel cab?",
      answer: "The cost varies depending on the type of vehicle and whether it’s a one-way or round-trip booking. Please contact our customer support for an accurate quote based on your requirements."
    }
  ];

  const testimonials = [
    {
      name: "Mr. Sangeeta & Family, Pune",
      text: "We had the most wonderful time at Shinde Agri Tourism! The kids had a blast exploring the farm and learning about sustainable farming. The fresh air, peaceful surroundings, and delicious food made for a perfect family day out."
    },
    {
      name: "Mr. Rajesh & Family, Pune",
      text: "Shinde Agri Tourism is the ideal one-day picnic spot near Pune! Our family enjoyed the nature, outdoor games, and great food. It was a truly enriching experience for all of us, and we’re already planning our next visit."
    }
  ];







  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | One Day Picnic Spot Near Pune for Family | Family Picnic Spots in Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for a one day picnic spot near Pune for family? Explore the best family picnic spots in Pune and nearby areas for a memorable day trip with your loved ones. Enjoy family-friendly activities and beautiful nature." />
  <meta name="keywords" content="One Day Picnic Spot Near Pune for Family, One Day Trip near Pune with Family, Family Picnic Spot in Pune, Best Family Picnic Spot near Pune, One Day Family Picnic Spot near Pune, Family One Day Trip near Pune, One Day Trip with Family Near Pune, Family Picnic Spot Pune, Om Agritourism, Shinde Krushi Paryatan" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/one-day-picnic-spot-near-pune-for-family" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - One Day Picnic Spot Near Pune for Family",
        "url": "https://shindekrushiparyatan.com/one-day-picnic-spot-near-pune-for-family",
        "description": "Looking for a one day picnic spot near Pune for family? Explore the best family picnic spots in Pune and nearby areas. Visit Shinde Krushi Paryatan for an unforgettable experience with nature and family-friendly activities.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/picnic-spot-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/12.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Onedaypicnic;