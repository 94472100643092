
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Natureresortnearpune() {



  const cardData =
  {
    keyword: ' Nature resorts near pune for weekend',
    heading:  'Nature resorts near pune for weekend: Shinde Agri Tourism',
    headingDescription: 'If you are looking to escape the daily hustle and immerse yourself in nature for a relaxing weekend near Pune, Shinde Agri Tourism is the perfect nature resort. Nestled amidst lush greenery and peaceful rural surroundings, this agro-tourism resort offers an ideal weekend retreat where you can unwind, reconnect with nature, and enjoy various outdoor activities in a serene setting.',

    top:    'Top Features of Shinde Agri Tourism as a Nature Resort Near Pune',
"topPlaces": [
  {
    "title": "Peaceful Farm Stay Experience",
    "description": "Experience the charm of rural life with a stay at Shinde Agri Tourism. The resort offers comfortable and cozy accommodation surrounded by beautiful farmland and lush greenery. Wake up to fresh air, chirping birds, and the sight of rolling fields, making it the ideal place for a peaceful weekend getaway."
  },
  {
    "title": "Nature Walks and Bird Watching",
    "description": "Shinde Agri Tourism is perfect for nature lovers. The resort offers scenic nature walks where guests can explore the surrounding landscape, observe local flora and fauna, and enjoy peaceful moments of solitude. Bird watchers will especially appreciate the variety of bird species that thrive in the area."
  },
  {
    "title": "Interactive Farming Activities",
    "description": "Take part in hands-on farming activities like harvesting crops, milking cows, and learning traditional farming techniques. These activities are both fun and educational, giving guests the chance to connect with nature and understand the agricultural practices that sustain rural life."
  },
  {
    "title": "Outdoor Games and Sports",
    "description": "For those who enjoy a more active weekend, Shinde Agri Tourism offers various outdoor sports such as cricket, badminton, and volleyball. The spacious grounds provide ample space for guests to engage in friendly games and enjoy some physical activity in the fresh air."
  },
  {
    "title": "Bonfire and Stargazing",
    "description": "End your day with a cozy bonfire under the starry sky. The serene environment of Shinde Agri Tourism, away from the city lights, provides an excellent opportunity for stargazing, making it a perfect way to relax and unwind after a day of adventure and exploration."
  },
  {
    "title": "Farm-to-Table Dining",
    "description": "The resort offers delicious farm-to-table meals prepared with fresh, locally sourced ingredients. Guests can savor authentic Maharashtrian and regional dishes, all served in an open-air setting, enhancing the connection with nature and the surrounding agricultural environment."
  },
  {
    "title": "Peaceful Picnic Spots",
    "description": "For a relaxing day out in nature, Shinde Agri Tourism offers picturesque picnic spots where you can enjoy your meals surrounded by greenery. These spots are perfect for families, friends, or couples who want to relax and enjoy a peaceful time in nature."
  },
  {
    "title": "Corporate Retreats and Team-Building Activities",
    "description": "If you're planning a corporate retreat or a team-building weekend, Shinde Agri Tourism provides customized packages for group activities and events. Enjoy team-building exercises in a natural setting, strengthening collaboration and communication while enjoying the outdoors."
  },
  {
    "title": "Weekend Wellness Retreat",
    "description": "Shinde Agri Tourism offers a relaxing atmosphere that’s perfect for a weekend wellness retreat. Guests can take time for mindfulness walks, meditation, and relaxation activities, enhancing their connection with nature and achieving a sense of calm and peace."
  }
],

    "services": [
  {
    "name": "Nature Resorts Near Pune for Couples",
    "description": "For couples seeking a nature resort near Pune, Shinde Agritourism is an ideal destination. Located in a peaceful rural setting, it offers a serene environment perfect for unwinding with your partner. Enjoy nature walks, farm tours, and the tranquility of the countryside, making it a perfect escape from the hustle and bustle of city life. Whether you’re strolling through the farm or having a quiet picnic, the resort provides an intimate setting for couples to relax and enjoy each other’s company."
  },
  {
    "name": "Budget Resorts Near Pune for Family",
    "description": "Looking for budget resorts near Pune for family? Shinde Agritourism offers an affordable yet enriching experience for families. The resort provides a variety of activities suitable for all ages, from organic farming tours to animal interactions and bullock cart rides. With its natural setting and fun-filled activities, it’s an excellent option for a family outing without breaking the bank."
  },
  {
    "name": "Resorts Near Pune with Activities",
    "description": "Shinde Agritourism is one of the best resorts near Pune with activities. The resort offers a wide range of engaging activities for families, couples, and groups, including farm tours, nature walks, bullock cart rides, outdoor games, and animal interactions. It’s an ideal spot for those looking for a combination of relaxation and adventure in a natural, rural setting."
  },
  {
    "name": "Luxury Resorts Near Pune with Activities",
    "description": "While Shinde Agritourism provides a serene and tranquil setting, it offers a more rural and rustic experience compared to traditional luxury resorts near Pune with activities. However, it offers luxury in terms of peaceful surroundings, quality time with nature, and a chance to reconnect with rural life. Enjoy nature walks, organic farming tours, and more, all while relaxing in the countryside."
  },
  {
    "name": "Nature Resorts Near Pune for Weekend",
    "description": "For a nature resort near Pune for weekend getaways, Shinde Agritourism is the perfect place. Just a short drive from Pune, the resort offers a rural escape with activities that connect you to nature, including bullock cart rides, farm tours, and nature walks. It’s an excellent option for couples, families, or groups looking for a refreshing weekend in a peaceful environment."
  },
  {
    "name": "Nature Resorts Near Pune for Family",
    "description": "Shinde Agritourism is a fantastic nature resort near Pune for family outings. With activities like farm tours, animal interactions, and nature walks, it’s a great place to introduce children to rural life and organic farming. The family-friendly activities and tranquil setting make it an ideal location for a relaxing and educational family trip."
  },
  {
    "name": "Resorts Near Pune for Family One Day Trip",
    "description": "If you’re looking for resorts near Pune for family one-day trips, Shinde Agritourism is a great option. The resort offers a variety of engaging activities, including organic farming tours, outdoor games, and animal interactions. It’s a perfect one-day getaway for families who want to relax and enjoy quality time together in a peaceful and natural environment."
  },
  {
    "name": "Budget Resorts Near Pune for Family",
    "description": "For budget resorts near Pune for family, Shinde Agritourism is an excellent choice. Offering a variety of activities for all ages, from bullock cart rides to organic farming tours, it’s a family-friendly destination that doesn’t require a large budget. The resort’s rural location provides a peaceful and affordable escape from the city."
  },
  {
    "name": "Budget Resorts Near Pune for Family with Swimming Pool",
    "description": "While Shinde Agritourism does not feature a traditional swimming pool, it offers a variety of other outdoor activities that will keep the family entertained, including farm tours, nature walks, and animal interactions. It’s a perfect budget-friendly option for families who enjoy nature-based activities and want to experience the countryside without the need for a swimming pool."
  },
  {
    "name": "Budget Resorts Near Pune for Family with Swimming Pool Near Me",
    "description": "Shinde Agritourism is a great choice for budget resorts near Pune with swimming pool alternatives. While it doesn’t have a pool, the resort offers plenty of outdoor activities, such as nature walks, farm tours, and outdoor games, in a peaceful rural setting. It’s an excellent destination for families looking for a budget-friendly outing that combines relaxation with fun."
  },
  {
    "name": "Om Agro Tourism Resort Near Pune",
    "description": "Shinde Agritourism is one of the top Om Agro Tourism resorts near Pune. Located on the outskirts of Pune, this resort provides a unique opportunity to connect with nature while enjoying farm tours, nature walks, and interactive activities. It’s a perfect choice for families, couples, and groups looking to escape the city and enjoy a peaceful, nature-filled experience."
  },
  {
    "name": "Shinde Agri Tourism Nature Resorts Near Pune for Weekend Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect nature resort near Pune for a weekend. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Jungle Resorts Near Pune", "- Best Forest Resorts Near Pune"],
    ["- Best Jungle Resorts Near Pune", "- Forest Stay Near Pune"],
    ["- Mango Forest Resort Pune", "- Pune Forest Resort"],
    ["- Nature Resorts Near Pune", "- Nature Resorts Near Pune for Weekend"],
    ["- Best Nature Resorts in Pune", "- Eco Resorts Near Pune"],
    ["- Best Nature Resorts Near Pune", "- Top Nature Resorts Near Pune"],
    ["- Jungle Resort Near Pune", "- Nature Friendly Resorts Near Pune"],
    ["- Nature Stay Near Pune", "- Resorts in Nature Near Pune"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Nestled in the Heart of Nature",
      "WhyChoosedescription": "Shinde Agri Tourism is located in the heart of nature, surrounded by lush forests and beautiful landscapes. The resort offers a perfect getaway where you can reconnect with nature. Experience fresh air, calming forest sounds, and peaceful surroundings—an ideal escape from the hustle of city life. Whether you're seeking relaxation or adventure, our forest setting provides the perfect environment for both."
    },
    {
      "WhyChooseheading": "Immersive Forest Experience",
      "WhyChoosedescription": "At Shinde Agri Tourism, you are not just staying at a resort, but immersing yourself in the forest environment. Enjoy guided nature walks, explore local flora and fauna, and indulge in birdwatching amidst the green canopy. The eco-friendly approach to our forest stay ensures that guests can experience the unspoiled beauty of the forest without harming its delicate ecosystem. Perfect for nature enthusiasts and those looking for a deeper connection with the environment."
    },
    {
      "WhyChooseheading": "Comfortable Accommodation in Nature",
      "WhyChoosedescription": "We offer a variety of accommodation options that blend harmoniously with the natural surroundings. From cozy rooms with modern amenities to comfortable cottages nestled within the forest, each space is designed to allow you to enjoy the serenity of the environment. Wake up to the sounds of birds chirping, witness the morning mist, and enjoy breathtaking views right outside your window. Our accommodations ensure a peaceful and rejuvenating stay."
    },
    {
      "WhyChooseheading": "Adventure Activities for Thrill Seekers",
      "WhyChoosedescription": "For those looking to spice up their retreat with a bit of adventure, we offer activities like trekking, zip-lining, and rock climbing. These activities give you an opportunity to explore the forest up close while enjoying the thrill of outdoor adventure. Whether you are looking for an adrenaline rush or a peaceful walk through nature, Shinde Agri Tourism caters to all preferences."
    },
    {
      "WhyChooseheading": "Peaceful Retreat for Families & Groups",
      "WhyChoosedescription": "Shinde Agri Tourism is the ideal destination for families, friends, and groups looking for a peaceful retreat amidst nature. Whether you're planning a quiet family getaway or a team-building event for your company, our expansive grounds provide the perfect setting. Engage in group activities, family games, or simply unwind in nature’s embrace. The space and tranquility of our resort ensure a memorable experience for everyone."
    },
    {
      "WhyChooseheading": "Organic Farming and Nature Education",
      "WhyChoosedescription": "Shinde Agri Tourism offers an opportunity to learn about sustainable farming practices. Participate in hands-on activities such as planting, harvesting, and even milking cows. Our farm-based educational experiences teach guests about organic farming and eco-friendly practices. It's the perfect way to combine learning with leisure while also promoting environmental sustainability."
    },
    {
      "WhyChooseheading": "Easy Access from Pune",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is a short 2 to 2.5-hour drive, making it the perfect destination for a weekend getaway or day trip. You don’t have to travel far to escape the chaos of city life and enjoy the calmness of nature. With easy accessibility and a convenient location, it’s the perfect option for anyone seeking a quick retreat to the forest."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located around 100 km from Pune, which takes about 2 to 2.5 hours to drive. It's an ideal destination for a quick weekend getaway."
    },
    {
      question: "What types of accommodations are available?",
      answer: "We offer a variety of accommodations, including cozy rooms, cottages, and eco-friendly tents, all designed to blend with the natural surroundings while providing modern amenities for a comfortable stay."
    },
    {
      question: "What activities can we do at Shinde Agri Tourism?",
      answer: "Guests can enjoy nature walks, bird watching, trekking, zip-lining, archery, outdoor games, and farm tours. We also offer team-building activities and relaxing options like bonfires and stargazing."
    },
    {
      question: "Is the resort suitable for families?",
      answer: "Yes! Shinde Agri Tourism is perfect for families. We offer family-friendly activities, comfortable accommodations, and plenty of space for children to play and explore. It's a great place for family bonding and creating lasting memories."
    },
    {
      question: "Can we enjoy organic farming experiences?",
      answer: "Yes! Our resort offers the chance to participate in organic farming activities, such as planting, harvesting, and interacting with farm animals. It’s a great educational experience for guests of all ages."
    },
    {
      question: "What kind of food is served at the resort?",
      answer: "We serve delicious, wholesome meals made from locally-sourced, organic ingredients. Our menu includes traditional Maharashtrian dishes like pithla-bhakri, sabudana khichdi, and modak, as well as fresh farm produce and healthy snacks."
    },
    {
      question: "Can we book the resort for corporate retreats or group outings?",
      answer: "Absolutely! Shinde Agri Tourism is ideal for corporate retreats, group outings, and team-building activities. We offer customized packages for large groups, including adventure activities, meals, and accommodations."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Nikhil & Family",
      text: "Shinde Agri Tourism was the perfect weekend getaway for our family. The peaceful surroundings, fun activities, and delicious food made it a memorable trip. We especially loved the nature walks and farm experiences. Highly recommend it!"
    },
    {
      name: "Miss. Kavita & Friends",
      text: "We had an amazing weekend at Shinde Agri Tourism! The resort is beautifully situated in nature, and the team made us feel at home. From the adventure activities to the relaxing nature walks, everything was perfect. The food was amazing too!"
    }
  ];
  





  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Nature Resorts Near Pune for Weekend | Family Resorts, Budget Stays & Resorts with Activities | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for nature resorts near Pune for weekend getaways? Discover family-friendly resorts, budget stays, resorts with activities, and luxury options at Shinde Krushi Paryatan. Perfect for family trips and couple getaways!" />
  <meta name="keywords" content="Nature Resorts Near Pune for Couples, Budget Resorts Near Pune for Family, Resorts Near Pune with Activities, Luxury Resorts Near Pune with Activities, Nature Resorts Near Pune for Weekend, Nature Resorts Near Pune for Family, Resorts Near Pune for Family One Day Trip, Budget Resorts Near Pune for Family, Budget Resorts Near Pune for Family with Swimming Pool, Budget Resorts Near Pune for Family with Swimming Pool Near Me, Om Agro Tourism Resort Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/nature-resorts-near-pune-for-weekend" />

  {/* JSON-LD Schema for Nature Resort */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Nature Resorts Near Pune for Weekend Getaways",
        "url": "https://shindekrushiparyatan.com/nature-resorts-near-pune-for-weekend",
        "description": "Escape to the best nature resorts near Pune for a weekend getaway. From family-friendly resorts to luxury stays with activities, discover the perfect spot for a memorable weekend at Shinde Krushi Paryatan.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/nature-resorts-near-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/26.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Natureresortnearpune;