
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Hurdaparty() {



  const cardData =
  {
    keyword: ' Hurda Party Places Near Pune',
    heading:  'Hurda Party Places Near Pune: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for an authentic and fun-filled Hurda Party experience near Pune, Shinde Agri Tourism offers the ideal venue. Surrounded by nature, this agri-tourism spot is known for its unique Hurda Party offering, where guests can enjoy traditional winter delicacies in a rustic farm setting. It’s the perfect place to unwind, relax, and celebrate with family, friends, or colleagues.',

    top: 'Top Features of Hurda Parties at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Traditional Hurda Feast in a Rural Setting",
          "description": "Shinde Agri Tourism brings you the authentic Hurda party experience, where you can savor fresh, roasted Hurda (young jowar), along with other traditional delicacies like bajra rotis, buttermilk, and more. Enjoy this delicious spread while taking in the natural beauty of the surroundings."
        },
        {
          "title": "Rustic Farm Environment for a Unique Celebration",
          "description": "Celebrate in the serene and rustic farm setting of Shinde Agri Tourism. The open spaces and natural surroundings create the perfect atmosphere for a memorable Hurda party, where you can engage in fun activities, relax, and enjoy the warmth of the bonfire."
        },
        {
          "title": "Bonfire and Music for a Cozy Party Atmosphere",
          "description": "No Hurda party is complete without a bonfire! Shinde Agri Tourism provides a cozy bonfire setup, perfect for gathering around with friends and family. Enjoy music, games, and the traditional feel of a Hurda party, creating an atmosphere of warmth and celebration."
        },
        {
          "title": "Interactive Farming Activities and Games",
          "description": "In addition to the traditional food and fun, guests can engage in hands-on farming activities, such as harvesting, milking cows, and more. It’s a perfect way to bond with nature and your guests while making lasting memories through traditional farming experiences."
        },
        {
          "title": "Outdoor Games and Leisure Activities",
          "description": "Shinde Agri Tourism offers various outdoor games such as cricket, badminton, and volleyball, along with relaxing picnic areas. Whether you’re looking for a fun activity or simply want to relax in nature, there’s something for everyone to enjoy during your Hurda party."
        }
      ],

    "services": [
  {
    "name": "Hurda Party Near Pune Nagar Road",
    "description": "For a hurda party near Pune Nagar Road, Shinde Agritourism is a fantastic option. Located just a short drive from the busy Pune-Nagar Road, this agritourism destination offers an immersive rural experience where guests can enjoy the traditional hurda party atmosphere. Surrounded by lush fields, you and your friends or family can savor fresh hurda (young jowar) roasted on an open flame, accompanied by traditional games and fun activities. It's the perfect place to celebrate the harvest season in a natural, peaceful setting."
  },
  {
    "name": "Hurda Party Places Near Pune",
    "description": "Looking for hurda party places near Pune? Shinde Agritourism provides one of the best settings to enjoy a hurda party. The farm’s rustic, open-air ambiance is perfect for groups, families, or corporate teams to gather and celebrate the harvest season with freshly roasted hurda, farm games, and traditional food. The location is easily accessible from Pune, making it an ideal getaway for a day of fun and relaxation in nature."
  },
  {
    "name": "Best Hurda Party in Pune",
    "description": "If you're searching for the best hurda party in Pune, Shinde Agritourism stands out as the top choice. Known for its authentic rural experience, it offers the best of both worlds: fresh hurda roasted on an open fire and the charm of farm activities like bullock cart rides, nature walks, and outdoor games. The peaceful environment combined with delicious food and engaging activities makes it a memorable destination for a traditional hurda party near Pune."
  },
  {
    "name": "Best Hurda Party Near Pune",
    "description": "Shinde Agritourism offers one of the best hurda parties near Pune. Nestled in the countryside, the farm offers a great setting to enjoy fresh hurda, accompanied by farm-based fun activities. Whether you're hosting a family outing, a group get-together, or a corporate team-building event, Shinde Agritourism provides the perfect location for a traditional hurda party with a unique twist."
  },
  {
    "name": "Best Places for Hurda Party Near Pune",
    "description": "For those looking for the best places for a hurda party near Pune, Shinde Agritourism is the ideal destination. The farm is known for its picturesque rural surroundings, delicious food, and engaging activities. Enjoy roasted hurda, play traditional games, take part in a bullock cart ride, and relax in the tranquility of nature. It’s the perfect place to enjoy a traditional hurda party with your loved ones."
  },
  {
    "name": "Hurda Party Locations Near Pune",
    "description": "Shinde Agritourism is one of the top hurda party locations near Pune. Located just outside Pune, the farm offers an authentic agricultural experience. The hurda party here is a great way to connect with nature, enjoy freshly roasted hurda, and indulge in farm-based activities. It’s a wonderful way to experience rural life and spend quality time with friends, family, or colleagues."
  },
  {
    "name": "Hurda Party Near Alandi Pune",
    "description": "For those looking for a hurda party near Alandi Pune, Shinde Agritourism is a great option. Located just a short distance from Alandi, the farm offers a peaceful, rural setting to enjoy the traditional joy of hurda parties. Roast fresh hurda by the fire, indulge in local delicacies, and participate in farm activities like nature walks, traditional games, and bullock cart rides."
  },
  {
    "name": "Hurda Party Near Pune Satara Road",
    "description": "Shinde Agritourism is also an excellent choice for a hurda party near Pune Satara Road. Just a short drive from the Pune-Satara Highway, the farm offers a natural and peaceful environment for a memorable hurda party. Guests can indulge in fresh, roasted hurda, engage in fun farm-based activities, and enjoy the beauty of nature."
  },
  {
    "name": "Hurda Party Places in Pune",
    "description": "If you’re looking for hurda party places in Pune, Shinde Agritourism offers a fantastic location to experience a traditional hurda party. The farm is located just outside Pune and offers a rustic, farm-based environment where you can enjoy fresh hurda, participate in fun outdoor games, and take part in unique experiences like farm tours and bullock cart rides. It’s a great place for groups, families, and corporate events."
  },
  {
    "name": "Hurda Party Resort Near Pune",
    "description": "For those looking for a hurda party resort near Pune, Shinde Agritourism provides an authentic farm experience with all the charm of a countryside resort. The farm is equipped with comfortable facilities, fresh food, and plenty of space for group activities. It’s a perfect place to host your hurda party in a relaxed setting, with additional features like traditional games and farm tours to make your event extra special."
  },
  {
    "name": "Hurda Places Near Pune",
    "description": "If you're wondering about hurda places near Pune, Shinde Agritourism is the best place to experience the authentic hurda party tradition. The farm is located in a scenic rural area, where guests can enjoy fresh hurda, traditional games, and other agricultural activities. It’s a great destination for friends, families, or corporate groups looking to experience the harvest season in a peaceful environment."
  },
  {
    "name": "One Day Hurda Party Near Pune",
    "description": "For a one day hurda party near Pune, Shinde Agritourism is the ideal place. Located just outside Pune, this farm-based destination offers a relaxing and fun-filled day of activities including fresh hurda roasting, farm games, and nature walks. It’s the perfect one-day getaway for anyone looking to enjoy the harvest festival in a traditional setting."
  },
  {
    "name": "Shinde Agri Tourism Hurda Party Places Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Hurda Party Places Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
    ],

    tableData: [
        ["- Hurda Party Near Pune Nagar Road", "- Hurda Party Places Near Pune"],
        ["- Best Hurda Party in Pune", "- Best Hurda Party Near Pune"],
        ["- Best Places for Hurda Party Near Pune", "- Hurda Party Locations Near Pune"],
        ["- Hurda Party Near Alandi Pune", "- Hurda Party Near Pune Satara Road"],
        ["- Hurda Party Places in Pune", "- Hurda Party Resort Near Pune"],
        ["- Hurda Places Near Pune", "- One Day Hurda Party Near Pune"]
      ],
      
      whychoose: [
        {
          "WhyChooseheading": "Traditional Hurda Party Experience",
          "WhyChoosedescription": "Shinde Agri Tourism offers an authentic and enjoyable Hurda Party experience. Celebrate the harvest season with the traditional 'hurda' (young jowar) served with fresh buttermilk, roasted peanuts, and other local treats, all served in the heart of nature."
        },
        {
          "WhyChooseheading": "Perfect Setting for Outdoor Fun",
          "WhyChoosedescription": "Surrounded by lush fields and scenic views, our venue is perfect for outdoor Hurda Parties. Whether you want to sit by a bonfire, enjoy traditional music, or engage in fun farm activities, the atmosphere is ideal for this lively celebration."
        },
        {
          "WhyChooseheading": "Customizable Packages for Groups",
          "WhyChoosedescription": "We offer customizable Hurda Party packages for families, friends, corporate teams, and large groups. Whether you are planning a small gathering or a large event, we can tailor the experience to meet your needs."
        },
        {
          "WhyChooseheading": "Farm Activities and Fun Games",
          "WhyChoosedescription": "While you enjoy your Hurda Party, take part in fun farm activities like bullock cart rides, tractor rides, camel rides, and more. We also offer games like tug-of-war, sack races, and traditional activities to keep the energy high and the celebrations lively."
        },
        {
          "WhyChooseheading": "Delicious Local Cuisine",
          "WhyChoosedescription": "Apart from Hurda, enjoy a feast of local Maharashtrian cuisine made from fresh ingredients sourced directly from our farm. Our menu includes traditional delicacies like pithla, bhakri, modak, and more, ensuring an authentic culinary experience."
        },
        {
          "WhyChooseheading": "Bonfire and Music",
          "WhyChoosedescription": "No Hurda Party is complete without a bonfire! Our venue is equipped with bonfire setups to add warmth and cheer to your celebration. Enjoy traditional folk music, dance, and a festive atmosphere under the stars."
        },
        {
          "WhyChooseheading": "Easy Accessibility from Pune",
          "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is a short drive away, making it the perfect destination for a Hurda Party. It's a convenient yet scenic escape from the city."
        }
      ]
      
  



  }

  const faqData = [
    {
      question: "What is a Hurda Party?",
      answer: "A Hurda Party is a traditional Maharashtrian celebration that marks the harvest of jowar (sorghum). It involves eating young jowar grains (hurda) roasted on an open flame, along with snacks like peanuts, fresh buttermilk, and seasonal treats, while enjoying the company of friends and family."
    },
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which takes about 2 to 2.5 hours by car, making it a great option for a quick getaway."
    },
    {
      question: "What activities are available during the Hurda Party?",
      answer: "In addition to enjoying the traditional Hurda meal, we offer various fun activities such as bullock cart rides, tractor rides, camel rides, outdoor games like tug-of-war and sack races, and more to keep everyone entertained."
    },
    {
      question: "Is there a bonfire during the Hurda Party?",
      answer: "Yes! We set up a bonfire to add warmth and ambiance to the Hurda Party. It’s the perfect setting for enjoying traditional music and dance in the cool evening air."
    },
    {
      question: "Can we bring our own food or drinks to the Hurda Party?",
      answer: "While we offer a full Hurda meal and traditional snacks, guests are welcome to bring their own drinks or additional snacks if they wish. We ask that you please respect the environment and dispose of trash responsibly."
    },
    {
      question: "Do you offer transportation to Shinde Agri Tourism for the Hurda Party?",
      answer: "Yes! We offer transportation options for groups traveling from Pune to Shinde Agri Tourism. You can book a cab or group transport for a comfortable journey to our venue."
    },
    {
      question: "Can I book a Hurda Party for a corporate group or large gathering?",
      answer: "Absolutely! We offer customizable packages for corporate outings, family reunions, and large groups. Our team will work with you to arrange the perfect Hurda Party experience for your group."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Rajesh & Team, Pune",
      text: "The Hurda Party at Shinde Agri Tourism was a fantastic experience! The food was delicious, and the outdoor activities made the day even more special. We had a great time with our colleagues and enjoyed the traditional fun. Highly recommend it!"
    },
    {
      name: "Miss. Priya & Family, Pune",
      text: "Our family had an unforgettable Hurda Party at Shinde Agri Tourism. From the warm bonfire to the traditional food and activities, everything was perfect. It was the perfect way to celebrate the harvest season together!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Hurda Party Places Near Pune | Best Hurda Party Locations & Resorts Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best hurda party places near Pune? Explore top locations for a memorable hurda party near Pune, including resorts and outdoor venues. Celebrate the traditional Maharashtra winter festival at Shinde Krushi Paryatan for an unforgettable experience." />
  <meta name="keywords" content="Hurda Party Near Pune, Hurda Party Places Near Pune, Best Hurda Party in Pune, Best Hurda Party Near Pune, Hurda Party Locations Near Pune, Hurda Party Near Alandi Pune, Hurda Party Near Pune Satara Road, Hurda Party Places in Pune, Hurda Party Resort Near Pune, Hurda Places Near Pune, One Day Hurda Party Near Pune, Best Places for Hurda Party Near Pune, Traditional Hurda Party Near Pune, Outdoor Hurda Party Near Pune, Hurda Party Venues Near Pune, Shinde Krushi Paryatan Hurda Party Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/hurda-party-places-near-pune" />

  {/* JSON-LD Schema for Hurda Party with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Hurda Party Near Pune",
        "url": "https://shindekrushiparyatan.com/hurda-party-places-near-pune",
        "description": "Celebrate the traditional Hurda Party at Shinde Krushi Paryatan, one of the best places for Hurda parties near Pune. Enjoy this unique experience with your friends and family at a beautiful resort with authentic rural ambiance.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/hurda-party-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/20.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Hurdaparty;