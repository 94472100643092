import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa'; // Arrow icon for dropdown

const Header = () => {
  const [expanded, setExpanded] = useState(false); // Track the navbar expansion state

  const handleToggle = () => setExpanded(!expanded); // Toggle navbar on mobile

  const handleNavItemClick = () => {
    setExpanded(false); // Close the navbar on mobile after clicking a link
  };

  return (
    <header className="main_header_area">
      <div className="header_menu" id="header_menu">
      <div className="header-top text-center" style={{
      backgroundColor:"#106B30"
      }}>
        <div className="auto-container">
          <div className="inner-container bg-redd text-white py-md-2">
            <div className="top-left">
              <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
                <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
                  <div>
                    <p className="mb-0">
                      <div className="phone-numbers d-md-flex px-md-5">
                        <a href="tel:+919356007002" className="d-block fw-bold text-white fs-">
                          <i className="fas fa-phone-alt  colored me-2"></i>+91 9356007002
                        </a>
                         <a href="tel:+919075990202" className="d-none d-md-block fw-bold text-white fs">
                          &nbsp; <i className="fas fa-phone-alt  colored me-2"></i>+91 9075990202
                        </a>
                        <a href="tel:+919075990202" className="d-block d-md-none text-white fw-bold fs">
                        <i className="fas fa-phone-alt  colored me-2"></i>+91 9075990202
                        </a>
                      </div>
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
                  {/* <FaEnvelope className="text-dark colored me-2" /> */}
                  <i class="fas fa-envelope text-white"></i>
                  <div>
                    <p className="mb-0">
                      <a href="mailto:booking@shindekrushiparyatan.com" className="d-block fw-bold text-white">&nbsp;booking@shindekrushiparyatan.com</a>
                    </p>
                  </div>
                </li>

                <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
                  <div>
                    <p className="mb-0">
                    <ul class="social-icons d-flex text-white p-0 ms-md-5 ">
  <li className='text-center'><a href="https://www.facebook.com/profile.php?id=61567764340240" target="_blank"><i class="fab fa-facebook-f text-white"></i></a></li>
  <li><a href="https://www.instagram.com/shinde_agritourism_02/" target="_blank"><i class="fab fa-instagram text-white px-4"></i></a></li>
  <li><a href="https://www.youtube.com/channel/UCIU3UC_RIAexaTv1bltND5A" target="_blank"><i class="fab fa-youtube text-white"></i></a></li>
</ul>

                    </p>
                  </div>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>

        <Navbar expanded={expanded} onToggle={handleToggle} bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <img src="/images/logo.jpg" className='logoo' alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav " className='borderr' />
            <Navbar.Collapse id="navbar-nav" >
              <Nav className="mx-auto navbarr">
                {/* Home Link */}
                <Nav.Item>
                  <Nav.Link as={Link} to="/" onClick={handleNavItemClick} className='navbarr fw-bold'>Home</Nav.Link>
                </Nav.Item>

                <NavDropdown title={<><span className='navbarr fw-bold'> About-Us</span> <FaAngleDown className='navbarr fw-bold' /></>} id="contact-dropdown" drop="down">
                  <NavDropdown.Item as={Link} to="/about" onClick={handleNavItemClick} className='navbarr fw-bold' >About</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Term-condition" onClick={handleNavItemClick} className='navbarr fw-bold'>Terms and Conditions</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Privacy-policy" onClick={handleNavItemClick} className='navbarr fw-bold'>Privacy Policy</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Refund-policy" onClick={handleNavItemClick} className='navbarr fw-bold'>Refund Policy</NavDropdown.Item>
                </NavDropdown>



                {/* Attraction Dropdown */}
                <NavDropdown 
                  title={<><span className='navbarr fw-bold'>Attraction</span> <FaAngleDown className='navbarr'/></>} 
                  id="attraction-dropdown" 
                  drop="down"
                  onClick={(e) => e.stopPropagation()} // Stop propagation to avoid closing the menu
                >
                  <NavDropdown.Item as={Link} to="/Attraction" onClick={handleNavItemClick} className='navbarr fw-bold'>
                    Attraction
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/near-places" onClick={handleNavItemClick} className='navbarr fw-bold'>
                  Nearby Places
                  </NavDropdown.Item>

                </NavDropdown>

                {/* Package Link */}
                <Nav.Item>
                  <Nav.Link as={Link} to="/package" onClick={handleNavItemClick} className='navbarr fw-bold'>Package</Nav.Link>
                </Nav.Item>

                {/* Gallery Link */}
                
                <NavDropdown title={<><span className='navbarr fw-bold'>Gallery </span> <FaAngleDown className='navbarr fw-bold' /></>} id="contact-dropdown" drop="down">
                  <NavDropdown.Item as={Link} to="/gallery" onClick={handleNavItemClick}className='navbarr fw-bold'>Photo Gallery</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/videos" onClick={handleNavItemClick} className='navbarr fw-bold'>Video Gallery</NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/360-images" onClick={handleNavItemClick} className='navbarr'>360 Gallery</NavDropdown.Item> */}
               
                </NavDropdown>
                {/* Nearby Places Link */}
                <Nav.Item>
                  <Nav.Link as={Link} to="/Activities" onClick={handleNavItemClick}className='navbarr fw-bold'>Activities</Nav.Link>
                </Nav.Item>

                {/* Ashtavinayak Darshan Stay Link */}
                <Nav.Item>
                  <Nav.Link as={Link} to="/ashtavinayak-darshan-stay" onClick={handleNavItemClick} className='navbarr fw-bold'>Ashtavinayak Darshan Stay</Nav.Link>
                </Nav.Item>

                {/* Contact Us Dropdown */}
                <NavDropdown title={<><span className='navbarr fw-bold'>Contact Us</span> <FaAngleDown className='navbarr fw-bold' /></>} id="contact-dropdown" drop="down">
                  <NavDropdown.Item as={Link} to="/contact" onClick={handleNavItemClick}className='navbarr fw-bold'>Contact</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/enquiry" onClick={handleNavItemClick} className='navbarr fw-bold'>Enquiry</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;







// import React from 'react';
// import { Link } from 'react-router-dom'; 

// const Header = () => {
//     return (
//         <header className="main_header_area">
//             <div className="header_menu" id="header_menu">
//                 <nav className="navbar navbar-default">
//                     <div className="container">
//                         <div className="navbar-flex d-flex align-items-center justify-content-between w-100 pb-3 pt-3">
//                             {/* Brand and toggle get grouped for better mobile display */}
//                             <div className="navbar-header">
//                                 <Link className="navbar-brand" to="/">
//                                     <img src="images/logo.jpg" alt="Logo" />
//                                 </Link>
//                             </div>
//                             <div className="navbar-collapse1 d-flex align-items-center" id="bs-example-navbar-collapse-1">
//                                 <ul className="nav navbar-nav" id="responsive-menu">
//                                     <li className="dropdown submenu active">
                                   

//                                     </li>
//                                     <li><Link to="/">Home</Link></li>
//                                     <li><Link to="/about">About Us</Link></li>
//                                     <li className="submenu dropdown">
//                                         <Link to="/Attraction" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
//                                         Attraction  <i className="icon-arrow-down" aria-hidden="true"></i>
//                                         </Link>
//                                         <ul className="dropdown-menu">
//                                             <li><Link to="/destination-list">Activities </Link></li>
                                          
//                                         </ul>
//                                     </li>



//                                     <li><Link to="/about">Package  </Link></li>
//                                     <li><Link to="/gallery">Gallery </Link></li>
//                                     <li><Link to="/Near-places"> Nearby Places </Link></li>
//                                     <li><Link to="/about">Ashtavinayak Darshan Stay</Link></li>


//                                     <li className="submenu dropdown">
//                                         <Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
//                                         Contact Us <i className="icon-arrow-down" aria-hidden="true"></i>
//                                         </Link>
//                                         <ul className="dropdown-menu">
//                                             <li><Link to="/contact">Contact</Link></li>
//                                             <li><Link to="/Enquiry">Enquiry </Link></li>
//                                         </ul>
//                                     </li>
                                   
                                    
//                                 </ul>
//                             </div>
                           
//                             <div id="slicknav-mobile"></div>
//                         </div>
//                     </div>
//                 </nav>
//             </div>
//         </header>
//     );
// };

// export default Header;
