
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Picnicdestination() {



  const cardData =
  {
    keyword: ' Picnic Destination Near Pune',
    heading:  'Picnic Destination Near Pune: Shinde Agri Tourism',
    headingDescription: 'For those in search of a peaceful picnic destination near Pune, Shinde Agri Tourism is the perfect escape. Surrounded by lush greenery, this agri-tourism spot provides a refreshing retreat for families, friends, or groups looking to relax and enjoy the countryside. Whether you are planning a fun day out or celebrating a special occasion, Shinde Agri Tourism offers a memorable experience for all.',

    top: 'Top Activities at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Pre-Wedding Photoshoots Amidst Nature",
          "description": "Capture cherished moments at Shinde Agri Tourism’s beautiful, rustic setting. The scenic fields and farmhouses make it an ideal spot for pre-wedding photoshoots, providing natural backdrops that add a unique charm to your special photos."
        },
        {
          "title": "Birthday Celebrations in a Serene Environment",
          "description": "Celebrate birthdays surrounded by nature's beauty at Shinde Agri Tourism. With open spaces, fresh air, and homemade food options, it’s a fantastic setting for family gatherings and kids' parties, making the day extra special with outdoor fun and relaxation."
        },
        {
          "title": "Engaging Farming Experience for All Ages",
          "description": "Dive into an authentic farming experience where visitors can learn about planting, harvesting, and animal care. This interactive activity is perfect for families, providing a fun, educational experience that fosters a deeper appreciation for rural life."
        },
        {
          "title": "Nature Walks and Bird Watching",
          "description": "For nature enthusiasts, Shinde Agri Tourism offers peaceful nature walks and bird-watching opportunities. The tranquil environment, lush landscapes, and diverse bird species create an ideal setting for relaxation and connection with nature."
        },
        {
          "title": "Outdoor Games and Picnic Areas",
          "description": "Enjoy a variety of outdoor games like cricket, badminton, and volleyball, or simply relax in the picnic areas. Shinde Agri Tourism provides plenty of space for both activity and leisure, ensuring a delightful day out for everyone."
        }
      ],

     "services": [
  {
    "name": "Picnic Destination Near Pune",
    "description": "Shinde Agritourism is one of the best picnic destinations near Pune. Situated in a peaceful rural setting, it offers a wide range of activities for families, couples, and groups. From farm tours and nature walks to animal feeding and bullock cart rides, it’s a perfect location to relax and enjoy a day outdoors, away from the hustle and bustle of the city."
  },
  {
    "name": "One Day Picnic Spot Near Pune",
    "description": "Shinde Agritourism makes for an ideal one day picnic spot near Pune. Located just a short drive from the city, the farm offers a peaceful escape where you can enjoy nature, learn about organic farming, interact with animals, and indulge in a traditional picnic surrounded by lush greenery. It’s a perfect spot to spend quality time with family or friends."
  },
  {
    "name": "One Day Resort Near Pune",
    "description": "If you’re looking for a one day resort near Pune, Shinde Agritourism is a great option. Though it’s not a conventional resort, it offers all the tranquility and beauty of a resort experience. You can enjoy a day of relaxation in the countryside, explore the farm, participate in fun activities, and have a memorable day out in nature."
  },
  {
    "name": "One Day Resort Near Pune for Family",
    "description": "Shinde Agritourism is an excellent one day resort near Pune for families. The farm provides an educational yet fun experience for all ages, from feeding animals and exploring organic farming to participating in traditional games. It’s a peaceful retreat for families to relax, bond, and enjoy a day away from the city in a rural setting."
  },
  {
    "name": "One Day Picnic Resorts Near Pune",
    "description": "If you're looking for one day picnic resorts near Pune, Shinde Agritourism offers a unique experience. The farm provides a mix of educational and recreational activities, making it an ideal destination for a one-day outing. Whether you want to relax with a picnic or engage in farm tours and games, it’s a great place to spend a day outdoors with family or friends."
  },
  {
    "name": "One Day Stay Resort Near Pune",
    "description": "For a one day stay resort near Pune, Shinde Agritourism is a great choice. While it’s not a traditional resort, it offers a peaceful rural escape where you can enjoy activities such as organic farming tours, bullock cart rides, and nature walks. It’s the perfect place to unwind, enjoy fresh organic meals, and experience rural life in a serene setting."
  },
  {
    "name": "One Day Picnic Resorts with Activities Near Pune",
    "description": "Shinde Agritourism offers one day picnic resorts with activities near Pune. The farm provides a variety of interactive experiences, including animal feeding, farm tours, and traditional games. The serene environment and educational activities make it an ideal spot for a picnic that’s both fun and informative."
  },
  {
    "name": "Resorts for Corporate Outing Near Pune",
    "description": "Shinde Agritourism is an ideal place for a corporate outing near Pune. The farm offers a peaceful environment where teams can engage in outdoor activities like nature walks, group games, and farm tours. It’s a fantastic location for team building, relaxation, and enjoying fresh, organic meals together."
  },
  {
    "name": "One Day Trip Near Pune Resort",
    "description": "Shinde Agritourism offers a fantastic one day trip near Pune resort experience. While it’s not a resort in the traditional sense, it provides a tranquil environment for relaxation and outdoor activities. Enjoy a day of fun and learning with farm tours, animal interactions, and a traditional picnic, making it an unforgettable day trip."
  },
  {
    "name": "Resorts Near Pune for One Day Trip",
    "description": "Shinde Agritourism is among the top resorts near Pune for a one day trip. The farm offers a variety of activities such as bullock cart rides, animal feeding, and organic farming tours. It’s an ideal destination for anyone looking to relax and unwind in nature, away from the city for a day."
  },
  {
    "name": "1 Day Resort Near Pune",
    "description": "For a 1 day resort near Pune, Shinde Agritourism is a wonderful option. While not a typical resort, it offers all the features of a relaxing day getaway. Spend the day exploring the farm, learning about sustainable farming practices, and enjoying a picnic in the open air. The peaceful surroundings and fresh organic food make it a great choice for a one day trip."
  },
  {
    "name": "One Day Trip Resort Near Pune",
    "description": "Shinde Agritourism is an ideal one day trip resort near Pune. The farm offers a range of activities for all ages, including organic farming tours, animal interactions, and fun games. Whether you’re looking to relax, learn, or bond with family or friends, this peaceful retreat provides the perfect backdrop for a memorable day trip."
  },
  {
    "name": "1 Day Outing Resorts Near Pune",
    "description": "If you're looking for 1 day outing resorts near Pune, Shinde Agritourism offers a fantastic rural retreat. While it isn’t a traditional resort, it provides a relaxing escape into nature with plenty of activities to keep everyone engaged. From nature walks and farm tours to traditional games and animal interactions, it's an ideal place for a day out with family or friends."
  },
  {
    "name": "One Day Picnic Resorts Near Pune for Family",
    "description": "Shinde Agritourism is a top choice for one day picnic resorts near Pune for families. The farm offers a wide variety of activities for children and adults alike, including farm tours, bullock cart rides, and animal interactions. It's a great spot for families to relax, learn, and enjoy a day together in nature."
  },
  {
    "name": "1 Day Picnic Resort Near Pune",
    "description": "For a 1 day picnic resort near Pune, Shinde Agritourism is an excellent option. The farm provides a unique rural experience where you can spend the day exploring nature, engaging in farm activities, and enjoying fresh organic meals. It's the perfect place for a picnic with family, friends, or colleagues."
  },
  {
    "name": "Best Resorts Near Pune for One Day Trip",
    "description": "Shinde Agritourism is among the best resorts near Pune for a one day trip. While it’s not a conventional resort, it offers a peaceful rural retreat with a variety of fun and educational activities. Whether you want to relax, enjoy nature, or learn about farming, it’s an ideal spot for a memorable day trip."
  },
  {
    "name": "Day Picnic Resorts Near Pune",
    "description": "Shinde Agritourism is a top choice for day picnic resorts near Pune. The farm offers a tranquil environment for you to enjoy a day of relaxation and fun. With activities like organic farming tours, animal feeding, and rural games, it's an excellent destination for a picnic with family or friends."
  },
  {
    "name": "Day Resorts Near Pune",
    "description": "For a day resort near Pune, Shinde Agritourism provides a peaceful and enjoyable experience. Spend the day exploring the farm, enjoying outdoor activities, and relaxing amidst nature. The serene environment and organic food make it an ideal place to unwind and have a relaxing day away from the city."
  },
  {
    "name": "One Day Outing Resorts Near Pune",
    "description": "Shinde Agritourism is a perfect one day outing resort near Pune. Though not a traditional resort, it offers a range of activities like farm tours, nature walks, and bullock cart rides that make for an enjoyable and educational day. It’s an excellent choice for anyone looking to escape the city and enjoy a peaceful day in nature."
  },
  {
    "name": "One Day Resort in Pune",
    "description": "If you're looking for a one day resort in Pune, Shinde Agritourism offers a unique alternative. The farm provides a peaceful setting with a variety of activities, including nature walks, animal interactions, and organic farming tours. It’s a great spot for anyone looking for a relaxing and educational one-day retreat."
  },
  {
    "name": "One Day Trip Destination Near Pune",
    "description": "Shinde Agritourism is one of the best one day trip destinations near Pune. The farm offers a variety of activities like animal feeding, farm tours, and nature walks, making it an ideal place for families, friends, or couples to enjoy a day out in nature. The peaceful rural setting provides the perfect backdrop for a relaxing day trip."
  },
  {
    "name": "One Day Stay Resorts Near Pune",
    "description": "For a one day stay resort near Pune, Shinde Agritourism is a wonderful option. The farm provides a relaxing rural experience with activities such as organic farming tours, bullock cart rides, and animal interactions. It’s the perfect place to spend a day away from the city in a peaceful and educational environment."
  },
  {
    "name": "Picnic Spot in Pune",
    "description": "Shinde Agritourism is a great picnic spot in Pune, located just outside the city. The farm offers a range of activities like nature walks, farm tours, and animal feeding, making it an ideal place for a day of relaxation and learning. It’s perfect for family picnics, corporate outings, or a day with friends."
  },
  {
    "name": "Picnic Spots Near Pune",
    "description": "For an unforgettable day out, Shinde Agritourism is one of the best picnic spots near Pune. The farm offers a serene atmosphere with plenty of activities to enjoy, including farm tours, traditional games, and animal interactions. It’s a perfect place for anyone looking to spend a peaceful day surrounded by nature."
  },
  {
    "name": "Om Agritourism Picnic Destination Near Pune",
    "description": "Om Agritourism, like Shinde Agritourism, offers a fantastic picnic destination near Pune. If you're looking for an educational and fun-filled day with family, friends, or colleagues, it provides a similar rural experience with a variety of activities that make it an ideal choice for a picnic or one-day getaway."
  }
],

tableData: [
    ["- Picnic Destination Near Pune", "- One Day Picnic Spot Near Pune"],
    ["- One Day Resort Near Pune", "- One Day Resort Near Pune for Family"],
    ["- One Day Picnic Resorts Near Pune", "- One Day Stay Resort Near Pune"],
    ["- One Day Picnic Resorts with Activities Near Pune", "- Resorts for Corporate Outing Near Pune"],
    ["- One Day Trip Near Pune Resort", "- Resorts Near Pune for One Day Trip"],
    ["- 1 Day Resort Near Pune", "- One Day Trip Resort Near Pune"],
    ["- 1 Day Outing Resorts Near Pune", "- One Day Picnic Resorts Near Pune for Family"],
    ["- 1 Day Picnic Resort Near Pune", "- Best Resorts Near Pune for One Day Trip"],
    ["- Day Picnic Resorts Near Pune", "- Day Resorts Near Pune"],
    ["- One Day Outing Resorts Near Pune", "- One Day Resort in Pune"],
    ["- One Day Trip Destination Near Pune", "- One Day Stay Resorts Near Pune"],
    ["- Picnic Spot in Pune", "- Picnic Spots Near Pune"],
    ["- Om Agritourism Picnic Destination Near Pune", "- Picnic Spot Near Pune"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Scenic & Peaceful Environment",
      "WhyChoosedescription": "Immerse yourself in the tranquility of nature. Our lush green surroundings and beautiful landscapes provide the perfect setting to relax, unwind, and enjoy quality time with family and friends."
    },
    {
      "WhyChooseheading": "Activities for All Ages",
      "WhyChoosedescription": "Whether you’re looking to explore the farm, enjoy outdoor games, or take a leisurely stroll along nature trails, Shinde Agri Tourism offers a variety of activities that everyone can enjoy. It's a great place for family bonding and fun."
    },
    {
      "WhyChooseheading": "Tasty, Authentic Maharashtrian Cuisine",
      "WhyChoosedescription": "Indulge in freshly prepared, authentic Maharashtrian dishes that are both delicious and healthy. Made from locally sourced ingredients, our meals are designed to give you a true taste of the region."
    },
    {
      "WhyChooseheading": "Safe, Clean, and Welcoming",
      "WhyChoosedescription": "We maintain high standards of hygiene and safety to ensure a comfortable and worry-free picnic experience. The cleanliness of our facilities is of the utmost importance."
    },
    {
      "WhyChooseheading": "Affordable Picnic Packages",
      "WhyChoosedescription": "Enjoy a stress-free day out without worrying about costs. Our picnic packages are designed to be affordable, making it accessible for families, groups, and corporate outings."
    },
    {
      "WhyChooseheading": "Convenient Location",
      "WhyChoosedescription": "Located just a short drive from Pune, Shinde Agri Tourism is easily accessible, allowing you to enjoy a quick getaway without spending hours on travel. It's the perfect spot for a day trip!"
    }
  ]
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which is about a 2 to 2.5-hour drive, making it a quick and easy getaway."
    },
    {
      question: "What activities are available at the picnic destination?",
      answer: "We offer a range of activities, including farm visits, nature walks, outdoor games, and scenic picnic spots. There’s plenty to keep both children and adults entertained."
    },
    {
      question: "Are meals included in the picnic package?",
      answer: "Yes! Our picnic packages include delicious Maharashtrian meals prepared with fresh, locally sourced ingredients. You can enjoy authentic regional dishes during your visit."
    },
    {
      question: "Can we bring our own food or drinks?",
      answer: "While we provide meals as part of our package, you are welcome to bring your own snacks or drinks. We just ask that you dispose of any trash responsibly and keep the environment clean."
    },
    {
      question: "Is transportation available to and from the picnic destination?",
      answer: "Yes! We offer transportation options for a smooth and comfortable ride from Pune to Shinde Agri Tourism. You can book a cab or group transport directly through us."
    },
    {
      question: "Is Shinde Agri Tourism suitable for children?",
      answer: "Absolutely! Shinde Agri Tourism is family-friendly, with activities that are perfect for kids. They will enjoy interacting with animals, exploring the farm, and participating in outdoor games."
    },
    {
      question: "How can I book a picnic package?",
      answer: "You can easily book your picnic package via our website or by calling our customer service team. We recommend booking in advance to secure your spot, especially on weekends or holidays."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Ravi Sabale",
      text: "Shinde Agri Tourism is the perfect picnic destination near Pune! We had such a wonderful time with our family, enjoying the outdoor activities and delicious food. It’s a peaceful and fun place for a quick getaway!"
    },
    {
      name: "Miss. Priya Shinde",
      text: "We had an unforgettable day at Shinde Agri Tourism! The kids loved the farm tour, and we all enjoyed the beautiful surroundings and great food. It’s the ideal place for a family picnic, and we’ll definitely be back."
    }
  ];
    






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Picnic Destination Near Pune | One Day Picnic Resorts & Family Resort Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for a picnic destination near Pune? Explore the best one day picnic resorts near Pune with activities for family, corporate outings, and more. Enjoy a relaxing day at top resorts for a memorable outing near Pune." />
  <meta name="keywords" content="Picnic Destination Near Pune, One Day Picnic Spot Near Pune, One Day Resort Near Pune, One Day Resort Near Pune for Family, One Day Picnic Resorts Near Pune, One Day Stay Resort Near Pune, One Day Picnic Resorts with Activities Near Pune, Resorts for Corporate Outing Near Pune, One Day Trip Near Pune Resort, Resorts Near Pune for One Day Trip, 1 Day Resort Near Pune, One Day Trip Resort Near Pune, 1 Day Outing Resorts Near Pune, One Day Picnic Resorts Near Pune for Family, 1 Day Picnic Resort Near Pune, Best Resorts Near Pune for One Day Trip, Day Picnic Resorts Near Pune, Day Resorts Near Pune, One Day Outing Resorts Near Pune, One Day Resort in Pune, One Day Trip Destination Near Pune, One Day Stay Resorts Near Pune, Picnic Spot in Pune, Picnic Spots Near Pune, Om Agritourism Picnic Destination Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/picnic-destination-near-pune" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Picnic Destination Near Pune",
        "url": "https://shindekrushiparyatan.com/picnic-destination-near-pune",
        "description": "Discover the best picnic destinations near Pune. Whether you're looking for a relaxing family outing or a corporate retreat, Shinde Krushi Paryatan offers one day picnic resorts with activities near Pune.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/picnic-resorts-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/16.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Picnicdestination;