
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Picnicsportnearme() {



  const cardData =
  {
    keyword: ' Picnic Spot Near Pune',
    heading: 'Picnic Spot Near Pune: Shinde Agri Tourism',
    headingDescription: 'For those seeking a refreshing picnic spot near Pune, Shinde Agri Tourism offers the ideal getaway. Nestled in the heart of nature, this agri-tourism destination provides an immersive experience combining relaxation, adventure, and cultural activities, perfect for families, groups, or anyone looking to unwind and reconnect with nature.',

    top: 'Top Activities at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Pre-Wedding Photoshoots in Scenic Surroundings",
          "description": "Shinde Agri Tourism is an excellent spot for pre-wedding photoshoots. With its lush green fields, traditional farmhouses, vibrant gardens, and rustic charm, couples can capture beautiful, natural moments. The rural backdrop creates a romantic and serene setting, ideal for timeless photos that reflect nature’s beauty and simplicity."
        },
        {
          "title": "Birthday Party Celebrations in a Natural Setting",
          "description": "Celebrate your special day with a birthday party at Shinde Agri Tourism! Enjoy a private picnic area, play games, and indulge in delicious homemade food while surrounded by nature. The open spaces are perfect for organizing games, setting up decorations, and hosting a memorable birthday event, especially for those who prefer a relaxed and scenic environment."
        },
        {
          "title": "Interactive Farming Experience for the Whole Family",
          "description": "Learn about traditional farming with hands-on activities such as planting seeds, harvesting crops, and milking cows. It’s a fun and educational experience for both kids and adults. Families can enjoy bonding while experiencing the rural way of life firsthand."
        },
        {
          "title": "Nature Walks and Bird Watching",
          "description": "Take a peaceful nature walk through the farm, breathing in fresh air and soaking up the beauty of the surroundings. Shinde Agri Tourism is home to diverse plant and animal life, making it a great spot for bird watching. The tranquil environment offers a perfect escape from the city, allowing families to reconnect with nature."
        },
        {
          "title": "Outdoor Games and Relaxing Picnic Areas",
          "description": "There’s no shortage of space for outdoor fun at Shinde Agri Tourism. Enjoy cricket, badminton, and volleyball with your family, or simply relax in the lush, well-maintained picnic areas. Whether you're playing games or lounging under the shade, the vast open areas offer plenty of room for everyone to enjoy."
        }
      ],

      services: [
        {
          "name": "Picnic Spot Near Pune",
          "description": "Shinde Agritourism is one of the top picnic spots near Pune. Located just outside the city, the farm offers a peaceful and serene environment, perfect for a family or group picnic. Whether you want to explore organic farming, engage in outdoor activities, or simply enjoy a picnic with fresh organic food, Shinde Agritourism is the ideal place for a relaxing getaway."
        },
        {
          "name": "One Day Trip Near Pune",
          "description": "For a memorable one-day trip near Pune, Shinde Agritourism offers a variety of activities for everyone. Whether you’re traveling with family, friends, or colleagues, the farm provides a perfect escape with nature walks, animal feeding, bullock cart rides, and traditional rural games. It’s an educational and fun experience, ideal for a day trip from Pune."
        },
        {
          "name": "Team Outing Places Near Pune",
          "description": "Looking for team outing places near Pune? Shinde Agritourism offers a great spot for corporate teams to unwind and bond. You can enjoy farm tours, participate in team-building activities, and indulge in traditional rural games. The tranquil surroundings and fresh air provide the perfect atmosphere for relaxation and team bonding away from the office."
        },
        {
          "name": "1 Day Picnic Spot Near Pune",
          "description": "If you're planning a one-day picnic near Pune, Shinde Agritourism is a fantastic choice. This farm-based destination offers a variety of activities including organic farming demonstrations, bullock cart rides, and nature walks. Families and friends can enjoy a fun and educational day while relaxing amidst the beautiful rural setting."
        },
        {
          "name": "One Day Picnic Spot Pune",
          "description": "Shinde Agritourism is a top pick for a one-day picnic spot in Pune. Just a short drive from the city, it offers the perfect escape with its peaceful atmosphere and a variety of activities. You can enjoy a picnic with freshly prepared farm meals, explore the farm, and engage in outdoor games, making it a perfect day out for families and friends."
        },
        {
          "name": "Best One Day Picnic Spot Near Pune",
          "description": "For the best one-day picnic spot near Pune, Shinde Agritourism is a must-visit. The farm provides a unique opportunity to spend a day in nature, with activities such as bullock cart rides, animal feeding, and organic farming tours. The peaceful rural setting and the chance to enjoy fresh farm food make it a memorable outing."
        },
        {
          "name": "Best One Day Picnic Spot in Pune",
          "description": "If you're looking for the best one-day picnic spot in Pune, Shinde Agritourism is an excellent choice. The farm is located just outside the city and offers a wide range of activities for families, friends, and even corporate teams. It’s a great place to relax, learn about sustainable farming, and enjoy a picnic in the lap of nature."
        },
        {
          "name": "Best Outing Places Near Pune",
          "description": "Shinde Agritourism is one of the best outing places near Pune. The tranquil setting offers an ideal escape from the city, where you can participate in activities like farm tours, animal interactions, and traditional rural games. Whether it's a family outing, a couple’s retreat, or a group trip, this agritourism site offers something for everyone."
        },
        {
          "name": "Best Picnic Places in Pune",
          "description": "For the best picnic places in Pune, Shinde Agritourism offers a perfect retreat. Located on the outskirts, the farm provides a peaceful environment where families can relax, explore nature, and enjoy outdoor activities. It’s an ideal picnic spot where you can experience farm life, interact with animals, and enjoy a picnic with organic farm-fresh food."
        },
        {
          "name": "Best Picnic Spot in Pune",
          "description": "Shinde Agritourism is one of the best picnic spots in Pune, offering a unique rural experience. The farm is a short drive from the city and provides a calm atmosphere for picnics, family outings, or team-building activities. Spend the day learning about sustainable farming, interacting with animals, and enjoying a traditional rural picnic."
        },
        {
          "name": "Best Picnic Spot Near Pune",
          "description": "If you are looking for the best picnic spot near Pune, Shinde Agritourism should be on your list. The farm offers a variety of fun and educational activities, including organic farming demonstrations, bullock cart rides, and animal interactions. Whether you're visiting with family, friends, or colleagues, it’s the perfect spot for a relaxing and enriching picnic."
        },
        {
          "name": "Best Picnic Spot Near Pune in Summer",
          "description": "Shinde Agritourism is the best picnic spot near Pune in summer. With its lush greenery, fresh air, and peaceful surroundings, it offers a cool escape from the summer heat. The farm provides a great opportunity for outdoor activities like nature walks, farm tours, and traditional games, making it a perfect summer destination."
        },
        {
          "name": "Best Place for Picnic in Pune",
          "description": "For the best place for a picnic in Pune, Shinde Agritourism is an ideal choice. The farm offers a variety of fun and educational activities for families, friends, and groups. Enjoy a picnic surrounded by nature, explore organic farming practices, and engage in outdoor games. It’s a perfect place to relax and enjoy quality time together."
        },
        {
          "name": "Best Place for One Day Trip Near Pune",
          "description": "Looking for the best place for a one-day trip near Pune? Shinde Agritourism is the perfect destination. The farm offers a peaceful retreat where you can enjoy nature, engage in farm activities, and indulge in fresh organic meals. It’s an ideal place for families, couples, and friends to spend the day together in a relaxing rural setting."
        },
        {
          "name": "Best Place for Picnic Near Pune",
          "description": "Shinde Agritourism is one of the best places for a picnic near Pune. The tranquil farm environment offers the perfect setting for outdoor activities, from farm tours and animal feeding to bullock cart rides. Enjoy a traditional rural picnic with fresh organic food and take in the beautiful surroundings, making it a memorable experience."
        },
        {
          "name": "Best Places for 1 Day Trip Near Pune",
          "description": "Shinde Agritourism is one of the best places for a 1-day trip near Pune. The farm offers a wide range of activities that cater to all ages, including farm tours, nature walks, and outdoor games. Whether you're looking to learn about organic farming or simply enjoy a day in nature, Shinde Agritourism is a fantastic option for a quick getaway."
        },
        {
          "name": "Best Places in Pune for Picnic",
          "description": "Shinde Agritourism ranks among the best places in Pune for a picnic. The farm offers an educational and fun-filled day out with activities like organic farming demonstrations, bullock cart rides, and animal interactions. It’s a perfect place to spend time with family and friends, enjoy fresh organic meals, and relax amidst nature."
        },
        {
          "name": "Best Places to Visit in Pune for One Day Picnic",
          "description": "If you're looking for the best places to visit in Pune for a one-day picnic, Shinde Agritourism is an excellent choice. Located just outside the city, the farm provides a peaceful environment to enjoy farm tours, participate in outdoor activities, and have a picnic surrounded by nature. It’s a wonderful destination for a relaxing and educational day trip."
        },
        {
          "name": "Best Places to Visit Near Pune for One Day Trip",
          "description": "Shinde Agritourism is one of the best places to visit near Pune for a one-day trip. The farm offers a peaceful and educational experience, perfect for families, couples, and groups. Whether you're interested in organic farming, animal interactions, or simply enjoying a day in nature, this agritourism spot is the ideal choice for a quick getaway from Pune."
        },
        {
          "name": "Couple Picnic Spot in Pune",
          "description": "For couples looking for a quiet and romantic picnic spot in Pune, Shinde Agritourism offers the perfect escape. The farm provides a peaceful atmosphere, where you can enjoy nature walks, explore the farm, and relax with a picnic in the serene countryside. It’s an idyllic spot for couples to unwind and enjoy each other's company away from the city's noise."
        },
        {
          "name": "Couple Picnic Spot Near Pune",
          "description": "Shinde Agritourism is an ideal couple picnic spot near Pune. With its tranquil setting, organic farm tours, and picturesque views, it provides a romantic and peaceful environment. You can enjoy a private picnic, engage in fun farm activities, and spend quality time together in the lap of nature."
        },
        {
          "name": "Day Picnic in Pune",
          "description": "For a relaxing day picnic in Pune, Shinde Agritourism offers a perfect setting. The farm is just a short drive away and offers a peaceful retreat with activities like nature walks, animal feeding, and farm-based games. It’s a great way to spend quality time outdoors, learn about farming, and enjoy fresh organic food."
        }
      ],

tableData: [
  ["- One Day Trip Near Pune for Family", "- One Day Picnic Spot Near Pune for Family"],
  ["- One Day Trip Near Pune for Couples", "- One Day Picnic Spot for Family Near Pune"],
  ["- Best Family Picnic Spot Near Pune", "- Family One Day Picnic Spot in Pune"],
  ["- Family Picnic Spot Near Pune", "- Family Picnic Spot in Pune"],
  ["- One Day Picnic Spot in Pune for Family", "- One Day Picnic Near Pune for Family"],
  ["- One Day Trip Near Pune in Winter with Family", "- One Day Trip Near Pune with Family in Summer"],
  ["- Pune Family Picnic Spot", "- Family One Day Trip Near Pune"],
  ["- Shinde Agri Tourism - One Day Picnic Spot Near Pune", "- Shinde Agri Tourism Family Picnic Spot Near Pune"]
],

whychoose: [
  {
    "WhyChooseheading": "Scenic & Serene Location",
    "WhyChoosedescription": "Nestled amidst lush greenery, Shinde Agri Tourism offers a peaceful environment perfect for a relaxing getaway from the hustle and bustle of city life. It’s a beautiful place to unwind and connect with nature."
  },
  {
    "WhyChooseheading": "Family-Friendly Activities",
    "WhyChoosedescription": "From nature trails and farming activities to outdoor games, there’s something for every family member. Kids can learn about sustainable farming while adults relax in a serene setting."
  },
  {
    "WhyChooseheading": "Delicious Local Cuisine",
    "WhyChoosedescription": "Enjoy authentic Maharashtrian dishes made with fresh, organic ingredients at our in-house restaurant. It’s a food lover's paradise, offering wholesome and traditional food for the whole family."
  },
  {
    "WhyChooseheading": "Safe & Clean Environment",
    "WhyChoosedescription": "We prioritize the safety and cleanliness of our environment. Our high standards ensure that every family member enjoys a safe and hygienic outing while having fun."
  },
  {
    "WhyChooseheading": "Affordable Packages",
    "WhyChoosedescription": "Our competitive pricing ensures you get a memorable experience without breaking the bank. With packages that cater to different needs and budgets, we offer an enriching outing for families at affordable rates."
  },
  {
    "WhyChooseheading": "Easy Accessibility from Pune",
    "WhyChoosedescription": "Just a short drive from Pune, Shinde Agri Tourism is a convenient and easy-to-reach location for families looking to escape the city for a day. A quick getaway that doesn’t require much travel time."
  }
]




  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which translates to a 2 to 2.5-hour drive, depending on traffic conditions."
    },
    {
      question: "What activities can we enjoy at the picnic spot?",
      answer: "We offer a variety of activities such as farm visits, nature walks, outdoor games, and relaxing picnic spots. Whether you want to explore, learn about farming, or just relax, there’s something for everyone."
    },
    {
      question: "Are meals included in the picnic package?",
      answer: "Yes! We serve a variety of authentic Maharashtrian meals as part of the picnic package. All our dishes are made with fresh, local ingredients."
    },
    {
      question: "Can we bring our own food and drinks?",
      answer: "While we provide delicious meals, guests are welcome to bring their own snacks or drinks. However, we ask that you please dispose of trash responsibly and respect the environment."
    },
    {
      question: "Is transportation available for the picnic?",
      answer: "Yes, we offer transportation options from Pune to Shinde Agri Tourism. You can easily book a cab or group transport through us."
    },
    {
      question: "Is this picnic spot suitable for children?",
      answer: "Absolutely! Our picnic spot is ideal for children. They can enjoy activities like animal interactions, nature exploration, and outdoor games in a safe, fun environment."
    },
    {
      question: "How do I book a picnic package?",
      answer: "You can book your picnic spot directly through our website or by calling our customer service team. We recommend booking in advance to ensure availability, especially on weekends."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Neha & Family, Pune",
      text: "Our family had an amazing time at Shinde Agri Tourism! It’s the perfect picnic spot near Pune — peaceful, fun, and full of activities for the kids. The food was also fantastic. Highly recommend it for a family outing!"
    },
    {
      name: "Mr. Suresh & Family, Pune",
      text: "A perfect day trip! We enjoyed the farm tour, nature walks, and the delicious food. Shinde Agri Tourism offers everything you need for a relaxing and fun picnic. We’ll definitely be coming back!"
    }
  ];
  






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Picnic Spot Near Pune | One Day Picnic Spots & Outing Places Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best picnic spot near Pune? Explore the top picnic spots for family, couples, and group outings. Enjoy a memorable one day picnic near Pune, with beautiful nature, activities, and perfect spots for team outings and family trips." />
  <meta name="keywords" content="Picnic Spot Near Pune, One Day Trip Near Pune, Team Outing Places Near Pune, 1 Day Picnic Spot Near Pune, Best One Day Picnic Spot Near Pune, Best Outing Places Near Pune, Best Picnic Places in Pune, Best Picnic Spot in Pune, Best Picnic Spot Near Pune in Summer, Best Place for Picnic in Pune, Best Place for One Day Trip Near Pune, Best Places for 1 Day Trip Near Pune, Best Places in Pune for Picnic, Best Places to Visit in Pune for One Day Picnic, Best Places to Visit Near Pune for One Day Trip, Couple Picnic Spot in Pune, Couple Picnic Spot Near Pune, Day Picnic in Pune, Family Picnic Spot Pune, Good Picnic Spots Near Pune, Group Picnic Spots Near Pune, Nearby Pune Picnic Spot" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/picnic-spot-near-pune" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Picnic Spot Near Pune",
        "url": "https://shindekrushiparyatan.com/picnic-spot-near-pune",
        "description": "Discover the best picnic spots near Pune for family, friends, and team outings. Enjoy a day trip with a variety of activities and scenic spots for one day picnics, group events, and romantic getaways.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/picnic-spot-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/15.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Picnicsportnearme;