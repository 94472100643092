
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Schoolpivnicsport() {



  const cardData =
  {
    keyword: ' School Picnic Spots Near Pune for Students',
    heading:  'School Picnic Spots Near Pune for Students: Shinde Agri Tourism',
    headingDescription: 'Looking for a school picnic spot near Pune that offers a blend of fun, education, and nature? Shinde Agri Tourism is the ideal destination for school picnics. Set in the heart of rural Pune, this agri-tourism spot provides a safe and engaging environment for students to learn about agriculture, enjoy outdoor activities, and experience the beauty of nature.',

    top: 'Top Reasons to Choose Shinde Agri Tourism for School Picnics	',
    topPlaces: [
        {
          "title": "Educational Farming Activities",
          "description": "Shinde Agri Tourism offers students a hands-on experience with traditional farming activities. From planting crops to milking cows and harvesting, students can learn about agriculture in a fun and interactive way. It’s an excellent opportunity for students to connect with nature and learn about sustainable farming practices."
        },
        {
          "title": "Nature Walks and Wildlife Observation",
          "description": "The lush landscapes of Shinde Agri Tourism are perfect for nature walks, where students can explore the flora and fauna of the region. Bird watching and observing wildlife in a natural setting provide students with a real-world learning experience, fostering an appreciation for the environment."
        },
        {
          "title": "Outdoor Games and Team-Building Activities",
          "description": "Shinde Agri Tourism offers a variety of outdoor games and activities that are perfect for school groups. Whether it’s cricket, badminton, volleyball, or team-building games, students can enjoy friendly competition and teamwork in a spacious and open environment."
        },
        {
          "title": "Picnic Areas and Relaxing Spots",
          "description": "After a day full of activities, students can relax in the peaceful picnic areas at Shinde Agri Tourism. Surrounded by nature, these spots are perfect for resting, enjoying a packed lunch, and unwinding after a fun-filled day. The serene atmosphere offers a much-needed break from the hustle and bustle of school life."
        },
        {
          "title": "Bonfire and Evening Entertainment",
          "description": "End the day with a cozy bonfire session where students can sit together, enjoy music, and share stories. This offers a perfect opportunity for bonding and creating lasting memories while enjoying the warmth of a traditional rural setting."
        },
        {
          "title": "Safe and Supervised Environment",
          "description": "Shinde Agri Tourism provides a safe and well-supervised environment for school picnics. The team ensures that all students are well taken care of and have a wonderful time exploring the various activities on offer."
        }
      ],

    "services": [
  {
    "name": "School Picnic Spots Near Pune for Students",
    "description": "For a memorable school picnic near Pune, Shinde Agritourism offers a perfect destination that combines fun, learning, and nature. Located just outside Pune, this agritourism spot is ideal for school trips. Students can enjoy farm activities, bullock cart rides, and traditional games that promote teamwork and learning about rural life. The natural setting offers a safe and enjoyable environment for students to explore and bond."
  },
  {
    "name": "Picnic Spot Near Pune",
    "description": "If you’re searching for a picnic spot near Pune, Shinde Agritourism is an excellent choice for families, friends, or school groups. Set amidst nature, the farm offers a variety of activities like organic farming tours, animal feeding, and outdoor games. It’s a peaceful and educational place to spend the day and relax while enjoying fresh, organic meals from the farm."
  },
  {
    "name": "One Day Trip Near Pune",
    "description": "Shinde Agritourism is the perfect location for a one-day trip near Pune. Whether you’re looking for a family outing, a day trip with friends, or a school excursion, the farm provides a unique experience with activities that highlight rural life. It’s a refreshing change from the usual one-day trip destinations, offering plenty of space to explore and enjoy nature."
  },
  {
    "name": "One Day Picnic Spot Near Pune",
    "description": "If you’re looking for a one-day picnic spot near Pune, Shinde Agritourism offers an ideal setting. With activities like nature walks, organic farming tours, and traditional farm games, it’s an excellent place for a quick getaway. You can enjoy a day filled with fun and learning while surrounded by the tranquility of nature."
  },
  {
    "name": "2 Day Trip Near Pune with Family",
    "description": "For a 2-day trip near Pune with family, Shinde Agritourism provides a relaxing countryside escape. While you enjoy a peaceful day with activities like animal interactions and farm-based games, you can also spend the night at the farm and immerse yourself in a truly rural experience. The fresh air, open spaces, and farm activities make it a great place to unwind and bond with your loved ones."
  },
  {
    "name": "1 Day Picnic Spot Near Pune",
    "description": "Shinde Agritourism is the perfect 1-day picnic spot near Pune for those looking for a peaceful day in nature. The farm offers a variety of educational and fun activities, from organic farming demonstrations to bullock cart rides. It’s an excellent choice for those who want to relax and connect with nature while enjoying farm-fresh meals."
  },
  {
    "name": "School Picnic Spots in Pune",
    "description": "If you’re looking for school picnic spots in Pune, Shinde Agritourism is an excellent choice. The farm offers a safe and educational environment for students to learn about organic farming, engage with animals, and participate in traditional outdoor games. It’s a fun and enriching experience for school students, combining education with outdoor adventure."
  },
  {
    "name": "Picnic Spots for School Students",
    "description": "For picnic spots for school students, Shinde Agritourism provides the perfect rural experience. Located near Pune, it offers a range of activities such as farm tours, bullock cart rides, and nature walks. These activities not only entertain but also teach students about the environment, agriculture, and rural life. It’s a perfect mix of fun and learning for school groups."
  },
  {
    "name": "Picnic Spot for School Students in Pune",
    "description": "If you’re looking for a picnic spot for school students in Pune, Shinde Agritourism offers a safe and fun environment. Students can participate in various educational activities, such as learning about organic farming and interacting with farm animals. It’s an excellent location for school picnics where children can enjoy outdoor games and fresh farm food while learning about rural life."
  },
  {
    "name": "Picnic Spot for Students",
    "description": "For school groups seeking a picnic spot for students, Shinde Agritourism provides an ideal rural setting. Students can engage in farm-based activities like planting seeds, harvesting crops, and participating in bullock cart rides. These hands-on activities teach valuable lessons in agriculture and teamwork while also allowing the students to enjoy nature and fresh air."
  },
  {
    "name": "Picnic Spots for School Students Near Me",
    "description": "For those searching for picnic spots for school students near me, Shinde Agritourism is a great option. Just a short distance from Pune, this agritourism farm offers a unique experience with its hands-on farming activities and outdoor fun. It’s an excellent choice for educational trips that blend learning with entertainment."
  },
  {
    "name": "Picnic Spot for School Students in Pimpri Chinchwad",
    "description": "Shinde Agritourism is located close to Pimpri Chinchwad, making it an ideal picnic spot for school students in Pimpri Chinchwad. The farm offers a range of activities perfect for school groups, including animal feeding, nature walks, and traditional rural games. It’s a great place for students to learn about agriculture, engage in fun activities, and bond with their classmates."
  },
  {
    "name": "School Picnic Spots in Pimpri Chinchwad",
    "description": "If you’re looking for school picnic spots in Pimpri Chinchwad, Shinde Agritourism is a perfect choice. The farm is just a short drive from Pimpri Chinchwad and offers a peaceful environment for students to engage in educational activities while having fun. From farm tours to organic farming lessons, it’s an enriching experience that blends education with outdoor adventure."
  },
  {
    "name": "School Picnic Spots in Kharadi",
    "description": "Shinde Agritourism is also conveniently located near Kharadi, making it an excellent choice for school picnic spots in Kharadi. The farm offers an immersive rural experience, where students can learn about organic farming, enjoy traditional games, and explore nature. It’s a great destination for school outings that combine education with fun outdoor activities."
  },
  {
    "name": "Picnic Spots Around Pune for School Students",
    "description": "When looking for picnic spots around Pune for school students, Shinde Agritourism stands out as a great option. The farm offers a variety of activities like animal interactions, farm tours, and outdoor games that are perfect for school groups. The peaceful environment and educational experiences make it an ideal destination for school picnics."
  },
  {
    "name": "Shinde Agri Tourism School Picnic Spots Near Pune for Students Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect School Picnic Spot Near Pune for Students. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- School Picnic Spots Near Pune for Students", "- Picnic Spot Near Pune"],
    ["- One Day Trip Near Pune", "- One Day Picnic Spot Near Pune"],
    ["- 2 Day Trip Near Pune with Family", "- 1 Day Picnic Spot Near Pune"],
    ["- School Picnic Spots in Pune", "- Picnic Spots for School Students"],
    ["- Picnic Spot for School Students in Pune", "- Picnic Spot for Students"],
    ["- Picnic Spots for School Students Near Me", "- Picnic Spot for School Students in Pune"],
    ["- Picnic Spot for School Students in Pimpri Chinchwad", "- School Picnic Spots in Pimpri Chinchwad"],
    ["- School Picnic Spots in Kharadi", "- Picnic Spots Around Pune for School Students"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Educational and Fun Activities",
      "WhyChoosedescription": "Shinde Agri Tourism offers an enriching experience that combines outdoor adventure with hands-on learning for students. Here, children can learn about the farming process by participating in activities like planting, harvesting crops, milking cows, and learning about sustainable farming practices. This immersive experience fosters a deeper understanding of nature, agriculture, and sustainability, making the trip both fun and educational. It's an ideal way for students to connect with the environment while enhancing their knowledge in a practical, enjoyable way."
    },
    {
      "WhyChooseheading": "Perfect for Group Activities",
      "WhyChoosedescription": "With our large, open grounds, Shinde Agri Tourism provides the perfect space for school groups to engage in team-building exercises, games, and outdoor sports. Our facility encourages collaborative learning and teamwork, helping students develop social skills and leadership qualities while having fun. Whether through structured games or free play, our activities help foster a spirit of cooperation and communication, making the picnic an enriching experience for all participants."
    },
    {
      "WhyChooseheading": "Farm and Nature Walks",
      "WhyChoosedescription": "A highlight of the trip is the guided farm and nature walks that offer students a chance to get up close and personal with the natural world. Our knowledgeable guides will take the students through lush farmland, introducing them to various crops and farm animals. These interactive walks not only allow students to explore the wonders of nature but also teach them valuable lessons in agriculture, animal husbandry, and ecology. It's a wonderful way for students to connect with the environment and learn about biodiversity in a fun and interactive setting."
    },
    {
      "WhyChooseheading": "Picnic-Friendly Areas",
      "WhyChoosedescription": "Shinde Agri Tourism offers designated picnic areas where students can relax and enjoy their packed snacks or meals in a peaceful, scenic setting. These spots are surrounded by nature, offering plenty of shade under trees or by a pond where students can unwind and socialize. These picnic zones are designed to provide a restful break during their visit, allowing students to enjoy the fresh air and scenic views while taking a moment to relax and recharge before heading back to more activities."
    },
    {
      "WhyChooseheading": "Safe and Supervised Environment",
      "WhyChoosedescription": "We prioritize the safety and well-being of every student. Our trained staff and guides ensure that all activities are supervised, with safety protocols in place at all times. Teachers and parents can rest assured knowing that their students are in good hands. Our facilities are well-maintained, and we ensure that there are clear instructions and rules for all activities. Whether students are exploring the farm, playing games, or enjoying the picnic areas, they will always be closely supervised in a secure and supportive environment."
    },
    {
      "WhyChooseheading": "Delicious and Nutritious Meals",
      "WhyChoosedescription": "Shinde Agri Tourism provides a selection of nutritious, kid-friendly meals made from locally sourced, fresh ingredients. From tasty snacks to wholesome meals, our food is prepared with care to keep students energized throughout the day. We offer traditional Maharashtrian dishes, as well as customizable menus to accommodate dietary preferences or restrictions. Whether it’s a hearty lunch or light snacks, our meals are both delicious and designed to meet the nutritional needs of active students."
    },
    {
      "WhyChooseheading": "Convenient Location",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is an easy 2 to 2.5-hour drive, making it the perfect destination for a day trip. The peaceful, rural setting provides a welcome escape from the hustle and bustle of the city, offering students a chance to experience nature up close. Its proximity to Pune ensures a hassle-free journey, allowing students to enjoy a fun and educational outing without long travel times. The scenic drive and tranquil atmosphere make it an ideal getaway for a school picnic."
    }
  ]
  
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located around 100 km from Pune, approximately a 2 to 2.5-hour drive, making it a perfect destination for a day picnic."
    },
    {
      question: "What activities are available for school groups?",
      answer: "We offer a variety of activities including farm tours, nature walks, animal interactions, team-building games, sports, and more. Students can also participate in hands-on farming activities like harvesting crops and milking cows."
    },
    {
      question: "Is the environment safe for students?",
      answer: "Yes! Safety is our top priority. Our staff is trained to supervise students throughout their visit, and we ensure all activities are conducted safely. Our venue is well-maintained and ideal for school groups."
    },
    {
      question: "Can you customize meals for students?",
      answer: "Yes! We offer a variety of meal options including vegetarian, non-vegetarian, and kid-friendly meals. All meals are prepared using fresh, locally sourced ingredients, and we can customize the menu based on dietary preferences and needs."
    },
    {
      question: "Are there options for outdoor games and team-building activities?",
      answer: "Absolutely! We have plenty of open space for outdoor games such as tug-of-war, sack races, and relay races. These activities promote teamwork, coordination, and healthy competition among students."
    },
    {
      question: "Is there a place for students to rest or have snacks?",
      answer: "Yes! We have dedicated picnic areas where students can relax, enjoy snacks, and take a break. These areas are shaded, comfortable, and surrounded by nature, providing a peaceful spot to unwind."
    },
    {
      question: "Can we book a school picnic for a large group?",
      answer: "Yes! We offer customized packages for large groups, including school picnics. Our team will work with you to tailor the activities, meals, and overall experience to suit your school’s needs."
    }
  ];
  
  const testimonials = [
    {
      name: "Mrs. Shalini",
      text: "Shinde Agri Tourism was the perfect spot for our school picnic! The students had an amazing time learning about farming, interacting with animals, and enjoying outdoor games. It was both fun and educational, and the meals were delicious!"
    },
    {
      name: "Mr. Rahul",
      text: "Our school picnic at Shinde Agri Tourism was an unforgettable experience! The kids loved the farm activities, and the team-building games were a great way to get everyone involved. The food was fresh and healthy, and the setting was beautiful. We can’t wait to visit again!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | School Picnic Spots Near Pune for Students | Best Picnic Spots for School Students Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for school picnic spots near Pune? Explore the best one-day and two-day picnic spots for school students in and around Pune. Plan a fun and educational trip for students with activities and nature." />
  <meta name="keywords" content="School Picnic Spots Near Pune for Students, Picnic Spot Near Pune, One Day Picnic Spot Near Pune, School Picnic Spots in Pune, Picnic Spots for School Students, Picnic Spot for Students in Pune, School Picnic Spots in Pimpri Chinchwad, Picnic Spot for School Students in Kharadi, School Picnic Spots Around Pune, Picnic Spots for Students Near Me, Best Picnic Spots for Students in Pune, Picnic Spots for Students Near Pune, School Picnic Locations Pune, One Day Trip Near Pune for Students" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/school-picnic-spots-near-pune-for-students" />

  {/* JSON-LD Schema for School Picnic Spot with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - School Picnic Spots Near Pune for Students",
        "url": "https://shindekrushiparyatan.com/school-picnic-spots-near-pune-for-students",
        "description": "Explore the best picnic spots for school students near Pune. Plan a memorable school picnic with fun activities, games, and beautiful nature at Shinde Krushi Paryatan.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/school-picnic-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/22.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Schoolpivnicsport;