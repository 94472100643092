
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Onedattripnear() {



  const cardData =
  {
    keyword: ' One-Day Trip Near Pune',
    heading: 'One-Day Trip Near Pune: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for a refreshing one-day trip near Pune, Shinde Agri Tourism is the perfect getaway. Situated amidst natures beauty, this agri-tourism spot offers a perfect blend of relaxation and adventure for families, groups, or those looking to celebrate a special occasion. Whether it is spending quality time with family or hosting an event, Shinde Agri Tourism offers an unforgettable experience.',

    top: 'Top Activities at Shinde Agri Tourism	',
    topPlaces: [
        {
            "title": "Pre-Wedding Photoshoots in Scenic Surroundings",
            "description": "Shinde Agri Tourism is an ideal spot for pre-wedding photoshoots, with its beautiful green fields, charming farmhouses, and rustic environment. Capture stunning moments with your partner amid nature's beauty, making your special day even more memorable with picturesque backdrops."
        },
        {
            "title": "Birthday Party Celebrations in a Natural Setting",
            "description": "Celebrate your special day surrounded by nature at Shinde Agri Tourism. Whether you’re organizing a family gathering or a kid's birthday party, the serene surroundings, open spaces, and fresh air provide the perfect setting for a fun and relaxed celebration. Enjoy delicious homemade food and engage in outdoor games to make the day even more special."
        },
        {
            "title": "Interactive Farming Experience for All Ages",
            "description": "Dive into traditional farming activities and enjoy the authentic rural experience. Learn about planting, harvesting, milking cows, and other farming techniques. This hands-on activity is both fun and educational, making it an excellent way for families to bond and appreciate nature’s wonders."
        },
        {
            "title": "Nature Walks and Bird Watching",
            "description": "For those who enjoy nature and serenity, Shinde Agri Tourism offers peaceful nature walks and bird watching opportunities. With lush landscapes and an array of bird species, you can relax and unwind while connecting with the environment. The peaceful atmosphere is perfect for escaping the city's hustle and bustle."
        },
        {
            "title": "Outdoor Games and Relaxing Picnic Areas",
            "description": "Whether you're a fan of sports or simply want to relax, Shinde Agri Tourism has it all. Enjoy outdoor games such as cricket, badminton, and volleyball, or simply unwind in the well-maintained picnic areas. With plenty of space to enjoy outdoor fun or a peaceful afternoon, the family-friendly environment ensures everyone has a great time."
        }
    ],
"services": [
    {
        "name": "One Day Picnic Spot Near Pune",
        "description": "Shinde Agritourism is one of the best one-day picnic spots near Pune. Surrounded by lush greenery and a peaceful environment, it offers a variety of activities such as organic farming tours, animal feeding, and nature walks. Whether you're looking to spend a relaxing day or engage in interactive activities, Shinde Agritourism has something for everyone. It’s the perfect spot to escape the hustle of city life and connect with nature."
    },
    {
        "name": "One Day Stay Trip Near Pune",
        "description": "If you’re looking for a one-day stay trip near Pune, Shinde Agritourism provides a great option. The serene farm offers overnight stays in eco-friendly accommodations, where you can enjoy fresh farm-to-table meals, engage in outdoor activities, and unwind in nature. It’s a perfect getaway to refresh your mind, body, and spirit while still being close to Pune."
    },
    {
        "name": "1 Day Trip Near Pune",
        "description": "Shinde Agritourism is an ideal 1-day trip destination near Pune. Located within easy reach, this agritourism site offers a peaceful escape with activities like farm tours, bullock cart rides, and animal feeding. The whole family can enjoy an educational yet fun day amidst nature, making it the perfect one-day getaway."
    },
    {
        "name": "One Day Outing Near Pune",
        "description": "For a delightful one-day outing near Pune, Shinde Agritourism is an excellent choice. The farm offers a range of interactive activities, such as nature walks, organic farming demonstrations, and traditional games. It's a great way to spend time with family and friends, enjoying the beauty of nature while learning about rural life."
    },
    {
        "name": "1 Day Picnic Spot Near Pune",
        "description": "Shinde Agritourism is one of the top 1-day picnic spots near Pune. Perfect for families, couples, or groups of friends, the farm provides a variety of activities to make your day enjoyable. You can explore the farm, participate in fun games, feed animals, and enjoy a peaceful picnic amidst nature."
    },
    {
        "name": "Day Outing Near Pune",
        "description": "A day outing at Shinde Agritourism near Pune is an ideal way to relax and unwind. The farm offers an enriching experience with activities such as learning about sustainable farming practices, interacting with farm animals, and enjoying nature walks. It's a refreshing day out for anyone looking to disconnect from the stresses of daily life."
    },
    {
        "name": "One Day Trip Near Pune Within 50 km",
        "description": "Located within 50 km of Pune, Shinde Agritourism makes for an easy and relaxing one-day trip. The farm offers a peaceful retreat with educational activities such as organic farming, animal feeding, and bullock cart rides. Whether you're seeking a fun day with the kids or a romantic getaway, Shinde Agritourism is a great option."
    },
    {
        "name": "One Day Picnic Spot Pune",
        "description": "Shinde Agritourism is a perfect one-day picnic spot in Pune, offering a blend of fun, education, and nature. Families can spend the day exploring the farm, playing traditional rural games, enjoying fresh organic food, and learning about farm life. It’s a great way to enjoy a peaceful day out within reach of the city."
    },
    {
        "name": "1 Day Stay Trip Near Pune",
        "description": "If you’re looking for a 1-day stay trip near Pune, Shinde Agritourism offers the perfect escape. With comfortable accommodations and activities that allow you to connect with nature, it’s an ideal destination for anyone seeking a short staycation. Relax, unwind, and enjoy the beauty of the countryside with farm-fresh meals and scenic surroundings."
    },
    {
        "name": "Places Near Pune for One Day Trip",
        "description": "Shinde Agritourism is one of the top places near Pune for a one-day trip. Located just a short drive from the city, it offers an array of activities such as farm tours, animal interactions, and bullock cart rides. It’s the perfect place for families and friends to relax, learn about farming, and enjoy nature in a peaceful rural setting."
    },
    {
        "name": "1 Day Destination Near Pune",
        "description": "Shinde Agritourism is a must-visit 1-day destination near Pune. The farm offers a variety of activities, including organic farming demonstrations, nature walks, and animal feeding, making it a perfect destination for a day trip. Enjoy fresh, organic meals and experience rural life up close in this tranquil countryside retreat."
    },
    {
        "name": "1 Day Outing Near Pune",
        "description": "Looking for a 1-day outing near Pune? Shinde Agritourism is an excellent option. The farm offers a peaceful escape with fun activities for the whole family. From nature walks and farm tours to traditional games and animal interactions, it's an educational and relaxing way to spend the day together."
    },
    {
        "name": "1 Day Outing in Pune",
        "description": "For those who want to stay within Pune, Shinde Agritourism offers a great 1-day outing experience. Located just on the outskirts, it provides the perfect setting for families to enjoy outdoor activities like nature walks, farm games, and animal feeding. It’s an ideal day trip for anyone looking to reconnect with nature while staying close to the city."
    },
    {
        "name": "1 Day Tour Near Pune",
        "description": "Shinde Agritourism is an ideal choice for a 1-day tour near Pune. The farm offers various activities like bullock cart rides, farm tours, and nature walks. It’s a great spot to learn about organic farming, interact with animals, and enjoy a peaceful day in the countryside. The perfect location for a quick day trip from the city."
    },
    {
        "name": "1 Day Trip in Pune",
        "description": "For a relaxing 1-day trip in Pune, Shinde Agritourism is an excellent choice. Located on the outskirts of Pune, the farm provides a peaceful environment where you can enjoy activities like nature walks, organic farming demonstrations, and traditional rural games. It's a fantastic option for anyone wanting to spend a day outdoors in Pune."
    },
    {
        "name": "Day Trip Near Pune",
        "description": "Shinde Agritourism offers a wonderful day trip near Pune. The farm provides an array of activities suitable for all ages, including organic farming tours, animal interactions, and bullock cart rides. Whether you're going on a family outing or a peaceful getaway, the farm offers the perfect setting for a day of fun and relaxation."
    },
    {
        "name": "Near Places to Pune for 1 Day Trip",
        "description": "Looking for near places to Pune for a 1-day trip? Shinde Agritourism is a great option. The farm is located just a short drive from Pune, offering activities such as farm tours, animal feeding, and nature walks. It’s an ideal place to spend a day in the countryside, learning about farming and enjoying the fresh air."
    },
    {
        "name": "Om Agritourism One Day Trip Near Pune",
        "description": "If you’re considering an agritourism experience, Shinde Agritourism is a perfect one-day trip near Pune. It offers various activities like organic farming demonstrations, bullock cart rides, and nature walks. For those looking for a peaceful and educational getaway, Shinde Agritourism provides the ideal escape from the busy city life."
    },
    {
        "name": "Shinde Agri Tourism One Day Trip Near Pune Contact Number",
        "description": "Escape to nature with Shinde Agri Tourism for a perfect One Day Trip Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
],
tableData: [
    ["- One Day Picnic Spot Near Pune", "- One Day Stay Trip Near Pune"],
    ["- 1 Day Trip Near Pune", "- One Day Outing Near Pune"],
    ["- 1 Day Picnic Spot Near Pune", "- Day Outing Near Pune"],
    ["- One Day Trip Near Pune Within 50 Km", "- One Day Picnic Spot Pune"],
    ["- 1 Day Stay Trip Near Pune", "- Places Near Pune for One Day Trip"],
    ["- 1 Day Destination Near Pune", "- 1 Day Outing Near Pune"],
    ["- 1 Day Tour Near Pune", "- 1 Day Trip in Pune"],
    ["- Day Trip Near Pune", "- Near Places to Pune for 1 Day Trip"],
    ["- Om Agritourism One Day Trip Near Pune", "- Shinde Agri Tourism One Day Trip Near Pune"]
],

whychoose: [
    {
      "WhyChooseheading": "Beautiful Natural Setting",
      "WhyChoosedescription": "Surrounded by nature’s beauty, Shinde Agri Tourism provides a peaceful environment where you can unwind, breathe fresh air, and reconnect with the outdoors. A perfect escape from city life."
    },
    {
      "WhyChooseheading": "Engaging Activities for All Ages",
      "WhyChoosedescription": "From farm visits and nature trails to outdoor games and relaxing picnic spots, we offer activities that cater to every family member. It’s the ideal place for both adventure seekers and those looking for a calm retreat."
    },
    {
      "WhyChooseheading": "Authentic Local Cuisine",
      "WhyChoosedescription": "Indulge in delicious Maharashtrian cuisine, freshly prepared with locally sourced ingredients. Whether you're a food enthusiast or simply craving regional flavors, our meals are sure to satisfy your palate."
    },
    {
      "WhyChooseheading": "Safe & Clean Environment",
      "WhyChoosedescription": "Your safety and comfort are our priorities. We maintain high hygiene standards and ensure that our guests enjoy a secure and pleasant experience throughout their visit."
    },
    {
      "WhyChooseheading": "Affordable Packages",
      "WhyChoosedescription": "We offer budget-friendly pricing for families and groups, ensuring that you can enjoy a quality experience without breaking the bank. Our packages provide excellent value for money."
    },
    {
      "WhyChooseheading": "Convenient Location",
      "WhyChoosedescription": "Located just a short drive from Pune, Shinde Agri Tourism is the ideal destination for a quick and convenient one-day trip. Spend more time enjoying nature and less time traveling."
    }
]





  }

  const faqData = [
    {
        question: "How far is Shinde Agri Tourism from Pune?",
        answer: "Shinde Agri Tourism is approximately 100 km from Pune, which takes about 2 to 2.5 hours by car."
    },
    {
        question: "What kind of activities are available during the day trip?",
        answer: "Our day trips include activities like nature walks, farm tours, outdoor games, and opportunities to enjoy the fresh air and beautiful surroundings. There’s something for every age group."
    },
    {
        question: "Are meals included in the one-day trip package?",
        answer: "Yes! We offer a variety of local dishes as part of our day trip package. All meals are made from fresh, organic ingredients."
    },
    {
        question: "Can we bring our own food or drinks?",
        answer: "While we provide delicious meals on-site, guests are welcome to bring their own snacks and drinks if they wish, but we ask that you respect the environment and dispose of trash properly."
    },
    {
        question: "Is transportation available for the day trip?",
        answer: "Yes! We offer convenient transportation options from Pune to Shinde Agri Tourism. You can book a cab or group transport through us for a comfortable ride."
    },
    {
        question: "Is it suitable for children and elderly guests?",
        answer: "Absolutely! Our activities are designed to be family-friendly, and there are plenty of relaxed areas for elderly guests to enjoy the scenery. Kids will love the farm and the outdoor games."
    },
    {
        question: "How do I book a day trip?",
        answer: "You can book your one-day trip directly through our website or by calling our customer service. We recommend booking in advance to ensure availability."
    }
];

const testimonials = [
    {
        name: "Ms. Ananya & Family, Pune",
        text: "The perfect family day trip from Pune! We had such a relaxing and fun day at Shinde Agri Tourism. The kids loved the farm activities, and we enjoyed the delicious food and peaceful surroundings. Can’t wait to return!"
    },
    {
        name: "Mr. Vishal & Family, Pune",
        text: "A fantastic day trip just outside Pune! We spent the whole day exploring nature, playing games, and enjoying great food. Shinde Agri Tourism is a wonderful place to relax with loved ones."
    }
];







  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | One Day Trip Near Pune | Best Day Outing & Picnic Spot Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for a one day trip near Pune? Explore the best one day picnic spots, stay trips, and day outings within 50 km of Pune. Enjoy a memorable trip with family and friends to nearby destinations. Plan your perfect one day trip today!" />
  <meta name="keywords" content="One Day Trip Near Pune, One Day Picnic Spot Near Pune, One Day Stay Trip Near Pune, 1 Day Trip Near Pune, One Day Outing Near Pune, 1 Day Picnic Spot Near Pune, Day Outing Near Pune, 1 Day Destination Near Pune, Places Near Pune for One Day Trip, One Day Trip in Pune, One Day Tour Near Pune, Om Agritourism One Day Trip Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/one-day-trip-near-pune" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - One Day Trip Near Pune",
        "url": "https://shindekrushiparyatan.com/one-day-trip-near-pune",
        "description": "Explore one day picnic spots and stay trips near Pune. Enjoy memorable outings and trips within 50 km of Pune, with family-friendly activities and beautiful destinations. Plan your perfect day trip with Shinde Krushi Paryatan.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/one-day-trip-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/14.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Onedattripnear;