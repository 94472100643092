
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Campingplaces() {



  const cardData =
  {
    keyword: ' Camping Places Near Pune',
    heading:  'Camping Places Near Pune: Shinde Agri Tourism',
    headingDescription: 'Shinde Agri Tourism offers a unique and refreshing camping experience near Pune. Nestled amidst the serene landscapes of rural Maharashtra, this agri-tourism destination provides a perfect escape for nature lovers, adventure seekers, and families looking to unwind in the great outdoors. Whether you are looking for a weekend getaway or a memorable outdoor adventure, Shinde Agri Tourism offers a peaceful camping environment with activities that connect you to nature.',

    top: 'What to Expect at Shinde Agri Tourism Camping Experience	',
    "topPlaces": [
  {
    "title": "Nature Camping Amidst Lush Green Fields",
    "description": "Experience the thrill of camping in the heart of nature. Shinde Agri Tourism offers spacious camping grounds surrounded by beautiful greenery, perfect for those who want to escape the city’s noise and pollution. Enjoy a peaceful night under the stars and wake up to the sounds of chirping birds and rustling leaves, making it an unforgettable outdoor experience."
  },
  {
    "title": "Tents for a Comfortable Stay",
    "description": "Shinde Agri Tourism provides well-maintained tents equipped with basic amenities, ensuring a comfortable and enjoyable camping experience. Whether you're camping with friends, family, or colleagues, the tents offer a cozy retreat after a day of outdoor activities. Spend your evenings around a campfire, sharing stories and enjoying the quiet of nature."
  },
  {
    "title": "Bonfire and Stargazing",
    "description": "One of the highlights of camping at Shinde Agri Tourism is the opportunity to gather around a warm bonfire. Relax, roast marshmallows, and enjoy the tranquility of the countryside. After dark, the clear skies offer a perfect setting for stargazing, where you can enjoy the beauty of the night sky away from city lights."
  },
  {
    "title": "Trekking and Nature Walks",
    "description": "Explore the surrounding countryside on nature walks or short treks. The area around Shinde Agri Tourism offers scenic trails that allow you to immerse yourself in the natural beauty of the region. Breathe in the fresh air, spot local wildlife, and enjoy the lush vegetation as you wander through forests and fields."
  },
  {
    "title": "Outdoor Sports and Games",
    "description": "For those who want to stay active, Shinde Agri Tourism offers outdoor games like cricket, badminton, volleyball, and more. The open fields provide plenty of space for group activities, making it a perfect destination for friends, families, or corporate teams looking to add some fun to their camping trip."
  },
  {
    "title": "Farm Activities and Rural Experiences",
    "description": "Combine your camping trip with a hands-on experience in agriculture. Participate in traditional farming activities like planting crops, milking cows, or learning about organic farming practices. This immersive experience is perfect for families or children looking to learn more about rural life and agriculture."
  },
  {
    "title": "Campfire Storytelling and Cultural Activities",
    "description": "As night falls, enjoy traditional cultural performances or storytelling sessions around the campfire. Local performers may share folk tales, songs, and dances that bring the region’s cultural heritage to life, offering a fun and educational experience for everyone."
  },
  {
    "title": "Eco-Friendly Camping Experience",
    "description": "Shinde Agri Tourism focuses on sustainability and eco-friendly practices, making it an ideal camping spot for environmentally conscious travelers. The site encourages guests to minimize their environmental impact and offers a green, clean, and natural camping environment."
  },
  {
    "title": "Campsite Meals: Farm-to-Table Dining",
    "description": "Enjoy delicious, freshly prepared meals at the campsite made from farm-fresh ingredients. You can indulge in local delicacies, organic produce, and homemade dishes that provide a true farm-to-table experience. Sharing meals around the campfire adds to the sense of community and relaxation that comes with camping."
  }
],

"services": [
  {
    "name": "Camping Sites Near Pune",
    "description": "If you're looking for camping sites near Pune, Shinde Agri Tourism offers a unique agro-tourism experience. Located just a short drive from Pune, this farm allows you to immerse yourself in both rural life and nature. Whether you want to camp in traditional tents, enjoy a bonfire, or participate in farming activities, Shinde Agri Tourism provides a peaceful and engaging camping experience near Pune."
  },
  {
    "name": "Best Camping Places Near Pune",
    "description": "For those searching for the best camping places near Pune, Shinde Agri Tourism is a top choice. Offering a combination of nature, agriculture, and relaxation, this site provides a peaceful camping environment. Visitors can experience organic farming firsthand, enjoy nature walks, and camp under the stars. It's an ideal spot for families, couples, and solo adventurers looking to experience rural life."
  },
  {
    "name": "Pune Camping Places",
    "description": "Among the popular Pune camping places, Shinde Agri Tourism stands out for its focus on agro-tourism. Set in a beautiful rural landscape, the farm provides a range of camping activities, including nature walks, farm tours, and engaging in agricultural practices. This site is perfect for those who want to connect with nature while learning about farming."
  },
  {
    "name": "Camping Location Near Pune",
    "description": "Looking for a great camping location near Pune? Shinde Agri Tourism offers the perfect rural retreat just outside the city. With a variety of camping experiences such as farm animal interaction, organic farming, and stargazing, it's a fantastic option for those seeking a peaceful camping getaway close to Pune."
  },
  {
    "name": "Camping Spots Near Pune",
    "description": "Among the top camping spots near Pune, Shinde Agri Tourism provides a unique blend of agro-tourism and outdoor camping. Set in a tranquil farm environment, it’s the perfect destination for nature lovers who want to experience rural life. The farm offers tents, bonfires, and activities like hiking and organic farming, making it one of the most interesting camping spots near Pune."
  },
  {
    "name": "Places for Camping in Pune",
    "description": "For those looking for places for camping in Pune, Shinde Agri Tourism offers a peaceful, educational, and hands-on experience. With scenic landscapes, comfortable tents, and opportunities to engage with nature, it’s a great choice for campers who want to combine outdoor adventure with agricultural learning."
  },
  {
    "name": "Best Camping Spots Near Pune",
    "description": "When searching for the best camping spots near Pune, Shinde Agri Tourism is an excellent option. Surrounded by nature, this farm offers a peaceful environment for camping. Visitors can participate in activities like farming workshops, nature walks, and even try their hand at organic gardening, making it an enriching experience for all types of campers."
  },
  {
    "name": "Camping Places Near Pimpri Chinchwad",
    "description": "If you’re looking for camping places near Pimpri Chinchwad, Shinde Agri Tourism is a great choice. Located just a short drive from Pimpri Chinchwad, the farm offers a quiet escape with hands-on farming activities, nature walks, and camping experiences in a serene setting. It’s perfect for families or groups looking for a unique camping experience close to the city."
  },
  {
    "name": "Indoor Camping Places Near Pimpri Chinchwad",
    "description": "For those searching for indoor camping places near Pimpri Chinchwad, Shinde Agri Tourism offers a mix of outdoor camping with comfortable indoor facilities. The farm provides a combination of traditional camping and modern amenities, including cozy indoor spaces to relax and enjoy the farm environment, ensuring comfort and adventure."
  },
  {
    "name": "Best Indoor Camping Places Near Pimpri Chinchwad",
    "description": "Shinde Agri Tourism offers some of the best indoor camping places near Pimpri Chinchwad. If you're looking for a more comfortable camping experience, the farm offers a mix of indoor accommodations with modern amenities while still maintaining the essence of a traditional rural camping experience. The peaceful ambiance and variety of activities make it a great option for both indoor and outdoor camping enthusiasts."
  },
  {
    "name": "1 Day Camping Near Pune",
    "description": "For 1 day camping near Pune, Shinde Agri Tourism provides a great option. Located close to Pune, this farm offers a day-long camping experience with activities like farm tours, bonfires, and nature walks. It’s ideal for those looking to enjoy a quick escape to nature without an overnight stay."
  },
  {
    "name": "Best Camping Places Near Pune",
    "description": "Among the best camping places near Pune, Shinde Agri Tourism offers a peaceful, immersive experience. It’s a great destination for families, couples, or solo travelers who want to explore organic farming, enjoy outdoor activities, and camp in a rural setting. The farm offers a variety of camping setups, from tents to interactive farming experiences."
  },
  {
    "name": "Overnight Camping Near Pune",
    "description": "For those interested in overnight camping near Pune, Shinde Agri Tourism provides an ideal setting. Camp under the stars, enjoy a bonfire, and wake up to the sounds of nature. The farm also offers activities like farming workshops and nature walks, making it an enriching overnight camping experience."
  },
  {
    "name": "Trekking and Camping Near Pune",
    "description": "If you’re looking for trekking and camping near Pune, Shinde Agri Tourism is a great option. The surrounding area offers natural trails for trekking, and after the trek, you can relax and camp at the farm. The combination of trekking and camping makes for an exciting and rewarding outdoor experience."
  },
  {
    "name": "Camping Near Pune for Family",
    "description": "For camping near Pune for family, Shinde Agri Tourism is the perfect spot. The farm offers a variety of activities for all ages, including farm tours, animal interactions, and nature walks. It’s an excellent place for families to bond, learn about farming, and enjoy a peaceful day in nature."
  },
  {
    "name": "Shinde Agri Tourism Camping Places Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for the perfect camping experience near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Camping Sites Near Pune", "- Best Camping Places Near Pune"],
    ["- Pune Camping Places", "- Best Place for Camping Near Pune"],
    ["- Camping Location Near Pune", "- Camping Spots Near Pune"],
    ["- Places for Camping in Pune", "- Best Camping Spots Near Pune"],
    ["- Camping Places Near Pimpri Chinchwad", "- Indoor Camping Places Near Pimpri Chinchwad"],
    ["- Best Indoor Camping Places Near Pimpri Chinchwad", "- 1 Day Camping Near Pune"],
    ["- Best Camping Places Near Pune", "- Overnight Camping Near Pune"],
    ["- Trekking and Camping Near Pune", "- Camping Near Pune for Family"],
    ["- Camping Near Pune for Couples", "- Camping Near Pune for Family"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Perfect Location for Camping",
      "WhyChoosedescription": "Shinde Agri Tourism is located amidst the tranquility of nature, offering an ideal camping experience. Whether you’re looking to relax under the stars or embark on an adventurous getaway, our campsite offers breathtaking views of nature, making it perfect for a peaceful retreat or a fun-filled outdoor adventure."
    },
    {
      "WhyChooseheading": "Adventure Activities",
      "WhyChoosedescription": "Our camping packages include a variety of outdoor activities for adventure enthusiasts. From trekking and nature walks to zip-lining, rock climbing, and archery, there’s no shortage of adrenaline-pumping activities to enjoy during your stay. You’ll have the opportunity to explore the natural surroundings while engaging in exciting outdoor pursuits."
    },
    {
      "WhyChooseheading": "Comfortable Tented Accommodation",
      "WhyChoosedescription": "Experience the outdoors without compromising on comfort. Our camping site offers well-maintained tents with cozy bedding and essentials to ensure a comfortable stay. Wake up to the fresh air and the sounds of nature, all while staying in a comfortable and secure environment."
    },
    {
      "WhyChooseheading": "Bonfire and Stargazing",
      "WhyChoosedescription": "Nothing beats the experience of sitting around a warm bonfire, enjoying stories, music, and camaraderie. After the bonfire, you can enjoy stargazing in the clear night sky. The absence of city lights allows you to enjoy the beauty of the night sky like never before."
    },
    {
      "WhyChooseheading": "Fresh Farm-to-Table Meals",
      "WhyChoosedescription": "One of the highlights of camping at Shinde Agri Tourism is the delicious food! We serve farm-to-table meals, prepared with fresh, organic ingredients sourced directly from our farm. Enjoy a range of traditional Maharashtrian dishes, grilled snacks, and fresh fruit – the perfect way to fuel up after a day of adventure."
    },
    {
      "WhyChooseheading": "Family-Friendly and Group Camping",
      "WhyChoosedescription": "Whether you’re looking for a family getaway, a group retreat, or a romantic escape, Shinde Agri Tourism offers customized camping packages to suit your needs. We have options for families with kids, as well as larger group bookings for corporate teams, friends, and more."
    },
    {
      "WhyChooseheading": "Unplug and Reconnect with Nature",
      "WhyChoosedescription": "If you're looking to disconnect from the fast-paced life of the city, camping at Shinde Agri Tourism offers the perfect opportunity. Experience the joy of waking up to birds singing, spending your days exploring nature, and unwinding under the stars at night. It’s a chance to reconnect with yourself, your loved ones, and the natural world."
    },
    {
      "WhyChooseheading": "Easy Accessibility from Pune",
      "WhyChoosedescription": "Located just 100 km from Pune (a 2 to 2.5-hour drive), Shinde Agri Tourism is a convenient destination for those looking for a quick weekend camping getaway. Whether you're planning a one-night stay or a longer trip, our location offers a perfect escape that is close enough to Pune for a spontaneous weekend."
    }
  ]
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, about a 2 to 2.5-hour drive. It’s an easy and convenient weekend getaway destination."
    },
    {
      question: "What camping accommodations do you offer?",
      answer: "We offer well-maintained tents equipped with comfortable bedding, lights, and necessary amenities. Our camping experience ensures that you enjoy the outdoors while staying comfortable and safe."
    },
    {
      question: "What activities are included in the camping experience?",
      answer: "Our camping packages include various outdoor activities such as trekking, zip-lining, rock climbing, archery, nature walks, and bonfire sessions. You can also enjoy farm tours, organic farming activities, and team-building games."
    },
    {
      question: "Is the campsite safe for families with children?",
      answer: "Yes! Shinde Agri Tourism is a family-friendly camping destination. The campsite is safe for children, and we offer kid-friendly activities such as farm visits, nature walks, and games. The natural setting provides a safe, calm environment for families to enjoy quality time together."
    },
    {
      question: "Can I book a camping trip for a corporate group?",
      answer: "Absolutely! Shinde Agri Tourism is ideal for corporate group outings, team-building exercises, and corporate retreats. We offer customized packages that include adventure activities, workshops, and team-building games, designed to promote collaboration and team spirit."
    },
    {
      question: "Are meals provided during the camping trip?",
      answer: "Yes, we provide delicious farm-to-table meals during your camping trip. Our menu includes traditional Maharashtrian dishes, grilled snacks, fresh salads, and fruit from our farm. Special dietary requirements can also be accommodated with prior notice."
    },
    {
      question: "Is there a bonfire during the camping experience?",
      answer: "Yes! Every camping trip at Shinde Agri Tourism includes a bonfire session. Gather around the bonfire, share stories, sing songs, and enjoy the warmth and beauty of the night sky."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Shubham & Family, Pune",
      text: "Our family had an amazing time camping at Shinde Agri Tourism! The tents were comfortable, the activities were fun, and the food was absolutely delicious. We loved the bonfire and stargazing in the peaceful environment. A perfect family getaway!"
    },
    {
      name: "Mr. Rohit & Friends, Mumbai",
      text: "I went camping with my friends at Shinde Agri Tourism, and it was an unforgettable experience! The trek through the forest, the exciting zip-lining, and the delicious farm-fresh meals were highlights. We’ll definitely be coming back for another adventure!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Camping Places Near Pune | Best Camping Spots, Trekking & Overnight Camping | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best camping places near Pune? Explore top camping spots for families, couples, trekking, and overnight stays near Pune and Pimpri Chinchwad. Enjoy a nature-filled experience with Shinde Krushi Paryatan." />
  <meta name="keywords" content="Camping places near Pune, best camping places near Pune, Pune camping places, best place for camping near Pune, camping location near Pune, camping spots near Pune, places for camping in Pune, best camping spots near Pune, camping places near Pimpri Chinchwad, indoor camping places near Pimpri Chinchwad, best indoor camping places near Pimpri Chinchwad, 1 day camping near Pune, best camping places near Pune, overnight camping near Pune, trekking and camping near Pune, camping near Pune for family, camping near Pune for couples" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/camping-places-near-pune" />

  {/* JSON-LD Schema for Camping Places Near Pune */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Camping Places Near Pune",
        "url": "https://shindekrushiparyatan.com/camping-places-near-pune",
        "description": "Find the best camping places near Pune for family trips, couples getaways, and overnight camping. Explore top camping spots for trekking, relaxation, and adventure at Shinde Krushi Paryatan near Pune and Pimpri Chinchwad.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pimpri Chinchwad, Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/camping-places-near-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/29.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Campingplaces;