import React from 'react';

const RecentArticlesSection = () => {
  const articles = [
    {
      category: 'Shinde agri Tourism',
      description: '"Experience the thrill of tractor riding at Shinde Agri Tourism – A unique adventure through scenic fields, perfect for nature lovers and adventure seekers! 🚜🌾"',
      author: '₹ 799 | Per day',
      articleImage: '/images/attraction/7.jpg',
      link: 'attraction',
    },
    {
      category: 'Shinde agri Tourism',
      description: 'Dive into relaxation at our sparkling swimming pool, offering the perfect spot to unwind after a day of fun-filled activities. 🏊‍♂️💦',
      author: ' ₹ 799 | Per day',
      articleImage: '/images/attraction/9.jpg',
      link: 'attraction',
    },
    {
      category: 'Shinde agri Tourism',
      description: 'Experience the thrill of bull riding at Shinde Agri Tourism! Test your skills and enjoy an exciting ride on our friendly bulls. 🐂🤠 Unforgettable moments await',
      author: '₹ 799 | Per day',
      articleImage: '/images/attraction/8.jpg',
      link: 'attraction',
    },
  ];

  return (
    <section className="trending recent-articles pb-6 pt-5">
      <div className="container">
        <div className="section-title mb-6 w-75 mx-auto text-center">
          <h4 className="mb-1 theme1">Our Blogs Offers</h4>
          
        </div>

        <div className="recent-articles-inner">
          <div className="row">
            {articles.map((article, index) => (
              <div className="col-lg-4" key={index}>
                <div className="trend-item box-shadow bg-white mb-4 rounded">
                  <div className="trend-image">
                    <img src={article.articleImage} alt={article.title} />
                  </div>
                  <div className="trend-content-main p-4 pb-2">
                    <div className="trend-content">
                      <h5 className="theme mb-1">{article.category}</h5>
                      <h4><a href={article.link}>{article.title}</a></h4>
                      <p className="mb-3">{article.description}</p>
                      <div className="entry-meta d-flex align-items-center justify-content-between">
                        <div className="entry-author mb-2">
                         
                          <span className='darkcolor'>{article.author}</span>
                        </div>
                        <div className="entry-button d-flex align-items-center mb-2">
                          <a href={article.link} className="nir-btn">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentArticlesSection;
