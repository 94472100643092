import React from 'react';
import '../css/NearbyPlaces.css';

const NearbyPlaces = () => {
  const places = [
    {
      name: 'Mahaganpati Ranjangaon Darshan',
      description: 'A famous temple dedicated to Lord Ganesha located in Ranjangaon. It is a major pilgrimage site in Maharashtra.',
      distance: '25 km',
      image: '/images/near/1.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Nighoj Kund (Ranjan Khalage)',
      description: 'A beautiful, serene place famous for its natural kund (water bodies) formed in the rocks. Ideal for nature lovers and photographers.',
      distance: '15 km',
      image: '/images/near/2.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Morachi Chincholi',
      description: 'Known for its large peacock population, this small village offers a peaceful retreat amidst nature. Perfect for bird watching.',
      distance: '35 km',
      image: '/images/near/3.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Pabal Vigyan Ashram',
      description: 'A hub for scientific and educational activities, focusing on agriculture, sustainable living, and rural development.',
      distance: '20 km',
      image: '/images/near/4.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Pabal Jain Temple (Pabal Jain Tirth)',
      description: 'A historical Jain temple located in Pabal, offering a glimpse of rich architectural heritage and tranquility.',
      distance: '18 km',
      image: '/images/near/5.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Mastani Samadhi Pabal',
      description: 'A historical site dedicated to Mastani, the beloved wife of Peshwa Baji Rao. It is a place of significant historical importance.',
      distance: '22 km',
      image: '/images/near/6.jpg',
      link: 'https://goo.gl/maps/example'
    }
  ];

  return (

<div>





    
    
<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
      <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3"> Near Places</h1>

          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>
    <section className="nearby-places">
      <div className="container">
        <h2 className="section-title text-white">Explore Nearby Places</h2>
        <p className="section-description text-white">Our resort is surrounded by many fascinating places of interest. Here are some nearby attractions that you must visit during your stay.</p>
        
        <div className="places-grid">
          {places.map((place, index) => (
            <div key={index} className="place-card">
              <img src={place.image} alt={place.name} className="place-image" />
              <div className="place-details">
                <h3 className="place-title">{place.name}</h3>
                <p className="place-description">{place.description}</p>
                <p><strong className='distanc'>Distance:</strong> {place.distance}</p>
                {/* <a href={place.link} target="_blank" rel="noopener noreferrer" className="cta-button">View on Map</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

</div>  );
};

export default NearbyPlaces;
