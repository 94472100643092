import React, { useEffect, useState } from 'react';

const Gallery = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Log images to check if they are being passed correctly
  useEffect(() => {
    console.log('Images:', images); // Debugging images prop
  }, [images]);

  // Automatically change the image every 3 seconds
  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // Change image every 3 seconds

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [images.length]);

  // Render a message if there are no images
  if (images.length === 0) {
    return <div></div>;
  }

  // Ensure the current index is within bounds
  const currentImage = images[currentIndex];
  console.log('Current Image:', currentImage); // Debugging current image

  return (
    <div className="gallery-container py-3">
      <h2 className="gallery-title">Gallery</h2>
      <div className="gallery-row">
        <div className="gallery-item col-md-12">
          <img
            src={currentImage?.src}  
            alt={currentImage?.alt}  // Use optional chaining to safely access 
            className="gallery-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
