
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Destination() {



  const cardData =
  {
    keyword: 'Destination Wedding Venue Near Pune',
    heading:  'Destination Wedding Venue Near Pune: Shinde Agri Tourism',
    headingDescription: 'For couples looking to tie the knot in a serene and picturesque setting, Shinde Agri Tourism offers the perfect destination wedding experience near Pune. Surrounded by lush greenery and a tranquil rural ambiance, this unique venue combines natural beauty with rustic charm, making it an unforgettable location for your special day.',

    top: 'Top Features of a Destination Wedding at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Scenic Outdoor Wedding Ceremonies",
          "description": "Say “I do” surrounded by nature at Shinde Agri Tourism. With its expansive fields, charming farmhouses, and breathtaking views, the venue offers an idyllic setting for an outdoor wedding. Whether you’re planning a traditional ceremony or a modern celebration, the natural surroundings create a magical backdrop."
        },
        {
          "title": "Pre-Wedding Photoshoots in a Picturesque Setting",
          "description": "Capture stunning pre-wedding photos amid the picturesque landscapes of Shinde Agri Tourism. The scenic beauty of the farm, the lush greenery, and the rustic ambiance offer the perfect backdrop to make your pre-wedding shoot truly special."
        },
        {
          "title": "Customizable Wedding Packages",
          "description": "Shinde Agri Tourism offers a variety of customizable wedding packages tailored to your needs. From catering to decoration, the venue can accommodate all your wedding requirements, ensuring your big day is smooth and stress-free. Celebrate your love in a place that reflects your personality and style."
        },
        {
          "title": "Open-Air Reception and Celebration Areas",
          "description": "Celebrate your union with family and friends in a spacious outdoor setting. Shinde Agri Tourism provides ample space for open-air receptions, where guests can enjoy delicious food, music, and dancing under the stars, all while being surrounded by the beauty of nature."
        },
        {
          "title": "Exclusive Wedding Activities and Experiences",
          "description": "In addition to the wedding ceremony, you can organize pre- or post-wedding activities like a fun farm experience, nature walks, or a relaxing picnic. Your guests can engage in interactive farming, bird watching, or just unwind in the peaceful environment, making the wedding experience even more memorable."
        }
      ],

   "services": [
  {
    "name": "Destination Wedding Near Pune with Budget",
    "description": "If you’re looking for a destination wedding near Pune with budget, Shinde Agritourism offers an affordable yet unforgettable venue. Nestled in a beautiful rural setting, this agritourism farm provides an ideal backdrop for intimate weddings. You can exchange vows surrounded by lush greenery, enjoy traditional farm-based experiences, and curate a unique celebration without exceeding your budget. With options for outdoor ceremonies, pre-wedding activities, and fresh, organic meals, it’s the perfect place for couples looking for a budget-friendly yet memorable wedding experience."
  },
  {
    "name": "Resort Wedding Packages in Pune",
    "description": "Shinde Agritourism offers customizable resort wedding packages in Pune, making it a great option for couples seeking a countryside wedding. With a focus on organic farming, rural activities, and a tranquil atmosphere, it’s a unique place to host your wedding celebration. From organic food catering to space for ceremonies and receptions, the venue provides a full-service wedding experience tailored to your needs. It's perfect for couples who want a rustic yet special wedding without the high costs of typical resort wedding packages."
  },
  {
    "name": "Luxury Wedding Venues in Pune",
    "description": "For those seeking luxury wedding venues in Pune, Shinde Agritourism offers a unique experience that blends nature with elegance. Though not a traditional luxury venue, the farm’s serene setting, open-air spaces, and customized services provide an exclusive, one-of-a-kind wedding experience. Enjoy luxury at its best with fresh farm-to-table dining, luxurious outdoor spaces, and nature-inspired decor. The ambiance is tranquil and elegant, making it perfect for couples who want a mix of luxury and authenticity."
  },
  {
    "name": "Budget Wedding Venues in Pune",
    "description": "If you are looking for budget wedding venues in Pune, Shinde Agritourism provides an affordable and picturesque setting for your special day. The venue allows for a rustic, relaxed wedding without the need for extravagant costs. The lush green surroundings and farm activities add a unique touch to your celebration, creating lasting memories while being mindful of your budget."
  },
  {
    "name": "Destination Wedding Packages Near Pune",
    "description": "For a destination wedding near Pune, Shinde Agritourism offers customized wedding packages that can suit a variety of budgets and preferences. Whether you’re planning a small, intimate ceremony or a larger gathering, the farm’s beautiful location, fresh air, and connection to nature will make your wedding feel truly special. The venue offers unique pre-wedding experiences, such as farm tours, bullock cart rides, and organic food catering, all of which can be included in your wedding package."
  },
  {
    "name": "Wedding Resorts in Pune",
    "description": "Shinde Agritourism is an ideal wedding resort in Pune for couples looking for a rural escape. Located just outside the city, it offers the perfect balance of peaceful surroundings and engaging farm-based activities. The venue can accommodate your wedding ceremony, reception, and post-wedding activities, all in a relaxed, nature-filled environment. Whether you’re looking for an intimate wedding or a larger celebration, Shinde Agritourism offers a memorable wedding experience."
  },
  {
    "name": "Destination Wedding Near Pimpri Chinchwad",
    "description": "For couples near Pimpri Chinchwad, Shinde Agritourism provides an ideal destination wedding near Pimpri Chinchwad. It’s a short drive from the city, making it convenient for both local and outstation guests. The farm setting offers a beautiful, natural backdrop for your wedding day, with plenty of space for outdoor ceremonies, receptions, and fun pre-wedding activities like bullock cart rides and farm tours. Celebrate your love amidst nature and make your wedding day truly special."
  },
  {
    "name": "Marriage Hall Near Me in Pune",
    "description": "If you are looking for a marriage hall near me in Pune, Shinde Agritourism provides an alternative to traditional indoor marriage halls. The venue offers expansive outdoor spaces perfect for a wedding ceremony or reception. With ample greenery, farm-based experiences, and a peaceful atmosphere, this destination offers a refreshing change from the typical marriage hall experience."
  },
  {
    "name": "Destination Wedding Near Pune",
    "description": "Shinde Agritourism is one of the best destination wedding venues near Pune, offering couples an immersive rural experience for their big day. The venue provides both indoor and outdoor options, allowing you to choose between a traditional farm wedding or an elegant outdoor celebration. The farm’s natural beauty, organic food options, and rural charm make it an unforgettable wedding destination just a short drive from Pune."
  },
  {
    "name": "Destination Wedding Near Pune with Budget",
    "description": "Planning a destination wedding near Pune with budget? Look no further than Shinde Agritourism. The farm provides an affordable yet picturesque setting for your wedding, with the charm of rural life and all the essentials you need to create a beautiful celebration. From farm tours to organic catering and rustic decor, Shinde Agritourism offers everything you need for a budget-friendly yet unforgettable wedding experience."
  },
  {
    "name": "Resorts Near Pune for Wedding",
    "description": "Shinde Agritourism offers a unique and picturesque venue for a wedding. The farm provides a rustic yet elegant setting for your ceremony and reception, with lush greenery and nature as your backdrop. The venue offers space for outdoor celebrations, farm-based activities, and customized catering, all while being located just outside Pune. It's the ideal location for couples seeking an alternative to traditional wedding resorts."
  },
  {
    "name": "Destination Wedding Near Pune Cost",
    "description": "The cost of a destination wedding near Pune at Shinde Agritourism is considerably more affordable compared to luxury wedding resorts, making it a perfect choice for couples on a budget. While prices vary depending on the size of your wedding, the venue provides affordable packages with options for outdoor ceremonies, organic meals, and farm-based activities. Reach out to Shinde Agritourism to inquire about pricing and availability for your dream rural wedding."
  },
  {
    "name": "Destination Wedding Packages Near Pune",
    "description": "Shinde Agritourism offers flexible destination wedding packages near Pune, designed to suit different tastes and budgets. Whether you’re planning an intimate gathering or a larger celebration, the farm provides the perfect setting for your special day. From the organic food catered from the farm to pre-wedding activities and post-wedding events, every aspect of your wedding can be customized to your preference."
  },
  {
    "name": "Budget Destination Wedding Near Pune",
    "description": "For couples seeking a budget destination wedding near Pune, Shinde Agritourism is the ideal venue. This agritourism farm offers an affordable and unique wedding experience. With a variety of rustic settings, organic food options, and a tranquil rural atmosphere, you can create a memorable wedding celebration without breaking the bank."
  },
  {
    "name": "Destination Wedding Resorts Near Pune",
    "description": "Looking for destination wedding resorts near Pune? Shinde Agritourism offers a unique farm-based setting that combines nature, tranquility, and a rural atmosphere. It’s perfect for couples who want an outdoor wedding with personalized services like organic catering, nature walks, and farm-based experiences. The venue offers a more authentic and affordable wedding experience compared to traditional luxury resorts."
  },
  {
    "name": "Best Destination Wedding Places Near Pune",
    "description": "Shinde Agritourism is one of the best destination wedding places near Pune. It provides a rustic, natural atmosphere where couples can celebrate their love surrounded by lush greenery and organic farm experiences. The tranquil ambiance, open spaces, and personalized services make it a great choice for couples looking for a memorable wedding that’s both unique and affordable."
  },
  {
    "name": "Destination Wedding Locations Near Pune",
    "description": "When considering destination wedding locations near Pune, Shinde Agritourism offers a picturesque setting perfect for a rural-themed celebration. The natural beauty of the farm, combined with activities such as bullock cart rides, nature walks, and organic farming, creates a unique and charming location for your big day."
  },
  {
    "name": "Destination Wedding Venues Near Pune",
    "description": "Shinde Agritourism stands out as one of the most unique destination wedding venues near Pune. The venue offers a rural, organic experience that provides a refreshing alternative to traditional wedding venues. With options for outdoor ceremonies, farm tours, and organic catering, Shinde Agritourism offers a memorable and affordable way to celebrate your love in the heart of nature."
  },
  {
    "name": "Outdoor Wedding Venues Pune",
    "description": "For outdoor wedding venues in Pune, Shinde Agritourism is an excellent choice. The expansive outdoor spaces, surrounded by lush greenery, make it a perfect location for a ceremony or reception. With a focus on sustainability and rural life, this venue offers a peaceful, nature-filled setting for couples looking for an outdoor wedding experience."
  },
  {
    "name": "Pre Wedding Destination Near Pune",
    "description": "For a pre-wedding destination near Pune, Shinde Agritourism is a great option. You can enjoy pre-wedding photoshoots surrounded by the natural beauty of the farm, engage in farm-based activities, and create unforgettable memories before your wedding day. The farm offers a serene, relaxed atmosphere that’s perfect for capturing stunning pre-wedding photos."
  },
  {
    "name": "Pune Wedding Resorts",
    "description": "Shinde Agritourism offers a peaceful escape for couples looking for wedding resorts in Pune. This farm-based venue offers rustic charm and tranquility, with personalized services and organic meals to make your wedding day truly special. Whether it’s an intimate ceremony or a larger gathering, Shinde Agritourism provides everything you need for a memorable celebration."
  },
  {
    "name": "Pune Resorts for Wedding",
    "description": "If you're looking for Pune resorts for weddings, Shinde Agritourism provides a wonderful alternative to traditional wedding resorts. The venue combines rural charm, organic farming, and serene natural surroundings, making it a unique and affordable choice for your wedding celebration."
  },
  {
    "name": "Pre Wedding Locations Near Pune",
    "description": "Looking for pre-wedding locations near Pune? Shinde Agritourism offers beautiful, rustic landscapes perfect for capturing intimate pre-wedding moments. With its expansive greenery, farm activities, and tranquil atmosphere, it’s an ideal location for pre-wedding shoots and celebrations."
  },
  {
    "name": "Resort Near Pune for Wedding",
    "description": "Shinde Agritourism is an ideal resort near Pune for weddings, providing a unique rural experience that combines nature, organic food, and farm-based activities. It’s the perfect place for couples looking for a peaceful, nature-inspired wedding away from the city."
  },
  {
    "name": "Resorts for Wedding Near Pune",
    "description": "Looking for resorts for weddings near Pune? Shinde Agritourism offers a serene, countryside setting that’s perfect for intimate weddings. The venue provides customizable packages, organic catering, and farm-based activities to make your wedding celebration one-of-a-kind."
  },
  {
    "name": "Resorts Near Pune for Pre Wedding Shoot",
    "description": "For resorts near Pune for pre-wedding shoots, Shinde Agritourism offers breathtaking rural backdrops, lush greenery, and farm activities. It’s a great location for couples who want to capture their pre-wedding moments in a peaceful and natural environment."
  },
  {
    "name": "Shinde Agri Tourism Picnic Spot Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Picnic Spot Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Destination Wedding Near Pune with Budget", "- Resort Wedding Packages in Pune"],
    ["- Luxury Wedding Venues in Pune", "- Budget Wedding Venues in Pune"],
    ["- Destination Wedding Packages Near Pune", "- Wedding Resorts in Pune"],
    ["- Destination Wedding Near Pimpri Chinchwad", "- Marriage Hall Near Me in Pune"],
    ["- Destination Wedding Near Pune", "- Destination Wedding Near Pune with Budget"],
    ["- Resorts Near Pune for Wedding", "- Destination Wedding Near Pune Cost"],
    ["- Destination Wedding Packages Near Pune", "- Budget Destination Wedding Near Pune"],
    ["- Destination Wedding Resorts Near Pune", "- Best Destination Wedding Places Near Pune"],
    ["- Destination Wedding Locations Near Pune", "- Destination Wedding Places Near Pune"],
    ["- Destination Wedding Venues Near Pune", "- Outdoor Wedding Venues Pune"],
    ["- Pre Wedding Destination Near Pune", "- Pune Wedding Resorts"],
    ["- Pune Resorts for Wedding", "- Pre Wedding Locations Near Pune"],
    ["- Resort Near Pune for Wedding", "- Resorts for Wedding Near Pune"],
    ["- Resorts Near Pune for Pre Wedding Shoot", "- Wedding Venues Around Pune"],
    ["- Om Agri Tourism Destination Wedding Near Pune", "- Destination Wedding Near Pune"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Scenic and Serene Venue",
      "WhyChoosedescription": "Shinde Agri Tourism provides a beautiful, natural backdrop for your wedding. With lush green fields, rolling hills, and tranquil surroundings, it’s the perfect setting for a romantic and memorable celebration. Say 'I do' in a peaceful, picturesque environment away from the city’s hustle and bustle."
    },
    {
      "WhyChooseheading": "Customizable Wedding Packages",
      "WhyChoosedescription": "We offer tailored wedding packages to suit your needs and preferences. From intimate ceremonies to grand celebrations, our team will work with you to create a wedding that reflects your vision. We handle every detail, from decorations to catering, ensuring a seamless experience."
    },
    {
      "WhyChooseheading": "Spacious Outdoor and Indoor Venues",
      "WhyChoosedescription": "Whether you prefer an outdoor wedding under the stars or an indoor ceremony in a beautifully designed hall, Shinde Agri Tourism offers both options. Our outdoor spaces are perfect for a rustic or garden-themed wedding, while our indoor venues provide a cozy and elegant atmosphere for your event."
    },
    {
      "WhyChooseheading": "Professional Wedding Planning Services",
      "WhyChoosedescription": "Our expert team of wedding planners is dedicated to making your dream wedding a reality. From coordinating the ceremony and reception to arranging for photographers, decorators, and entertainment, we handle all the logistics so you can enjoy every moment of your special day."
    },
    {
      "WhyChooseheading": "Exquisite Catering Services",
      "WhyChoosedescription": "Enjoy delicious, authentic Maharashtrian cuisine at your wedding! Our catering services feature locally sourced, organic ingredients, prepared with care. We offer a range of menu options, from traditional delicacies to customized dishes, ensuring that your guests enjoy a memorable culinary experience."
    },
    {
      "WhyChooseheading": "Accommodation for Guests",
      "WhyChoosedescription": "We offer comfortable, spacious accommodation for your wedding guests. Whether it’s a close-knit group or a larger gathering, we ensure your family and friends have a restful stay, with cozy rooms and beautiful views."
    },
    {
      "WhyChooseheading": "Easy Accessibility from Pune",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is easily accessible for your wedding guests. It’s a short and convenient drive, making it an ideal location for a destination wedding that’s close to the city but feels far from it."
    }
  ]
    



  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located about 100 km from Pune, approximately a 2 to 2.5-hour drive. It’s easily accessible, making it an ideal destination for guests coming from Pune."
    },
    {
      question: "Do you offer both indoor and outdoor wedding venues?",
      answer: "Yes! We offer both outdoor and indoor venues for your wedding. You can choose from a scenic outdoor ceremony or an elegant indoor setting, depending on your preference and the theme of your wedding."
    },
    {
      question: "Can you help with wedding planning and coordination?",
      answer: "Absolutely! Our experienced wedding planners will assist you with every aspect of your destination wedding, including decorations, catering, photographer arrangements, and more. We’ll ensure that your wedding day goes off without a hitch."
    },
    {
      question: "What catering options do you offer for destination weddings?",
      answer: "We offer a wide range of catering options, including traditional Maharashtrian dishes, vegetarian and non-vegetarian menus, and customized food arrangements based on your preferences. All our meals are made with fresh, locally sourced ingredients."
    },
    {
      question: "Is accommodation available for wedding guests?",
      answer: "Yes! We offer comfortable accommodation options for your wedding guests. With clean, spacious rooms and beautiful views of the surrounding nature, your guests will have a restful stay."
    },
    {
      question: "Can I bring my own vendors (decorators, photographers, etc.)?",
      answer: "While we have a list of preferred vendors who we recommend, you are welcome to bring your own vendors. We can coordinate with them to ensure a smooth experience on your wedding day."
    },
    {
      question: "How can I book Shinde Agri Tourism for my destination wedding?",
      answer: "You can easily book your wedding venue by contacting our customer service team or filling out the booking form on our website. We recommend booking well in advance to ensure availability on your desired wedding date."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Radhika Yadav",
      text: "Our destination wedding at Shinde Agri Tourism was everything we dreamed of and more! The beautiful surroundings, excellent service, and delicious food made our wedding day truly special. We couldn’t have asked for a better venue for our big day."
    },
    {
      name: "Mr. Arjun Patil",
      text: "The perfect setting for a destination wedding! The team at Shinde Agri Tourism took care of every detail, and our wedding day was flawless. Our guests loved the beautiful outdoor ceremony and the amazing food. We highly recommend it!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Destination Wedding Near Pune | Best Wedding Resorts & Budget Wedding Packages Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for a destination wedding near Pune? Explore the best wedding resorts, luxury wedding venues, and budget-friendly destination wedding packages near Pune. Celebrate your special day at Shinde Krushi Paryatan with stunning outdoor venues and beautiful landscapes." />
  <meta name="keywords" content="Destination Wedding Near Pune, Destination Wedding with Budget Near Pune, Resort Wedding Packages in Pune, Luxury Wedding Venues in Pune, Budget Wedding Venues in Pune, Destination Wedding Packages Near Pune, Wedding Resorts in Pune, Destination Wedding Near Pimpri Chinchwad, Marriage Hall Near Me in Pune, Wedding Resorts Near Pune, Destination Wedding Cost Near Pune, Budget Destination Wedding Near Pune, Best Destination Wedding Places Near Pune, Destination Wedding Locations Near Pune, Destination Wedding Venues Near Pune, Outdoor Wedding Venues Pune, Pre Wedding Locations Near Pune, Pre Wedding Destination Near Pune, Pune Wedding Resorts, Pune Resorts for Wedding, Resorts for Wedding Near Pune, Resorts Near Pune for Pre Wedding Shoot, Wedding Venues Around Pune, Om Agri Tourism Destination Wedding Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/destination-wedding-near-pune" />

  {/* JSON-LD Schema for Wedding Venue with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Destination Wedding Near Pune",
        "url": "https://shindekrushiparyatan.com/destination-wedding-near-pune",
        "description": "Plan your destination wedding at Shinde Krushi Paryatan with budget-friendly wedding packages, luxury venues, and beautiful outdoor wedding spaces near Pune. Make your special day unforgettable.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/destination-wedding-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/19.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Destination;