import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/RefundPolicy.css'; // Ensure this CSS file includes the necessary styles

const RefundPolicy = () => {
  return (
    <div className="refund-policy-page">
      <Helmet>
        <title>Refund Policy | Shinde Agri Tourism</title>
        <meta name="description" content="Read the refund and cancellation policy for Shinde Agri Tourism. Learn about our terms and conditions regarding bookings, cancellations, and liabilities." />
        <meta name="keywords" content="Shinde Agri Tourism, Refund Policy, Cancellation, Terms and Conditions, Legal, Liability, Booking Policy" />
      </Helmet>




      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Refund and Cancellation Policy</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>



      <section className="refund-policy-section">
        <div className="container">
          <div className="row">
            {/* Refund Policy Content */}
            <div className="col-lg-9 refund-policy-content">
              <h3 className='darkcolor'>Cancellation and Refunds</h3>
              <p>Once a booking is confirmed or passes are purchased on <strong>www.shindekrushiparyatan.com</strong>, they cannot be cancelled, transferred, postponed, or refunded. Please ensure your booking details are accurate before confirming your reservation.</p>

              <h4 className='darkcolor'>Breach of Terms</h4>
              <p>In case of a breach of the Terms of Use, Privacy Policy, or any other regulations, we reserve the right to take actions including suspending or terminating access to the website and legal actions if necessary.</p>

              <h4 className='darkcolor'>Indemnity</h4>
              <p>The User agrees to indemnify Shinde Agri Tourism and its affiliates from any claims, damages, or penalties arising out of their breach of these terms or violation of laws.</p>

              <h4 className='darkcolor'>Applicable Law</h4>
              <p>This policy is governed by the laws of India. Disputes related to this policy will be subject to the jurisdiction of courts in Pune, Maharashtra, India.</p>

              <h4 className='darkcolor'>Copyright Complaint</h4>
              <p>If you believe your work has been copied and constitutes copyright infringement, please contact us at: <a href="mailto:booking@shindekrushiparytan.com">booking@shindekrushiparytan.com</a>.</p>

              <h4 className='darkcolor'>Risk of Loss</h4>
              <p>The risk of loss for all items purchased passes to the User once the items are delivered to the carrier.</p>

              <h4 className='darkcolor'>Limitation of Liability</h4>
              <p>Shinde Agri Tourism will not be liable for any direct or indirect damages arising from the use of this website, including any errors or omissions in content, or damages resulting from the inability to use the website.</p>
            </div>

            {/* Image Section */}
            {/* <div className="col-lg-4">
              <div className="image-container">
                <img src="/images/refund-policy.jpg" alt="Refund Policy" className="refund-policy-image" />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RefundPolicy;
