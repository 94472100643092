import React from 'react';

const Guid = () => {
  return (
    <section
      className="about-us pt-0"
      style={{ backgroundImage: 'url(images/bg/bg-trans.png)' }}
    >
      <div className="container">
        <div className="about-image-box">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-6 mb-4 pe-4">
              <div className="about-image1 overflow-hidden rounded">
                <img src="/images/155.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-4 ps-4">
  <div className="about-content text-center text-lg-start mb-4">
    <h4 className="theme d-inline-block mb-0">Perfect Team</h4>
    <h2 className="mb-2">Our Expert Guides</h2>
    <p className="mb-2">
      At Shinde Agritourism, our experienced guides ensure that your visit is memorable. They provide personalized tours, sharing insights about the rich agricultural practices and the local culture. Our team is dedicated to offering the best agro-tourism experience.
    </p>
    <div className="about-listing border-b mb-2 pb-2">
      <h5 className="mb-1">
        <i className="icon-location-pin theme"></i> Expert Agro-Tourism Guides
      </h5>
      <p className="mb-0">
        Our guides are experts in local farming techniques and the history of agritourism, offering a rich and informative experience.
      </p>
    </div>
    <div className="about-listing border-b mb-2 pb-2">
      <h5 className="mb-1">
        <i className="icon-briefcase theme"></i> Correct Farm Directions
      </h5>
      <p className="mb-0">
        Our team ensures you're led through the best routes on our farm tours, providing easy and convenient access to every attraction.
      </p>
    </div>
    <div className="about-listing">
      <h5 className="mb-1">
        <i className="icon-folder theme"></i> Save Time & Learn More
      </h5>
      <p className="mb-0">
        With our expert guidance, you’ll make the most of your visit, exploring everything Shinde Agritourism has to offer while saving time and energy.
      </p>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
      <div className="white-overlay"></div>
    </section>
  );
};

export default Guid;
