
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
// import Gallery from './Smallimages';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Picnicspotnearnashiks() {



  const cardData =
  {
    keyword: ' Picnic Spot Near Nashik',
    heading:  'Picnic Spot Near Nashik: Shinde Agri Tourism',
    headingDescription: 'Looking for a peaceful and refreshing picnic spot near Nashik? Shinde Agri Tourism is an ideal choice for a one-day getaway. Located just a short drive from Nashik, this agro-tourism destination offers a unique experience amidst nature, with plenty of activities for family, friends, or colleagues. Whether you’re looking to relax, enjoy outdoor sports, or engage in farm activities, Shinde Agri Tourism provides a perfect blend of relaxation and adventure, making it the ideal picnic spot near Nashik.',

    top: 'Top Activities at Shinde Agri Tourism	',
    "topPlaces": [
  {
    "title": "Nature Walks and Bird Watching",
    "description": "Take a relaxing walk through lush greenery, where you can enjoy the beauty of nature and spot various bird species. The tranquil atmosphere is perfect for unwinding and connecting with the environment."
  },
  {
    "title": "Outdoor Games and Sports",
    "description": "Engage in a variety of outdoor sports such as cricket, badminton, and volleyball. With wide open spaces, it’s an excellent spot for picnics where families and friends can bond over friendly competition."
  },
  {
    "title": "Interactive Farming Activities",
    "description": "Get hands-on with farming activities like milking cows, planting crops, and harvesting produce. These activities are both fun and educational, providing a unique way to experience rural life while enjoying quality time with loved ones."
  },
  {
    "title": "Barbecue and Bonfire",
    "description": "After a day of activities, enjoy a cozy barbecue followed by a bonfire. It’s a great way to end your picnic with good food, relaxation, and casual conversations in a peaceful setting."
  },
  {
    "title": "Farm-Fresh Meals",
    "description": "Enjoy freshly prepared meals made from organic ingredients grown right on the farm. The farm-to-table experience adds an authentic touch to your picnic, ensuring that your meal is both healthy and delicious."
  },
  {
    "title": "Relaxing Picnic Areas",
    "description": "Shinde Agri Tourism offers well-maintained picnic spots with ample space to relax and enjoy a peaceful day outdoors. Perfect for spreading out a blanket and enjoying a meal with family or friends."
  }
],

"services": [
    {
      "name": "1 Day Picnic Spot Near Nashik",
      "description": "For a 1 day picnic spot near Nashik, Shinde Agri Tourism is a wonderful option. Located just outside Nashik, this agro-tourism farm provides a relaxing, nature-filled day trip with activities such as organic farming workshops, nature walks, and bullock cart rides. It’s a perfect spot for a peaceful one-day escape."
    },
    {
      "name": "2 Days Picnic Spot Near Nashik",
      "description": "If you’re looking for a 2 days picnic spot near Nashik, Shinde Agri Tourism offers overnight stays and a peaceful rural environment to unwind. Enjoy two days of farm activities, including learning about organic farming, taking part in farm tours, and exploring the surrounding natural beauty."
    },
    {
      "name": "Best Places Near Nashik for Picnic",
      "description": "The best places near Nashik for picnic include Shinde Agri Tourism, which offers a unique agro-tourism experience. From farm tours to nature trails and hands-on agricultural activities, it’s a great destination to experience rural life and spend quality time with family or friends."
    },
    {
      "name": "Best Picnic Spot Near Nashik",
      "description": "For the best picnic spot near Nashik, Shinde Agri Tourism provides an ideal setting. The farm’s activities, such as bullock cart rides, farm tours, and nature walks, offer an enjoyable and educational experience for all ages, making it a perfect picnic destination."
    },
    {
      "name": "Family Picnic Spot Near Nashik",
      "description": "If you're looking for a family picnic spot near Nashik, Shinde Agri Tourism is a fantastic option. The farm offers a variety of family-friendly activities, including farm visits, organic farming demonstrations, and nature walks, ensuring everyone from kids to adults has a fun and educational experience."
    },
    {
      "name": "Nashik One Day Picnic Spot",
      "description": "For a Nashik one day picnic spot, Shinde Agri Tourism is an excellent choice. With a range of outdoor activities like nature walks, farm tours, and bullock cart rides, it provides a relaxing, hands-on experience with nature, perfect for a day trip from Nashik."
    },
    {
      "name": "Nashik Picnic Point",
      "description": "Shinde Agri Tourism is a great Nashik picnic point. The farm provides a serene environment away from the hustle and bustle of the city, offering activities that let you explore nature, learn about farming, and bond with friends or family."
    },
    {
      "name": "One Day Picnic Spot Near Nashik",
      "description": "For a one day picnic spot near Nashik, Shinde Agri Tourism is ideal. Its peaceful rural setting offers various fun and educational activities, including farm tours, nature walks, and organic farming workshops. It’s the perfect escape for a quick, nature-filled outing."
    },
    {
      "name": "One Day Picnic Spot Near Nashik for Family",
      "description": "Shinde Agri Tourism is one of the best one day picnic spots near Nashik for family. Families can engage in farm activities, enjoy nature walks, and learn about organic farming, making it a fun and enriching experience for all ages."
    },
    {
      "name": "One Day Return Picnic Spot Near Nashik",
      "description": "If you’re looking for a one day return picnic spot near Nashik, Shinde Agri Tourism is an excellent choice. The farm’s rural charm and wide variety of activities, including nature walks, farm visits, and organic farming experiences, offer a relaxing and educational day out for everyone."
    },
    {
      "name": "Picnic Places Near Nashik",
      "description": "Shinde Agri Tourism offers a unique experience among the picnic places near Nashik. With its tranquil rural setting, it provides a variety of activities, from farm tours to bullock cart rides, making it perfect for a peaceful and fun-filled picnic."
    },
    {
      "name": "Picnic Point in Nashik",
      "description": "The picnic point in Nashik you shouldn’t miss is Shinde Agri Tourism. It offers an immersive farm experience with activities like organic farming, nature walks, and learning about sustainable agriculture, making it an ideal picnic spot."
    },
    {
      "name": "Picnic Spot Near Nashik for 2 Days",
      "description": "For a picnic spot near Nashik for 2 days, Shinde Agri Tourism provides the perfect environment. You can stay overnight and enjoy farm-based activities, nature trails, and outdoor experiences. It’s an ideal spot for those who want a longer escape from the city."
    },
    {
      "name": "Picnic Spot Near Nashik for One Day",
      "description": "For a picnic spot near Nashik for one day, Shinde Agri Tourism is a wonderful option. The farm offers a variety of activities, including bullock cart rides, organic farming workshops, and farm tours, providing a full day of relaxation and fun."
    },
    {
      "name": "Picnic Spots Near Nashik for 1 Day",
      "description": "If you are looking for picnic spots near Nashik for 1 day, Shinde Agri Tourism offers the perfect mix of outdoor activities and relaxation. Enjoy nature walks, farm tours, and organic farming experiences that offer a fun and educational break from city life."
    },
    {
      "name": "Places Near Nashik for One Day",
      "description": "For places near Nashik for one day, Shinde Agri Tourism is an ideal destination. Offering a range of activities like nature trails, farm visits, and organic farming demonstrations, it makes for an enriching day out in the countryside."
    },
    {
      "name": "Shinde Agri Tourism Krushi Paryatan Kendra near Pune Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],

  tableData: [
    ["- 1 Day Picnic Spot Near Nashik", "- 2 Days Picnic Spot Near Nashik"],
    ["- Best Places Near Nashik for Picnic", "- Best Picnic Spot Near Nashik"],
    ["- Family Picnic Spot Near Nashik", "- Nashik One Day Picnic Spot"],
    ["- Nashik Picnic Point", "- One Day Picnic Spot Near Nashik"],
    ["- One Day Picnic Spot Near Nashik for Family", "- One Day Return Picnic Spot Near Nashik"],
    ["- Picnic Places Near Nashik", "- Picnic Point in Nashik"],
    ["- Picnic Spot Near Nashik for 2 Days", "- Picnic Spot Near Nashik for One Day"],
    ["- Picnic Spots Near Nashik for 1 Day", "- Places Near Nashik for One Day"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Ideal Location Close to Nashik",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 60 km from Nashik, making it a perfect choice for a quick and easy day trip or weekend outing. Our location is easily accessible by road, ensuring a hassle-free and enjoyable journey for you and your loved ones."
    },
    {
      "WhyChooseheading": "Beautiful and Peaceful Surroundings",
      "WhyChoosedescription": "Our resort is surrounded by lush greenery, scenic landscapes, and peaceful farm surroundings. It’s the perfect setting to escape the noise and pollution of the city, relax with loved ones, and enjoy nature at its best. The tranquil environment helps you unwind, rejuvenate, and connect with nature."
    },
    {
      "WhyChooseheading": "Family-Friendly Destination",
      "WhyChoosedescription": "Shinde Agri Tourism is perfect for families, especially with young children. Kids will love the chance to participate in animal interactions, fruit picking, and nature walks. There’s plenty of space for kids to run around, while adults can relax and enjoy the peaceful surroundings."
    },
    {
      "WhyChooseheading": "Affordable Picnic Packages",
      "WhyChoosedescription": "We offer affordable picnic packages that cater to different group sizes and preferences. Whether you're a small family or a large group, we can provide packages that include activities, meals, and everything you need for a fun and memorable outing."
    },
    {
      "WhyChooseheading": "Eco-Friendly and Comfortable Facilities",
      "WhyChoosedescription": "Our resort is designed with sustainability in mind. We offer eco-friendly accommodations for those who want to extend their stay, as well as picnic spots equipped with seating and shade to make your day comfortable."
    },
    {
      "WhyChooseheading": "Perfect for Group Picnics",
      "WhyChoosedescription": "Whether you're organizing a picnic for family, friends, or colleagues, Shinde Agri Tourism is an ideal venue. Our spacious grounds and diverse range of activities ensure that everyone has something fun to do, making it a great spot for group gatherings."
    },
    {
      "WhyChooseheading": "Relax and Unwind",
      "WhyChoosedescription": "After a day of activities, relax in the peaceful ambiance and enjoy a leisurely evening with your loved ones. You can even have a small campfire or simply enjoy the fresh air and starry skies. It’s the perfect place to disconnect from the stresses of daily life and reconnect with nature."
    }
  ]
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Nashik?",
      answer: "Shinde Agri Tourism is located 60 km from Nashik, which is about an hour to 1.5 hours by car. It’s an easy and convenient drive, making it a perfect destination for a quick day trip or weekend getaway."
    },
    {
      question: "What activities are available for a picnic at Shinde Agri Tourism?",
      answer: "We offer a range of activities for picnic-goers, including farm visits, fruit picking, animal interactions, outdoor games (cricket, volleyball, badminton), nature walks, and adventure activities like zip-lining, rock climbing, and archery. There’s something for everyone!"
    },
    {
      question: "Is food provided during the picnic?",
      answer: "Yes, we offer a farm-to-table dining experience, serving fresh, delicious meals made from organic ingredients grown on our farm. Our picnic lunch options can be customized to your group’s preferences, ensuring a tasty and fulfilling experience for all."
    },
    {
      question: "Is Shinde Agri Tourism suitable for family picnics?",
      answer: "Absolutely! Shinde Agri Tourism is perfect for family picnics. There are plenty of kid-friendly activities like animal interactions, fruit picking, and outdoor games, and the peaceful surroundings allow for a relaxing day for adults as well."
    },
    {
      question: "Can I book a picnic for a large group?",
      answer: "Yes! We cater to both small and large groups. Whether you're planning a family outing or a group picnic with friends or colleagues, we offer flexible packages that can be customized to your needs and group size."
    },
    {
      question: "Are there accommodations available if we want to stay overnight?",
      answer: "Yes, we offer eco-friendly cottages and tented stays for those who want to extend their picnic into an overnight trip. Our accommodations are comfortable and offer a relaxing environment to enjoy the natural surroundings."
    },
    {
      question: "Can we arrange a special picnic package for an occasion like a birthday or anniversary?",
      answer: "Yes, we can customize picnic packages for special occasions such as birthdays, anniversaries, or corporate outings. Let us know the details, and we’ll arrange everything to make your occasion extra special."
    },
    {
      question: "How do I book a picnic at Shinde Agri Tourism?",
      answer: "Booking a picnic is easy! Simply contact us via our website or phone, and we will help you select the perfect picnic package. We’ll assist you with everything from selecting activities to arranging meals for your group."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Snehal & Family, Nashik",
      text: "We had an amazing family picnic at Shinde Agri Tourism! The kids had a blast interacting with the animals and picking fruits, and the adults enjoyed the peaceful surroundings and farm-fresh food. It was a perfect day, and we can’t wait to come back!"
    },
    {
      name: "Mr. Amit, Group Outing Organizer, Nashik",
      text: "Shinde Agri Tourism is the perfect picnic spot near Nashik! The variety of activities kept everyone entertained, and the fresh meals made it all the more enjoyable. It was a relaxing day with beautiful surroundings, and we’ll definitely be returning for our next picnic."
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Picnic Spot Near Nashik | Best One Day Picnic Spots & Family Picnic Locations | Nashik Picnic Points | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best picnic spots near Nashik? Explore top 1-day and 2-day picnic spots for families, friends, and couples. Find ideal picnic locations near Nashik for a one-day return trip, family picnic points, and scenic getaway options." />
  <meta name="keywords" content="Picnic Spot Near Nashik, 1 Day Picnic Spot Near Nashik, 2 Days Picnic Spot Near Nashik, Best Picnic Places Near Nashik, Family Picnic Spot Near Nashik, Nashik One Day Picnic Spot, Nashik Picnic Point, One Day Picnic Spot Near Nashik, One Day Picnic Spot Near Nashik for Family, One Day Return Picnic Spot Near Nashik, Picnic Places Near Nashik, Picnic Point in Nashik, Picnic Spot Near Nashik for 2 Days, Picnic Spot Near Nashik for One Day, Picnic Spots Near Nashik for 1 Day, Places Near Nashik for One Day Picnic" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/picnic-spot-near-nashik" />

  {/* JSON-LD Schema for Picnic Spot Near Nashik */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Picnic Spot Near Nashik",
        "url": "https://shindekrushiparyatan.com/picnic-spot-near-nashik",
        "description": "Discover the best picnic spots near Nashik. Explore 1-day and 2-day picnic locations, family-friendly picnic spots, and scenic destinations perfect for a getaway near Nashik.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Nashik",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.9975",
          "longitude": "73.7898"
        },
        "image": "https://shindekrushiparyatan.com/images/picnic-spot-near-nashik.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/38.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Picnicspotnearnashiks;