import React from 'react';



const Ashtavinayak = () => {
  return (
    <div>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Ashtavinayak Darshan Stay</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>




      <section className="ashtavinayak-darshan">
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6  '>
              <h1>Ashtavinayak Darshan</h1>
              <p>
                The Ashtavinayak pilgrimage consists of eight revered temples of Lord Ganesha
                in Maharashtra. Here's the list of these divine destinations and the best place to stay:
              </p>

              {/* Theur Ganpati */}
              <div className='row mahad-ganpati-row'>
                <h2>Theur Ganpati</h2>

                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/1.jfif" alt="Theur Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Theur Ganpati, also known as "Chintamani Vinayak," is one of the most important
                      temples in the Ashtavinayak circuit. It is said that Lord Ganesha here grants the
                      fulfillment of wishes and removes obstacles.
                    </p>
                  </div>
                </div>
              </div>


              {/* Moregaon Ganpati */}
              <div className='row mahad-ganpati-row'>
                <h2>Moregaon Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/mor.jpg" alt="Moregaon Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      The Moregaon Ganpati temple is located in the town of Morgaon and is known for its
                      peaceful atmosphere. It is also called "Moreshwar," named after the Lord's connection
                      with the peacock.
                    </p>
                  </div>
                </div>
              </div>


              {/* Siddhatek Ganpati */}
              <div className='row mahad-ganpati-row'>
                <h2>Siddhatek Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/Sid.jpg" alt="Siddhatek Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Siddhatek Ganpati, located in Siddhatek near Ahmednagar, is considered the center of
                      knowledge and wisdom. It is the place where Lord Ganesha is believed to have given
                      knowledge to the great sage, Lord Parshuram.
                    </p>
                  </div>
                </div>
              </div>








              {/* Ranjangaon Ganpati */}
              <div className='row mahad-ganpati-row'>
                <h2>Ranjangaon Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/ran.jpg" alt="Ranjangaon Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Ranjangaon Ganpati is located in the town of Ranjangaon and is known for its grandeur.
                      This temple is special because it is associated with the victory of Lord Ganesha over
                      demons, and the idol here is in the form of "Vighnaharta" (remover of obstacles).
                    </p>
                  </div>
                </div>
              </div>








              {/* Shinde Agri Tourism - Best Place to Stay */}
              <div className="stay-section mahad-ganpati-row">
                <h2>Shinde Agri Tourism - Best Place to Stay</h2>
                {/* <img src="/images/homee.jpg" alt="Shinde Agri Tourism" /> */}
                <p className='text-white'>
                  After your Ranjangaon Darshan, **Shinde Agri Tourism** is the best place to relax and
                  unwind. Located in a serene and peaceful environment, it offers comfortable farm-style
                  stays. You can enjoy organic food, explore agricultural activities, and relax in nature’s
                  lap before continuing your Ashtavinayak journey.
                </p>
                <ul >
                  <li className='text-white'>Beautiful farm-style cottages with modern amenities</li>
                  <li className='text-white'>Organic and fresh food served on-site</li>
                  <li className='text-white'>Close proximity to the Ashtavinayak temples</li>
                  <li className='text-white'>Perfect for pilgrims and nature lovers alike</li>
                </ul>
              </div>

              {/* Ozar Ganpati */}
              <div className='row mahad-ganpati-row'>
                <h2>Ozar Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/ozar.webp" alt="Ozar Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Ozar Ganpati is located in the village of Ozar and is also known as "Vigneshwar." The temple
                      is unique for its beauty and divine serenity. It is said that Lord Ganesha here is the
                      destroyer of obstacles.
                    </p>
                  </div>
                </div>
              </div>




              {/* Lenyadri Ganpati */}

              <div className='row mahad-ganpati-row'>
                <h2>Lenyadri Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/lay.jpg" alt="Lenyadri Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Lenyadri Ganpati is located on a hill in the Junnar region, known for its historical significance.
                      The temple is built into the caves, offering an excellent view of the surrounding hills and valley.
                    </p>
                  </div>
                </div>
              </div>



              <div className='row mahad-ganpati-row'>
                <h2>Mahad Ganpati</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/mahad.jfif" alt="Mahad Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Mahad Ganpati is located in Mahad, and it is famous for its large idol and natural surroundings.
                      It is believed that Lord Ganesha here protects people from all kinds of dangers.
                    </p>
                  </div>
                </div>
              </div>




              {/* Pali Ganpati */}

              <div className='row mahad-ganpati-row'>
                <h2>Pali Ganpati(Vighneshwar)</h2>
                <div className='col-12 col-md-6 mahad-ganpati-image'>
                  <img src="/images/pali.jfif" alt="Pali Ganpati" />
                </div>
                <div className='col-12 col-md-6 mahad-ganpati-description'>
                  <div className="temple">
                    <p>
                      Pali Ganpati is a revered temple located in Pali, near Lonavala. The idol here is called
                      "Vighneshwar," and it is said that worshipping here removes all kinds of obstacles.
                    </p>
                  </div>
                </div>
              </div>

            </div>





            <div className='col-12 col-md-6'>
              <div>
                <iframe src="https://www.google.com/maps/d/embed?mid=11t9cNmxicZO6JQQNkrsVUO2bFvKKkOI&ehbc=2E312F" width="640" height="580"></iframe>
              </div>
              < img src='/images/Map.png' className='' />
              <div className='py-md-5'>
                <img src="/images/homee.jpg" alt="Shinde Agri Tourism" />
              </div>

            </div>


          </div>
        </div>
      </section>



      <section>

      </section>
    </div>
  );
};

export default Ashtavinayak;
            