
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Activitiesresort() {



  const cardData =
  {
    keyword: ' Activities Resort Near Pune',
    heading:  'Activities Resort Near Pune: Shinde Agri Tourism',
    headingDescription: 'If you are looking for a resort near Pune that offers a variety of activities for all ages, Shinde Agri Tourism is the perfect destination. Nestled in the heart of nature, this agri-tourism resort blends relaxation with adventure, offering a wide range of activities for families, groups, and corporate teams. Whether you are seeking outdoor sports, cultural experiences, or educational farm activities, Shinde Agri Tourism ensures an unforgettable experience.',

    top: 'Top Activities at Shinde Agri Tourism	',
   "topPlaces": [
  {
    "title": "Outdoor Sports and Games",
    "description": "Shinde Agri Tourism offers a variety of outdoor sports and games to keep everyone entertained. From cricket to badminton, volleyball, and football, there’s plenty of space for fun and friendly competition. Whether you're with friends or family, these activities add an element of excitement to your visit."
  },
  {
    "title": "Nature Walks and Trekking",
    "description": "Explore the beautiful countryside surrounding Shinde Agri Tourism through nature walks or trekking trails. Immerse yourself in lush landscapes, spot local wildlife, and enjoy the peace and tranquility that nature provides. This is a great way to connect with the environment and experience the scenic beauty of the area."
  },
  {
    "title": "Interactive Farming Activities",
    "description": "Dive into the world of agriculture with hands-on farming activities. You can try your hand at planting crops, harvesting produce, or even experience milking cows. This is a fantastic experience for families and children, offering educational and interactive ways to learn about farming and rural life."
  },
  {
    "title": "Bonfire and Stargazing",
    "description": "At night, gather around a campfire with your loved ones. Share stories, enjoy marshmallows, and bask in the warmth of the flames. Afterward, enjoy a relaxing session of stargazing, as the clear skies and lack of light pollution make it the perfect environment to spot constellations."
  },
  {
    "title": "Cultural Performances and Folk Music",
    "description": "Immerse yourself in local culture through traditional folk performances and live music. Shinde Agri Tourism offers a glimpse into Maharashtra’s rich cultural heritage with folk dances, music performances, and storytelling sessions around the bonfire."
  },
  {
    "title": "Farm-to-Table Dining Experience",
    "description": "Enjoy a unique farm-to-table dining experience at the resort. The food is prepared using fresh, organic ingredients sourced directly from the farm. You can savor authentic Maharashtrian dishes, home-cooked meals, and local delicacies while enjoying the scenic surroundings."
  },
  {
    "title": "Nature Photography",
    "description": "With its stunning landscapes, Shinde Agri Tourism is a photographer's paradise. Whether you’re an amateur or a professional, the beautiful fields, rustic farmhouses, and natural beauty of the area offer fantastic opportunities for photography. Capture memories of your visit through photos that reflect the beauty and serenity of the resort."
  },
  {
    "title": "Bird Watching and Wildlife Spotting",
    "description": "The resort's lush environment is home to a variety of birds and wildlife, making it an ideal location for bird watching. Nature enthusiasts can enjoy spotting different bird species and other animals in their natural habitat, especially during the early mornings or late evenings."
  },
  {
    "title": "Adventure Activities for Groups",
    "description": "If you’re visiting with a group, Shinde Agri Tourism provides several group activities like team-building games, treasure hunts, and scavenger hunts. These activities promote teamwork, communication, and problem-solving skills in a fun and engaging environment."
  },
  {
    "title": "Relaxing Picnic Areas",
    "description": "Shinde Agri Tourism features spacious picnic areas for visitors who simply want to relax and enjoy the outdoors. Whether you’re lounging with family or friends or reading a book in the shade, the peaceful setting ensures a rejuvenating experience."
  }
],

  "services": [
  {
    "name": "Resorts Near Pune with Activities",
    "description": "If you're looking for resorts near Pune with activities, Shinde Agri Tourism is a great choice. Located just outside Pune, this agro-tourism destination offers a range of activities such as farm tours, nature walks, bullock cart rides, and hands-on agricultural experiences. It’s perfect for those seeking a mix of adventure, relaxation, and learning in a natural setting."
  },
  {
    "name": "Adventure Resorts Near Pune",
    "description": "For those seeking adventure resorts near Pune, Shinde Agri Tourism provides a perfect blend of adventure and rural life. Activities like trekking, nature walks, and interactive farming activities make it an ideal spot for adventure lovers. The scenic beauty of the surroundings adds to the overall experience, making it a great destination for nature-based adventure."
  },
  {
    "name": "One Day Picnic Resorts with Activities Near Pune",
    "description": "For a one day picnic resort with activities near Pune, Shinde Agri Tourism offers the perfect getaway. With farm visits, bullock cart rides, and nature walks, it’s an ideal place to spend a day with family or friends. The peaceful rural environment, combined with fun activities, ensures an enjoyable one-day picnic."
  },
  {
    "name": "Resorts Near Pune for Corporate Outing",
    "description": "Shinde Agri Tourism is an excellent choice for a corporate outing near Pune. The farm offers team-building activities, farm experiences, and the chance to relax in a serene environment. It’s perfect for corporate groups looking for an outdoor escape with educational and engaging activities related to farming and nature."
  },
  {
    "name": "Resort with Activities Near Pune",
    "description": "Looking for a resort with activities near Pune? Shinde Agri Tourism is an ideal destination. It offers a variety of activities such as organic farming workshops, nature walks, bullock cart rides, and more. The rural setting provides a peaceful atmosphere where you can enjoy both learning and adventure in a relaxed environment."
  },
  {
    "name": "Resorts with Adventure Activities Near Pune",
    "description": "For resorts with adventure activities near Pune, Shinde Agri Tourism offers unique experiences like trekking, nature walks, and other outdoor activities in a beautiful farm setting. If you're looking for a bit of adventure mixed with rural charm, this agro-tourism center has plenty to offer."
  },
  {
    "name": "Resorts for Corporate Outing Near Pune",
    "description": "For resorts for corporate outing near Pune, Shinde Agri Tourism is an excellent option. It provides a tranquil yet engaging environment, perfect for corporate team-building activities. From farm tours to nature-based workshops, your team can enjoy both adventure and relaxation, all within close proximity to Pune."
  },
  {
    "name": "Resort Near Pune with Activities",
    "description": "A resort near Pune with activities like Shinde Agri Tourism offers a range of activities for all ages. From organic farming and rural life experiences to bullock cart rides and nature walks, it’s an ideal destination for those looking to connect with nature and enjoy outdoor activities."
  },
  {
    "name": "Resorts in Pune with Activities",
    "description": "Among the resorts in Pune with activities, Shinde Agri Tourism stands out for its immersive agro-tourism experience. With activities like farm tours, organic farming workshops, and nature walks, it offers a hands-on experience that combines learning with fun in a peaceful rural setting."
  },
  {
    "name": "Resorts Near Pune for Family with Activities",
    "description": "For resorts near Pune for family with activities, Shinde Agri Tourism is a great option. Families can enjoy interactive farming experiences, bullock cart rides, nature trails, and more. It’s the perfect destination for a family outing, offering a combination of education, fun, and relaxation in nature."
  },
  {
    "name": "One Day Adventure Resort Near Pune",
    "description": "If you’re looking for a one day adventure resort near Pune, Shinde Agri Tourism is an ideal choice. With activities like nature walks, farm tours, and hands-on farming experiences, it offers a full day of fun and learning. It's perfect for a quick adventure trip with family or friends."
  },
  {
    "name": "Activity Resorts in Pune",
    "description": "Shinde Agri Tourism is one of the top activity resorts in Pune. The farm offers various activities like bullock cart rides, nature walks, organic farming workshops, and more. It’s a great place for those looking to experience rural life and enjoy outdoor activities close to Pune."
  },
  {
    "name": "Adventure Activities Resort Near Pune",
    "description": "For an adventure activities resort near Pune, Shinde Agri Tourism is an excellent option. While the focus is on agro-tourism, you can enjoy outdoor adventures like trekking, bullock cart rides, and exploring the natural beauty of the surrounding countryside."
  },
  {
    "name": "Adventure Games Resort Near Pune",
    "description": "Shinde Agri Tourism may not offer typical 'adventure games' but it provides adventure in its own way. With activities like trekking, nature walks, and farm experiences, it’s a great place to get outdoors and engage in a different kind of adventure close to Pune."
  },
  {
    "name": "Adventure Resort Pune Day Out",
    "description": "If you're looking for an adventure resort Pune day out, Shinde Agri Tourism provides a day full of activities like farm tours, organic farming workshops, and nature walks. It’s a great option for those looking to escape the city for a day and experience rural life."
  },
  {
    "name": "Adventure Resorts in Pune",
    "description": "For adventure resorts in Pune, Shinde Agri Tourism offers a more nature-focused experience. While it doesn’t provide extreme sports, it offers peaceful outdoor activities such as nature walks, farm tours, and bullock cart rides, making it an adventure in rural life and nature."
  },
  {
    "name": "Adventure Sports Resort Near Pune",
    "description": "Though Shinde Agri Tourism focuses more on farming and nature experiences rather than extreme adventure sports, it still offers plenty of outdoor activities like trekking and nature walks for those looking to get closer to nature. It’s perfect for a peaceful yet engaging experience near Pune."
  },
  {
    "name": "Best Activity Resorts Near Pune",
    "description": "The best activity resorts near Pune don’t just focus on relaxation, they also offer activities. Shinde Agri Tourism is a great example of this, offering farm tours, organic farming workshops, nature walks, and more. It’s the perfect place for those who want to enjoy both the outdoors and rural life."
  },
  {
    "name": "Best Resort with Activities Near Pune",
    "description": "For the best resort with activities near Pune, Shinde Agri Tourism is a top choice. This agro-tourism farm offers a wide range of engaging activities like organic farming workshops, nature walks, bullock cart rides, and much more, all in a peaceful rural setting."
  },
  {
    "name": "Best Resorts Near Pune with Activities",
    "description": "Shinde Agri Tourism is one of the best resorts near Pune with activities, offering everything from farm tours and educational workshops to nature walks and outdoor adventures. It’s perfect for those looking to enjoy both adventure and relaxation in a natural, rural setting."
  },
  {
    "name": "Day Outing Resorts Near Pune",
    "description": "For a day outing resort near Pune, Shinde Agri Tourism is an ideal destination. It offers a full day of engaging activities like farm tours, nature walks, bullock cart rides, and organic farming workshops, all within a peaceful rural environment just a short drive from Pune."
  },
  {
    "name": "Day Trip Resorts Near Pune",
    "description": "Shinde Agri Tourism is an excellent option for a day trip resort near Pune. You can spend the day exploring the farm, participating in hands-on farming activities, and enjoying the natural beauty of the area. It’s the perfect place for a relaxing yet educational day trip."
  },
  {
    "name": "One Day Outing Resort Near Pune",
    "description": "For a one day outing resort near Pune, Shinde Agri Tourism is an excellent choice. The farm offers a variety of activities, from farm tours to nature walks, making it the perfect destination for a one-day getaway filled with adventure and learning."
  },
  {
    "name": "One Day Activity Resort Near Pune",
    "description": "Shinde Agri Tourism is ideal for a one day activity resort near Pune. With activities like nature walks, farm tours, and educational workshops on organic farming, you’ll have plenty to do in just one day. It’s the perfect place for a relaxing, activity-filled escape from the city."
  },
  {
    "name": "Shinde Agri Tourism Activities Resort Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for the perfect activities resort near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Resorts Near Pune with Activities", "- Adventure Resorts Near Pune"],
    ["- One Day Picnic Resorts with Activities Near Pune", "- Resorts Near Pune for Corporate Outing"],
    ["- Resort with Activities Near Pune", "- Resorts with Adventure Activities Near Pune"],
    ["- Resorts for Corporate Outing Near Pune", "- Resort Near Pune with Activities"],
    ["- Resorts in Pune with Activities", "- Resorts Near Pune for Family with Activities"],
    ["- One Day Adventure Resort Near Pune", "- Activity Resorts in Pune"],
    ["- Adventure Activities Resort Near Pune", "- Adventure Games Resort Near Pune"],
    ["- Adventure Resort Pune Day Out", "- Adventure Resorts in Pune"],
    ["- Adventure Sports Resort Near Pune", "- Best Activity Resorts Near Pune"],
    ["- Best Resort with Activities Near Pune", "- Best Resorts Near Pune with Activities"],
    ["- Day Outing Resorts Near Pune", "- Day Trip Resorts Near Pune"],
    ["- One Day Outing Resort Near Pune", "- One Day Activity Resort Near Pune"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Team-Building Activities for Corporate Groups",
      "WhyChoosedescription": "Shinde Agri Tourism is an ideal destination for corporate team-building activities. From problem-solving challenges to collaborative games, our resort helps foster teamwork, leadership, and communication skills. Activities like trust falls, obstacle courses, and group games can be tailored to suit your corporate needs."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining Experience",
      "WhyChoosedescription": "After a day full of activities, enjoy delicious, freshly prepared meals made from locally grown, organic produce. Our farm-to-table concept ensures that you indulge in healthy, fresh, and wholesome food. Enjoy traditional Maharashtrian dishes, grilled snacks, organic salads, and more."
    },
    {
      "WhyChooseheading": "Nature and Relaxation",
      "WhyChoosedescription": "For those looking to unwind and relax, our resort offers tranquil nature walks, bird watching, and peaceful spaces to meditate and connect with nature. The serene atmosphere provides the perfect balance between activity and relaxation, making it a great choice for those seeking both adventure and peace."
    },
    {
      "WhyChooseheading": "Adventure for All Ages",
      "WhyChoosedescription": "Shinde Agri Tourism offers activities that cater to every age group, from young children to adults. Kids can enjoy activities like obstacle courses, nature trails, and farm tours, while adults can engage in more challenging activities like zip-lining and rock climbing. It’s an inclusive environment for all kinds of outdoor enthusiasts."
    },
    {
      "WhyChooseheading": "Spacious and Comfortable Accommodations",
      "WhyChoosedescription": "Our resort features a range of comfortable accommodations, including well-maintained cottages, eco-friendly tents, and rooms equipped with modern amenities. Whether you're looking for a luxurious stay or a more rustic experience, we provide a range of options to suit your needs."
    },
    {
      "WhyChooseheading": "Scenic Location Near Pune",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is easily accessible by a 2 to 2.5-hour drive. The resort is nestled in a scenic location surrounded by lush greenery, hills, and farmland, offering a peaceful escape from the hustle and bustle of city life."
    },
    {
      "WhyChooseheading": "Perfect for Group Getaways",
      "WhyChoosedescription": "Whether you're planning a family vacation, a group of friends, or a corporate outing, Shinde Agri Tourism is an excellent choice. We offer customizable packages for group bookings, ensuring that everyone in your group has an unforgettable experience filled with fun, adventure, and bonding."
    }
  ]
  


  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located around 100 km from Pune, approximately a 2 to 2.5-hour drive. It’s the perfect weekend getaway destination close to the city."
    },
    {
      question: "What activities are available at the resort?",
      answer: "We offer a variety of activities, including zip-lining, rock climbing, rappelling, archery, nature walks, trekking, and outdoor games such as volleyball and badminton. There are also team-building exercises for corporate groups."
    },
    {
      question: "Are there any activities for children?",
      answer: "Yes! Our resort is family-friendly, and we offer a range of activities for children, such as nature trails, farm tours, obstacle courses, and games. It’s a perfect place for family bonding and adventure."
    },
    {
      question: "Can we have a corporate outing at Shinde Agri Tourism?",
      answer: "Absolutely! Shinde Agri Tourism is an ideal destination for corporate groups. We offer customized team-building packages, outdoor games, leadership exercises, and workshops designed to enhance teamwork and communication."
    },
    {
      question: "What kind of food is served at the resort?",
      answer: "We serve freshly prepared meals with a farm-to-table concept. Our menu includes traditional Maharashtrian dishes, organic salads, grilled snacks, and more. The food is made with locally sourced ingredients from our farm."
    },
    {
      question: "Do you offer overnight stays at the resort?",
      answer: "Yes, we offer comfortable overnight accommodations. Our options include cottages, eco-friendly tents, and rooms with modern amenities. Stay in comfort while enjoying the natural surroundings and activities."
    },
    {
      question: "Is Shinde Agri Tourism suitable for a family weekend trip?",
      answer: "Absolutely! Our resort is perfect for family weekend trips. It offers a wide range of activities for all ages, comfortable accommodations, delicious food, and plenty of opportunities for family bonding in nature."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Radhika & Family, Pune",
      text: "Our family had an amazing weekend at Shinde Agri Tourism! The activities were so much fun – the zip-lining and rock climbing were real highlights. The kids enjoyed the farm tour and nature walk, while the food was absolutely delicious. Highly recommend it for a fun family getaway!"
    },
    {
      name: "Mr. Vikram, HR Manager, Mumbai",
      text: "Shinde Agri Tourism is the perfect place for a corporate outing. The team-building activities were well-organized, and everyone had a great time. The combination of adventure and relaxation in such a beautiful setting made it a memorable experience for all of us!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Activities Resort Near Pune | Adventure Resorts, Corporate Outings & Family Resorts with Activities | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for resorts near Pune with activities? Explore adventure resorts, one-day picnic resorts, resorts for corporate outings, and family-friendly resorts with fun activities at Shinde Krushi Paryatan. Perfect for day trips and family outings!" />
  <meta name="keywords" content="Resorts near Pune with activities, adventure resorts near Pune, one day picnic resorts with activities near Pune, resorts near Pune for corporate outing, resort with activities near Pune, resorts with adventure activities near Pune, resorts for corporate outing near Pune, resort near Pune with activities, resorts in Pune with activities, resorts near Pune for family with activities, one day adventure resort near Pune, activity resorts in Pune, adventure activities resort near Pune, adventure games resort near Pune, adventure resort Pune day out, adventure resorts in Pune, adventure sports resort near Pune, best activity resorts near Pune, best resort with activities near Pune, best resorts near Pune with activities, day outing resorts near Pune, day trip resorts near Pune, one day outing resort near Pune, one day activity resort near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/activities-resort-near-pune" />

  {/* JSON-LD Schema for Activities Resort Near Pune */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Activities Resort Near Pune",
        "url": "https://shindekrushiparyatan.com/activities-resort-near-pune",
        "description": "Find the best resorts near Pune with activities like adventure games, team outings, and family fun. Enjoy one-day picnic resorts, corporate outing resorts, and adventure sports at Shinde Krushi Paryatan near Pune.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/activities-resort-near-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "200"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/30.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Activitiesresort;