
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Onedaypicnicspot() {



  const cardData =
  {
    keyword: ' One Day Picnic Spot From Mumbai',
    heading:  'One Day Picnic Spot From Mumbai: Shinde Agri Tourism',
    headingDescription: 'If you are looking for a quick getaway from Mumbai that combines nature, relaxation, and fun activities, Shinde Agri Tourism is the perfect choice for a one-day picnic. Located just a short drive from Mumbai, this beautiful agro-tourism destination offers an escape from the hustle and bustle of city life and provides a refreshing experience in the lap of nature.',

    top: 'Top Activities at Shinde Agri Tourism for a One-Day Picnic	',
  "topPlaces": [
  {
    "title": "Interactive Farming Activities",
    "description": "Spend the day exploring traditional farming practices with activities like planting crops, milking cows, and harvesting vegetables. These fun and educational experiences will allow you to connect with nature while learning about sustainable farming methods."
  },
  {
    "title": "Outdoor Games and Sports",
    "description": "Get your group involved in some friendly competition with outdoor games like cricket, badminton, and volleyball. The large open spaces are perfect for group activities and sports, making it an ideal setting for family and friends to bond."
  },
  {
    "title": "Nature Walks and Bird Watching",
    "description": "For those who enjoy peace and tranquility, enjoy a nature walk through lush greenery, or indulge in bird watching. With a variety of bird species and serene surroundings, the resort is a perfect place to unwind and connect with nature."
  },
  {
    "title": "Picnic Areas with Scenic Views",
    "description": "Relax with your loved ones in the resort's well-maintained picnic spots. These peaceful areas, surrounded by nature, are perfect for a laid-back afternoon. Bring along your picnic basket, enjoy a delicious meal, and soak in the beautiful views."
  },
  {
    "title": "Traditional Maharashtrian Cuisine",
    "description": "Savor delicious Maharashtrian food made with fresh ingredients straight from the farm. The resort offers home-cooked meals that allow you to experience the authentic flavors of the region. Enjoy a hearty meal after a fun-filled day of activities."
  },
  {
    "title": "Photography Opportunities",
    "description": "Shinde Agri Tourism provides plenty of opportunities for photography, with its beautiful landscapes, rustic farmhouses, and vibrant fields. Capture memorable moments with your friends and family against the picturesque backdrop of nature."
  },
  {
    "title": "Bonfire and Stargazing",
    "description": "As the day winds down, gather around a bonfire to enjoy the warmth and share stories. Afterward, indulge in stargazing as you enjoy the peaceful night sky, far from the city’s light pollution."
  },
  {
    "title": "Traditional Folk Performances",
    "description": "Experience the local culture by enjoying folk performances that showcase Maharashtra's rich heritage. This adds a unique cultural touch to your picnic, making it even more memorable."
  },
  {
    "title": "Relaxing by the Farm",
    "description": "Take a moment to relax by the farm, breathing in the fresh air and enjoying the peaceful environment. Whether you choose to sit by the fields or walk around the farm, it’s a great place to unwind and enjoy the simplicity of rural life."
  },
  {
    "title": "Team Building Activities",
    "description": "If you’re planning a picnic with colleagues or a large group of friends, engage in some team-building activities. These activities help build teamwork, communication, and collaboration, all while having fun in a natural setting."
  }
],

     "services": [
  {
    "name": "One Day Picnic Resorts Near Mumbai",
    "description": "For one day picnic resorts near Mumbai, Shinde Agri Tourism is an excellent choice. Located just a few hours from the city, this agro-tourism farm offers a variety of activities like farm tours, nature walks, bullock cart rides, and organic farming experiences, making it a perfect place for a quick, refreshing getaway from Mumbai."
  },
  {
    "name": "One Day Picnic in Mumbai",
    "description": "If you're looking for a one day picnic in Mumbai, Shinde Agri Tourism offers a great option just outside the city. The farm offers peaceful surroundings, nature walks, and educational experiences, making it an ideal choice for a relaxing, nature-filled day."
  },
  {
    "name": "One Day Picnic Spots Near Mumbai for Family",
    "description": "For a one day picnic spot near Mumbai for family, Shinde Agri Tourism provides a fantastic experience. Families can enjoy outdoor activities such as bullock cart rides, farm visits, and learning about sustainable agriculture in a tranquil rural setting. It’s the perfect location for a fun-filled, educational day trip with the family."
  },
  {
    "name": "Day Picnic Near Mumbai",
    "description": "A day picnic near Mumbai at Shinde Agri Tourism offers the perfect escape from the city. Spend the day exploring the farm, engaging in activities like nature walks and bullock cart rides, and relaxing in the natural surroundings of the countryside."
  },
  {
    "name": "One Day Return Trip Near Mumbai",
    "description": "For a one day return trip near Mumbai, Shinde Agri Tourism is an ideal destination. You can leave Mumbai in the morning, enjoy a full day of activities like farm tours and organic farming workshops, and return by evening, all while experiencing the charm of rural life."
  },
  {
    "name": "1 Day Picnic Spot in Mumbai",
    "description": "Though Shinde Agri Tourism is located just outside Mumbai, it remains one of the best 1 day picnic spots near Mumbai. The farm provides a peaceful and educational experience, perfect for a short getaway."
  },
  {
    "name": "Day Picnic Resorts Near Mumbai",
    "description": "For day picnic resorts near Mumbai, Shinde Agri Tourism is a great option. The farm offers an array of outdoor activities, farm experiences, and the opportunity to relax in nature, all within close proximity to the city."
  },
  {
    "name": "1 Day 1 Night Picnic Spots Near Mumbai",
    "description": "For those looking to enjoy a 1 day 1 night picnic spot near Mumbai, Shinde Agri Tourism can be a fantastic option. The farm offers overnight stays, where you can enjoy the natural surroundings and a variety of activities such as farm tours, nature walks, and organic farming workshops."
  },
  {
    "name": "1 Day Picnic in Mumbai",
    "description": "For a 1 day picnic in Mumbai, Shinde Agri Tourism offers an excellent option just outside the city. It’s the perfect place for a day filled with fun and learning, offering farm tours, nature walks, and more in a peaceful, rural environment."
  },
  {
    "name": "1 Day Picnic for Couples in Mumbai",
    "description": "Looking for a 1 day picnic for couples in Mumbai? Shinde Agri Tourism offers a peaceful and romantic environment for couples. Enjoy the calm, scenic beauty of the farm, take a nature walk, and explore organic farming while enjoying a quiet day away from the city."
  },
  {
    "name": "1 Day Picnic Near Mumbai",
    "description": "For a 1 day picnic near Mumbai, Shinde Agri Tourism provides a memorable experience. With activities like bullock cart rides, farm visits, and nature trails, it's the perfect place to relax and enjoy the beauty of rural life for a day."
  },
  {
    "name": "1 Day Picnic Spot in Mumbai for Couples",
    "description": "Shinde Agri Tourism is a great 1 day picnic spot near Mumbai for couples. Spend a day surrounded by nature, take a peaceful walk, explore the farm, and enjoy the serenity of the countryside, making it an ideal spot for couples to unwind and connect."
  },
  {
    "name": "1 Day Picnic Places in Mumbai",
    "description": "If you’re looking for 1 day picnic places in Mumbai, Shinde Agri Tourism offers a great rural retreat. With nature-based activities, farm experiences, and peaceful surroundings, it’s perfect for those seeking a quick and refreshing getaway."
  },
  {
    "name": "1 Day Picnic Spot Near Mumbai",
    "description": "For a relaxing 1 day picnic spot near Mumbai, Shinde Agri Tourism provides a unique agro-tourism experience. Enjoy the peaceful farm atmosphere, take part in activities like nature walks, and learn about sustainable agriculture, all in a tranquil setting just outside Mumbai."
  },
  {
    "name": "1 Day Picnic Spot Near Mumbai for Couples",
    "description": "For a 1 day picnic spot near Mumbai for couples, Shinde Agri Tourism is a great option. The serene atmosphere, combined with activities like farm tours, nature walks, and organic farming workshops, offers couples a peaceful and unique day out in nature."
  },
  {
    "name": "1 Day Spot Near Mumbai",
    "description": "Shinde Agri Tourism is a great 1 day spot near Mumbai, offering visitors the chance to escape the city and enjoy a day filled with farm experiences, nature walks, and relaxation in the countryside."
  },
  {
    "name": "1 Day Stay Picnic Spot Near Mumbai",
    "description": "For a 1 day stay picnic spot near Mumbai, Shinde Agri Tourism is an excellent option. The farm offers overnight stays, where you can enjoy the natural beauty, participate in farming activities, and relax in a rural setting."
  },
  {
    "name": "1 Night Picnic Spot Near Mumbai",
    "description": "If you’re looking for a 1 night picnic spot near Mumbai, Shinde Agri Tourism offers a perfect combination of adventure and relaxation. With overnight stays, you can enjoy the peace of the countryside and participate in activities like farm tours, bullock cart rides, and nature walks."
  },
  {
    "name": "1 Night Stay Picnic Spot Near Mumbai",
    "description": "For a 1 night stay picnic spot near Mumbai, Shinde Agri Tourism provides a peaceful retreat. Enjoy the tranquil environment of the farm, take part in fun activities, and relax in the rural surroundings for a rejuvenating one-night getaway."
  },
  {
    "name": "2 Days Stay Picnic Spot Near Mumbai",
    "description": "Shinde Agri Tourism is also a great option for 2 days stay picnic spot near Mumbai. Enjoy two full days of farm tours, nature walks, organic farming experiences, and relaxation in a peaceful countryside setting, just a short drive from Mumbai."
  },
  {
    "name": "Shinde Agri Tourism One Day Picnic Spot from Mumbai Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism One Day Picnic Spot From Mumbai. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- One Day Picnic Resorts Near Mumbai", "- One Day Picnic in Mumbai"],
    ["- One Day Picnic Spots Near Mumbai for Family", "- Day Picnic Near Mumbai"],
    ["- One Day Return Trip Near Mumbai", "- 1 Day Picnic Spot in Mumbai"],
    ["- Day Picnic Resorts Near Mumbai", "- 1 Day 1 Night Picnic Spots Near Mumbai"],
    ["- 1 Day Picnic in Mumbai", "- 1 Day Picnic for Couples in Mumbai"],
    ["- 1 Day Picnic Near Mumbai", "- 1 Day Picnic Spot in Mumbai for Couples"],
    ["- 1 Day Picnic Places in Mumbai", "- 1 Day Picnic Spot Near Mumbai"],
    ["- 1 Day Picnic Spot Near Mumbai for Couples", "- 1 Day Spot Near Mumbai"],
    ["- 1 Day Stay Picnic Spot Near Mumbai", "- 1 Night Picnic Spot Near Mumbai"],
    ["- 1 Night Stay Picnic Spot Near Mumbai", "- 2 Days Stay Picnic Spot Near Mumbai"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Close Proximity to Mumbai",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 100 km from Mumbai, making it a convenient and easily accessible spot for a quick one-day picnic. The 2 to 2.5-hour drive ensures you can make the most of your day without spending too much time traveling."
    },
    {
      "WhyChooseheading": "Outdoor Games and Recreation",
      "WhyChoosedescription": "Whether you're visiting with friends, family, or colleagues, we offer a variety of outdoor games and activities to keep everyone entertained. Enjoy playing volleyball, cricket, or badminton on our spacious grounds, or try out archery and nature trails for a fun and active day."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining",
      "WhyChoosedescription": "One of the highlights of your day will be the farm-to-table dining experience. Savor delicious, fresh meals made from organic produce harvested directly from our farm. Our menu includes traditional Maharashtrian dishes, grilled snacks, and fresh salads that are both wholesome and tasty."
    },
    {
      "WhyChooseheading": "Relaxing Environment",
      "WhyChoosedescription": "While you’re engaged in activities, you’ll also have the opportunity to relax in a peaceful, natural setting. Our farm is surrounded by lush greenery, creating the perfect backdrop for unwinding. Take a stroll through our nature trails, enjoy a quiet picnic by the farm, or simply relax and enjoy the tranquil atmosphere."
    },
    {
      "WhyChooseheading": "Family-Friendly Atmosphere",
      "WhyChoosedescription": "Shinde Agri Tourism is perfect for families. The variety of activities available makes it easy to keep everyone entertained, from young children to adults. Kids will love getting involved in farm activities, exploring the outdoors, and playing games, while adults can relax and enjoy the peaceful environment."
    },
    {
      "WhyChooseheading": "Perfect for Groups and Corporate Outings",
      "WhyChoosedescription": "Our resort also caters to larger groups, making it ideal for corporate outings, team-building activities, or large family gatherings. We can customize your experience to include fun challenges, team-building games, and group activities that encourage bonding and collaboration."
    },
    {
      "WhyChooseheading": "Convenient Day Trip",
      "WhyChoosedescription": "Whether you're planning a short getaway with the family or a fun day out with friends, our resort provides the perfect setting for a one-day trip. With our close proximity to Mumbai, you’ll be able to enjoy a refreshing break without taking up too much of your time."
    }
  ]
  


  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Mumbai, which takes about 2 to 2.5 hours to reach by car. It’s the perfect destination for a quick and easy one-day picnic."
    },
    {
      question: "What activities are available for a one-day picnic?",
      answer: "During your one-day visit, you can participate in activities like fruit and vegetable picking, milking cows, organic farming workshops, and animal interactions. You can also enjoy outdoor games like volleyball, badminton, and archery, or simply relax with a nature walk or picnic."
    },
    {
      question: "Is Shinde Agri Tourism suitable for families with kids?",
      answer: "Yes! Shinde Agri Tourism is very family-friendly. There are plenty of activities for children, including farm visits, animal care, and outdoor games. Kids will love getting involved in the farming activities and learning about nature in a fun and engaging way."
    },
    {
      question: "Is food included in the picnic package?",
      answer: "Yes! We offer a delicious farm-to-table dining experience with meals made from fresh, organic produce harvested from our farm. You can enjoy traditional Maharashtrian food, grilled snacks, fresh salads, and juices. Special dietary requirements can be accommodated with prior notice."
    },
    {
      question: "Can we book a picnic for a corporate group?",
      answer: "Absolutely! Shinde Agri Tourism is a great choice for corporate outings and team-building activities. We can customize the experience to include activities like group games, team-building exercises, and workshops designed to promote collaboration and teamwork."
    },
    {
      question: "Are there any relaxation spots at the resort?",
      answer: "Yes! Apart from the activities, our resort offers several peaceful spots to relax. You can enjoy nature walks, sit by the farm and have a picnic, or simply unwind in the serene, green surroundings. It’s a great way to enjoy the beauty of nature and recharge."
    },
    {
      question: "Can we do a one-day trip from Mumbai on weekends?",
      answer: "Yes! Shinde Agri Tourism is perfect for a weekend day trip. It’s easy to reach from Mumbai, and you can enjoy a full day of activities, relaxation, and delicious food, all without having to travel far from the city."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Ayesha & Family, Mumbai",
      text: "We had a fantastic day at Shinde Agri Tourism for our one-day picnic! The kids loved picking fresh fruits, milking cows, and learning about organic farming. The food was incredible, and the peaceful atmosphere made it the perfect escape from city life. We can’t wait to come back!"
    },
    {
      name: "Mr. Sandeep, HR Manager, Mumbai",
      text: "Our corporate team outing at Shinde Agri Tourism was amazing! The team-building games and outdoor activities were well-organized and fun. The food was fresh and delicious, and the entire experience was perfect for fostering teamwork and relaxation. Highly recommend it for corporate events!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | One Day Picnic Spot from Mumbai | Family Picnic Resorts, Couples Getaways, & Day Picnic Spots Near Mumbai | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best one day picnic spots from Mumbai? Discover family-friendly picnic resorts, couples' getaways, and day trips for an unforgettable one-day picnic experience near Mumbai." />
  <meta name="keywords" content="One day picnic resorts near Mumbai, One day picnic in Mumbai, One day picnic spots near Mumbai for family, Day picnic near Mumbai, One day return trip near Mumbai, 1 day picnic spot in Mumbai, Day picnic resorts near Mumbai, 1 day 1 night picnic spots near Mumbai, 1 day picnic in Mumbai, 1 day picnic for couples in Mumbai, 1 day picnic near Mumbai, 1 day picnic spot in Mumbai for couples, 1 day picnic places in Mumbai, 1 day picnic spot near Mumbai, 1 day picnic spot near Mumbai for couples, 1 day spot near Mumbai, 1 day stay picnic spot near Mumbai, 1 night picnic spot near Mumbai, 1 night stay picnic spot near Mumbai, 2 days stay picnic spot near Mumbai" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/one-day-picnic-spot-from-mumbai" />

  {/* JSON-LD Schema for One Day Picnic Spot from Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - One Day Picnic Spot from Mumbai",
        "url": "https://shindekrushiparyatan.com/one-day-picnic-spot-from-mumbai",
        "description": "Looking for the best one day picnic spots near Mumbai? Enjoy family picnic resorts, couples' getaways, and day trips that promise an unforgettable experience for a day out of the city.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/one-day-picnic-spot-from-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "180"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/32.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Onedaypicnicspot;