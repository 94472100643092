
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Adventureonedayinpune() {



  const cardData =
  {
    keyword: ' One Day Adventure Resorts Near Pune',
    heading:  'One Day Adventure Resorts Near Pune: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for a one-day adventure getaway near Pune, Shinde Agri Tourism offers the perfect combination of adventure, nature, and relaxation. Nestled in the rural landscape, this agri-tourism resort provides a variety of outdoor activities and experiences that make it an exciting destination for day-trippers, families, or groups seeking an adventurous escape from the city.',

    top: 'Top Adventure Activities at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Nature Trekking and Hiking",
          "description": "Explore the beautiful surroundings of Shinde Agri Tourism on an invigorating nature trek. The trails offer a perfect mix of natural beauty and physical challenge, with scenic views of lush fields, hills, and vibrant landscapes. It’s a great way to get active, enjoy the outdoors, and connect with nature."
        },
        {
          "title": "Outdoor Sports and Games",
          "description": "For adventure seekers, Shinde Agri Tourism offers a range of outdoor sports such as cricket, badminton, and volleyball. Whether you want to engage in friendly competition or simply enjoy some physical activity, these sports facilities add an exciting element to your adventure-filled day."
        },
        {
          "title": "Interactive Farming Experience",
          "description": "Experience the thrill of farm-based activities such as harvesting crops, milking cows, and engaging in traditional farming methods. These hands-on activities are both fun and educational, making them ideal for those looking to connect with rural life and gain a deeper understanding of farming practices."
        },
        {
          "title": "Adventure Cycling",
          "description": "For cycling enthusiasts, Shinde Agri Tourism offers cycling routes through its beautiful farm surroundings. Ride through green fields, scenic paths, and rural roads, experiencing the thrill of adventure cycling in a peaceful yet challenging environment."
        },
        {
          "title": "Campfire and Evening Fun",
          "description": "After a day filled with adventure activities, unwind around a cozy bonfire. Enjoy music, storytelling, and outdoor games, creating a perfect ending to your action-packed day. The campfire experience adds warmth and camaraderie to the adventure, offering a perfect mix of relaxation and fun."
        },
        {
          "title": "Team-Building Activities",
          "description": "Shinde Agri Tourism is an excellent spot for corporate outings or group adventures. The resort offers team-building activities like obstacle courses, relay races, and group challenges designed to promote teamwork, communication, and problem-solving skills, all while having a blast."
        },
        {
          "title": "Photography and Wildlife Observation",
          "description": "For those interested in capturing nature's beauty, the resort offers great opportunities for wildlife photography and bird watching. The natural surroundings are home to various species of birds and wildlife, making it an exciting adventure for photographers and nature enthusiasts."
        }
      ],

     "services": [
  {
    "name": "One Day Adventure Resorts Near Pune",
    "description": "For a thrilling one-day adventure resort near Pune, Shinde Agritourism offers the perfect blend of adventure, nature, and relaxation. Located on the outskirts of Pune, this resort provides a variety of fun activities, including bullock cart rides, farm-based games, nature walks, and animal interactions. It’s an ideal destination for those seeking a short getaway filled with adventure and exploration in a rural setting."
  },
  {
    "name": "Adventure Resort Pune Day Out",
    "description": "Looking for an adventure resort in Pune for a day out? Shinde Agritourism is the place to be. The resort offers a range of activities like nature walks, organic farming tours, and traditional games that are perfect for individuals, families, or corporate teams. It’s a great way to spend the day outdoors, away from the city, and indulge in adventurous activities while enjoying fresh, organic meals from the farm."
  },
  {
    "name": "One Day Adventure Resort Near Pune",
    "description": "For a one-day adventure resort near Pune, Shinde Agritourism is an excellent choice. The resort provides a peaceful and adventurous escape with its various outdoor activities, from farm tours and bullock cart rides to engaging in rural games. Whether you’re looking for adventure or just a relaxing day in nature, this resort offers the perfect balance for a memorable day trip."
  },
  {
    "name": "One Day Adventure Resorts Near Pune",
    "description": "When it comes to one-day adventure resorts near Pune, Shinde Agritourism stands out for its range of engaging activities. You can enjoy nature walks, try your hand at organic farming, or feed animals as part of an interactive experience. For those who love adventure, the resort also organizes fun games and bullock cart rides, ensuring an adventurous yet relaxing outing."
  },
  {
    "name": "1 Day Adventure Resort Near Pune",
    "description": "Shinde Agritourism offers an ideal 1-day adventure resort near Pune experience. A perfect destination for adventure seekers, the resort provides a variety of activities, from exploring the farm and participating in nature-based games to interacting with farm animals. The tranquil setting combined with outdoor activities makes it an ideal choice for a fun-filled day trip."
  },
  {
    "name": "Best Adventure Resorts Near Pune for Day Outing",
    "description": "For the best adventure resorts near Pune for a day outing, look no further than Shinde Agritourism. The resort provides an excellent mix of adventure and relaxation. Activities like nature walks, bullock cart rides, and farm tours make it a great option for those looking for adventure in a rural environment. The beautiful natural setting ensures a rejuvenating day out with family or friends."
  },
  {
    "name": "Adventure Resorts Near Pune for Corporate Outing",
    "description": "Shinde Agritourism is an ideal choice for adventure resorts near Pune for corporate outings. The farm offers team-building activities, such as farm-based games, bullock cart rides, and nature walks, which are perfect for corporate groups looking to unwind and strengthen teamwork. The peaceful and natural environment makes it an ideal location for both work-related bonding and relaxation."
  },
  {
    "name": "Resorts Near Pune for Family One Day Trip",
    "description": "For a family one-day trip near Pune, Shinde Agritourism is a fantastic option. The resort offers family-friendly activities like animal feeding, farm tours, and nature walks. There are also plenty of outdoor games for children and adults alike, making it a perfect place to bond with family members while enjoying the peaceful countryside."
  },
  {
    "name": "One Day Picnic Resorts with Activities Near Pune",
    "description": "Shinde Agritourism is the perfect one-day picnic resort with activities near Pune. With activities such as bullock cart rides, nature walks, and hands-on farming experiences, it’s a great way to spend the day. Whether you’re a family, group of friends, or corporate team, the resort ensures that everyone has a fun-filled and educational experience while enjoying organic food and outdoor games."
  },
  {
    "name": "Adventure Park in Pune for Family",
    "description": "If you're looking for an adventure park in Pune for families, Shinde Agritourism offers a rural adventure experience unlike any other. Kids can engage in outdoor games, learn about farming, and feed animals, while adults can relax and enjoy the fresh air. It’s a perfect combination of education and adventure for families."
  },
  {
    "name": "Best Adventure Resort in Pune",
    "description": "The best adventure resort in Pune is Shinde Agritourism for those who want to experience nature and adventure all in one place. The resort offers activities like nature walks, bullock cart rides, farm tours, and traditional games. It’s the ideal place to enjoy a day of adventure while being surrounded by the beauty of the countryside."
  },
  {
    "name": "Adventure Resorts Near Pune for Family",
    "description": "For adventure resorts near Pune for family, Shinde Agritourism is a top choice. The farm offers a variety of family-friendly activities, from animal interactions and farm tours to engaging in fun games and bullock cart rides. The peaceful, rural setting makes it the perfect place for families to relax and enjoy a day together outdoors."
  },
  {
    "name": "One Day Picnic Resorts with Activities Near Pune",
    "description": "Shinde Agritourism offers the ideal one-day picnic resort with activities near Pune. Spend the day participating in farm-based activities, such as organic farming, bullock cart rides, and animal feeding. The beautiful natural surroundings and range of outdoor activities ensure a fun-filled and relaxing experience for all ages."
  },
  {
    "name": "Om Agro Tourism Adventure Resort",
    "description": "For a unique adventure resort experience near Pune, Om Agro Tourism at Shinde Agritourism is a must-visit. Offering activities like farm tours, traditional games, and animal interactions, the resort ensures that guests have a fun, educational, and adventurous day. Whether you're looking for a family outing, corporate team-building event, or a quick getaway with friends, this adventure resort near Pune has something for everyone."
  },
  {
    "name": "Shinde Agri Tourism School One Day Adventure Resorts Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect School One Day Adventure Resort Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- One Day Adventure Resorts Near Pune", "- Adventure Resort Pune Day Out"],
    ["- One Day Adventure Resort Near Pune", "- One Day Adventure Resorts Near Pune"],
    ["- 1 Day Adventure Resort Near Pune", "- Best Adventure Resorts Near Pune for Day Outing"],
    ["- Adventure Resorts Near Pune for Corporate Outing", "- Resorts Near Pune for Family One Day Trip"],
    ["- One Day Picnic Resorts with Activities Near Pune", "- Adventure Park in Pune for Family"],
    ["- Best Adventure Resort in Pune", "- Adventure Resorts Near Pune for Family"],
    ["- One Day Picnic Resorts with Activities Near Pune", "- Om Agro Tourism Adventure Resort"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Thrilling Adventure Activities",
      "WhyChoosedescription": "At Shinde Agri Tourism, adventure comes alive with a variety of exciting activities designed to pump up your adrenaline. From rock climbing, zip-lining, and obstacle courses to trekking, archery, and bullock cart rides, there's something to challenge adventurers of all levels. Whether you’re looking for a high-energy experience or a fun challenge with friends and family, we’ve got activities that will thrill you and create lasting memories."
    },
    {
      "WhyChooseheading": "Ideal for All Age Groups",
      "WhyChoosedescription": "Our adventure activities are designed to be enjoyed by people of all ages, making Shinde Agri Tourism the perfect destination for families, groups, and corporate outings. Whether you're seeking adrenaline-pumping thrills or a more relaxed adventure, we offer a variety of options that suit both young adventurers and those looking for a laid-back day of fun. Everyone can join in the excitement!"
    },
    {
      "WhyChooseheading": "Nature Trails and Trekking",
      "WhyChoosedescription": "Shinde Agri Tourism is nestled in a picturesque, lush green landscape, ideal for nature walks and trekking. Enjoy a peaceful trek through scenic trails while soaking in the beauty of the surroundings. Our guided nature walks are perfect for those who want to learn about local flora and fauna, offering a tranquil escape from the city and an opportunity to connect with nature."
    },
    {
      "WhyChooseheading": "Team Building Activities for Groups",
      "WhyChoosedescription": "Our resort is an excellent choice for team outings and group adventures. We offer a variety of team-building activities that promote communication, problem-solving, and collaboration. Whether you’re with colleagues, friends, or family, our group exercises are designed to encourage teamwork while having a great time. It’s an ideal setting for corporate outings, school groups, or family reunions."
    },
    {
      "WhyChooseheading": "Comfortable Stay and Dining",
      "WhyChoosedescription": "Though we specialize in day outings, Shinde Agri Tourism offers comfortable accommodation for those who wish to stay overnight and continue the adventure. After a day packed with fun activities, relax in our cozy, well-maintained rooms. Enjoy delicious meals made from fresh, organic ingredients that will refuel you after a day full of excitement. Our dining options cater to all preferences, offering local Maharashtrian dishes and a range of nutritious meals."
    },
    {
      "WhyChooseheading": "Relax and Unwind in Nature",
      "WhyChoosedescription": "After your adventure-filled day, Shinde Agri Tourism provides the perfect setting to unwind. Enjoy a peaceful evening by the pond, sit around a bonfire, or simply relax in the open fields. The serene and calm environment of the resort allows you to recharge, making it the perfect balance of excitement and relaxation for a rejuvenating day out."
    },
    {
      "WhyChooseheading": "Easy Access from Pune",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 100 km from Pune, making it an ideal choice for a one-day adventure getaway. With a scenic drive that takes just 2 to 2.5 hours, it’s easily accessible from Pune and offers a peaceful escape from the city’s hustle and bustle. The serene natural surroundings make it the perfect place to disconnect, relax, and enjoy a fun-filled day surrounded by nature."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located around 100 km from Pune, which is about a 2 to 2.5-hour drive. It's an easy and quick getaway for a one-day adventure trip."
    },
    {
      question: "What adventure activities are available at Shinde Agri Tourism?",
      answer: "We offer a variety of adventure activities including rock climbing, zip-lining, archery, obstacle courses, trekking, and bullock cart rides. Whether you're seeking adrenaline or just fun outdoor activities, we have something for everyone."
    },
    {
      question: "Are the adventure activities suitable for all ages?",
      answer: "Yes! Our activities are designed to cater to all age groups. From easy treks and nature walks for families to more challenging activities like zip-lining and rock climbing for adventure enthusiasts, everyone can join in the fun."
    },
    {
      question: "Can we do team-building activities for our corporate group?",
      answer: "Absolutely! We offer customized team-building activities that are designed to foster collaboration, communication, and problem-solving. These activities are ideal for corporate groups and can be tailored to meet your team’s needs."
    },
    {
      question: "Is there food available at Shinde Agri Tourism?",
      answer: "Yes! We provide delicious and nutritious meals, including traditional Maharashtrian cuisine, made with fresh and organic ingredients. We also cater to specific dietary needs upon request."
    },
    {
      question: "Can we extend our trip and stay overnight?",
      answer: "Yes! While we specialize in one-day adventure trips, we also offer comfortable accommodation if you'd like to extend your stay. Our rooms are cozy, clean, and provide a restful environment for guests who wish to stay overnight."
    },
    {
      question: "Do you offer transportation from Pune to Shinde Agri Tourism?",
      answer: "Yes! We offer transportation options for groups and individuals traveling from Pune to Shinde Agri Tourism. You can book a cab or group transport for a comfortable journey to our resort."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Sandeep & Team, Pune",
      text: "We had an amazing time at Shinde Agri Tourism! The adventure activities like zip-lining and rock climbing were thrilling, and the team-building exercises brought us closer as a group. The food was fantastic, and the natural surroundings made everything even better. Highly recommend it!"
    },
    {
      name: "Miss. Anita & Family, Pune",
      text: "Our family had an unforgettable day at Shinde Agri Tourism. The kids loved the obstacle course and the trekking trails. It was a perfect one-day getaway, filled with adventure, laughter, and great memories. The meals were delicious, and the staff was super friendly."
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | One Day Adventure Resorts Near Pune | Best Adventure Resorts for Day Outings & Corporate Outings Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for adventure resorts near Pune for a day outing? Explore the best adventure resorts near Pune for family trips, corporate outings, and exciting day adventures with activities and nature at Shinde Krushi Paryatan." />
  <meta name="keywords" content="One Day Adventure Resorts Near Pune, Adventure Resort Pune Day Out, Best Adventure Resorts Near Pune for Day Outing, Adventure Resorts Near Pune for Corporate Outing, One Day Picnic Resorts with Activities Near Pune, Family Adventure Resorts Near Pune, Adventure Park in Pune for Family, Best Adventure Resort in Pune, Adventure Resorts Near Pune for Family, 1 Day Adventure Resort Near Pune, Om Agro Tourism Adventure Resort" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/one-day-adventure-resorts-near-pune" />

  {/* JSON-LD Schema for Adventure Resort */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - One Day Adventure Resorts Near Pune",
        "url": "https://shindekrushiparyatan.com/one-day-adventure-resorts-near-pune",
        "description": "Explore the best adventure resorts near Pune for a one-day outing. Enjoy thrilling activities, family-friendly experiences, and corporate outings with nature at Shinde Krushi Paryatan.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/adventure-resort-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "95"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/23.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Adventureonedayinpune;