
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Waterparknear() {



  const cardData =
  {
    keyword: ' Water Park Near Pune',
    heading:  'Water Park Near Pune: Shinde Agri Tourism',
    headingDescription: 'While Shinde Agri Tourism is renowned for its serene farm environment, it also offers a refreshing experience for those looking to cool off and unwind near Pune. While it may not have traditional water park features like water slides and pools, the natural water-based activities and outdoor spaces make it a great spot for enjoying water-related fun in a rustic, scenic setting. ',

    top: 'Top Water-Based Activities at Shinde Agri Tourism',
    topPlaces: [
        {
          "title": "Farm Fresh Water Activities",
          "description": "Experience the joy of farm-based water activities, such as helping with water irrigation, or engaging in rural farming tasks that involve water. These activities not only cool you down but also offer an immersive and educational experience that connects you with nature."
        },
        {
          "title": "Outdoor Splash and Picnic Areas",
          "description": "The natural surroundings and spacious picnic areas at Shinde Agri Tourism make it a great place to cool off and relax by the water. Although not a traditional water park, guests can enjoy refreshing picnics and outdoor fun in open spaces, making it an excellent alternative for those who want to spend time outdoors."
        },
        {
          "title": "Pond and Waterbody Walks",
          "description": "Take a peaceful walk by the serene ponds and water bodies at Shinde Agri Tourism. These scenic spots offer a calm and relaxing environment, where you can enjoy the sounds of nature while cooling off by the water’s edge."
        },
        {
          "title": "Interactive Farming with Water-Based Crops",
          "description": "Shinde Agri Tourism offers the chance to learn about water-dependent crops through hands-on farming activities. Visitors can engage in harvesting or planting crops like rice or sugarcane, which require water, offering a unique and refreshing rural experience."
        },
        {
          "title": "Bonfire and Evening Water Reflection",
          "description": "While not a water park in the traditional sense, the tranquil ambiance around Shinde Agri Tourism's ponds makes it an ideal spot for evening reflection, as you gather around a bonfire or enjoy a peaceful water-side setting."
        }
      ],

     "services": [
  {
    "name": "Water Park Near Pune",
    "description": "If you’re looking for a water park near Pune, Shinde Agritourism offers an exciting rural experience that combines outdoor activities and nature, though it may not have water slides or pools. For families looking for a break from the usual water park options, Shinde Agritourism is a refreshing choice. Located just outside Pune, this farm offers a relaxing getaway with fun farm-based activities, organic food, and the chance to connect with nature. If you're seeking a peaceful day out with the family, this agritourism spot provides a unique alternative to traditional water parks."
  },
  {
    "name": "Best Water Park Near Pune",
    "description": "While there are many water parks in Pune, for those seeking something different, Shinde Agritourism provides the best alternative to water parks near Pune. With its peaceful farm setting, activities like bullock cart rides, nature walks, and organic farming tours offer a refreshing contrast to the hustle and bustle of water parks. If you prefer something more tranquil, Shinde Agritourism provides a unique way to connect with nature while enjoying the outdoors."
  },
  {
    "name": "Near Water Park Pune",
    "description": "Looking for a near water park in Pune? While there are plenty of options in the city, Shinde Agritourism is the perfect destination for those looking for a quiet, nature-based experience near Pune. Here, visitors can enjoy farm activities, fresh food, and scenic views, offering a unique and peaceful experience that’s a perfect complement to the excitement of traditional water parks."
  },
  {
    "name": "Pune City Water Park",
    "description": "If you’re specifically looking for a Pune city water park, there are several popular water parks within the city limits, but Shinde Agritourism offers something different. Instead of the usual water-based fun, you can enjoy activities like bullock cart rides, feeding farm animals, and participating in traditional games. It’s an excellent choice for a relaxed day out if you want to experience rural life near Pune."
  },
  {
    "name": "Pune Mein Water Park",
    "description": "When you think of pune mein water park, you’re probably envisioning a day filled with water slides and pools. While Shinde Agritourism doesn’t offer a traditional water park experience, it provides an exciting alternative for families looking for something different, with activities like hurda parties, organic farming, and nature walks. It’s a great way to enjoy the outdoors and have fun without the typical water park setting."
  },
  {
    "name": "Pune Near Water Park",
    "description": "Looking for something different than the usual Pune near water park destinations? Shinde Agritourism is located just outside Pune, offering a serene, natural environment with plenty of activities for everyone. While you won’t find water slides, you’ll enjoy farm-based activities, fresh air, and traditional games."
  },
  {
    "name": "Pune Water Park Near Me",
    "description": "For those searching for a Pune water park near me, you’ll find several options within the city. However, if you prefer a countryside experience, Shinde Agritourism offers an alternative that combines farm life, nature, and relaxation. Located just a short drive from Pune, this agritourism destination is ideal for those seeking a quiet escape while still enjoying fun outdoor activities."
  },
  {
    "name": "Pune Water Resort",
    "description": "Shinde Agritourism is not a traditional Pune water resort, but it offers an alternative experience where families can enjoy fresh, organic meals, engage in farm activities, and experience nature at its best. Instead of water slides and pools, you can relax in the peaceful surroundings, enjoy traditional rural games, and explore organic farming practices."
  },
  {
    "name": "Top Water Parks in Pune",
    "description": "While the top water parks in Pune are popular among those looking for water-based fun, Shinde Agritourism provides a unique experience away from the crowds. If you're looking for a peaceful day out in nature, enjoy a hurda party, nature walks, and animal interactions at Shinde Agritourism."
  },
  {
    "name": "Top Water Park in Pune",
    "description": "If you’re looking for the top water park in Pune, there are plenty of well-known options within the city. But for those who want to spend a day outdoors in a calm, scenic setting, Shinde Agritourism provides an excellent escape. You can enjoy rural activities, fresh food, and natural beauty, all without the typical water park environment."
  },
  {
    "name": "Water Park and Resort Near Pune",
    "description": "Looking for a water park and resort near Pune? Shinde Agritourism offers a great alternative to the usual water park resorts. While it doesn’t have water slides, it offers a unique combination of farm activities, organic food, and beautiful surroundings. You can enjoy a fun day out with family or friends in a relaxing, rural environment."
  },
  {
    "name": "Water Park Around Pune",
    "description": "For those searching for a water park around Pune, there are many options. But if you're looking for something out of the ordinary, Shinde Agritourism offers a peaceful and educational experience in the countryside. Enjoy farm tours, nature walks, and outdoor games while taking in the scenic beauty of the area."
  },
  {
    "name": "Water Park in Pune Near Me",
    "description": "If you’re specifically looking for a water park in Pune near me, there are a number of options within the city. However, for a different type of outing, Shinde Agritourism offers a serene environment with activities that connect you with nature. It’s a perfect way to spend the day with family or friends in a peaceful setting."
  },
  {
    "name": "Water Park in Pune Near Hadapsar",
    "description": "While there are several water parks near Hadapsar in Pune, Shinde Agritourism is a great alternative if you’re looking for a different kind of outdoor experience. Located just a short drive away, it offers a peaceful countryside setting where you can engage in farm activities, enjoy fresh organic meals, and relax in nature."
  },
  {
    "name": "Water Park in Pune Near Pimpri Chinchwad",
    "description": "Shinde Agritourism is not a water park in Pune near Pimpri Chinchwad, but it is a great place for those who want to enjoy nature and rural activities. Located near Pimpri Chinchwad, it’s an ideal getaway to experience organic farming, traditional games, and relaxing farm-based activities."
  },
  {
    "name": "Water Park Near By Pune",
    "description": "For those looking for a water park near by Pune, there are several options. But if you’re seeking a tranquil day in nature, Shinde Agritourism offers an ideal rural experience with activities like farm tours, bullock cart rides, and fresh food, all set in a peaceful environment."
  },
  {
    "name": "Water Park Near Pune City",
    "description": "Looking for a water park near Pune city? While many water parks are located in and around Pune, Shinde Agritourism offers a different kind of escape with its rural charm. Enjoy fresh hurda parties, outdoor games, and farm tours instead of water slides and pools."
  },
  {
    "name": "Water Park Near Wakad",
    "description": "While there are water parks near Wakad, Shinde Agritourism offers an exciting and refreshing alternative. Located just outside Pune, it’s a place to enjoy nature-based activities, engage with farm animals, and learn about organic farming in a peaceful, rural setting."
  },
  {
    "name": "Water Park Pune Near Me",
    "description": "For those searching for a water park in Pune near me, Shinde Agritourism provides a fun alternative to the typical water park experience. Spend the day exploring the farm, roasting hurda, and enjoying nature-based activities with your family or friends."
  },
  {
    "name": "Water Parks in Pune India",
    "description": "If you’re looking for water parks in Pune India, there are many well-known options within the city. However, Shinde Agritourism provides a unique alternative with farm-based activities and a relaxing rural experience."
  },
  {
    "name": "Waterpark Near Pune Maharashtra",
    "description": "Shinde Agritourism is a unique waterpark near Pune Maharashtra offering a rural retreat where visitors can relax, enjoy organic meals, and participate in traditional farm activities. While it may not have water slides, it offers a refreshing experience for those looking to get away from the usual water park crowds."
  },
  {
    "name": "Shinde Agri Tourism Picnic Spot Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Picnic Spot Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Water Park Near Pune", "- Best Water Park Near Pune"],
    ["- Near Water Park Pune", "- Pune City Water Park"],
    ["- Pune Mein Water Park", "- Pune Near Water Park"],
    ["- Pune Water Park Near Me", "- Pune Water Resort"],
    ["- Top 10 Water Park in Pune", "- Top Water Park in Pune"],
    ["- Water Park and Resort Near Pune", "- Water Park Around Pune"],
    ["- Water Park in Pune Near Me", "- Water Park in Pune Near Hadapsar"],
    ["- Water Park in Pune Near Pimpri Chinchwad", "- Water Park Nearby Pune"],
    ["- Water Park Near Pune City", "- Water Park Near Wakad"],
    ["- Water Park Near Wakad Pune", "- Water Park Pune Near Me"],
    ["- Water Parks in Pune India", "- Waterpark Near Pune Maharashtra"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Exciting Water Activities",
      "WhyChoosedescription": "At Shinde Agri Tourism, we offer a wide variety of water activities that cater to thrill-seekers and those looking for a more relaxed experience. Our water park features high-speed water slides for an adrenaline rush, a wave pool for some fun in the waves, splash pads for the little ones, and a lazy river for those looking to relax and unwind. There’s something for everyone – from the adventurous to those just looking to enjoy a calm, refreshing experience in the water."
    },
    {
      "WhyChooseheading": "Family-Friendly Atmosphere",
      "WhyChoosedescription": "Shinde Agri Tourism is designed to be the ultimate family destination. With a range of activities suitable for all ages, from toddlers to adults, we create an inclusive environment where families can bond over shared fun and laughter. The water park features dedicated spaces for children, while also offering thrilling rides and activities for the more adventurous adults. It’s the perfect place to spend quality time with loved ones, away from the chaos of everyday life."
    },
    {
      "WhyChooseheading": "Scenic Outdoor Setting",
      "WhyChoosedescription": "Unlike conventional water parks in the city, Shinde Agri Tourism is set amidst lush greenery, far from the noise and pollution of urban life. Our water park offers a serene and refreshing outdoor environment, where guests can enjoy all the fun of water activities while surrounded by beautiful natural landscapes. Take a dip in the pool or enjoy a leisurely ride along the lazy river, all while taking in the scenic views of rolling hills and farm fields — a perfect blend of nature and water-based adventure."
    },
    {
      "WhyChooseheading": "Well-Maintained Facilities",
      "WhyChoosedescription": "We understand the importance of cleanliness and safety when it comes to water parks. That’s why Shinde Agri Tourism maintains high standards of hygiene, with all water attractions regularly cleaned and sanitized to ensure a safe experience for our guests. Our water features are designed to provide maximum fun, while our well-trained staff ensures your safety by supervising rides and monitoring pools. We prioritize guest comfort and take all measures to ensure your visit is both enjoyable and secure."
    },
    {
      "WhyChooseheading": "Affordable Packages",
      "WhyChoosedescription": "At Shinde Agri Tourism, we believe in making your water park experience both fun and affordable. We offer a variety of pricing packages that cater to different group sizes and budget ranges, so you don’t have to worry about overspending. Whether you are visiting solo, with your family, or as part of a corporate team, we have flexible packages that suit your needs. Our affordable pricing ensures that everyone can enjoy a day full of fun, without breaking the bank."
    },
    {
      "WhyChooseheading": "Perfect for Corporate Outings & Groups",
      "WhyChoosedescription": "Looking for an exciting destination for your corporate outing or group event? Shinde Agri Tourism is the perfect spot for team-building activities, corporate getaways, school trips, and group gatherings. Our water park offers ample space for large groups to enjoy water activities together, making it a fun and engaging way to strengthen team bonds. Customizable group packages are available, including team-building games, catering, and private spaces for larger events, ensuring that your corporate outing is a memorable one."
    },
    {
      "WhyChooseheading": "Easy Accessibility",
      "WhyChoosedescription": "Shinde Agri Tourism is conveniently located just 100 km from Pune, making it easily accessible for both day trips and weekend getaways. The scenic drive to our location ensures that your journey is part of the fun, and our easy-to-reach venue allows you to spend more time enjoying the water park and less time traveling. Whether you’re coming from the heart of Pune or nearby areas, our location makes it easy for everyone to join in the fun without spending too much time on the road."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, about a 2 to 2.5-hour drive. It’s an easy and quick getaway for a day of water-based fun."
    },
    {
      question: "What types of water attractions are available?",
      answer: "We offer a variety of water attractions including water slides, a wave pool, a lazy river, splash pads, and more. There’s something for everyone, from thrilling rides to relaxing water zones."
    },
    {
      question: "Is the water park suitable for kids?",
      answer: "Yes! Shinde Agri Tourism is very family-friendly, with water zones designed specifically for children. We have shallow pools, splash areas, and smaller slides that are perfect for young ones to enjoy safely."
    },
    {
      question: "Are there food and beverage options available at the water park?",
      answer: "Yes, we have a variety of food Options available, including snacks, fast food, and refreshing drinks. You can enjoy a range of Maharashtrian and Indian dishes while you take a break from the water activities."
    },
    {
      question: "Are the water attractions safe?",
      answer: "Absolutely! Safety is our priority. All our water rides and attractions are designed to meet high safety standards, and our trained lifeguards ensure your safety at all times. We also maintain high hygiene and cleanliness in all areas of the water park."
    },
    {
      question: "Can I book a day pass for the water park?",
      answer: "Yes! We offer day passes for individuals and families. You can enjoy all the water attractions with a single-day pass, which is available at an affordable price."
    },
    {
      question: "Can we host a group or corporate outing at the water park?",
      answer: "Yes! Shinde Agri Tourism is an excellent venue for group outings, school trips, or corporate events. We offer customizable packages for large groups, including access to water attractions, meals, and other activities."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Vijay & Family, Pune",
      text: "Shinde Agri Tourism is the perfect place for a day of fun in the water! Our family had an amazing time on the water slides and in the wave pool. The whole experience was refreshing and exciting, and the natural surroundings made it even more special."
    },
    {
      name: "Miss. Rina & Team, Pune",
      text: "We had a corporate outing at Shinde Agri Tourism, and it was fantastic! The water park was amazing, and the whole team had a blast. It was a great mix of adventure, relaxation, and team bonding. Highly recommended for group outings!"
    }
  ];
  





  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Water Park Near Pune | Best Water Parks & Resorts Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best water parks near Pune? Explore top water parks and resorts near Pune for a fun-filled day with family and friends. Visit Shinde Krushi Paryatan for an exciting water park experience close to Pune." />
  <meta name="keywords" content="Water Park Near Pune, Best Water Park Near Pune, Pune Water Park, Water Resort Near Pune, Water Parks in Pune, Top Water Park in Pune, Water Park Around Pune, Water Park and Resort Near Pune, Pune Water Park Near Me, Pune City Water Park, Water Park Near Hadapsar, Water Park Near Pimpri Chinchwad, Water Parks Near Pune, Water Park in Pune Near Me, Water Park in Pune Near Wakad, Top 10 Water Parks in Pune, Water Park Near Pune Maharashtra" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/water-park-near-pune" />

  {/* JSON-LD Schema for Water Park with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Water Park Near Pune",
        "url": "https://shindekrushiparyatan.com/water-park-near-pune",
        "description": "Enjoy a thrilling water park experience at Shinde Krushi Paryatan, one of the best water parks near Pune. Perfect for family fun and relaxation with exciting water rides and activities.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/water-park-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/21.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Waterparknear;