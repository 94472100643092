
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Onedaypicnicpackages() {



  const cardData =
  {
    keyword: ' One Day Picnic Packages Near Mumbai',
    heading:  'One Day Picnic Packages Near Mumbai: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for a refreshing one-day picnic near Mumbai, Shinde Agri Tourism offers the perfect getaway. Located just a short drive away from Mumbai, this agro-tourism destination provides a range of exciting picnic packages, making it an ideal choice for families, corporate outings, or group trips. With nature at its core, Shinde Agri Tourism promises a day full of adventure, relaxation, and memorable experiences.',

    top: 'Top Activities at Shinde Agri Tourism	',
    "topPlaces": [
  {
    "title": "Farming Experience for All Ages",
    "description": "Dive into traditional farming activities like milking cows, planting crops, and harvesting fresh produce. This hands-on experience is educational and fun, perfect for both kids and adults."
  },
  {
    "title": "Outdoor Sports and Games",
    "description": "Enjoy a variety of outdoor activities like cricket, volleyball, badminton, and more. Whether you’re looking to relax or get competitive, there’s something for everyone."
  },
  {
    "title": "Bird Watching and Nature Walks",
    "description": "Explore the beautiful landscape through peaceful nature walks and bird-watching activities. The tranquil environment is ideal for connecting with nature and unwinding."
  },
  {
    "title": "Barbecue and Bonfire Experience",
    "description": "Enjoy a relaxing evening with a barbecue meal followed by a bonfire under the stars. This is perfect for groups or families looking to end their day with warmth and joy."
  },
  {
    "title": "Farm-Fresh Meals",
    "description": "Indulge in delicious, home-cooked meals made from farm-fresh ingredients. Traditional Maharashtrian cuisine and snacks will leave you feeling satisfied and refreshed."
  }
],

"services": [
  {
    "name": "Resort Near Me for One Day Picnic",
    "description": "For a resort near me for one day picnic, Shinde Agri Tourism is a fantastic option. Located just outside Mumbai, this agro-tourism farm offers a relaxing day out with activities like farm tours, bullock cart rides, and nature walks. It’s the perfect place for a one-day escape, offering a peaceful, rural setting."
  },
  {
    "name": "Resorts for One Day Picnic",
    "description": "When looking for resorts for one day picnic, Shinde Agri Tourism is an ideal choice. The farm offers a wide range of activities, from exploring organic farming to engaging in nature walks and outdoor games, making it a great resort for a refreshing day trip close to Mumbai."
  },
  {
    "name": "1 Day Picnic Resort",
    "description": "A great 1 day picnic resort is Shinde Agri Tourism. This agro-tourism destination offers farm experiences, educational workshops, and relaxing outdoor activities like nature trails and bullock cart rides, all within a tranquil rural setting."
  },
  {
    "name": "One Day Picnic Resort Near Me",
    "description": "If you're looking for a one day picnic resort near me, Shinde Agri Tourism is a perfect destination. Located just outside Mumbai, it’s an easy drive from the city and offers a serene environment for a day full of fun and relaxation."
  },
  {
    "name": "Best Resort for One Day Picnic",
    "description": "The best resort for one day picnic near Mumbai is Shinde Agri Tourism. With a range of activities such as farm tours, nature walks, and interactive farming workshops, it’s a great choice for those looking to enjoy a peaceful, educational, and fun-filled day in nature."
  },
  {
    "name": "Day Picnic Resort",
    "description": "For a day picnic resort, Shinde Agri Tourism offers an immersive agro-tourism experience. You can enjoy a day of outdoor activities, organic farming experiences, and nature walks, all within a peaceful, rural setting that’s just a short drive from Mumbai."
  },
  {
    "name": "Nearby Resort for One Day Picnic",
    "description": "A great nearby resort for one day picnic is Shinde Agri Tourism, located just a short drive from Mumbai. The farm offers a variety of activities like bullock cart rides, farm tours, and nature walks, making it the perfect place for a day trip to relax and unwind."
  },
  {
    "name": "One Day Picnic in Resort",
    "description": "For a one day picnic in resort, Shinde Agri Tourism provides the perfect setting. The farm’s peaceful atmosphere and range of activities, such as farm visits, nature walks, and organic farming workshops, make it an ideal spot for a one-day getaway."
  },
  {
    "name": "Resort for Day Picnic",
    "description": "A great resort for day picnic is Shinde Agri Tourism. Located just outside Mumbai, it offers a range of outdoor activities, including bullock cart rides, farm tours, and nature walks, making it an excellent place for a relaxing day picnic with family or friends."
  },
  {
    "name": "Resort Near Me One Day Picnic",
    "description": "If you’re looking for a resort near me one day picnic, Shinde Agri Tourism is the ideal choice. With a variety of fun, educational activities and a peaceful, rural setting, it’s the perfect place for a one-day picnic close to Mumbai."
  },
  {
    "name": "Resorts for One Day Picnic Near Me",
    "description": "Shinde Agri Tourism is one of the best resorts for one day picnic near me. Offering farm tours, bullock cart rides, and nature walks, it provides a perfect rural escape for anyone looking for a quick day trip from Mumbai."
  },
  {
    "name": "Resort Near Me with Price for One Day Picnic",
    "description": "To find a resort near me with price for one day picnic, Shinde Agri Tourism offers affordable day trip packages. For more information on pricing, you can contact them directly or visit their website for details on the activities included in their day outing packages."
  },
  {
    "name": "One Day Picnic Spots Near Navi Mumbai for Family",
    "description": "For one day picnic spots near Navi Mumbai for family, Shinde Agri Tourism is an excellent choice. The farm offers a variety of activities suitable for families, including bullock cart rides, nature walks, farm visits, and more. It’s an ideal spot for a family day out in nature."
  },
  {
    "name": "One Day Picnic Spots Near Mumbai for Family",
    "description": "For one day picnic spots near Mumbai for family, Shinde Agri Tourism is a top choice. It offers a range of activities that are perfect for families, from interactive farming experiences to nature trails and relaxing in the beautiful rural surroundings."
  },
  {
    "name": "Shinde Agri Tourism Krushi Paryatan Kendra near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Resort Near Me for One Day Picnic", "- Resorts for One Day Picnic"],
    ["- 1 Day Picnic Resort", "- One Day Picnic Resort Near Me"],
    ["- Best Resort for One Day Picnic", "- Day Picnic Resort"],
    ["- Nearby Resort for One Day Picnic", "- One Day Picnic in Resort"],
    ["- Resort for Day Picnic", "- Resorts for One Day Picnic Near Me"],
    ["- Resort Near Me with Price for One Day Picnic", "- One Day Picnic Spots Near Navi Mumbai for Family"],
    ["- One Day Picnic Spots Near Mumbai for Family", "- "]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenient Location, Easy Access",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 100 km from Mumbai, which means you can enjoy a relaxing picnic without having to travel far. The 2 to 2.5-hour drive from Mumbai makes it perfect for a day trip, whether you're planning a family outing or a quick getaway with friends."
    },
    {
      "WhyChooseheading": "Customizable Picnic Packages",
      "WhyChoosedescription": "Our one-day picnic packages are designed to provide a variety of experiences, depending on your group's preferences. Choose from different activity bundles like farm tours, adventure activities, team-building exercises, or relaxation time. We can tailor the itinerary to match your group's needs, ensuring everyone has a memorable day."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining Experience",
      "WhyChoosedescription": "Enjoy delicious, freshly prepared meals as part of your picnic package. Our farm-to-table dining offers a variety of traditional Maharashtrian dishes, organic salads, grilled snacks, fresh juices, and more, all made from produce harvested directly from our farm. The food is wholesome, healthy, and made with love."
    },
    {
      "WhyChooseheading": "Ideal for Families, Friends, and Groups",
      "WhyChoosedescription": "Our picnic packages are designed to cater to all kinds of groups, whether you're visiting with family, friends, or colleagues. We offer family-friendly activities, while corporate groups can engage in team-building exercises and outdoor challenges to enhance collaboration and teamwork."
    },
    {
      "WhyChooseheading": "Relax in a Peaceful Environment",
      "WhyChoosedescription": "After engaging in fun activities, you can take a break and unwind in our peaceful natural setting. Sit by the farm and enjoy a picnic, or simply relax on the lush green lawns. The resort is surrounded by beautiful landscapes, making it the perfect place to escape the noise of the city and enjoy some quiet time."
    },
    {
      "WhyChooseheading": "Affordable and Flexible Packages",
      "WhyChoosedescription": "Our one-day picnic packages are not only affordable but also flexible. You can choose the activities that best suit your group’s interests and budget. Whether you want a full day of adventure or a relaxed day of farm visits and food, we’ve got a package for you."
    },
    {
      "WhyChooseheading": "Great for Corporate Outings & Team Building",
      "WhyChoosedescription": "For corporate groups looking to break the routine, our one-day picnic packages offer a refreshing change. Enjoy team-building activities, group games, and leadership workshops that help strengthen communication and collaboration in a fun, informal setting."
    }
  ]
  
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located around 100 km from Mumbai, about a 2 to 2.5-hour drive. It's the ideal distance for a quick one-day picnic without taking up too much of your time."
    },
    {
      question: "What are the picnic packages available?",
      answer: "We offer customizable one-day picnic packages that include activities like farm tours, fruit and vegetable picking, milking cows, adventure activities (zip-lining, rock climbing, archery), outdoor games, nature walks, and birdwatching. You can select the activities based on your group’s preferences."
    },
    {
      question: "Is food included in the picnic package?",
      answer: "Yes! All our picnic packages include a farm-to-table dining experience. We serve freshly prepared meals made with organic ingredients from our farm. You’ll enjoy traditional Maharashtrian dishes, organic salads, fresh juices, grilled snacks, and more."
    },
    {
      question: "Can we book a picnic for a family?",
      answer: "Yes, our picnic packages are perfect for families. With activities like fruit picking, farm animal interactions, and outdoor games, your family will have a great time. Kids and adults alike can participate in fun and educational activities together."
    },
    {
      question: "Are the picnic packages suitable for corporate outings?",
      answer: "Absolutely! We offer team-building picnic packages for corporate groups. These packages include group activities, leadership exercises, and outdoor challenges designed to foster teamwork, collaboration, and communication in a relaxed and enjoyable setting."
    },
    {
      question: "What kind of activities are available for kids?",
      answer: "Kids can enjoy a variety of fun, hands-on activities like fruit picking, milking cows, and interacting with farm animals. There are also outdoor games like volleyball and cricket, as well as nature walks and birdwatching."
    },
    {
      question: "How long does the picnic experience last?",
      answer: "Our one-day picnic packages are designed for a full day of fun. You can arrive in the morning, participate in activities throughout the day, enjoy a delicious lunch, and then unwind before heading back to Mumbai in the evening."
    },
    {
      question: "How do I book a one-day picnic package?",
      answer: "You can book your one-day picnic package by contacting us through our website or by phone. We will help customize your itinerary and confirm your booking based on your preferred activities and group size."
    }
  ];
  
  const testimonials = [
    {
      name: "Priya & Family, Mumbai",
      text: "We had a fantastic day at Shinde Agri Tourism! The kids loved picking fresh fruits and interacting with the animals. The picnic package was well-organized, with a perfect mix of activities and relaxation. The farm-to-table food was amazing! Highly recommend for a family day trip."
    },
    {
      name: "Vikas, HR Head, Mumbai",
      text: "Our team had a wonderful time at Shinde Agri Tourism. The picnic package was perfect for team-building, and the activities were fun and engaging. The food was delicious, and the peaceful surroundings helped us relax and bond as a team. We will definitely return!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | One Day Picnic Packages Near Mumbai | Resorts for One Day Picnic, Family Picnic Spots & Day Picnic Resorts Near Mumbai | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for one day picnic packages near Mumbai? Discover the best resorts for day picnics, family-friendly spots, and one day picnic resorts near you for a memorable outing experience." />
  <meta name="keywords" content="Resort near me for one day picnic, Resorts for one day picnic, 1 day picnic resort, One day picnic resort near me, Best resort for one day picnic, Day picnic resort, Nearby resort for one day picnic, One day picnic in resort, Resort for day picnic, Resort near me one day picnic, Resorts for one day picnic near me, Resort near me with price for one day picnic, One day picnic spots near Navi Mumbai for family, One day picnic spots near Mumbai for family" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/one-day-picnic-packages-near-mumbai" />

  {/* JSON-LD Schema for One Day Picnic Packages Near Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - One Day Picnic Packages Near Mumbai",
        "url": "https://shindekrushiparyatan.com/one-day-picnic-packages-near-mumbai",
        "description": "Find the best one day picnic packages near Mumbai. Explore resorts offering day picnic experiences, perfect for family outings and memorable one-day getaways.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/one-day-picnic-packages-near-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "160"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/33.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Onedaypicnicpackages;