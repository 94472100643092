import React from 'react';
import '../css/TermsConditions.css';


const Termcondition = () => {
  return (
    <div>

<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Term & Conditions</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>



      <section className="terms-section">
        <div className="container">
          <div className="row g-0">
            {/* Text Content Column */}
            <div className="col-12 col-md-8">
              <div className="terms-content">
                <h3 className='darkcolor'>Terms and Conditions</h3>
                <p>This document is an electronic record published in accordance with the Information Technology Act, 2000, and rules made thereunder for access or usage of www.shindekrushiparyatan.com ("Website").</p>
                
                <p><b>By using or accessing the Website, the User agrees to adhere to the terms and conditions ("Terms of Use") laid out by Shinde Agri Tourism for the use of the Website.</b></p>

                <h4 className='darkcolor'>Website Information</h4>
                <p>All information available on the Website is provided without any liability whatsoever to Shinde Agri Tourism. The information on this Website is meant solely for providing details about the present facilities, services, and activities at Shinde Agri Tourism, along with the rules and regulations governing the use of the facilities offered. The User is bound by and responsible for their use of any services provided on the Website, including reviews, and agrees to follow the applicable rules, guidelines, policies, terms, and conditions that are part of these Terms of Use. While we strive for accuracy, certain information on the Website may occasionally be incorrect, and Shinde Agri Tourism does not offer any express or implied guarantee regarding the accuracy of the information. Shinde Agri Tourism reserves the right to modify or update the information on the Website at any time, especially for maintenance or updates. The User should periodically check these Terms of Use for any changes. Continued use of the Website constitutes acceptance of these changes.</p>

                <h4 className='darkcolor'>Location and Ownership</h4>
                <p>The Website is owned and registered by Shinde Agri Tourism, located at:</p>
                <p><i>At post - Malthan (Pabal Road),<br />
                  Taluka Shirur,<br />
                  District Pune, Maharashtra - 412218.</i></p>

                <h4 className='darkcolor'>Membership Eligibility</h4>
                <p>The User represents that they are a person capable of forming legally binding contracts under the Indian Contract Act, 1872, and are not incompetent to contract, nor a minor, nor an undischarged insolvent. Users under the age of 18 must not register or use the Website. Shinde Agri Tourism retains the right to terminate or block the User’s access to the Website at its sole discretion if it finds the User to be incompetent to contract or in violation of the applicable rules.</p>

                <h4 className='darkcolor'>User Responsibility</h4>
                <p>The User is solely responsible for maintaining the confidentiality of their User ID, Password, and any transaction details. The User agrees to provide accurate, current, and complete information as requested by Shinde Agri Tourism. Failure to provide such information or if Shinde Agri Tourism suspects that such information is inaccurate or incomplete, may lead to suspension, termination, or blocking of access to the Website.</p>

                <h4 className='darkcolor'>No Guarantees</h4>
                <p>Shinde Agri Tourism does not guarantee that the Website will be accessible without interruptions, or that it will be free from viruses or other harmful elements.</p>

                <h4 className='darkcolor'>Electronic Communications</h4>
                <p>By using the Website, the User consents to receive communications from Shinde Agri Tourism through electronic means, such as email, or other communication modes as deemed fit by Shinde Agri Tourism.</p>

                <h4 className='darkcolor'>Use of Website</h4>
                <p>The User agrees not to use the Website for any purpose that:</p>
                <ul>
                  <li>Violates any law, regulation, or third-party rights.</li>
                  <li>Is illegal, harmful, or offensive, such as content related to defamation, obscenity, pornography, or promoting violence.</li>
                  <li>Infringes on intellectual property rights.</li>
                  <li>Misleads or deceives other users or harms their experience.</li>
                  <li>Engages in spamming, phishing, or any unauthorized commercial activity without prior written consent from Shinde Agri Tourism.</li>
                </ul>

                <h4 className='darkcolor'>Intellectual Property Rights</h4>
                <p>All content, including but not limited to text, graphics, logos, and images, found on the Website is the property of Shinde Agri Tourism. Users are not authorized to copy, reproduce, republish, or distribute any part of the Website without prior written consent from Shinde Agri Tourism.</p>

                <h4 className='darkcolor'>Other Businesses</h4>
                <p>Shinde Agri Tourism is not responsible for the actions, products, or services of third-party websites linked through the Website. The presence of such links does not imply endorsement of the content or services provided by these external websites.</p>

              </div>
            </div>
          </div>

          <div className="contact-info">
            <h2 className='darkcolor'>Contact Us</h2>
            <p>If you have any questions regarding our terms, please reach out:</p>
            <ul>
              <li><b className='darkcolor'>Phone:</b> <a href="tel:+919356007002">+91 9356007002</a></li>
              <li><b className='darkcolor'>Email:</b> <a href="mailto:booking@shindekrushiparytan.com">booking@shindekrushiparytan.com</a></li>
            </ul>
          </div>
        </div>
      </section>
    
  










    </div>
  );
};

export default Termcondition;
