import React from 'react';



const Imagess = () => {
  return (
    <div>

<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">360 Images </h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>
    </div>
  );
};

export default Imagess;
