import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Import slick carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import the default theme

const TopDealsSlider = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Speed of transition
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500, // Speed of autoplay transition (2.5 seconds)
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="trending pb-6" style={{ backgroundImage: 'url(images/shape4.png)' }}>
      <div className="container">
        <div className="row align-items-center justify-content-between mb-6">
        <div className="section-title text-center text-lg-center">
              <h4 className="mb-1 theme1">Top Activities</h4>
              {/* <h2 className="mb-1">The Last <span className="theme">Minute Deals</span></h2> */}
              {/* <p className='fs-0'>Experience the thrill of outdoor adventures at Shinde Agri Tourism! From exciting rope courses and zip lines to serene nature walks and farm activities, there's something for everyone to enjoy and explore.</p> */}
            </div>
          <div className="col-lg-7">

          </div>
          <div className="col-lg-5"></div>
        </div>

        <div className="trend-box">
          {/* Initialize Slider with settings */}
          <Slider {...settings}>
            {/* Slide 1 */}
            <div>
              <div className="trend-item1 rounded box-shadow bg-white mb-4 p-3">
                <div className="trend-image position-relative">
                  <img src="/images/gallery/14.jpg" alt="Japan" />
                  <div className="trend-content1 p-4">
                    <h5 className="theme1 mb-1">
                      <i className="flaticon-location-pin"></i> Birds Watching
                    </h5>
                    <h3 className="mb-1 white">
                      <a href="" className="white">Shinde Agri Tourism</a>
                    </h3>  
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>

            {/* Slide 2 */}
            <div>
              <div className="trend-item1 rounded box-shadow bg-white mb-4 p-3">
                <div className="trend-image position-relative">
                  <img src="/images/gallery/15.jpg" alt="Japan" />
                  <div className="trend-content1 p-4">
                    <h5 className="theme1 mb-1">
                      <i className="flaticon-location-pin"></i> CampFire
                    </h5>
                    <h3 className="mb-1 white">
                      <a href="" className="white">Shinde Agri Tourism</a>
                    </h3>  
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>

            {/* Slide 3 */}
            <div>
              <div className="trend-item1 rounded box-shadow bg-white mb-4 p-3">
                <div className="trend-image position-relative">
                  <img src="/images/gallery/18.jpg" alt="Japan" />
                  <div className="trend-content1 p-4">
                    <h5 className="theme1 mb-1">
                      <i className="flaticon-location-pin"></i>  Rain Dance
                    </h5>
                    <h3 className="mb-1 white">
                      <a href="" className="white">Shinde Agri Tourism</a>
                    </h3>  
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>

            {/* Slide 4 */}
            <div>
              <div className="trend-item1 rounded box-shadow bg-white mb-4 p-3">
                <div className="trend-image position-relative">
                  <img src="/images/gallery/20.jpg" alt="Japan" />
                  <div className="trend-content1 p-4">
                    <h5 className="theme1 mb-1">
                      <i className="flaticon-location-pin"></i> Party
                    </h5>
                    <h3 className="mb-1 white">
                      <a href="" className="white">Shinde Agri Tourism</a>
                    </h3>  
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>
            </div>

            {/* Add more slides as needed */}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default TopDealsSlider;
