import React from 'react';
import Banner from '../pages/Banner';
import Trending from '../pages/Trending';
import Counter from '../pages/Counter';
import TrendingPackages from '../pages/TrendingPackages';
import LastMinuteDeals from '../pages/LastMinuteDeals';
import TourGuides from '../pages/TourGuides';
import TestimonialSection from '../pages/TestimonialSection';
import RecentArticlesSection from '../pages/RecentArticlesSection';

const Hero = () => {
  return (
    <div>

<Banner/>
<Trending/>
<Counter/>
<TrendingPackages/>
<LastMinuteDeals/>
{/* <TourGuides/> */}
<TestimonialSection/>
<RecentArticlesSection/>
    </div>
  );
};

export default Hero;
