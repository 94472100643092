
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Krushiparyatan() {



  const cardData =
  {
    keyword: ' Krushi Paryatan Kendra Near Pune',
    heading:  'Krushi Paryatan Kendra Near Pune: Shinde Agri Tourism',
    headingDescription: 'Shinde Agri Tourism offers the perfect Krushi Paryatan Kendra (Agricultural Tourism Center) experience near Pune. Located amidst lush greenery, this unique agro-tourism destination invites visitors to explore the world of farming and rural life while enjoying a peaceful escape from the urban hustle. Whether you are a nature enthusiast, an agriculture aficionado, or simply looking to spend quality time with family or friends, Shinde Agri Tourism offers an enriching and educational experience.',

    top: 'What to Expect at Shinde Agri Tourism Krushi Paryatan Kendra	',
   "topPlaces": [
  {
    "title": "Interactive Farming Activities",
    "description": "Shinde Agri Tourism is designed to give visitors a hands-on farming experience. You can learn about traditional farming techniques, participate in activities like planting, harvesting, and milking cows. It’s a great way for families, children, and groups to bond over farming practices while getting a deeper appreciation for agriculture."
  },
  {
    "title": "Explore Organic Farming",
    "description": "At the Krushi Paryatan Kendra, you can see and experience organic farming firsthand. Learn about sustainable farming practices, eco-friendly techniques, and how crops are grown without the use of harmful chemicals. It’s an educational opportunity for anyone interested in going green and supporting organic agriculture."
  },
  {
    "title": "Farm-to-Table Experience",
    "description": "Enjoy a farm-to-table dining experience at Shinde Agri Tourism. The center offers freshly prepared meals using ingredients sourced directly from the farm. You can taste organic vegetables, fruits, and other farm-fresh products that will make your visit even more memorable."
  },
  {
    "title": "Educational Workshops on Agriculture",
    "description": "Shinde Agri Tourism offers informative workshops where you can learn about various aspects of agriculture, including crop cultivation, dairy farming, agro-based products, and more. These workshops are perfect for school groups, agriculture students, or anyone interested in expanding their knowledge of farming."
  },
  {
    "title": "Cultural Experiences and Rural Traditions",
    "description": "In addition to agriculture, Shinde Agri Tourism celebrates rural life and culture. Enjoy traditional folk performances, local dances, and music that showcase the rich heritage of Maharashtra. It’s a great opportunity to immerse yourself in the local culture while enjoying the beautiful surroundings."
  },
  {
    "title": "Farm Stays and Rural Getaways",
    "description": "For those who wish to experience rural life more intimately, Shinde Agri Tourism offers farm stays where you can spend a night or two on the farm. Enjoy the tranquility of nature, sleep under the stars, and wake up to the sounds of birds chirping in the morning. It’s the perfect way to reconnect with nature and experience the simplicity of farm life."
  },
  {
    "title": "Picnic Spots and Relaxation Areas",
    "description": "The peaceful, open spaces at Shinde Agri Tourism make it an ideal place to relax and unwind. Enjoy a family picnic, take a nature walk, or simply enjoy the beauty of the surrounding fields and forests. The serene atmosphere is perfect for a day out with loved ones or a quiet retreat."
  },
  {
    "title": "Agri-Tourism Souvenirs",
    "description": "Visitors can purchase locally made products and farm-related souvenirs, such as organic jams, pickles, handmade crafts, and more. These make for great keepsakes or gifts that represent the essence of rural life and agriculture."
  }
],

    "services": [
  {
    "name": "Krushi Paryatan Kendra Near Pune Phone Number",
    "description": "If you're looking to contact a Krushi Paryatan Kendra near Pune, you can reach out to various agro-tourism centers in and around the Pune region. These centers often provide a mix of farm activities, educational tours, and nature-based experiences. It's best to check the local directories or official agro-tourism websites for updated phone numbers for specific locations. One popular destination is Om Agri Tourism, a recognized agro-tourism site near Pune."
  },
  {
    "name": "Krushi Paryatan Kendra Near Me",
    "description": "To find a Krushi Paryatan Kendra near me, you can look for agro-tourism farms or rural tourism centers in areas like Koyali, Pimpri Chinchwad, and other nearby villages. Om Agri Tourism in Koyali, Pune, offers a range of experiences, from farming activities to nature walks, making it a great option for those seeking a hands-on farm experience."
  },
  {
    "name": "Agro Tourism Near Pune for Family",
    "description": "For families looking to enjoy agro-tourism near Pune, Om Agri Tourism is a fantastic choice. It provides an immersive experience in farming, with educational activities, farm tours, bullock cart rides, and organic farming demonstrations. Located just outside Pune, it’s perfect for families looking to relax, learn about agriculture, and enjoy a peaceful day in nature."
  },
  {
    "name": "Best Agro Tourism Near Pune for Family",
    "description": "The best agro-tourism near Pune for family is undoubtedly Om Agri Tourism. With its welcoming environment, interactive farm experiences, and educational activities, it offers a unique blend of fun and learning for families. Whether you're exploring the farm, feeding animals, or picking fresh produce, it’s a wonderful place to introduce kids to agriculture and nature."
  },
  {
    "name": "Agro Tourism Near Pune for Stay",
    "description": "If you're looking for agro-tourism near Pune for stay, consider staying at Om Agri Tourism or other agro-resorts located in the outskirts of Pune. These farms offer overnight stays, where you can experience rural life, organic farming, and traditional rural activities. It's a perfect escape from the city, providing a peaceful and rejuvenating experience surrounded by nature."
  },
  {
    "name": "Krushi Paryatan Kendra Near Pimpri Chinchwad",
    "description": "There are several Krushi Paryatan Kendras near Pimpri Chinchwad, offering farm stays, educational tours, and family-friendly activities. These centers give visitors the chance to interact with farm animals, learn about organic farming, and explore rural life. One popular option is Om Agri Tourism in Koyali, which is a short drive from Pimpri Chinchwad and offers a variety of agro-tourism experiences."
  },
  {
    "name": "Krushi Paryatan Kendra Near Pimpri Chinchwad Contact Number",
    "description": "For specific Krushi Paryatan Kendra near Pimpri Chinchwad contact numbers, it's best to check the official websites of agro-tourism centers like Om Agri Tourism or search local business directories. These centers typically provide phone numbers for reservations, inquiries, and details about the services offered at their facilities."
  },
  {
    "name": "Krushi Paryatan Kendra in Pune Address",
    "description": "Om Agri Tourism located in Koyali, near Pune, is a prominent Krushi Paryatan Kendra. It offers a hands-on agricultural experience where you can engage in farming activities and enjoy a variety of agro-tourism events. The exact address for Om Agri Tourism is: Om Agri Tourism, Koyali, Pune. For directions, you can call the resort directly or check their website for more details."
  },
  {
    "name": "Krushi Paryatan Kendra Near Me",
    "description": "To find the nearest Krushi Paryatan Kendra near me, you can search for agro-tourism spots in the surrounding regions of Pune, such as Koyali, Chakan, and Pimpri Chinchwad. Many of these locations provide immersive farm tours and hands-on activities related to agriculture. Om Agri Tourism is one of the top choices for visitors in the Pune area."
  },
  {
    "name": "Krushi Paryatan Kendra in Pimpri Chinchwad",
    "description": "There are several Krushi Paryatan Kendras in Pimpri Chinchwad, offering farm tours and educational experiences related to organic farming. Agro-tourism centers near Pimpri Chinchwad provide an excellent opportunity to learn about sustainable farming practices, engage in outdoor activities, and enjoy the rural environment. Om Agri Tourism is one such facility located a short distance from Pimpri Chinchwad, providing visitors with an enriching agro-tourism experience."
  },
  {
    "name": "Shinde Agri Tourism Krushi Paryatan Kendra Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Krushi Paryatan Kendra Near Pune", "- Krushi Paryatan Kendra Near Me"],
    ["- Agro Tourism Near Pune for Family", "- Best Agro Tourism Near Pune for Family"],
    ["- Agro Tourism Near Pune for Stay", "- Krushi Paryatan Kendra Near Pimpri Chinchwad"],
    ["- Krushi Paryatan Kendra Near Pimpri Chinchwad Contact Number", "- Krushi Paryatan Kendra in Pune Address"],
    ["- Krushi Paryatan Kendra Near Me", "- Krushi Paryatan Kendra in Pimpri Chinchwad"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Experience Traditional Farming Practices",
      "WhyChoosedescription": "At Shinde Agri Tourism, we bring you closer to the roots of agriculture. Our Krushi Paryatan Kendra lets you explore organic farming methods, learn about crop cultivation, and experience the day-to-day operations of a working farm. It’s an educational yet fun way to understand the importance of sustainable farming."
    },
    {
      "WhyChooseheading": "Hands-On Farming Activities",
      "WhyChoosedescription": "Participate in a variety of hands-on activities, such as planting seeds, harvesting crops, milking cows, collecting fresh eggs from the farm, and even making traditional farm products. These activities are not only fun but also educational, giving you an immersive experience of rural life."
    },
    {
      "WhyChooseheading": "Learn Sustainable Farming Practices",
      "WhyChoosedescription": "As part of our Krushi Paryatan Kendra, guests can learn about sustainable farming techniques, organic agriculture, and eco-friendly farming practices. You’ll gain valuable insights into how to grow food in harmony with nature, helping preserve the environment."
    },
    {
      "WhyChooseheading": "Ideal for Families and School Trips",
      "WhyChoosedescription": "Our Krushi Paryatan Kendra is a fantastic educational experience for families, schools, and children. Kids will love interacting with animals, learning about plant growth, and experiencing the beauty of farm life. It’s a wonderful opportunity to reconnect with nature and understand where our food comes from."
    },
    {
      "WhyChooseheading": "Agri-Tourism Workshops and Training",
      "WhyChoosedescription": "We offer workshops on various aspects of farming, including organic farming, horticulture, livestock management, and farm-to-table processes. Whether you’re an aspiring farmer or just curious about agriculture, these workshops provide practical knowledge to help you learn new skills."
    },
    {
      "WhyChooseheading": "Rejuvenate in Nature",
      "WhyChoosedescription": "While you immerse yourself in the farming experience, enjoy the tranquil natural surroundings of our farm. Shinde Agri Tourism is surrounded by lush greenery, making it the perfect setting for nature walks, picnics, and relaxation. It’s an opportunity to disconnect from the busy city life and reconnect with the peaceful rhythms of nature."
    },
    {
      "WhyChooseheading": "Fresh Farm-to-Table Meals",
      "WhyChoosedescription": "After a day spent on the farm, indulge in delicious, fresh, and organic meals made from the farm’s produce. Our menu includes traditional Maharashtrian food as well as fresh salads, fruits, and juices that are both nutritious and flavorful."
    },
    {
      "WhyChooseheading": "Convenient Location from Pune",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism’s Krushi Paryatan Kendra is easily accessible by a short 2 to 2.5-hour drive, making it a perfect weekend getaway or a day trip. The farm’s close proximity to Pune makes it an ideal location for those seeking a quick, refreshing escape into rural life."
    }
  ]
  



  }

  const faqData = [
    {
      question: "What is Krushi Paryatan Kendra?",
      answer: "Krushi Paryatan Kendra is an agricultural tourism center where guests can experience rural life, learn about farming practices, participate in farm activities, and enjoy an immersive agricultural experience. It’s a great way to understand sustainable farming and get hands-on experience."
    },
    {
      question: "What activities can we do at Krushi Paryatan Kendra?",
      answer: "Visitors can participate in various activities, including planting seeds, harvesting crops, milking cows, collecting eggs, and making farm products. Additionally, we offer educational workshops on organic farming, sustainable agriculture, and farm-to-table processes."
    },
    {
      question: "Is Krushi Paryatan Kendra suitable for children?",
      answer: "Yes! Krushi Paryatan Kendra is an ideal experience for children. They can interact with animals, learn how to grow plants, and experience farm life firsthand. It’s a fun and educational outing for families with kids."
    },
    {
      question: "Can we learn about organic farming at Krushi Paryatan Kendra?",
      answer: "Absolutely! Our Krushi Paryatan Kendra focuses on organic farming methods, where you can learn about sustainable farming practices, eco-friendly techniques, and how to cultivate crops without harmful chemicals. We offer workshops to teach visitors the benefits and practices of organic farming."
    },
    {
      question: "Is food included in the visit to Krushi Paryatan Kendra?",
      answer: "Yes! We serve freshly prepared farm-to-table meals using organic produce from our farm. Enjoy a traditional Maharashtrian meal with seasonal fruits, vegetables, and freshly harvested ingredients. It’s a wholesome experience for both the body and soul."
    },
    {
      question: "Is the Krushi Paryatan Kendra open year-round?",
      answer: "Yes, the Krushi Paryatan Kendra is open throughout the year. However, certain farming activities and harvest seasons may vary depending on the time of year. We recommend checking with us for the best time to visit for specific activities or events."
    },
    {
      question: "How can I book a visit to Krushi Paryatan Kendra?",
      answer: "You can easily book your visit to Krushi Paryatan Kendra by contacting our customer service team, filling out the booking form on our website, or calling us directly. We recommend booking in advance, especially for groups and schools, to ensure availability."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Anjali & Family, Pune",
      text: "Our visit to Shinde Agri Tourism’s Krushi Paryatan Kendra was an eye-opening experience! The kids loved interacting with the animals, and we enjoyed learning about organic farming. It was a fun and educational experience that we’ll never forget."
    },
    {
      name: "Mr. Ravi, Mumbai",
      text: "As an aspiring farmer, I found the workshops at Krushi Paryatan Kendra incredibly useful. The staff was knowledgeable and eager to share their expertise on sustainable farming practices. The whole experience was a great way to learn more about agriculture."
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Krushi Paryatan Kendra Near Pune | Agro Tourism for Families | Stay & Explore | Krushi Paryatan Kendra</title>
  <meta name="description" content="Looking for Krushi Paryatan Kendra near Pune? Visit Shinde Krushi Paryatan for the best agro tourism experience for families. Enjoy farm stays, agro tourism, and explore nature in Pimpri Chinchwad and nearby areas." />
  <meta name="keywords" content="Krushi Paryatan Kendra near Pune, Krushi Paryatan Kendra near me, Agro tourism near Pune for family, Best agro tourism near Pune for family, Agro tourism near Pune for stay, Krushi Paryatan Kendra near Pimpri Chinchwad, Krushi Paryatan Kendra near Pimpri Chinchwad contact number, Krushi Paryatan Kendra in Pune address, Krushi Paryatan Kendra near me, Krushi Paryatan Kendra in Pimpri Chinchwad" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/krushi-paryatan-kendra-near-pune" />

  {/* JSON-LD Schema for Krushi Paryatan Kendra */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Krushi Paryatan Kendra Near Pune",
        "url": "https://shindekrushiparyatan.com/krushi-paryatan-kendra-near-pune",
        "description": "Experience the best Krushi Paryatan Kendra near Pune for agro tourism and family-friendly stays. Visit Shinde Krushi Paryatan in Pimpri Chinchwad for a unique farm experience and nature exploration.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pimpri Chinchwad, Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5196",
          "longitude": "73.7997"
        },
        "image": "https://shindekrushiparyatan.com/images/krushi-paryatan-kendra.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "100"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/28.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Krushiparyatan;