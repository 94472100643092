import React from 'react';
import '../css/activities.css';

const activityImages = {
  rainDance: '/images/gallery/18.jpg',
  tractorRiding: '/images/attraction/2.jpg',
  bullRiding: '/images/attraction/8.jpg',
  birdWatching: '/images/attraction/6.jpg',
  swimmingPool: '/images/attraction/9.jpg',
  campfire: '/images/gallery/15.jpg',
};

const Activities = () => {
  const activities = [
    {
      title: 'Rain Dance',
      icon: 'icon-water',
      description: 'Get ready to splash and dance in the rain! Our rain dance area provides the perfect environment for fun-filled water activities.',
      image: activityImages.rainDance,
    },
    {
      title: 'Tractor Riding',
      icon: 'icon-tractor',
      description: 'Experience the thrill of riding a tractor through the farm’s lush landscapes. Feel like a farmer for a day!',
      image: activityImages.tractorRiding,
    },
    {
      title: 'Bull Riding',
      icon: 'icon-bullhorn',
      description: 'Take on the challenge of bull riding! Our safe, fun bull-riding experience is perfect for those seeking adventure.',
      image: activityImages.bullRiding,
    },
    {
      title: 'Bird Watching',
      icon: 'icon-bird',
      description: 'Enjoy the peace and beauty of nature as you spot various species of birds in the serene environment of Shinde Agritourism.',
      image: activityImages.birdWatching,
    },
    {
      title: 'Swimming Pool',
      icon: 'icon-swimming-pool',
      description: 'Relax and cool off in our clean and refreshing swimming pool. Perfect for a leisurely swim after a day full of activities.',
      image: activityImages.swimmingPool,
    },
    {
      title: 'Campfire',
      icon: 'icon-swimming-pool',
      description: '"Unwind under the stars and enjoy the warmth of our cozy campfire. Perfect for a relaxing evening with friends and family, sharing stories and roasting marshmallows after a day full of adventure."',
      image: activityImages.campfire,
    },
  ];

  return (
    <div>
      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Activities</h2>
            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>

      <section className="activities-section py-5">
        <div className="container">
          <h2 className="mb-4 text-center">Exciting Activities at Shinde Agritourism</h2>
          <p className="mb-3 text-center">Enjoy a range of fun-filled activities that bring you closer to nature and farm life!</p>

          <div className="row">
            {activities.map((activity, index) => (
              <div className="col-lg-6 mb-4" key={index}>
                <div className="activity-card d-flex flex-column flex-lg-row">
                  <div className="activity-image">
                    <img src={activity.image} alt={activity.title} className="img-fluid rounded" />
                  </div>
                  <div className="activity-content ps-lg-4 pt-3 pt-lg-0">
                    <h5><i className={`${activity.icon} theme`}></i> {activity.title}</h5>
                    <p>{activity.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Activities;
