
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Bestcottagepune() {



  const cardData =
  {
    keyword: ' Best Cottage Near Pune',
    heading:  'Best Cottage Near Pune: Shinde Agri Tourism',
    headingDescription: 'Shinde Agri Tourism offers a unique and refreshing cottage experience near Pune. Located in the heart of rural Maharashtra, this agri-tourism destination is a perfect blend of nature, comfort, and relaxation. Whether you are looking for a peaceful weekend retreat or a serene stay surrounded by lush greenery, our well-appointed cottages offer the ideal escape..',

    top: 'What to Expect at Shinde Agri Tourism Camping Experience	',
"topPlaces": [
  {
    "title": "Comfortable Cottages Surrounded by Nature",
    "description": "Shinde Agri Tourism offers beautifully designed cottages surrounded by the peaceful landscapes of rural Maharashtra. These cottages provide a cozy, comfortable environment to relax and unwind, with stunning views of the lush greenery. Enjoy the serenity and natural beauty while having all the comforts of a modern home in the heart of nature."
  },
  {
    "title": "Perfect Weekend Retreat",
    "description": "Escape the hustle and bustle of the city with a weekend getaway to Shinde Agri Tourism's cottages. Whether you're looking to relax or explore the surrounding nature, these cottages offer the perfect retreat for families, friends, and couples. Spend quality time together in a peaceful, scenic environment away from the urban chaos."
  },
  {
    "title": "Nature Walks and Exploration",
    "description": "Take a stroll through the beautiful countryside surrounding the cottages. Explore the scenic trails, breathe in the fresh air, and immerse yourself in the natural beauty of rural Maharashtra. These nature walks offer a great way to connect with the environment and enjoy the tranquility of the countryside."
  },
  {
    "title": "Farm-to-Table Dining Experience",
    "description": "Indulge in delicious farm-fresh meals served at Shinde Agri Tourism's cottages. With a focus on organic produce, guests can enjoy home-cooked meals made from locally sourced ingredients. Whether it's a hearty breakfast or a cozy dinner, the farm-to-table experience adds a unique touch to your stay."
  },
  {
    "title": "Cultural Activities and Traditional Experiences",
    "description": "Immerse yourself in the local culture with traditional performances, storytelling sessions, and cultural activities around the cottages. Learn about the heritage of rural Maharashtra and experience folk dances, songs, and art, making your stay not only relaxing but also culturally enriching."
  },
  {
    "title": "Relax and Unwind in Comfort",
    "description": "Each cottage at Shinde Agri Tourism is designed for relaxation and comfort. Featuring spacious rooms, modern amenities, and serene surroundings, these cottages provide the perfect place to unwind and enjoy some quiet time in nature. Whether you're reading a book, meditating, or simply resting, the peaceful ambiance will rejuvenate your mind and soul."
  },
  {
    "title": "Group Getaways and Family Stays",
    "description": "Shinde Agri Tourism’s cottages are ideal for group getaways and family stays. With spacious accommodations, comfortable facilities, and a variety of outdoor activities, it's a perfect destination for families and friends to bond and create lasting memories together."
  },
  {
    "title": "Sustainable and Eco-Friendly Stay",
    "description": "Shinde Agri Tourism promotes sustainability, with eco-friendly cottages built to minimize environmental impact. The property encourages guests to adopt green practices, making it an ideal choice for eco-conscious travelers looking to enjoy nature responsibly."
  },
  {
    "title": "Peaceful Nights in the Heart of Nature",
    "description": "Enjoy peaceful nights in our cottages, where you can listen to the sounds of nature and unwind under a starlit sky. The tranquility of the surroundings and the comfort of the cottages create the perfect setting for a restful night, allowing you to recharge for the next day's adventures."
  }
],

"services": [
  {
    "name": "Best Cottages Near Pune",
    "description": "For those searching for the best cottages near Pune, Shinde Agri Tourism offers a perfect getaway. Set amidst nature, our cottages provide a serene escape from the hustle and bustle of the city. Enjoy a peaceful stay with comfortable amenities, scenic views, and a variety of outdoor activities like farm tours and nature walks."
  },
  {
    "name": "Cottage Stay Near Pune",
    "description": "Looking for a cottage stay near Pune? Shinde Agri Tourism offers cozy cottages with modern amenities and beautiful surroundings. Whether you're looking for a weekend retreat or a longer stay, these cottages provide a perfect blend of comfort and tranquility, allowing you to unwind and reconnect with nature."
  },
  {
    "name": "Luxury Cottages Near Pune",
    "description": "For a luxurious stay near Pune, Shinde Agri Tourism offers luxury cottages that combine comfort with style. Our luxury cottages feature well-designed interiors, high-end amenities, and picturesque views. Enjoy a relaxing and exclusive experience, with activities like farm visits, organic gardening, and nature trails."
  },
  {
    "name": "Cottages for Couples Near Pune",
    "description": "If you're looking for cottages for couples near Pune, Shinde Agri Tourism is an ideal choice. Our intimate and private cottages are perfect for a romantic getaway. Surrounded by nature, you can enjoy peaceful moments, stargazing, cozy bonfires, and personalized services, making it an unforgettable experience."
  },
  {
    "name": "Weekend Cottage Getaway Near Pune",
    "description": "Looking for a weekend cottage getaway near Pune? Shinde Agri Tourism offers a serene environment with comfortable cottages, allowing you to relax and rejuvenate. With activities like nature walks, organic farming, and farm tours, this weekend retreat offers the perfect escape from your busy routine."
  },
  {
    "name": "Eco Cottages Near Pune",
    "description": "For eco-conscious travelers, Shinde Agri Tourism provides eco cottages near Pune. These cottages are designed with sustainable materials, offering a nature-friendly stay. Experience rural life, participate in organic farming, and enjoy nature trails while staying in an eco-friendly environment."
  },
  {
    "name": "Affordable Cottages Near Pune",
    "description": "For those on a budget, Shinde Agri Tourism offers affordable cottages near Pune. Enjoy a comfortable stay with essential amenities, while being surrounded by nature. This is the perfect choice for a budget-friendly yet peaceful getaway, offering a range of outdoor activities and a relaxed environment."
  },
  {
    "name": "Cottage Retreat Near Pune",
    "description": "Shinde Agri Tourism offers a peaceful cottage retreat near Pune, ideal for those seeking to disconnect and relax. Our cottages are nestled in a tranquil environment, providing a cozy place to unwind. Enjoy activities like bonfires, farm visits, and nature walks, making it a perfect retreat for relaxation."
  },
  {
    "name": "Cottage Resorts Near Pune",
    "description": "If you’re looking for a resort-style cottage experience near Pune, Shinde Agri Tourism offers a perfect combination of rural charm and modern comfort. Our cottage resorts offer spacious accommodations, beautiful surroundings, and activities such as organic farming workshops, nature walks, and stargazing."
  },
  {
    "name": "Family Cottages Near Pune",
    "description": "Shinde Agri Tourism offers family-friendly cottages near Pune. These cottages are perfect for family getaways, offering enough space and comfort for all family members. Enjoy activities like farm animal interactions, nature trails, and educational farming experiences, making it an ideal location for a family vacation."
  },
  {
    "name": "Overnight Cottage Stay Near Pune",
    "description": "For an overnight cottage stay near Pune, Shinde Agri Tourism provides a peaceful and scenic retreat. Stay in comfortable cottages, enjoy a cozy bonfire, and wake up to the fresh air of the countryside. It’s perfect for those who want to escape the city for just one night and enjoy a relaxing time in nature."
  },
  {
    "name": "Weekend Cottage Stay Near Pune",
    "description": "Shinde Agri Tourism offers a perfect weekend cottage stay near Pune. Located just a short drive from the city, our cottages are surrounded by nature and provide a peaceful environment for relaxation. Spend your weekend exploring the farm, participating in nature walks, and enjoying the comfort of your cozy cottage."
  },
  {
    "name": "Cottage Stay with Farm Activities Near Pune",
    "description": "For a unique experience, Shinde Agri Tourism offers cottage stays near Pune with farm activities. Immerse yourself in rural life by participating in organic farming, interacting with farm animals, and exploring nature. It’s a great way to combine relaxation with educational and fun activities."
  },
  {
    "name": "Pet-Friendly Cottages Near Pune",
    "description": "Shinde Agri Tourism offers pet-friendly cottages near Pune, so you can bring your furry friends along on your getaway. With plenty of open space and natural surroundings, our cottages provide a comfortable and safe environment for pets, allowing you to enjoy your stay without worrying about your pets being left behind."
  },
  {
    "name": "Group Cottages Near Pune",
    "description": "Shinde Agri Tourism also offers group cottages near Pune for large families or groups. These cottages are spacious and perfect for gatherings. Enjoy activities such as team-building exercises, farm tours, nature walks, and bonfires, making it an ideal place for group bonding and relaxation."
  },
  {
    "name": "Shinde Agri Tourism Cottages Near Pune Contact Number",
    "description": "For more information or to book your stay at Shinde Agri Tourism, contact us at +91 9356007002. Whether you’re looking for a peaceful getaway or an adventurous retreat, we offer a variety of cottages near Pune to suit your needs. Let us help you plan the perfect stay surrounded by nature."
  }
],
tableData: [
    ["- Best Cottages Near Pune", "- Luxury Cottages Near Pune"],
    ["- Cottage Stay Near Pune", "- Weekend Cottage Getaway Near Pune"],
    ["- Affordable Cottages Near Pune", "- Family Cottages Near Pune"],
    ["- Romantic Cottages Near Pune", "- Cottages for Couples Near Pune"],
    ["- Eco Cottages Near Pune", "- Pet-Friendly Cottages Near Pune"],
    ["- Weekend Cottage Stay Near Pune", "- Cottage Retreat Near Pune"],
    ["- Luxury Cottage Resorts Near Pune", "- Cottage Resorts Near Pune"],
    ["- Group Cottages Near Pune", "- Cottages with Farm Activities Near Pune"],
    ["- Overnight Cottage Stay Near Pune", "- Cottage Stay with Nature Walks Near Pune"],
    ["- Best Farm Cottages Near Pune", "- Cottages for Nature Lovers Near Pune"],
    ["- Budget Cottages Near Pune", "- Best Places for Cottages Near Pune"],
    ["- Luxury Cottages Near Pune for Groups", "- Peaceful Cottage Stay Near Pune"]
],

  whychoose: [
    {
      "WhyChooseheading": "Perfect Location for Cottage Stay",
      "WhyChoosedescription": "Our cottages are nestled in serene surroundings, offering a peaceful retreat from the city hustle. Whether you're seeking a tranquil weekend getaway or a romantic escape, the location of our cottages provides a perfect balance of nature, comfort, and accessibility."
    },
    {
      "WhyChooseheading": "Luxurious and Comfortable Accommodation",
      "WhyChoosedescription": "Our cottages near Pune are equipped with modern amenities to ensure a luxurious and comfortable stay. From cozy beds to scenic views and spacious rooms, every detail has been carefully designed to offer a relaxing and rejuvenating experience."
    },
    {
      "WhyChooseheading": "Scenic Views and Nature",
      "WhyChoosedescription": "Stay at our cottages and wake up to breathtaking views of lush greenery, rolling hills, and peaceful surroundings. Whether it's a morning walk, an evening sunset, or simply lounging in your room, nature is at your doorstep, providing a truly immersive experience."
    },
    {
      "WhyChooseheading": "Privacy and Tranquility",
      "WhyChoosedescription": "If you're looking for privacy and peace, our cottages offer the perfect escape. Each cottage is secluded, allowing you to enjoy a private retreat while surrounded by nature. It’s the ideal place to unwind, relax, and disconnect from the stresses of everyday life."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining Experience",
      "WhyChoosedescription": "Indulge in a farm-to-table dining experience at our cottages, where fresh, organic ingredients are sourced directly from our farm. Enjoy a variety of traditional Maharashtrian dishes, healthy meals, and fresh fruits, all prepared with love and care."
    },
    {
      "WhyChooseheading": "Perfect for Family and Group Getaways",
      "WhyChoosedescription": "Our cottages are designed to cater to families, friends, and groups looking for a unique getaway experience. Whether it's a family reunion, a corporate retreat, or a group of friends looking to spend time together, our spacious cottages and personalized service make for a memorable stay."
    },
    {
      "WhyChooseheading": "Rejuvenate and Relax",
      "WhyChoosedescription": "The peaceful ambiance and tranquil surroundings of our cottages are perfect for those seeking relaxation and rejuvenation. Whether it's taking a leisurely walk through the farm, reading a book by the fireplace, or enjoying a spa treatment, you'll find ample opportunities to unwind."
    },
    {
      "WhyChooseheading": "Adventure and Outdoor Activities",
      "WhyChoosedescription": "For those looking for some outdoor fun, our cottages offer easy access to various activities like nature walks, farm tours, bird watching, and more. Engage with the natural surroundings and enjoy fun-filled activities with your family and friends while staying at our cottages."
    },
    {
      "WhyChooseheading": "Easy Accessibility from Pune",
      "WhyChoosedescription": "Located just a short drive from Pune, our cottages offer a quick and easy getaway without the hassle of long travel. Whether you’re looking for a weekend break or a short retreat, our cottages are conveniently located to provide a perfect escape from the city."
    },
    {
      "WhyChooseheading": "Pet-Friendly Cottages",
      "WhyChoosedescription": "For pet lovers, we offer pet-friendly cottages, so you can bring your furry friends along for the getaway. Enjoy a relaxing holiday with your pets, explore the outdoors, and make memories together in the lap of nature."
    }
]

  



  }

  const faqData = [
    {
      question: "How far are the cottages from Pune?",
      answer: "Our cottages are located just 100 km from Pune, approximately a 2 to 2.5-hour drive. It’s a convenient destination for a quick weekend getaway or a short retreat."
    },
    {
      question: "What kind of accommodations are offered at the cottages?",
      answer: "Our cottages are fully equipped with modern amenities, offering luxurious, comfortable rooms with scenic views. Each cottage is designed to provide privacy, comfort, and a peaceful stay."
    },
    {
      question: "Are meals included during the stay at the cottages?",
      answer: "Yes, we offer delicious farm-to-table meals made from fresh, organic ingredients. Our menu includes traditional Maharashtrian dishes, fresh salads, grilled snacks, and more. Special dietary requirements can also be accommodated."
    },
    {
      question: "Can I bring my pets to the cottages?",
      answer: "Yes, our cottages are pet-friendly! You can bring your furry friends along to enjoy the tranquil surroundings, and your pets can join in the relaxation and outdoor activities."
    },
    {
      question: "What activities can I enjoy while staying at the cottages?",
      answer: "Guests can enjoy a variety of outdoor activities, such as nature walks, farm tours, bird watching, and more. We also offer relaxation opportunities like reading by the fireplace or enjoying the scenic views."
    },
    {
      question: "Are the cottages suitable for family and group bookings?",
      answer: "Yes! Our cottages are perfect for family getaways, group retreats, and even corporate events. We offer packages that can be customized based on your needs, making it an ideal destination for a peaceful retreat with loved ones or colleagues."
    },
    {
      question: "Is there a bonfire at the cottages?",
      answer: "Yes, we organize bonfire sessions at our cottages, where you can relax by the fire, share stories, and enjoy the starry night sky. It’s a perfect way to bond with friends or family while embracing the natural beauty around you."
    }
];

const testimonials = [
    {
      name: "Mr. Arvind & Family, Pune",
      text: "We had an incredible experience staying at the cottages! The location was perfect, with stunning views and a peaceful atmosphere. The cottage was spacious and comfortable, and the farm-to-table meals were delicious. It was a much-needed family retreat."
    },
    {
      name: "Ms. Priya & Friends, Mumbai",
      text: "Our stay at the cottages was amazing! The property was beautiful, and we enjoyed every moment. From the relaxing walks in nature to the cozy nights by the bonfire, it was the perfect getaway. We’ll definitely be back soon!"
    }
];






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Best cottages Near Pune | Best Camping Spots, Trekking & Overnight Camping | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best camping places near Pune? Explore top camping spots for families, couples, trekking, and overnight stays near Pune and Pimpri Chinchwad. Enjoy a nature-filled experience with Shinde Krushi Paryatan." />
  <meta name="keywords" content="Camping places near Pune, best camping places near Pune, Pune camping places, best place for camping near Pune, camping location near Pune, camping spots near Pune, places for camping in Pune, best camping spots near Pune, camping places near Pimpri Chinchwad, indoor camping places near Pimpri Chinchwad, best indoor camping places near Pimpri Chinchwad, 1 day camping near Pune, best camping places near Pune, overnight camping near Pune, trekking and camping near Pune, camping near Pune for family, camping near Pune for couples" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/camping-places-near-pune" />

  {/* JSON-LD Schema for Camping Places Near Pune */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Camping Places Near Pune",
        "url": "https://shindekrushiparyatan.com/camping-places-near-pune",
        "description": "Find the best camping places near Pune for family trips, couples getaways, and overnight camping. Explore top camping spots for trekking, relaxation, and adventure at Shinde Krushi Paryatan near Pune and Pimpri Chinchwad.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pimpri Chinchwad, Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/camping-places-near-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/155.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bestcottagepune;