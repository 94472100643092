import React from 'react';



const Videos = () => {
  return (
    <div>

<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Videos </h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>

      {/* <section className="banner overflow-hidden">
      <div className="banner-main">
        <div className="banner-image">
          <div className="video-banner">
            <video autoPlay muted loop id="vid" className="w-100">
              <source src="/images/vid/shinde.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="banner-content">
             
        </div>
      </div>
    </section> */}
        <div style={{ textAlign: 'center' }}>
    
      {/* YouTube Video Embed */}
      <div style={{ maxWidth: '100%', position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <iframe
          src="https://www.youtube.com/embed/TNtIBIsI1ZU?si=EtMesExQLsX9vrqf"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        ></iframe>
      </div>
    </div>

    </div>
  );
};

export default Videos;
