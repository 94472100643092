
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Corporateoutingnearmumbai() {



  const cardData =
  {
    keyword: ' Corporate Outing Near Mumbai',
    heading:  'Corporate Outing Near Mumbai: Shinde Agri Tourism',
    headingDescription: 'Looking for the perfect location for a corporate outing near Mumbai that combines team-building activities, relaxation, and fresh air? Shinde Agri Tourism offers a unique agro-tourism experience, ideal for corporate groups seeking a break from the office. Located just a short drive from Mumbai, this resort provides a serene and rejuvenating environment where teams can bond, engage in outdoor activities, and enjoy fresh farm-to-table meals. It’s the perfect blend of fun and productivity, making it the ideal destination for your next corporate outing.',

    top: 'Top Activities for Corporate Outings at Shinde Agri Tourism	',
  "topPlaces": [
  {
    "title": "Team-Building Challenges",
    "description": "Engage in a variety of team-building exercises such as relay races, tug-of-war, and problem-solving activities designed to foster collaboration and improve communication among team members. These games promote teamwork while ensuring everyone has a great time."
  },
  {
    "title": "Outdoor Sports and Recreation",
    "description": "Choose from a variety of outdoor sports like cricket, badminton, and volleyball. These games encourage teamwork and friendly competition, offering a great way to relax and bond with colleagues in a beautiful setting."
  },
  {
    "title": "Farm Experience and Agricultural Activities",
    "description": "Experience the rural charm of agro-tourism by engaging in hands-on farming activities. Teams can participate in activities like planting crops, milking cows, and harvesting fresh produce. This is a fun and educational experience that brings colleagues closer together while learning about sustainable farming."
  },
  {
    "title": "Nature Walks and Bird Watching",
    "description": "Enjoy a peaceful nature walk or bird watching in the serene, green surroundings. This is a great activity for teams to unwind, relax, and connect with nature, providing a refreshing break from work."
  },
  {
    "title": "Barbecue and Bonfire Evening",
    "description": "After a day of fun and activities, gather around a cozy bonfire and enjoy a barbecue. This evening setup is perfect for informal conversations, relaxation, and building relationships with colleagues in a more laid-back environment."
  },
  {
    "title": "Workshops and Skill-Building Sessions",
    "description": "Host workshops or team development sessions focused on leadership, communication, or problem-solving. These sessions can be customized to address the specific needs of your team, providing valuable skills in a relaxed, engaging setting."
  },
  {
    "title": "Farm-Fresh Dining Experience",
    "description": "Enjoy delicious, organic meals prepared from fresh ingredients grown on-site. The resort’s farm-to-table meals offer an authentic dining experience, ensuring your team is fueled for the day’s activities with healthy and tasty food."
  }
],

"services": [
    {
      "name": "Corporate Picnic Near Mumbai",
      "description": "For a corporate picnic near Mumbai, Shinde Agri Tourism is an excellent option. Located just outside the city, it offers a peaceful and educational environment for corporate teams. The farm offers activities like farm tours, nature walks, bullock cart rides, and organic farming workshops—making it an ideal spot for team bonding and relaxation away from the office."
    },
    {
      "name": "Resorts for Corporate Outing Near Mumbai",
      "description": "If you're looking for resorts for corporate outing near Mumbai, Shinde Agri Tourism provides a refreshing rural retreat with a variety of team-building activities. From nature walks to farm visits and even hands-on farming experiences, it’s perfect for creating team synergy in a serene environment."
    },
    {
      "name": "Team Outing Places Near Mumbai",
      "description": "For team outing places near Mumbai, Shinde Agri Tourism offers a unique agro-tourism experience. Located just a short drive from Mumbai, it provides a perfect environment for group activities, team-building, and relaxation. Enjoy the tranquility of the farm, engage in nature walks, and take part in team-building activities in an unhurried, natural setting."
    },
    {
      "name": "One Day Corporate Outing Near Mumbai",
      "description": "Shinde Agri Tourism is an ideal choice for a one day corporate outing near Mumbai. Located just outside the city, it provides the perfect balance of outdoor activities, team-building, and relaxation in a natural setting. You can enjoy farm tours, team-building exercises, and hands-on agricultural activities, all within one day."
    },
    {
      "name": "Corporate Picnic Venues Near Mumbai",
      "description": "For corporate picnic venues near Mumbai, Shinde Agri Tourism is a wonderful option. The farm offers educational and fun-filled activities, including organic farming workshops, nature walks, and farm visits. It’s perfect for corporate groups seeking an enriching yet fun outing close to Mumbai."
    },
    {
      "name": "Resorts Near Mumbai for Corporate Picnic",
      "description": "If you’re looking for resorts near Mumbai for corporate picnic, Shinde Agri Tourism provides a unique, relaxing environment with a variety of activities. Enjoy nature walks, organic farming demonstrations, and bullock cart rides—perfect for a day of bonding and relaxation away from the office."
    },
    {
      "name": "Corporate Offsite Near Mumbai",
      "description": "For a corporate offsite near Mumbai, Shinde Agri Tourism offers a rustic and engaging experience with activities like farm tours, nature walks, and team-building exercises. Located just outside Mumbai, it’s an ideal location for an offsite meeting or retreat, allowing teams to relax, learn, and bond in a beautiful rural setting."
    },
    {
      "name": "Corporate Offsite Locations Near Mumbai",
      "description": "If you’re searching for corporate offsite locations near Mumbai, Shinde Agri Tourism is an excellent option. The farm offers an array of activities such as team-building exercises, organic farming workshops, and nature trails—all designed to enhance group interaction and relaxation."
    },
    {
      "name": "Corporate Picnic Spots Near Mumbai",
      "description": "Shinde Agri Tourism is one of the best corporate picnic spots near Mumbai. It offers a peaceful rural setting with a variety of activities, making it perfect for a corporate picnic. Engage in farm activities, nature walks, and team-building exercises to strengthen teamwork and enjoy the fresh air."
    },
    {
      "name": "Office Outing Places Near Mumbai",
      "description": "For office outing places near Mumbai, Shinde Agri Tourism offers a unique environment with farm visits, nature walks, and outdoor activities. The farm’s serene environment is ideal for office groups looking for an immersive and fun-filled day trip."
    },
    {
      "name": "Resorts Near Mumbai for Team Outing",
      "description": "If you're looking for resorts near Mumbai for team outing, Shinde Agri Tourism is a great choice. It offers outdoor activities that promote team-building, relaxation, and bonding. With activities like bullock cart rides, organic farming, and nature walks, it’s perfect for strengthening teamwork in a peaceful rural environment."
    },
    {
      "name": "Team Outing Resorts Near Mumbai",
      "description": "Shinde Agri Tourism is an excellent team outing resort near Mumbai. The farm offers a mix of fun and educational activities, such as farm tours, nature walks, and bullock cart rides, making it ideal for team-building exercises and creating strong bonds in a natural setting."
    },
    {
      "name": "Corporate Outing Near Mumbai",
      "description": "For a corporate outing near Mumbai, Shinde Agri Tourism provides an ideal location with a variety of activities aimed at team-building and relaxation. The farm offers a refreshing rural escape where you can unwind, engage in team-building activities, and learn about sustainable farming practices."
    },
    {
      "name": "Corporate Offsite Locations Near Mumbai",
      "description": "Shinde Agri Tourism is one of the best corporate offsite locations near Mumbai. With a focus on agro-tourism and team-building activities, it’s a fantastic location for corporate groups seeking to step away from the usual city routine. Participate in farm activities, enjoy nature walks, and foster team spirit in a serene rural environment."
    },
    {
      "name": "Team Outing Near Mumbai",
      "description": "For a team outing near Mumbai, Shinde Agri Tourism is an excellent destination. Located just outside the city, it offers a variety of outdoor activities designed to build teamwork and encourage relaxation. From farm visits to bullock cart rides and nature trails, it provides the perfect setting for an interactive and enjoyable team outing."
    },
    {
      "name": "Corporate Outing in Mumbai",
      "description": "If you're looking for a corporate outing in Mumbai, Shinde Agri Tourism provides a refreshing option. While it’s located just outside the city, it’s worth the short drive for a fun and engaging corporate outing. The farm offers activities that can help foster better teamwork, creativity, and bonding."
    },
    {
      "name": "Shinde Agri Tourism Corporate Outing Near Mumbai Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Corporate Outing Near Mumbai. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],

  tableData: [
    ["- Corporate Picnic Near Mumbai", "- Resorts for Corporate Outing Near Mumbai"],
    ["- Team Outing Places Near Mumbai", "- One Day Corporate Outing Near Mumbai"],
    ["- Corporate Picnic Venues Near Mumbai", "- Resorts Near Mumbai for Corporate Picnic"],
    ["- Corporate Offsite Near Mumbai", "- Corporate Offsite Locations Near Mumbai"],
    ["- Corporate Picnic Spots Near Mumbai", "- Office Outing Places Near Mumbai"],
    ["- Resorts Near Mumbai for Team Outing", "- Team Outing Resorts Near Mumbai"],
    ["- Corporate Outing Near Mumbai", "- Corporate Offsite Locations Near Mumbai"],
    ["- Team Outing Near Mumbai", "- Corporate Outing in Mumbai"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Close Proximity to Mumbai",
      "WhyChoosedescription": "Located just 100 km from Mumbai, our resort is a short 2 to 2.5-hour drive, making it an ideal location for a day trip or weekend retreat. The easy accessibility ensures minimal travel time and maximum relaxation."
    },
    {
      "WhyChooseheading": "Tailored Corporate Outing Packages",
      "WhyChoosedescription": "We understand that every team has unique needs. That’s why we offer customized corporate outing packages. Whether you’re looking to focus on team-building, leadership development, employee wellness, or simply a fun day out of the office, we’ll tailor your outing to meet your goals."
    },
    {
      "WhyChooseheading": "Team-Building Activities",
      "WhyChoosedescription": "Our resort is the perfect setting for team-building exercises. From problem-solving challenges, trust-building activities, and communication games to exciting outdoor activities like zip-lining, rock climbing, and archery, we have everything to foster teamwork, leadership, and collaboration."
    },
    {
      "WhyChooseheading": "Outdoor Adventures and Recreation",
      "WhyChoosedescription": "For teams seeking adventure, we offer a range of outdoor activities. You can enjoy activities such as zip-lining, nature walks, trekking, and volleyball, which are perfect for encouraging team spirit and physical fitness. Our expansive grounds also provide plenty of space for informal games and recreational activities."
    },
    {
      "WhyChooseheading": "Relaxing and Peaceful Environment",
      "WhyChoosedescription": "After a day filled with activities, unwind in the peaceful and natural surroundings of our resort. Surrounded by lush greenery and a beautiful farm landscape, Shinde Agri Tourism offers a calm atmosphere that encourages relaxation and reflection, providing your team with a much-needed break from the corporate grind."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining",
      "WhyChoosedescription": "Our farm-to-table dining experience ensures that your team enjoys delicious, fresh, and healthy meals. All meals are made from organic ingredients grown right on our farm, offering nutritious Maharashtrian dishes, salads, grilled snacks, and fresh juices. It's the perfect fuel for your team after a busy day of activities."
    },
    {
      "WhyChooseheading": "Eco-Friendly Accommodation",
      "WhyChoosedescription": "For overnight corporate retreats, we offer comfortable and eco-friendly accommodations. Choose from spacious cottages or tented stays, all equipped with modern amenities to ensure a restful stay. Our accommodations are designed to help your team relax, recharge, and bond in a comfortable, natural environment."
    },
    {
      "WhyChooseheading": "Perfect for Strategy Sessions and Workshops",
      "WhyChoosedescription": "If you want to combine relaxation with business, we offer the perfect setting for strategic workshops, leadership training, and corporate brainstorming sessions. Our spacious conference areas and serene environment allow for focused discussions, creative thinking, and team collaboration."
    },
    {
      "WhyChooseheading": "Flexible Group Sizes and Customization",
      "WhyChoosedescription": "Whether your group is small or large, we cater to all sizes. We offer flexible packages and can accommodate corporate teams of various sizes, ensuring a personalized experience for everyone. Whether it's a department outing or an entire company retreat, we’ve got you covered."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located 100 km from Mumbai, which is about a 2 to 2.5-hour drive. It’s a convenient location for a day trip or a quick corporate retreat, with minimal travel time."
    },
    {
      question: "What types of corporate activities are available at the resort?",
      answer: "We offer a variety of team-building activities such as problem-solving challenges, trust-building games, communication exercises, and outdoor adventures like zip-lining, archery, and rock climbing. We can also customize workshops on leadership development, collaboration, and strategic planning."
    },
    {
      question: "Can Shinde Agri Tourism accommodate overnight stays for corporate teams?",
      answer: "Yes, we offer eco-friendly accommodations, including spacious cottages and tented stays. Our accommodations are designed for comfort and relaxation, providing the perfect space for corporate teams to unwind after a day of activities."
    },
    {
      question: "Is food included in the corporate outing package?",
      answer: "Yes, we offer a farm-to-table dining experience, featuring fresh meals made from organic ingredients grown on our farm. We serve a variety of nutritious dishes, including Maharashtrian cuisine, salads, juices, and more. We ensure your team is well-fed and energized throughout the outing."
    },
    {
      question: "Are the team-building activities suitable for all team sizes?",
      answer: "Yes, we can accommodate teams of all sizes. Whether you’re a small department or a large corporate group, we offer activities that are scalable and customizable to meet the needs of your team. We ensure that every participant has a fulfilling and engaging experience."
    },
    {
      question: "Can we hold meetings or workshops at the resort?",
      answer: "Yes, Shinde Agri Tourism is the perfect place for strategy sessions, brainstorming workshops, or corporate training. We offer spacious meeting areas that allow for focused discussions and productive sessions in a relaxed, peaceful environment."
    },
    {
      question: "What recreational activities are available for relaxation?",
      answer: "Apart from team-building and adventure activities, you can enjoy relaxing activities such as nature walks, birdwatching, campfire gatherings, and informal games. These recreational activities allow your team to relax and bond in a serene, natural setting."
    },
    {
      question: "How do I book a corporate outing at Shinde Agri Tourism?",
      answer: "Booking is easy! Simply contact us through our website or by phone. We will work with you to customize the perfect outing based on your team size, objectives, and preferences. We’ll guide you through the entire process and ensure your outing is a success."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Neha, HR Manager, Mumbai",
      text: "Our corporate outing at Shinde Agri Tourism was absolutely amazing. The team-building activities were both fun and effective, and we really saw an improvement in communication and teamwork afterward. The food was healthy and fresh, and the peaceful surroundings made it the perfect place to relax and bond with colleagues."
    },
    {
      name: "Mr. Ramesh, Operations Director, Mumbai",
      text: "Shinde Agri Tourism is the perfect location for a corporate retreat! The outdoor adventure activities helped our team build trust and communication skills, while the farm-to-table meals kept everyone energized. It was an ideal mix of fun, relaxation, and productivity. We’ll definitely be back!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Corporate Outing Near Mumbai | Team Outing Resorts & Corporate Picnic Venues | Best Corporate Offsite Locations | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best corporate outing locations near Mumbai? Explore top resorts for team outings, corporate picnics, offsite events, and one-day outings. Find ideal venues for office outings, team-building activities, and corporate picnics near Mumbai." />
  <meta name="keywords" content="Corporate Picnic Near Mumbai, Resorts for Corporate Outing Near Mumbai, Team Outing Places Near Mumbai, One Day Corporate Outing Near Mumbai, Corporate Picnic Venues Near Mumbai, Resorts Near Mumbai for Corporate Picnic, Corporate Offsite Near Mumbai, Corporate Offsite Locations Near Mumbai, Corporate Picnic Spots Near Mumbai, Office Outing Places Near Mumbai, Resorts Near Mumbai for Team Outing, Team Outing Resorts Near Mumbai, Corporate Outing Near Mumbai, Corporate Offsite Locations Near Mumbai, Team Outing Near Mumbai, Corporate Outing in Mumbai" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/corporate-outing-near-mumbai" />

  {/* JSON-LD Schema for Corporate Outing Near Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Corporate Outing Near Mumbai",
        "url": "https://shindekrushiparyatan.com/corporate-outing-near-mumbai",
        "description": "Discover the best corporate outing locations near Mumbai. From team-building activities and corporate picnics to offsite events and one-day outings, find the perfect venues for your office outing near Mumbai.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/corporate-outing-near-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "140"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/37.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Corporateoutingnearmumbai;