
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Malthanagri() {



  const cardData =
  {
    keyword: ' Malthan Agri Tourism',
    heading: 'Malthan Agri Tourism: Shinde Agri Tourism',
    headingDescription: 'Looking for an ideal spot to enjoy a day away from the city hustle? Shinde Agri Tourism’s Malthan Agri Tourism offers a delightful experience amidst nature. With lush green landscapes, farm-to-table meals, and a host of interactive activities, it’s the perfect destination for families, friends, and corporate outings. Spend quality time with your loved ones, reconnect with nature, and enjoy a peaceful escape close to home.',

    top: 'Top Features of Malthan Agri Tourism at Shinde Agri Tourism',
    "topPlaces": [
        {
          "title": "Nature Walks and Bird Watching",
          "description": "Take a leisurely walk through beautiful, green surroundings where you can spot a variety of bird species. The peaceful environment is ideal for unwinding, enjoying nature, and observing local wildlife. Malthan Agri Tourism offers the perfect backdrop for birdwatching, where you can connect with nature and experience the calm of the countryside. Whether you're a nature enthusiast or simply seeking a moment of tranquility, these walks provide an enriching experience."
        },
        {
          "title": "Outdoor Games and Sports",
          "description": "Engage in outdoor activities like cricket, badminton, and volleyball. With ample space, Malthan Agri Tourism provides an excellent venue for friendly competitions and fun family bonding moments. The vast, open fields allow for active recreation, whether you're playing a competitive game or simply enjoying a relaxed afternoon in the sun. It’s the perfect place for groups, families, and friends to come together and make lasting memories through sports and laughter."
        },
        {
          "title": "Interactive Farming Activities",
          "description": "Experience farm life firsthand with activities like milking cows, planting crops, and harvesting fresh produce. These interactive activities are both fun and educational, making your visit unique and memorable. Whether you’re looking to learn about sustainable farming practices or simply enjoy the hands-on experience of farm chores, Malthan Agri Tourism offers an immersive opportunity to connect with the land and gain insight into rural life."
        },
        {
          "title": "Barbecue and Bonfire",
          "description": "As the day winds down, gather around a bonfire and enjoy a delicious barbecue with your group. It’s a wonderful way to relax, share stories, and enjoy a cozy evening together. The serene environment of Malthan Agri Tourism provides the perfect setting for an intimate bonfire experience. Whether it’s roasting marshmallows or grilling your favorite food, the barbecue and bonfire create a warm and festive atmosphere that’s perfect for families and groups."
        },
        {
          "title": "Farm-Fresh Meals",
          "description": "Savor healthy, freshly prepared meals made from ingredients grown right on the farm. This authentic farm-to-table dining experience adds a delightful, wholesome touch to your day. Enjoy delicious meals that are not only nutritious but also bring the flavors of the farm to your table. With produce harvested straight from the fields, each meal offers a fresh and natural taste, enhancing your overall experience at Malthan Agri Tourism."
        },
        {
          "title": "Relaxing Picnic Areas",
          "description": "Malthan Agri Tourism provides well-maintained picnic spots with ample seating and space to spread out a blanket, making it easy to settle in and enjoy a peaceful day with family and friends. These scenic picnic areas, surrounded by nature, create a calm and inviting atmosphere. Whether you’re having a quiet lunch or enjoying snacks in the great outdoors, these spaces offer the perfect setting to relax and connect with your loved ones."
        }
      ],


  "services": [
    {
      "name": "Malthan Agritourism Packages",
      "description": "Malthan Agritourism offers a range of exciting packages, including day trips, overnight stays, and customized experiences. Guests can enjoy farm activities such as bullock cart rides, nature walks, and organic farming tours. The packages are designed to provide an immersive experience into rural life, making it perfect for family getaways, corporate outings, and weekend retreats."
    },
    {
      "name": "Shinde Krushi Paryatan",
      "description": "Shinde Krushi Paryatan (Shinde Agri Tourism) is an ideal destination for those seeking an authentic agro-tourism experience. Located in the serene countryside near Pune, it offers visitors a chance to enjoy farm activities, relax in a rustic setting, and explore the beauty of rural India. Guests can experience organic farming, farm tours, bullock cart rides, and much more."
    },
    {
      "name": "Shinde Agri Tourism",
      "description": "Shinde Agri Tourism offers a peaceful, rustic escape from the urban hustle. Guests can stay at beautiful farmhouses, participate in various agro-tourism activities, enjoy local food, and connect with nature. The farm is perfect for family get-togethers, corporate offsites, and group retreats. Experience the countryside while relaxing in a tranquil setting."
    },
    {
      "name": "Malthan AgriTourism Contact Number",
      "description": "For bookings and inquiries, you can contact Malthan Agritourism at [insert contact number]. Their team will assist you with package details, reservations, and any other queries you might have regarding your visit."
    },
    {
      "name": "Agri Tourism in Shirur",
      "description": "Shirur is home to several agri-tourism destinations, where visitors can experience rural life up close. Activities include farm tours, nature walks, bullock cart rides, and participating in organic farming. It’s an ideal destination for those looking to escape the city and experience farm life in a serene environment."
    },
    {
      "name": "Picnic Spot Near Ranjangaon MIDC Shirur",
      "description": "For a relaxing picnic near Ranjangaon MIDC Shirur, you can visit Khandala Hill, a peaceful hilltop with breathtaking views, perfect for a day out with family or friends. Alternatively, Shirur Lake offers a calm environment to unwind, enjoy nature, and spend quality time."
    },
    {
      "name": "Picnic Spot Near Shikrapur",
      "description": "If you are looking for a picnic spot near Shikrapur, head to Wagholi Dam or Bhigwan Bird Sanctuary. These spots offer scenic beauty, perfect for a peaceful outing. Khandala Hill is also an option nearby, with great views and opportunities for light trekking."
    },
    {
      "name": "Picnic Spot Near Chakan",
      "description": "For a picnic near Chakan, Morachi Chincholi is a great destination. Known for its peacock sanctuary, this place offers a peaceful environment for a relaxing day out. Pune-Okayama Friendship Garden is another nearby spot, offering lush greenery and walking trails."
    },
    {
      "name": "Picnic Spot Near Rajgurunagar",
      "description": "Rajgurunagar offers multiple scenic picnic spots, including Khadakwasla Dam and Sinhagad Fort. For a more nature-filled experience, Pashan Lake is also a great choice for families and groups looking to enjoy a peaceful day surrounded by nature."
    },
    {
      "name": "Picnic Spot Near Khed Rajgurunagar",
      "description": "If you’re in Khed Rajgurunagar, a visit to Khadakwasla Dam is a must. This picturesque spot offers boat rides and beautiful landscapes. Additionally, Mulshi Lake is nearby and is perfect for a day picnic amidst lush greenery and serene waters."
    },
    {
      "name": "Picnic Spot Near Junnar",
      "description": "Junnar is home to Naneghat and Lohagad Fort, which are excellent for a day of trekking and picnicking. For a more relaxing experience, Malshej Ghat is nearby, offering stunning views, waterfalls, and lush greenery, making it an ideal picnic destination."
    },
    {
      "name": "Picnic Spot Near Sanaswadi",
      "description": "For a peaceful picnic near Sanaswadi, you can visit Khadakwasla Dam or Pashan Lake. These serene locations are ideal for family picnics, nature walks, and quiet relaxation. Mulshi Lake is another option, known for its calm surroundings and scenic beauty."
    },
    {
      "name": "Picnic Spot Near Daund",
      "description": "Daund offers several nature spots ideal for picnics, such as Bhigwan Bird Sanctuary and Khadakwasla Dam. These locations provide scenic views and are great for birdwatching, relaxing, and spending quality time with family and friends."
    },
    {
      "name": "Cheapest Ashtavinayak Darshan Stay",
      "description": "For a budget-friendly Ashtavinayak Darshan stay, you can find affordable packages that include transportation and accommodation for visits to the Ashtavinayak Temples. Many travel agencies in Pune offer economical group tours with basic stay options, making it an ideal choice for pilgrims on a budget."
    },
    {
      "name": "Picnic Spot in Morachi Chincholi",
      "description": "Morachi Chincholi, famous for its peacock sanctuary, is a fantastic spot for a nature-filled picnic. The area is quiet, with plenty of open space for a relaxed family day. Khadakwasla Dam and Pune-Okayama Friendship Garden are also nearby for those looking for a peaceful retreat."
    },
    {
      "name": "Picnic Spot Near Wagholi",
      "description": "For a delightful picnic near Wagholi, you can visit Pune-Okayama Friendship Garden or Khadakwasla Dam. Bhigwan Bird Sanctuary is also nearby and is an excellent spot for birdwatching and enjoying nature. These locations are perfect for a day of outdoor fun and relaxation."
    },
    {
      "name": "Shinde Agri Tourism Malthan Agri Tourism Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Malthan Agri Tourism experience. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],


      tableData: [
  ["- Malthan Agritourism Packages", "- Shinde Krushi Paryatan"],
  ["- Shinde Agri Tourism", "- Malthan AgriTourism Contact Number"],
  ["- Agri Tourism in Shirur", "- Picnic Spot Near Ranjangaon MIDC Shirur"],
  ["- Picnic Spot Near Shikrapur", "- Picnic Spot Near Chakan"],
  ["- Picnic Spot Near Rajgurunagar", "- Picnic Spot Near Khed Rajgurunagar"],
  ["- Picnic Spot Near Junnar", "- Picnic Spot Near Sanaswadi"],
  ["- Picnic Spot Near Daund", "- Cheapest Ashtavinayak Darshan Stay"],
  ["- Picnic Spot in Morachi Chincholi", "- Picnic Spot Near Wagholi"]
],

whychoose: [
  {
    "WhyChooseheading": "Convenient Location",
    "WhyChoosedescription": "Shinde Agri Tourism is strategically located just 50 km from Pune, making it an easily accessible escape from the hustle and bustle of city life. Whether you’re driving with family, friends, or colleagues, the short travel time ensures that you can spend more time in nature, enjoying the peaceful surroundings, and less time on the road. It's the perfect quick getaway for a refreshing weekend or a day trip."
  },
  {
    "WhyChooseheading": "Serene and Peaceful Environment",
    "WhyChoosedescription": "If you're looking for a break from the noise and stress of the city, Shinde Agri Tourism provides the perfect tranquil setting. Surrounded by lush green fields, open spaces, and a serene atmosphere, our property offers the peace you need to relax and recharge. Whether you want to take a leisurely walk through the farm, meditate by the river, or just unwind under the shade of trees, the environment here is designed to bring you closer to nature and rejuvenate your senses."
  },
  {
    "WhyChooseheading": "Authentic Farm Experiences",
    "WhyChoosedescription": "Experience rural life up close at Shinde Agri Tourism. We offer a range of interactive farm activities like fruit picking, organic farming tours, milking cows, and animal feeding, giving you a hands-on experience in sustainable farming. You can get involved in farm chores, interact with friendly animals, and learn about traditional agricultural practices that have been passed down through generations. It's the perfect opportunity for both kids and adults to connect with nature and understand the importance of eco-friendly farming."
  },
  {
    "WhyChooseheading": "Adventure Activities for All",
    "WhyChoosedescription": "For thrill-seekers and adventure enthusiasts, Shinde Agri Tourism offers an exciting array of outdoor activities. From zip-lining over scenic views to rock climbing, archery, and nature trails, there’s plenty to keep adrenaline junkies entertained. Whether you're a beginner or an experienced adventurer, we have something for everyone. Our guided nature walks and treks are perfect for those who want to explore the natural beauty of the area while getting a good workout. It's a great way to bond with friends or family while experiencing the outdoors."
  },
  {
    "WhyChooseheading": "Farm-to-Table Dining",
    "WhyChoosedescription": "At Shinde Agri Tourism, we offer a delightful farm-to-table dining experience where all food is sourced from our organic farm. Enjoy fresh, healthy meals made from locally grown produce, such as organic vegetables, fruits, and grains. Our traditional Maharashtrian meals, fresh salads, juices, and snacks are made with love and care, offering an authentic taste of rural India. You’ll savor the flavors of homegrown food while enjoying the beautiful surroundings, making it a truly immersive experience in every way."
  },
  {
    "WhyChooseheading": "Perfect Venue for Special Occasions",
    "WhyChoosedescription": "Looking for a unique venue for your special event? Whether you're celebrating a family reunion, birthday, anniversary, or planning a corporate retreat, Shinde Agri Tourism offers an ideal setting. Our customizable event packages include food, activities, and event planning services to ensure your occasion is truly special. From intimate gatherings to large celebrations, we can accommodate all kinds of events. With our outdoor spaces, comfortable farmhouses, and personalized services, your event will be memorable for all the right reasons."
  },
  {
    "WhyChooseheading": "Exclusivity and Privacy",
    "WhyChoosedescription": "When you rent out our farmhouse, you get exclusive access to the entire property, ensuring complete privacy for your group. Whether you’re hosting a picnic, organizing a team-building event, or enjoying a quiet retreat, you’ll have the space and freedom to do so without interruptions. You can relax by the bonfire, enjoy a private outdoor meal, or simply unwind in the solitude of nature. The entire property is yours to explore, providing the ultimate sense of exclusivity and comfort."
  },
  {
    "WhyChooseheading": "Affordable and Flexible Packages",
    "WhyChoosedescription": "Shinde Agri Tourism offers a variety of flexible packages to suit different group sizes, budgets, and travel preferences. Whether you're looking for a one-day picnic, a weekend getaway, or a week-long stay, we have an option for you. Our affordable pricing ensures that you get the best value for your money, with customizable experiences that match your needs. You can choose from a range of activities, meals, and accommodations to create the perfect outing, all while staying within your budget."
  },
  {
    "WhyChooseheading": "Eco-Friendly and Sustainable Practices",
    "WhyChoosedescription": "At Shinde Agri Tourism, we are deeply committed to sustainability and eco-friendly practices. Our farm operates with organic farming techniques, using natural fertilizers and methods to ensure the health of the land and crops. We also practice rainwater harvesting, use renewable energy sources, and have implemented waste management systems to reduce our environmental impact. By staying with us, you are supporting sustainable tourism and helping protect the environment. It’s the perfect way to enjoy a getaway while being mindful of the planet."
  }
]





  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located about 50 km from Pune, which is approximately a 1 to 1.5-hour drive, making it perfect for a weekend getaway or day trip."
    },
    {
      question: "What activities can I enjoy at Shinde Agri Tourism?",
      answer: "We offer a wide range of activities including fruit picking, organic farming, animal feeding, bullock cart rides, zip-lining, rock climbing, archery, nature walks, and much more!"
    },
    {
      question: "Can I book Shinde Agri Tourism for a special occasion or corporate retreat?",
      answer: "Yes, Shinde Agri Tourism is ideal for family gatherings, birthday parties, anniversaries, and corporate retreats. We offer customizable packages that include food, activities, and event planning to make your special occasion unforgettable."
    },
    {
      question: "Is food included in the booking?",
      answer: "Yes, we offer a farm-to-table dining experience with fresh meals made from organic ingredients grown on our farm. You’ll enjoy a variety of traditional Maharashtrian dishes, fresh salads, juices, and other healthy snacks."
    },
    {
      question: "How many people can Shinde Agri Tourism accommodate?",
      answer: "We can comfortably accommodate small to large groups, whether it's a family outing, group of friends, or corporate retreat. The farmhouse and surrounding area offer plenty of space for a variety of group sizes."
    },
    {
      question: "Is Shinde Agri Tourism eco-friendly?",
      answer: "Yes, we prioritize sustainability at Shinde Agri Tourism. Our practices include organic farming, rainwater harvesting, and waste management systems that minimize our environmental impact."
    },
    {
      question: "How do I book a stay at Shinde Agri Tourism?",
      answer: "Booking is easy! You can reach out to us through our website or phone. Simply tell us your preferred dates, group size, and any special requests, and we’ll handle the rest."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. and Mrs. Deshmukh, Pune",
      text: "We had an incredible time at Shinde Agri Tourism! The kids had a blast picking fruits, feeding the animals, and going on tractor rides, while we enjoyed the peaceful environment and fresh farm-to-table meals. A perfect family getaway!"
    },
    {
      name: "Ms. Aditi, HR Manager, Pune",
      text: "Our team-building retreat at Shinde Agri Tourism was fantastic! The activities were engaging and fun, and the food was delicious. It’s the ideal spot to mix work and relaxation. Highly recommend it!"
    }
  ];
  






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Malthan Agri Tourism | Best Agri Tourism Packages Near Shirur, Pune | Shinde Krushi Paryatan | Picnic Spots Near Ranjangaon MIDC, Shikrapur, Chakan, Rajgurunagar & More</title>
  <meta name="description" content="Discover the best Agri Tourism experiences near Shirur with Shinde Krushi Paryatan. Explore Malthan Agri Tourism packages, picnic spots near Ranjangaon MIDC, Shikrapur, Chakan, Rajgurunagar, and more. Ideal for family getaways, team outings, and nature lovers." />
  <meta name="keywords" content="Malthan AgriTourism Packages, Shinde Krushi Paryatan, Shinde Agri Tourism, Agri Tourism in Shirur, Picnic Spot Near Ranjangaon MIDC Shirur, Picnic Spot Near Shikrapur, Picnic Spot Near Chakan, Picnic Spot Near Rajgurunagar, Picnic Spot Near Khed Rajgurunagar, Picnic Spot Near Junnar, Picnic Spot Near Sanaswadi, Picnic Spot Near Daund, Cheapest Ashtavinayak Darshan Stay, Picnic Spot in Morachi Chincholi, Picnic Spot Near Wagholi" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/malthan-agri-tourism" />

  {/* JSON-LD Schema for Malthan Agri Tourism */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Malthan Agri Tourism",
        "url": "https://shindekrushiparyatan.com/malthan-agri-tourism",
        "description": "Experience the charm of Malthan Agri Tourism near Shirur, Pune. Explore agri-tourism packages, picnic spots, and getaways near Ranjangaon MIDC, Shikrapur, Chakan, and more. Perfect for family outings and corporate retreats.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Shirur",
          "addressRegion": "Maharashtra",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.3672",
          "longitude": "74.1888"
        },
        "image": "https://shindekrushiparyatan.com/images/malthan-agri-tourism.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "150"
        }
      }
    `} 
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/41.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Malthanagri;