
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Besthotalsinranjan() {



  const cardData =
  {
    keyword: ' Best Hotels Near Ranjangaon Ganpati',
    heading: 'Best Hotels Near Ranjangaon Ganpati: Shinde Agri Tourism',
    headingDescription: 'Looking for a relaxing and comfortable stay near Ranjangaon Ganpati? Shinde Agri Tourism offers a unique blend of agri-tourism experiences and well-maintained accommodation options near the famous Ranjangaon Ganpati Temple. Whether you’re visiting for a spiritual journey or a peaceful retreat, our resort provides the perfect balance of comfort, serenity, and easy access to the temple and nearby attractions.',

    top: 'Top Hotels at Shinde Agri Tourism Near Ranjangaon Ganpati',
    "topPlaces": [
        {
          "title": "Shinde Agri Tourism Resort",
          "description": "Located just a short drive from Ranjangaon Ganpati Temple, Shinde Agri Tourism Resort offers comfortable and spacious accommodations surrounded by lush greenery. Enjoy serene views, cozy rooms, and a relaxing atmosphere, perfect for pilgrims and families visiting the area. The peaceful setting provides an ideal retreat to relax after a day of sightseeing, with easy access to the temple and surrounding attractions."
        },
        {
          "title": "Farm Stay Rooms",
          "description": "At Shinde Agri Tourism, we offer farm stay rooms that provide a rustic, yet comfortable experience. The rooms are well-furnished and equipped with modern amenities, making it ideal for those looking for a peaceful and immersive stay close to nature. Experience the tranquility of farm life while enjoying a cozy, home-like atmosphere during your stay."
        },
        {
          "title": "Luxury Cottages",
          "description": "Our luxury cottages provide a higher level of comfort and privacy. With modern furnishings, air conditioning, and beautiful garden views, these cottages are perfect for guests seeking a more luxurious stay while visiting Ranjangaon Ganpati. The spacious design and serene surroundings ensure that your stay is both relaxing and memorable."
        },
        {
          "title": "Eco-Friendly Rooms",
          "description": "For eco-conscious travelers, Shinde Agri Tourism offers eco-friendly rooms built with sustainable materials. These rooms are designed to blend into the natural surroundings while offering comfortable amenities, perfect for nature lovers and those seeking a greener stay. Enjoy the beauty of nature while minimizing your environmental impact."
        },
        {
          "title": "Group Accommodation",
          "description": "For large groups visiting Ranjangaon Ganpati, Shinde Agri Tourism provides spacious group accommodation options. These rooms are designed to accommodate families or corporate groups, offering a cozy and affordable stay with easy access to the temple and nearby attractions. The rooms are well-equipped to ensure comfort and convenience for all guests."
        },
        {
          "title": "Tent Accommodation",
          "description": "Experience a unique stay with our luxury tents. Designed for guests looking for adventure combined with comfort, these tents provide a cozy atmosphere with all necessary amenities, making it an ideal option for those seeking something different near Ranjangaon Ganpati. The glamping-style tents offer a comfortable escape amidst nature."
        }
      ],
      "services": [
        {
          "name": "Best Agri Tourism Near Ahmednagar",
          "description": "If you're looking for agri tourism near Ahmednagar, Shinde Agri Tourism offers a great rural retreat with farm activities, organic farming, and a peaceful environment. Another good option is Malthan Agri Tourism, providing a variety of agro-tourism experiences such as farm tours, bullock cart rides, and nature walks."
        },
        {
          "name": "Best Agro Tourism Resort Near Pune",
          "description": "For a luxurious agro-tourism experience near Pune, Shinde Agri Tourism and Malthan Agritourism offer top-notch rural stays with a variety of activities. Resorts like The Corinthians Resort and Aamby Valley provide a blend of agro-tourism with luxury amenities, perfect for a relaxing getaway."
        },
        {
          "name": "One Day Picnic Agro Tourism Near Pune",
          "description": "For a one-day picnic near Pune, visit Shinde Agri Tourism for a day of fun farm activities, nature walks, and relaxation. Malthan Agritourism also offers day packages with bullock cart rides, organic farming experiences, and a peaceful retreat for a quick escape from the city."
        },
        {
          "name": "Ahmednagar Picnic Spot for Family",
          "description": "For a family picnic near Ahmednagar, head to Khandala Hill or Bhandardara Dam, both offering scenic views and peaceful environments. Shirdi is also an option, providing both spiritual experiences and picnic-friendly spots for families to enjoy a relaxing day."
        },
        {
          "name": "Tourist Places Near Ahmednagar Within 50 Km",
          "description": "There are many tourist spots near Ahmednagar within a 50 km radius, including Shirdi (famous for the Sai Baba Temple), Bhandardara Dam (ideal for picnics and nature lovers), Shani Shingnapur (known for its temple), and Nandur Madhmeshwar Wildlife Sanctuary (bird watching and nature tours)."
        },
        {
          "name": "One Day Picnic Spot Near Ahmednagar",
          "description": "Bhandardara Dam is a perfect one-day picnic spot near Ahmednagar, offering beautiful landscapes and serene water views. Alternatively, visit Khandala Hill for a peaceful escape, or Shirdi for both spiritual and recreational experiences."
        },
        {
          "name": "Agri Tourism Near Nashik",
          "description": "Nashik is home to some fantastic agri-tourism experiences: Sula Vineyards offers a mix of wine tourism and rural experiences, with tours of their vineyards and wine-tasting sessions. Shinde Agri Tourism near Nashik provides a serene rural experience, with farm tours, nature walks, and relaxation. Malthan Agritourism near Nashik also offers an authentic agro-tourism experience."
        },
        {
          "name": "Agri Tourism Near Mumbai",
          "description": "For agri tourism near Mumbai, head to Malshej Ghat, where you can enjoy the countryside and experience agro-tourism activities. Tungarli Lake near Lonavala offers a perfect mix of agro-tourism and adventure. Malthan Agri Tourism also offers a countryside escape from Mumbai, with various farming experiences and nature activities."
        },
        {
          "name": "Agro Tourism Near Aurangabad",
          "description": "Aurangabad has several agro-tourism options for nature lovers: Ajanta and Ellora Caves are popular tourist destinations, but nearby villages offer rural tourism experiences such as organic farming, bullock cart rides, and farm stays. Malthan Agri Tourism is also a good choice for farm activities and relaxation near Aurangabad."
        },
        {
          "name": "Adventure Park Near Aurangabad",
          "description": "For adventure activities near Aurangabad, visit Daulatabad Fort, offering some light trekking and historical exploration. Ajanta and Ellora Caves provide a combination of adventure and culture, though not specifically an 'adventure park.' Dnyanganga Wildlife Sanctuary offers nature walks and exploration."
        },
        {
          "name": "Picnic Spot Near Aliyanagar",
          "description": "If you're looking for a picnic spot near Aliyanagar, visit Khadakwasla Dam, offering beautiful lakeside views, boating, and picnic areas. Pune-Okayama Friendship Garden is also a peaceful spot for a day out in nature."
        },
        {
          "name": "Farmhouse Near Ahmednagar",
          "description": "Shinde Agri Tourism offers beautiful farmhouses near Ahmednagar, providing a rustic experience with nature walks, farm activities, and relaxation. For a more luxurious stay, you can visit resorts around Bhandardara or Khandala Hill."
        },
        {
          "name": "Tourist Places in Ahmednagar",
          "description": "Some key tourist attractions in Ahmednagar include Shirdi (Sai Baba Temple), Shani Shingnapur (famous for its temple), Bhandardara Dam (picnic spot), Khandala Hill (trekking and scenic views), and Nandur Madhmeshwar Wildlife Sanctuary (bird-watching and nature walks)."
        },
        {
          "name": "Agri Tourism Near Nashik",
          "description": "Nashik offers some of the best agro-tourism experiences: Sula Vineyards for wine lovers, while Shinde Agri Tourism and Malthan Agri Tourism provide authentic rural stays with activities like organic farming, nature walks, and traditional village experiences."
        },
        {
          "name": "Best Agro Tourism Near Nashik",
          "description": "For the best agro-tourism experiences near Nashik, Sula Vineyards stands out for wine tourism, while Shinde Agri Tourism and Malthan Agri Tourism provide authentic rural stays with farm tours, bullock cart rides, and an immersive agro-tourism experience."
        },
        {
          "name": "Shinde Agri Tourism Agri Tourism Near Ahmednagar Contact Number",
          "description": "Escape to nature with Shinde Agri Tourism for a perfect Agri Tourism Near Ahmednagar experience. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
        }
      ],
      tableData: [
        ["- Hotels in Ranjangaon MIDC Pune", "- Best Hotel for Stay in Ashtavinayak Darshan"],
        ["- Hotels On Pune Nagar Road Near Kondhapuri", "- Business Hotels in Ranjangaon MIDC"],
        ["- Luxury Hotels in Ranjangaon MIDC", "- Resort in Ranjangaon Ganpati"],
        ["- Ranjangaon Ganpati Bhakta Niwas", "- Ranjangaon Ganpati Stay"]
      ],
      
      whychoose: [
        {
          "WhyChooseheading": "Proximity to Ranjangaon Ganpati Temple",
          "WhyChoosedescription": "Shinde Agri Tourism is ideally located just 10-15 km from the Ranjangaon Ganpati Temple. This makes it a convenient and peaceful place for pilgrims and tourists alike, offering a tranquil retreat after a visit to the temple, with easy access to the sacred site."
        },
        {
          "WhyChooseheading": "Spacious and Comfortable Accommodations",
          "WhyChoosedescription": "Our well-maintained rooms and cottages are spacious and equipped with modern amenities to ensure a comfortable stay. Whether you are traveling solo, with family, or with a group, our accommodations cater to your needs, providing a restful and cozy atmosphere."
        },
        {
          "WhyChooseheading": "Peaceful and Tranquil Environment",
          "WhyChoosedescription": "Shinde Agri Tourism is nestled in a serene setting, surrounded by lush greenery and open spaces. The peaceful environment away from the hustle and bustle of the city provides the perfect place to unwind, relax, and connect with nature after visiting Ranjangaon Ganpati or enjoying family activities."
        },
        {
          "WhyChooseheading": "Farm and Adventure Activities",
          "WhyChoosedescription": "Our guests can indulge in a variety of fun farm activities such as fruit picking, animal feeding, and organic farming. For adventure enthusiasts, we offer outdoor activities like zip-lining, rock climbing, and archery, making Shinde Agri Tourism a destination that caters to both relaxation and adventure."
        },
        {
          "WhyChooseheading": "Farm-to-Table Dining Experience",
          "WhyChoosedescription": "At Shinde Agri Tourism, we take pride in offering freshly prepared meals made from organic ingredients grown right on our farm. Our farm-to-table dining experience includes traditional Maharashtrian dishes, fresh salads, juices, and grilled snacks, providing a healthy and authentic food experience."
        },
        {
          "WhyChooseheading": "Customizable Packages for Special Occasions",
          "WhyChoosedescription": "Whether it’s a religious celebration, family gathering, birthday, or anniversary, Shinde Agri Tourism is an ideal venue for your special occasion. We offer customizable packages with catering, activities, and decorations to ensure that your event is both memorable and hassle-free."
        },
        {
          "WhyChooseheading": "Privacy and Exclusivity",
          "WhyChoosedescription": "When you book Shinde Agri Tourism, you enjoy complete privacy. Rent out the entire property for your group and host family gatherings, bonfire evenings, or corporate events in a peaceful and exclusive setting, far away from the distractions of everyday life."
        },
        {
          "WhyChooseheading": "Eco-Friendly and Sustainable Practices",
          "WhyChoosedescription": "At Shinde Agri Tourism, sustainability is at the heart of everything we do. We employ eco-friendly practices such as rainwater harvesting, organic farming, and waste management systems, ensuring that your stay is both enjoyable and environmentally responsible."
        },
        {
          "WhyChooseheading": "Affordable and Flexible Packages",
          "WhyChoosedescription": "We offer flexible and affordable rental packages that can be tailored to your needs and budget. Whether you're planning a day trip, a weekend getaway, or an extended stay, Shinde Agri Tourism has options to suit your group's size and preferences."
        }
      ]
      




  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Ranjangaon Ganpati Temple?",
      answer: "Shinde Agri Tourism is located approximately 10-15 km from Ranjangaon Ganpati Temple, making it a short and easy drive for pilgrims and tourists visiting the temple."
    },
    {
      question: "What types of activities are available at Shinde Agri Tourism?",
      answer: "We offer a variety of activities including fruit picking, organic farming, animal feeding, bullock cart rides, zip-lining, rock climbing, archery, and nature walks. There’s something for everyone, whether you’re looking for relaxation or adventure!"
    },
    {
      question: "Can we book Shinde Agri Tourism for special occasions or events?",
      answer: "Yes, Shinde Agri Tourism is the perfect venue for special occasions such as family reunions, birthday parties, anniversaries, or corporate retreats. We offer customizable event packages that include food, activities, and decor to make your occasion memorable."
    },
    {
      question: "Is food included in the stay?",
      answer: "Yes, we offer a farm-to-table dining experience, with freshly prepared meals made from organic ingredients grown on our farm. Enjoy traditional Maharashtrian dishes, salads, juices, and grilled snacks during your stay."
    },
    {
      question: "How many people can Shinde Agri Tourism accommodate?",
      answer: "Shinde Agri Tourism can comfortably accommodate large groups, making it ideal for family gatherings, corporate retreats, or group outings. The number of guests can be customized based on the type of accommodation and event setup."
    },
    {
      question: "Is Shinde Agri Tourism eco-friendly?",
      answer: "Yes, we are committed to sustainability. Our farm practices include organic farming, rainwater harvesting, and waste management to minimize our environmental impact and provide a green and eco-conscious experience."
    },
    {
      question: "How do I book Shinde Agri Tourism for my stay?",
      answer: "Booking is simple! You can contact us via our website or phone. Just let us know your preferred dates, group size, and any special requirements, and we’ll take care of the rest to ensure a comfortable and memorable stay."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. and Mrs. Joshi, Pune",
      text: "Our stay at Shinde Agri Tourism was simply wonderful! The location is perfect for visiting Ranjangaon Ganpati Temple, and the serene environment made our family getaway unforgettable. The kids had a blast feeding the animals and picking fruits from the farm. Highly recommend it for a peaceful retreat!"
    },
    {
      name: "Mr. Rohit, HR Manager, Pune",
      text: "We booked Shinde Agri Tourism for our corporate retreat, and it was an amazing experience. The farmhouse was spacious and well-equipped, and the team-building activities were both fun and engaging. The food was fresh and delicious. Definitely our go-to place for the next team outing!"
    }
  ];
    







  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Best Hotels in Ranjangaon Ganpati | Luxury & Business Hotels Near Ranjangaon MIDC, Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Explore the best hotels in Ranjangaon Ganpati, near Pune, offering luxury, business, and budget stays. Book your stay near Ashtavinayak Darshan, Ranjangaon Ganpati, and nearby resorts for a comfortable and memorable visit." />
  <meta name="keywords" content="Hotels in Ranjangaon MIDC Pune, Best Hotel for Stay in Ashtavinayak Darshan, Hotels on Pune Nagar Road Near Kondhapuri, Business Hotels in Ranjangaon MIDC, Luxury Hotels in Ranjangaon MIDC, Resort in Ranjangaon Ganpati, Ranjangaon Ganpati Bhakta Niwas, Ranjangaon Ganpati Stay" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/best-hotels-in-ranjangaon-ganpati" />

  {/* JSON-LD Schema for Best Hotels in Ranjangaon Ganpati */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Shinde Krushi Paryatan - Best Hotels in Ranjangaon Ganpati",
        "url": "https://shindekrushiparyatan.com/best-hotels-in-ranjangaon-ganpati",
        "description": "Looking for the best hotels near Ranjangaon Ganpati? Find luxury, business, and budget-friendly hotels, including stays near Ashtavinayak Darshan, resorts in Ranjangaon, and comfortable stays for pilgrims and travelers.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Ranjangaon",
          "addressRegion": "Maharashtra",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.9184",
          "longitude": "74.2906"
        },
        "image": "https://shindekrushiparyatan.com/images/hotels-in-ranjangaon-ganpati.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.5",
          "reviewCount": "150"
        }
      }
    `} 
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/44.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Besthotalsinranjan;