
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Corporateout() {



  const cardData =
  {
    keyword: 'Corporate Outing Resort Near Pune',
    heading:  'Corporate Outing Resort Near Pune: Shinde Agri Tourism',
    headingDescription: 'If you are planning a corporate outing near Pune, Shinde Agri Tourism provides the perfect blend of relaxation and team-building activities in a refreshing natural environment. This resort is designed to give teams a break from the city’s hustle, offering a space where colleagues can connect, recharge, and engage in meaningful experiences together.',

    top: 'Top Activities at Shinde Agri Tourism for Corporate Outings	',
    topPlaces: [
      {
        "title": "Team-Building Activities and Outdoor Games",
        "description": "Strengthen team bonds with various outdoor games and team-building exercises. With open fields for cricket, volleyball, and other sports, Shinde Agri Tourism provides an ideal setting to foster teamwork and camaraderie among colleagues."
      },
      {
        "title": "Scenic Pre-Wedding and Corporate Photoshoots",
        "description": "The picturesque environment at Shinde Agri Tourism offers a unique backdrop for corporate photoshoots. Capture moments that highlight team spirit and enjoy the beautiful surroundings as a backdrop for memorable team photos."
      },
      {
        "title": "Interactive Farming Experiences",
        "description": "Engage your team in traditional farming activities like planting, harvesting, and animal care. This hands-on experience not only educates but also brings team members closer, as they work together in an entirely different setting, away from the usual office atmosphere."
      },
      {
        "title": "Nature Walks and Bird Watching for Relaxation",
        "description": "Encourage relaxation and mindfulness with nature walks and bird watching. Shinde Agri Tourism’s peaceful surroundings make it easy to unwind, offering team members a much-needed break to recharge and enjoy the serenity of nature."
      },
      {
        "title": "Spacious Conference and Picnic Areas for Meetings",
        "description": "Shinde Agri Tourism features ample space for both informal gatherings and more structured meetings. Whether you need a picnic-style setup or a conference area for discussions, this resort caters to all your needs, combining business with leisure for a productive and enjoyable outing."
      }
    ],


"services": [
  {
    "name": "Team Outing Places Near Pune",
    "description": "Shinde Agritourism is an excellent choice for team outings near Pune. The farm offers a variety of team-building activities that encourage collaboration and relaxation. From farm tours to bullock cart rides and traditional rural games, it provides a refreshing break from the office environment. Whether you're planning a day of fun, learning, or team-building exercises, Shinde Agritourism is an ideal spot for a memorable outing."
  },
  {
    "name": "Resorts Near Pune for Corporate Outing",
    "description": "Shinde Agritourism, while not a traditional resort, offers a unique and refreshing setting for corporate outings near Pune. The peaceful farm environment provides the perfect backdrop for team-building activities, nature walks, and learning about sustainable farming practices. You can enjoy a day of relaxation, outdoor games, and delicious organic food, making it a fantastic choice for a corporate outing away from the usual resort-style destinations."
  },
  {
    "name": "Best Resort for Corporate Outing Near Pune",
    "description": "Shinde Agritourism is one of the best 'resorts' for corporate outings near Pune. It may not offer conventional resort amenities, but its immersive rural experience makes it a standout destination for team-building and corporate outings. The range of interactive activities—from farm tours to animal feeding—helps improve team bonding and provides a much-needed break from the usual office routine. It’s a great way to encourage team collaboration in a calm, natural setting."
  },
  {
    "name": "Corporate Team Outing Places in Pune",
    "description": "For a unique corporate team outing near Pune, Shinde Agritourism offers a fantastic option. The farm's peaceful rural setting combined with its array of team-building activities—like nature walks, bullock cart rides, and organic farming experiences—makes it an ideal place for employees to unwind, interact, and strengthen relationships outside the office. It’s a memorable outing that combines relaxation, education, and fun."
  },
  {
    "name": "Corporate Team Outing Places Near Pune",
    "description": "Looking for corporate team outing places near Pune? Shinde Agritourism is a great option. Offering a mix of fun and educational activities, it provides a refreshing escape from the busy corporate environment. Your team can participate in group games, learn about sustainable farming practices, and bond over a shared picnic experience, all in the tranquil setting of the farm. It’s the perfect place for a team outing with a rural touch."
  },
  {
    "name": "Resorts for Team Outing Near Pune",
    "description": "Shinde Agritourism is one of the top resorts for team outings near Pune. While not a traditional resort, the farm’s serene environment and range of activities—such as farm tours, animal interactions, and bullock cart rides—make it an ideal destination for corporate groups looking for a relaxing yet productive outing. It’s a great place to enjoy outdoor fun and foster teamwork in a peaceful rural environment."
  },
  {
    "name": "Team Outing Resorts Near Pune",
    "description": "For an unforgettable team outing experience, Shinde Agritourism offers a unique alternative to traditional team outing resorts near Pune. The farm is equipped with a variety of activities designed to promote teamwork and relaxation, such as farm-based games, team-building exercises, and animal interactions. It’s a fantastic place to bond with your colleagues while enjoying the fresh air and beautiful surroundings."
  },
  {
    "name": "Team Outing Places in Pune",
    "description": "Shinde Agritourism is one of the best team outing places in Pune. Located just outside the city, it offers a calm and rejuvenating atmosphere where corporate teams can relax, enjoy a variety of outdoor activities, and engage in educational experiences like organic farming tours. It's an ideal setting for corporate groups to relax and rejuvenate while strengthening team dynamics."
  },
  {
    "name": "Resorts for Team Outing Near Pune",
    "description": "Looking for resorts for a team outing near Pune? Shinde Agritourism is an excellent choice. The farm offers a serene escape with a wide variety of activities for team bonding. From farm tours to fun games, it's an ideal location for corporate groups to take a break from work, relax, and engage in activities that foster team spirit and collaboration. The natural environment provides a refreshing change from typical resort settings."
  },
  {
    "name": "Corporate Outing Near Pune",
    "description": "Shinde Agritourism is a fantastic destination for a corporate outing near Pune. With its range of team-building activities, such as group games, nature walks, and farm tours, the farm offers a relaxing and engaging environment for employees. The peaceful rural setting also provides the perfect backdrop for team-building activities and quality bonding time away from the office."
  },
  {
    "name": "Resorts for Corporate Outing Near Pune",
    "description": "Shinde Agritourism offers a unique experience as a resort for corporate outings near Pune. The farm provides a combination of educational and recreational activities that are ideal for team-building exercises. You can explore organic farming, enjoy nature walks, feed animals, and participate in traditional rural games—all in a beautiful, serene rural environment."
  },
  {
    "name": "Corporate Day Outing Near Pune",
    "description": "For a corporate day outing near Pune, Shinde Agritourism is an excellent choice. It offers a wide range of activities for teams to unwind and bond in nature. Enjoy a farm tour, take part in bullock cart rides, and learn about organic farming while enjoying organic meals. The farm’s peaceful atmosphere is perfect for a day of relaxation and team-building activities."
  },
  {
    "name": "Office Outing Places Near Pune",
    "description": "Shinde Agritourism is one of the best office outing places near Pune. It provides a unique rural experience with various activities that promote team bonding and relaxation. Corporate teams can take part in farm-based activities, learn about organic farming, and participate in group games, all while enjoying the natural beauty of the surroundings. It’s a great place for employees to bond and unwind."
  },
  {
    "name": "One Day Corporate Outing Near Pune",
    "description": "Shinde Agritourism is an ideal location for a one day corporate outing near Pune. With activities like farm tours, team-building games, and animal interactions, your team can enjoy a fun and educational day. The farm’s peaceful environment and fresh air make it the perfect place to relax, bond, and strengthen relationships outside of the office."
  },
  {
    "name": "Places for Corporate Team Outing Near Pune",
    "description": "If you're looking for places for a corporate team outing near Pune, Shinde Agritourism should be on your list. The farm provides a variety of team-building activities designed to foster collaboration and relaxation. Your team can engage in farm activities, enjoy nature walks, and relax with a traditional picnic, all in the serene countryside, making it a great destination for corporate groups."
  },
  {
    "name": "Team Outing Near Pune",
    "description": "For a unique team outing near Pune, consider Shinde Agritourism. The farm offers a peaceful, nature-filled environment with plenty of activities to engage your team. From interactive farm tours to animal feeding, group games, and nature walks, it’s the perfect spot to unwind, bond, and refresh your team spirit in the great outdoors."
  },
  {
    "name": "Om Agritourism Corporate Outing Resorts Near Pune",
    "description": "Om Agritourism, like Shinde Agritourism, offers fantastic corporate outing resorts near Pune. It provides a rural setting with engaging activities such as farm tours, animal interactions, and outdoor games, making it a perfect place for team-building and corporate outings. If you're looking for a retreat that combines fun, relaxation, and education, Om Agritourism is another excellent option for your corporate group."
  },
  {
    "name": "Shinde Agri Tourism Picnic Spot Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Picnic Spot Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
  ["- Team Outing Places Near Pune", "- Resorts Near Pune for Corporate Outing"],
  ["- Best Resort for Corporate Outing Near Pune", "- Corporate Team Outing Places in Pune"],
  ["- Corporate Team Outing Places Near Pune", "- Resorts for Team Outing Near Pune"],
  ["- Team Outing Resorts Near Pune", "- Team Outing Places in Pune"],
  ["- Resorts for Team Outing Near Pune", "- Corporate Outing Near Pune"],
  ["- Resorts for Corporate Outing Near Pune", "- Corporate Day Outing Near Pune"],
  ["- Office Outing Places Near Pune", "- One Day Corporate Outing Near Pune"],
  ["- Places for Corporate Team Outing Near Pune", "- Team Outing Near Pune"],
  ["- Om Agritourism Corporate Outing Resorts Near Pune", "- Corporate Outing Resorts Near Pune"]
],

whychoose: [
  {
    "WhyChooseheading": "Ideal Location for Team Building",
    "WhyChoosedescription": "Located just a short drive from Pune, Shinde Agri Tourism offers a peaceful, distraction-free environment, perfect for team-building activities and workshops. The natural setting promotes collaboration and relaxation, making it an ideal retreat for employees."
  },
  {
    "WhyChooseheading": "Tailored Corporate Packages",
    "WhyChoosedescription": "We offer customized packages designed to suit the needs of corporate groups. Whether you're planning a day trip, an extended retreat, or an off-site meeting, our team will work with you to create the perfect experience for your group."
  },
  {
    "WhyChooseheading": "Engaging Team Activities",
    "WhyChoosedescription": "From outdoor team-building games to farm tours and nature walks, our resort offers various activities that promote teamwork and communication. These activities help improve morale and foster better relationships within the team."
  },
  {
    "WhyChooseheading": "Relaxing & Comfortable Accommodation",
    "WhyChoosedescription": "Our resort offers comfortable and well-maintained accommodation options for overnight stays. Enjoy a peaceful retreat away from the city, with modern amenities and clean, spacious rooms."
  },
  {
    "WhyChooseheading": "Delicious Catering Services",
    "WhyChoosedescription": "We offer a variety of meal options, including authentic Maharashtrian cuisine, perfect for corporate groups. Our catering team can customize menus to fit your group’s preferences and dietary needs, ensuring a delightful dining experience."
  },
  {
    "WhyChooseheading": "Professional & Friendly Service",
    "WhyChoosedescription": "Our team at Shinde Agri Tourism is dedicated to making your corporate outing a success. We provide exceptional service and attention to detail to ensure your event runs smoothly, from arrival to departure."
  },
  {
    "WhyChooseheading": "Easy Accessibility",
    "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is easily accessible, making it the perfect place for a quick and convenient corporate outing."
  }
]

  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which is around a 2 to 2.5-hour drive, making it an easily accessible location for corporate groups."
    },
    {
      question: "What kind of corporate activities can we do at Shinde Agri Tourism?",
      answer: "We offer a variety of team-building activities including outdoor games, farm tours, nature walks, and workshops. Our activities are designed to improve communication, collaboration, and teamwork."
    },
    {
      question: "Can you organize corporate events or meetings?",
      answer: "Yes! We offer spaces for corporate meetings, seminars, and events. Our team can assist you in arranging audio-visual equipment, meeting rooms, and other necessary amenities to make your event a success."
    },
    {
      question: "Is accommodation available for corporate groups?",
      answer: "Yes! We offer comfortable and spacious rooms for corporate groups who wish to stay overnight. Our accommodation is equipped with all necessary amenities to ensure a pleasant and restful stay."
    },
    {
      question: "Can we customize the meals for our group?",
      answer: "Yes, we provide customizable meal options to fit your group’s preferences. Our catering team can prepare everything from snacks and refreshments to full-course meals, including vegetarian and non-vegetarian Maharashtrian cuisine."
    },
    {
      question: "Is transportation provided for corporate groups?",
      answer: "Yes! We offer transportation options from Pune to Shinde Agri Tourism for your group. You can book a cab, bus, or other transport services to ensure a comfortable journey."
    },
    {
      question: "How do I book a corporate outing package?",
      answer: "You can easily book a corporate outing package by visiting our website or by contacting our customer service team. We recommend booking in advance to ensure availability, especially for large groups."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Amit & Team",
      text: "Our corporate outing at Shinde Agri Tourism was fantastic! The team-building activities were engaging and helped improve communication within our team. The food was amazing, and the serene surroundings made it the perfect place for a productive retreat."
    },
    {
      name: "Miss. Neha & Team",
      text: "Shinde Agri Tourism provided the ideal setting for our corporate retreat. The team enjoyed the outdoor activities, and the accommodation was comfortable and refreshing. We had an amazing time and came back with stronger team bonds!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Corporate Outing Resorts Near Pune | Team Outing Resorts & Corporate Team Outing Places Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for corporate outing resorts near Pune? Explore the best resorts for team outings and corporate day outings near Pune. Enjoy team-building activities and a memorable experience with your colleagues at Shinde Krushi Paryatan." />
  <meta name="keywords" content="Team Outing Places Near Pune, Resorts Near Pune for Corporate Outing, Best Resort for Corporate Outing Near Pune, Corporate Team Outing Places in Pune, Corporate Team Outing Places Near Pune, Resorts for Team Outing Near Pune, Team Outing Resorts Near Pune, Team Outing Places in Pune, Resorts for Team Outing Near Pune, Corporate Outing Near Pune, Resorts for Corporate Outing Near Pune, Corporate Day Outing Near Pune, Office Outing Places Near Pune, One Day Corporate Outing Near Pune, Places for Corporate Team Outing Near Pune, Team Outing Near Pune, Om Agritourism Corporate Outing Resorts Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/corporate-outing-resorts-near-pune" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Corporate Outing Resorts Near Pune",
        "url": "https://shindekrushiparyatan.com/corporate-outing-resorts-near-pune",
        "description": "Explore top corporate outing resorts near Pune for team-building activities and a memorable office outing. Shinde Krushi Paryatan offers the best resorts and team outing places for a successful corporate day outing.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/corporate-outing-resorts.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/17.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Corporateout;