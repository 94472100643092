import React from 'react';
import '../css/PrivacyPolicy.css';


const Privacy = () => {
  return (
    <div>

<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">Privacy & Policy</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section className="privacy-policy-section">
        <div className="container">
          <div className="row">
            {/* Privacy Policy Content */}
            <div className="col-lg-9 privacy-policy-content">
              <h3 className='darkcolor'>Introduction</h3>
              <p>Shinde Agri Tourism understands the privacy concerns of its users, and the protection of personal information is of utmost priority. This Privacy Policy outlines the practices regarding the collection, use, storage, and protection of personal information when you use our website, <strong>www.shindekrushiparyatan.com</strong>. By using this website, you agree to the terms set out in this policy.</p>

              <h4 className='darkcolor'>Information We Collect</h4>
              <p>We may collect the following types of personal information:</p>
              <ul>
                <li><strong className='darkcolor'>Personal Identification Information:</strong> Name, email address, phone number, postal address, and other contact details.</li>
                <li><strong className='darkcolor'>Transaction Information:</strong> Payment details and order information when making a purchase.</li>
                <li><strong className='darkcolor'>Usage Data:</strong> Information regarding your use of the website, including browser details, IP address, and pages visited.</li>
              </ul>

              <h4 className='darkcolor'>How We Use Your Information</h4>
              <p>The collected information is used for the following purposes:</p>
              <ul>
                <li>To process orders and services efficiently.</li>
                <li>To communicate with you regarding bookings, services, and updates.</li>
                <li>To enhance the user experience on our website by improving functionality and personalizing services.</li>
                <li>To send relevant updates, offers, and promotions (with your consent) via email, SMS, or phone calls.</li>
              </ul>

              <h4 className='darkcolor'>Protection of Your Personal Information</h4>
              <p>Your personal information is stored securely on servers protected by reasonable technological and physical security measures. We comply with the Information Technology Act, 2000, and related rules to ensure the security and confidentiality of your information.</p>

              <h4 className='darkcolor'>Disclaimer of Warranties and Liability</h4>
              <p>The materials and services on this website are provided on an "AS IS" basis. Shinde Agri Tourism does not warrant that the website will be uninterrupted, error-free, or free from harmful components. We disclaim any implied warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee the accuracy, reliability, or completeness of the content or services provided on this website.</p>

              <h4 className='darkcolor'>Personal Details</h4>
              <p>When placing an order or making a booking on our website, you must provide a valid postal address and phone number. By registering your phone number with Shinde Agri Tourism, you consent to receiving communications regarding order updates via phone calls or SMS. We will not use your personal information for marketing or promotional purposes unless you have provided explicit consent.</p>

              <h4 className='darkcolor'>Services and Payment</h4>
              <p>Shinde Agri Tourism is not responsible for any issues arising due to:</p>
              <ul>
                <li>Lack of authorization for any transactions.</li>
                <li>Exceeding preset limits agreed between you and your bank.</li>
                <li>Any payment issues or declined transactions.</li>
              </ul>
              <p>All payments made for services or purchases on the website will only be accepted in Indian Rupees (INR). You will be required to provide valid photo identification when accepting orders or deliveries for verification purposes.</p>

              <h4 className='darkcolor'>Phone Consent for Promotions</h4>
              <p>By calling our customer service number <strong>9356007002</strong>, you agree to receive promotional offers and updates related to Shinde Agri Tourism’s services, offers, and events. You can unsubscribe from receiving these promotions at any time by contacting us directly.</p>

              <h4 className='darkcolor'>Changes to This Privacy Policy</h4>
              <p>Shinde Agri Tourism reserves the right to modify or update this privacy policy at any time. We encourage users to review the policy periodically for any changes. Your continued use of the website signifies your acceptance of the updated policy.</p>

              <h4 className='darkcolor'>Contact Us</h4>
              <p>If you have any questions or concerns regarding this privacy policy or the use of your personal information, feel free to contact us:</p>
              <ul>
                <li><b className='darkcolor'>Phone:</b> <a href="tel:+919356007002">+91 9356007002</a></li>
                <li><b className='darkcolor'>Email:</b> <a href="mailto:booking@shindekrushiparytan.com">booking@shindekrushiparytan.com</a></li>
              </ul>
            </div>

            {/* Image Column */}
          
          </div>
        </div>
      </section>



    </div>
  );
};

export default Privacy;
