import React from 'react';
import Slider from 'react-slick'; // Import react-slick
import 'slick-carousel/slick/slick.css'; // Slick Carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Slick Carousel theme styles

const TestimonialSection = () => {
  const testimonials = [
    {
      name: 'Mr. Ravi and Family',
      // role: 'Supervisor',
      image: 'images/testimonial/img1.jpg',
      text: '"Our family had an amazing time at Shinde Agri Tourism! The lush green surroundings, peaceful ambiance, and the opportunity to experience farm life were exactly what we needed. The staff was incredibly friendly, and the activities were fun and educational. Highly recommend this place for anyone looking to unwind in nature!"',
    },
    {
      name: ' Miss. Priya, Mumbai',
      // role: 'Project Manager',
      image: 'images/testimonial/img2.jpg',
      text: '"I was looking for a retreat where I could reconnect with nature, and Shinde Agri Tourism delivered just that. The farm-to-table meals were delicious, and I enjoyed learning about organic farming. The hospitality was top-notch, and the tranquil environment made it a perfect escape from city life."',
    },
    {
      name: 'Mr. Suresh, HR Manager',
      // role: 'Senior Developer',
      image: 'images/testimonial/img3.jpg',
      text: '"Our company held a team-building event at Shinde Agri Tourism, and it was fantastic. The team activities on the farm brought everyone together, and the peaceful environment helped us bond. The food was fresh and tasty, and we left feeling rejuvenated. Well definitely be coming back!"',
    },
    {
      name: 'Miss. Anita, Pune',
      // role: 'Designer',
      image: 'images/testimonial/img4.jpg',
      text: '"I celebrated my birthday at Shinde Agri Tourism, and it was an unforgettable experience! The team went above and beyond to make the day special, with personalized touches and a great barbecue. The natural beauty and serene setting added to the charm. I will definitely recommend it to friends for a unique celebration."',
    },
    // Add more testimonials as needed
  ];

  // React-Slick Settings
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Slide interval in milliseconds
    pauseOnHover: true, // Pause autoplay when hovering
    responsive: [
      {
        breakpoint: 768, // For smaller screens
        settings: {
          slidesToShow: 1, // Show 1 slide at a time on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial py-5" style={{ backgroundImage: 'url(images/testimonial.png)' }}>
      <div className="container">
        <div className="section-title mb-6 text-center w-75 mx-auto">
          <h4 className="mb-1 theme1">Our Testimonials</h4>
          <h2 className="mb-1">
            Good Reviews By <span className="theme">Clients</span>
          </h2>
          
        </div>

        {/* React-Slick Slider Component */}
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <div className="testimonial-item1 rounded text-lg-start text-center">
                <div className="author-info d-lg-flex align-items-center mb-4">
                  {/* <img src={testimonial.image} alt={testimonial.name} /> */}
                  <div className="author-title ms-3">
                    <h5 className="m-0 theme">{testimonial.name}</h5>
                    <span>{testimonial.role}</span>
                  </div>
                </div>
                <div className="details">
                  <p className="m-0">
                    <i className="fa fa-quote-left me-2 fs-1"></i>{testimonial.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialSection;
