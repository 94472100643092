
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';

import './smallkey.css';
import { Helmet } from 'react-helmet';

function Resortnearmumbai() {



  const cardData =
  {
    keyword: ' Resort Near Mumbai',
    heading:  'Resort Near Mumbai: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for a relaxing getaway near Mumbai, Shinde Agri Tourism is an excellent choice. Situated just a short drive away from the city, this agro-tourism resort provides a peaceful escape from the hustle and bustle of urban life. Whether you’re planning a family vacation, a corporate retreat, or a weekend getaway, Shinde Agri Tourism offers the perfect blend of nature, adventure, and relaxation.',

    top: 'Top Activities at Shinde Agri Tourism	',
"topPlaces": [
  {
    "title": "Farming Experience and Interactive Learning",
    "description": "At Shinde Agri Tourism, you can enjoy hands-on farming activities. Learn about the process of farming, plant crops, harvest fresh produce, and interact with farm animals like cows and goats. This experience is both fun and educational for people of all ages."
  },
  {
    "title": "Outdoor Sports and Recreation",
    "description": "Engage in various outdoor sports like cricket, badminton, volleyball, and more. Whether you want to relax or get competitive, the resort offers plenty of open space for all kinds of recreational activities."
  },
  {
    "title": "Nature Walks and Bird Watching",
    "description": "Explore the serene beauty of nature with guided walks through the lush landscape. You’ll also have the opportunity to spot different species of birds and enjoy the peaceful surroundings, making it a perfect activity for nature lovers."
  },
  {
    "title": "Barbecue and Bonfire",
    "description": "Unwind in the evening with a barbecue and bonfire. This cozy setup creates a perfect atmosphere for family gatherings or group activities, offering warmth and a sense of togetherness."
  },
  {
    "title": "Farm-Fresh Meals and Local Delicacies",
    "description": "Savor delicious meals made from fresh, organic ingredients grown on-site. The farm-fresh food at Shinde Agri Tourism includes traditional Maharashtrian dishes, ensuring a unique and tasty experience."
  }
],


   "services": [
  {
    "name": "Best Resorts Near Mumbai",
    "description": "For one of the best resorts near Mumbai, Shinde Agri Tourism stands out. This agro-tourism destination offers a peaceful rural setting just a short drive from Mumbai. It provides a variety of farm experiences, nature walks, and educational activities, perfect for those looking for a retreat close to the city."
  },
  {
    "name": "Pet Friendly Resorts Near Mumbai",
    "description": "If you’re looking for pet-friendly resorts near Mumbai, Shinde Agri Tourism is a great choice. The farm is surrounded by nature, and pets are often welcome, allowing you to enjoy a day out with your furry friends. Check with the resort for specific pet policies."
  },
  {
    "name": "Luxury Resorts Near Mumbai",
    "description": "While Shinde Agri Tourism offers a unique agro-tourism experience, if you’re looking for a more relaxed, nature-focused getaway rather than a typical luxury resort, it offers a rustic and peaceful environment with activities like farm tours, nature walks, and organic farming experiences."
  },
  {
    "name": "Resort Mumbai",
    "description": "Shinde Agri Tourism, located just outside Mumbai, offers a great option for those looking to experience a rural, educational retreat. With nature walks, bullock cart rides, and organic farming experiences, it provides an alternative to typical city resorts."
  },
  {
    "name": "Resorts Near Mumbai for Family",
    "description": "For resorts near Mumbai for family, Shinde Agri Tourism is a fantastic option. Families can enjoy hands-on farming activities, nature walks, and outdoor games. It’s a perfect destination for children to learn about nature and farming while enjoying time with their loved ones."
  },
  {
    "name": "Resort Near Mumbai for 1 Night Stay",
    "description": "If you’re looking for a resort near Mumbai for 1 night stay, Shinde Agri Tourism offers overnight stays, where you can relax and unwind surrounded by nature. The peaceful, rural setting and the variety of activities available make it a great place for a one-night getaway."
  },
  {
    "name": "Best Resort in Mumbai",
    "description": "For those searching for the best resort in Mumbai, Shinde Agri Tourism offers a unique experience outside the city. With its educational agro-tourism activities, it provides a refreshing alternative to city resorts, making it ideal for nature lovers and families."
  },
  {
    "name": "Resorts Near Mumbai for Couples",
    "description": "For resorts near Mumbai for couples, Shinde Agri Tourism offers a serene and romantic rural setting. Couples can enjoy the tranquility of the countryside, take nature walks, and relax together in a peaceful environment. It’s a great place for couples looking for a quiet, nature-filled retreat."
  },
  {
    "name": "Adventure Resorts Near Mumbai",
    "description": "If you’re looking for adventure resorts near Mumbai, Shinde Agri Tourism offers a range of activities such as bullock cart rides, nature walks, and farm visits, giving you the chance to explore the rural landscape while participating in hands-on experiences."
  },
  {
    "name": "Good Resorts Near Mumbai",
    "description": "Shinde Agri Tourism is one of the good resorts near Mumbai that combines nature, education, and fun. With activities like farm tours, organic farming workshops, and nature trails, it’s a great destination for families, groups, or anyone looking to connect with nature and relax."
  },
  {
    "name": "Mumbai Beach Resort",
    "description": "While Shinde Agri Tourism is not a beach resort, it offers an entirely different experience with its farm-based activities. If you’re looking for something unique away from the crowded beaches, this farm retreat provides a peaceful rural experience just outside Mumbai."
  },
  {
    "name": "Resorts in Mumbai for Couples",
    "description": "For resorts in Mumbai for couples, Shinde Agri Tourism offers a tranquil environment perfect for romantic getaways. Couples can enjoy peaceful walks, learn about organic farming, and relax in the natural surroundings of the farm."
  },
  {
    "name": "Best Resorts Near Mumbai for Family",
    "description": "Shinde Agri Tourism is one of the best resorts near Mumbai for family. With a focus on agro-tourism, the farm offers family-friendly activities like nature walks, bullock cart rides, and farm visits, making it an ideal place for families to bond and learn together."
  },
  {
    "name": "Best Nature Resorts Near Mumbai",
    "description": "If you’re looking for best nature resorts near Mumbai, Shinde Agri Tourism is a great choice. Surrounded by green fields and farmland, it offers an immersive nature experience with opportunities to explore the outdoors, learn about farming, and enjoy nature trails."
  },
  {
    "name": "Nature Resorts Near Mumbai",
    "description": "Shinde Agri Tourism is one of the top nature resorts near Mumbai. The serene, rural setting provides a peaceful escape where you can engage in farming activities, explore nature trails, and connect with the natural world."
  },
  {
    "name": "Best Resorts Near Mumbai for Couples",
    "description": "For a best resort near Mumbai for couples, Shinde Agri Tourism provides a romantic, peaceful atmosphere. Couples can enjoy quiet walks through nature, participate in farming activities, and spend quality time together in a rural setting away from the city noise."
  },
  {
    "name": "One Day Picnic Resorts Near Mumbai",
    "description": "Shinde Agri Tourism is a great option for one day picnic resorts near Mumbai. With a variety of outdoor activities like farm tours, nature walks, and bullock cart rides, it offers an enriching and fun-filled day out for families, groups, or individuals."
  },
  {
    "name": "Resorts Near Mumbai for Overnight Stay",
    "description": "If you’re looking for resorts near Mumbai for overnight stay, Shinde Agri Tourism offers overnight accommodations where you can experience rural life, participate in farm activities, and enjoy the tranquility of nature. It’s a great place for a peaceful, overnight retreat."
  },
  {
    "name": "Resort with Activities Near Mumbai",
    "description": "For a resort with activities near Mumbai, Shinde Agri Tourism is a fantastic choice. The farm offers a range of activities, from farm tours and nature walks to organic farming workshops, making it an excellent option for those seeking a more interactive and educational getaway."
  },
  {
    "name": "Private Pool Resorts Near Mumbai",
    "description": "While Shinde Agri Tourism doesn’t specialize in private pool resorts, it offers a range of activities, including nature walks, bullock cart rides, and farming experiences, providing a unique rural retreat close to Mumbai."
  },
  {
    "name": "Luxury Stay Near Mumbai",
    "description": "For a luxury stay near Mumbai, Shinde Agri Tourism offers a unique experience focused on agro-tourism. While it’s not a traditional luxury resort, it provides a peaceful, immersive, and enriching environment for those seeking to unwind in nature."
  },
  {
    "name": "Budget Resorts Near Mumbai",
    "description": "For budget resorts near Mumbai, Shinde Agri Tourism offers affordable day trips and overnight stays with a variety of activities. It’s an ideal choice for those looking for an inexpensive, yet enriching getaway close to Mumbai."
  },
  {
    "name": "Shinde Agri Tourism Resort Near Mumbai Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Resort Near Mumbai. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Best Resorts near Mumbai", "- Pet Friendly Resorts near Mumbai"],
    ["- Luxury Resorts near Mumbai", "- Resort Mumbai"],
    ["- Resorts near Mumbai for Family", "- Resort near Mumbai for 1 Night Stay"],
    ["- Best Resort in Mumbai", "- Resorts near Mumbai for Couples"],
    ["- Adventure Resorts near Mumbai", "- Good Resorts near Mumbai"],
    ["- Mumbai Beach Resort", "- Resorts in Mumbai for Couples"],
    ["- Best Resorts near Mumbai for Family", "- Best Nature Resorts near Mumbai"],
    ["- Nature Resorts near Mumbai", "- Best Resorts near Mumbai for Couples"],
    ["- One Day Picnic Resorts near Mumbai", "- Resorts near Mumbai for Overnight Stay"],
    ["- Resort with Activities near Mumbai", "- Private Pool Resorts near Mumbai"],
    ["- Luxury Stay near Mumbai", "- Budget Resorts near Mumbai"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenient Location",
      "WhyChoosedescription": "Located just 100 km from Mumbai, Shinde Agri Tourism is a short 2 to 2.5-hour drive from the city. The ease of access makes it the perfect choice for a weekend getaway, day trip, or quick retreat without spending too much time traveling."
    },
    {
      "WhyChooseheading": "Serene and Peaceful Atmosphere",
      "WhyChoosedescription": "Our resort is nestled amidst lush greenery, providing a peaceful and calm environment, ideal for relaxation. Escape the noise of the city and unwind in a natural setting surrounded by farms, hills, and fresh air. It's the perfect destination for those seeking peace and tranquility."
    },
    {
      "WhyChooseheading": "Family-Friendly Resort",
      "WhyChoosedescription": "Shinde Agri Tourism is an ideal resort for families. Kids can enjoy hands-on activities like fruit picking, animal interactions, and milking cows. There are also plenty of spaces for kids to play and run around. Parents can unwind in a peaceful setting while their children engage in educational and fun activities."
    },
    {
      "WhyChooseheading": "Corporate Getaways and Team Building",
      "WhyChoosedescription": "Our resort also caters to corporate groups looking for team-building exercises or a break from the office routine. We offer customized packages that include team-building games, leadership workshops, and outdoor challenges designed to foster collaboration, communication, and teamwork in a relaxed, fun environment."
    },
    {
      "WhyChooseheading": "Comfortable Accommodation",
      "WhyChoosedescription": "Shinde Agri Tourism offers comfortable and eco-friendly accommodation options, including cozy cottages and tented stays. All rooms are well-equipped to ensure a pleasant stay with modern amenities, allowing you to relax in comfort while surrounded by nature."
    },
    {
      "WhyChooseheading": "Ideal for Day Trips or Weekend Getaways",
      "WhyChoosedescription": "Whether you have a single day or a full weekend to spare, Shinde Agri Tourism is the ideal place for a refreshing break. Its proximity to Mumbai makes it an excellent destination for a quick getaway or a relaxing weekend retreat."
    },
    {
      "WhyChooseheading": "Health and Wellness",
      "WhyChoosedescription": "In addition to outdoor activities, you can also indulge in wellness options like meditation, yoga sessions, or simply enjoy a peaceful day of rest by the farm, listening to the soothing sounds of nature."
    }
  ]
  
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Mumbai, which takes about 2 to 2.5 hours by car. It’s a convenient distance for a quick getaway without spending too much time traveling."
    },
    {
      question: "What activities are available at the resort?",
      answer: "We offer a range of activities including farm visits, outdoor games (cricket, volleyball, badminton), adventure activities (zip-lining, rock climbing, archery), nature walks, birdwatching, and team-building exercises for corporate groups. You can also enjoy a farm-to-table dining experience with fresh, organic meals."
    },
    {
      question: "Is the resort family-friendly?",
      answer: "Yes! Our resort is ideal for families. Kids can enjoy hands-on activities like fruit picking, milking cows, and interacting with animals. There are also plenty of outdoor spaces for children to play, while adults can relax in the peaceful surroundings."
    },
    {
      question: "Are there accommodations available at the resort?",
      answer: "Yes, we offer comfortable accommodations including eco-friendly cottages and tented stays. All our accommodations are well-equipped with modern amenities, ensuring you have a comfortable and enjoyable stay amidst nature."
    },
    {
      question: "Can Shinde Agri Tourism be booked for corporate outings?",
      answer: "Absolutely! We cater to corporate groups and offer customized packages that include team-building activities, leadership workshops, and other group activities that promote collaboration and communication. It’s the perfect place for corporate retreats or team bonding."
    },
    {
      question: "Is food provided at the resort?",
      answer: "Yes! We offer a farm-to-table dining experience, with meals made from organic ingredients grown on our farm. Our menu includes traditional Maharashtrian dishes, fresh salads, juices, and more, all prepared with wholesome, healthy ingredients."
    },
    {
      question: "Are there any relaxation activities at the resort?",
      answer: "Yes! In addition to the outdoor and adventure activities, you can unwind with a nature walk, enjoy a yoga session, or simply relax by the farm and take in the peaceful surroundings."
    },
    {
      question: "Can I book a one-day trip to the resort?",
      answer: "Yes! Shinde Agri Tourism is ideal for both one-day trips and weekend getaways. The resort’s close proximity to Mumbai makes it an easy destination for a quick escape or a longer retreat."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Radhika & Family, Mumbai",
      text: "We had an amazing weekend at Shinde Agri Tourism! The peaceful atmosphere, the delicious food, and the variety of activities made it the perfect getaway from Mumbai. We especially enjoyed the farm activities and nature walks. Highly recommend this resort for a relaxing retreat!"
    },
    {
      name: "Mr. Arvind, HR Manager, Mumbai",
      text: "Our corporate team-building retreat at Shinde Agri Tourism was fantastic. The activities were well-organized, the food was fresh and healthy, and the team-building games were a great way to foster collaboration. It was the perfect mix of work and relaxation. We’ll definitely be back!"
    }
  ];
  
    






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Resort Near Mumbai | Best Resorts, Pet Friendly, Luxury & Adventure Resorts Near Mumbai | Family, Couples & Nature Resorts | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best resorts near Mumbai? Discover pet-friendly, luxury, adventure, and nature resorts for family and couples. Explore one-night stays, overnight resorts, and resorts with activities near Mumbai." />
  <meta name="keywords" content="Best Resorts near Mumbai, Pet Friendly Resorts near Mumbai, Luxury Resorts near Mumbai, Resort Mumbai, Resorts near Mumbai for Family, Resort near Mumbai for 1 Night Stay, Best Resort in Mumbai, Resorts near Mumbai for Couples, Adventure Resorts near Mumbai, Good Resorts near Mumbai, Mumbai Beach Resort, Resorts in Mumbai for Couples, Best Resorts near Mumbai for Family, Best Nature Resorts near Mumbai, Nature Resorts near Mumbai, Best Resorts near Mumbai for Couples, One Day Picnic Resorts near Mumbai, Resorts near Mumbai for Overnight Stay, Resort with Activities near Mumbai, Private Pool Resorts near Mumbai, Luxury Stay near Mumbai, Budget Resorts near Mumbai" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/resort-near-mumbai" />

  {/* JSON-LD Schema for Resort Near Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Resort Near Mumbai",
        "url": "https://shindekrushiparyatan.com/resort-near-mumbai",
        "description": "Find the best resorts near Mumbai including pet-friendly, luxury, adventure, and nature resorts for family and couples. Explore one-night stays, overnight resorts, and resorts with activities.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/resort-near-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "200"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/35.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Resortnearmumbai;