import React from 'react';

const Banner = () => {
  return (
    <section className="banner overflow-hidden">
      <div className="banner-main">
        <div className="banner-image">
          <div className="video-banner">
            <video autoPlay muted loop id="vid" className="w-100">
              <source src="/images/vid/shinde.mp4" type="video/mp4" />
            </video>
          </div>
          {/* <div className="dot-overlay"></div> */}
        </div>
        <div className="banner-content">
             
        </div>
      </div>
    </section>
  );
};

export default Banner;
