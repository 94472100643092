
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Picnicspotpimpri() {



  const cardData =
  {
    keyword: ' Picnic Spot Near Pimpri Chinchwad',
    heading: 'Picnic Spot Near Pimpri Chinchwad: Shinde Agri Tourism',
    headingDescription: 'Looking for an ideal spot to enjoy a day away from the city hustle? Shinde Agri Tourism near Pimpri Chinchwad offers a delightful picnic experience amidst nature. With lush green landscapes, farm-to-table meals, and a host of interactive activities, it’s the perfect destination for families, friends, and corporate outings. Spend quality time with your loved ones, reconnect with nature, and enjoy a peaceful escape close to home.',

    top: 'Top Features of the Picnic Spot at Shinde Agri Tourism',
    topPlaces: [
        {
          "title": "Nature Walks and Bird Watching",
          "description": "Take a leisurely walk through beautiful, green surroundings where you can spot a variety of bird species. The peaceful environment is ideal for unwinding, enjoying nature, and observing local wildlife."
        },
        {
          "title": "Outdoor Games and Sports",
          "description": "Engage in outdoor activities like cricket, badminton, and volleyball. With ample space, Shinde Agri Tourism provides an excellent venue for friendly competitions and fun family bonding moments."
        },
        {
          "title": "Interactive Farming Activities",
          "description": "Experience farm life firsthand with activities like milking cows, planting crops, and harvesting fresh produce. These interactive activities are both fun and educational, making your picnic day unique and memorable."
        },
        {
          "title": "Barbecue and Bonfire",
          "description": "As the day winds down, gather around a bonfire and enjoy a delicious barbecue with your group. It’s a wonderful way to relax, share stories, and enjoy a cozy evening together."
        },
        {
          "title": "Farm-Fresh Meals",
          "description": "Savor healthy, freshly prepared meals made from ingredients grown right on the farm. This authentic farm-to-table dining experience adds a delightful, wholesome touch to your picnic day."
        },
        {
          "title": "Relaxing Picnic Areas",
          "description": "Shinde Agri Tourism provides well-maintained picnic spots with ample seating and space to spread out a blanket, making it easy to settle in and enjoy a peaceful day with family and friends."
        }
      ],
      services: [
        {
          "name": "Places to Visit in Pimpri-Chinchwad for Family",
          "description": "If you're looking for family-friendly places to visit in Pimpri-Chinchwad, you can explore attractions like The Rajiv Gandhi Infotech Park, which offers parks and walking spaces, or the Appu Ghar amusement park for a fun-filled day. Families can also enjoy nature at Pimpri-Chinchwad's parks or visit the serene ISKCON Temple for some peaceful time."
        },
        {
          "name": "One Day Picnic Spot Near Pimpri-Chinchwad",
          "description": "For a quick getaway from Pimpri-Chinchwad, you can head to Khadakwasla Dam, just a short drive away. It's a scenic spot ideal for a one-day picnic, offering boat rides and natural beauty. Alternatively, Pune-Okayama Friendship Garden offers lush greenery, perfect for a relaxing family picnic."
        },
        {
          "name": "Places to Visit in Pimpri-Chinchwad for Couples",
          "description": "Couples can enjoy a peaceful day at The Secret Garden, a calm and serene spot perfect for a romantic stroll. For a more adventurous day, head to Pashan Lake or Dagdusheth Halwai Ganapati Temple for a cultural experience. If you love nature, Mulshi Lake is a great escape just outside the city."
        },
        {
          "name": "Family Picnic Spot Near Pimpri-Chinchwad",
          "description": "For a memorable family picnic near Pimpri-Chinchwad, you can visit Pune-Okayama Friendship Garden for a serene environment, or Khadakwasla Dam, known for its stunning views and boating opportunities. Sinhagad Fort is also a great spot to enjoy family picnics with some history and adventure."
        },
        {
          "name": "Tourist Places Near Pimpri-Chinchwad",
          "description": "Pimpri-Chinchwad is surrounded by many fascinating tourist spots. Shaniwar Wada, Sinhagad Fort, Khadakwasla Dam, and Pune-Okayama Friendship Garden are popular spots. For spiritual tourists, Ashtavinayak Temples are also nearby."
        },
        {
          "name": "School Picnic Near Pimpri-Chinchwad",
          "description": "For school picnics, Pimpri-Chinchwad’s Rajiv Gandhi Infotech Park offers educational and recreational value. Schools can also visit Ranjangaon Ganapati Temple for a cultural experience or take a fun trip to Appu Ghar for entertainment and adventure."
        },
        {
          "name": "Ashtavinayak Darshan Stay Near Pimpri-Chinchwad",
          "description": "For a spiritual trip to Ashtavinayak, there are several accommodation options near Pimpri-Chinchwad. Shirdi, Lonavala, and Pune offer affordable stays with easy access to the Ashtavinayak pilgrimage circuit, making it an ideal place for devotees seeking a peaceful experience."
        },
        {
          "name": "Cheapest Ashtavinayak Tour from Pune",
          "description": "For the most affordable Ashtavinayak tour from Pune, opt for budget-friendly travel packages that offer both transport and Darshan. Many local tour operators provide economical options for group tours to the Ashtavinayak Temples, which cover all the important temples in a day or two."
        },
        {
          "name": "Get Together Places Near Pimpri-Chinchwad",
          "description": "Looking for places to host a get-together near Pimpri-Chinchwad? Pune-Okayama Friendship Garden and Khadakwasla Dam are excellent spots for casual meetups. Chinchwad Ganapati Mandir and ISKCON Temple offer peaceful environments for more spiritual gatherings. For more lively events, check out venues around Mahalunge or Tata Management Training Centre."
        },
        {
          "name": "Wedding Destination Near Pimpri-Chinchwad",
          "description": "For a wedding destination near Pimpri-Chinchwad, The Corinthians Resort & Club, located a short drive from the city, offers a beautiful, spacious setting for both indoor and outdoor ceremonies. Alternatively, Chakan has many resorts perfect for hosting wedding events in a lush environment."
        },
        {
          "name": "Pre-Wedding Shoot Near Pimpri-Chinchwad",
          "description": "For a picturesque pre-wedding shoot near Pimpri-Chinchwad, head to Sinhagad Fort, Pashan Lake, or Mulshi Lake. The Pune-Okayama Friendship Garden also provides a beautiful and romantic backdrop for shoots. These locations offer beautiful landscapes and peaceful settings ideal for couples looking to capture memories before their big day."
        },
        {
          "name": "Shinde Agri Tourism Picnic Spot Near Pimpri Chinchwad Contact Number",
          "description": "Escape to nature with Shinde Agri Tourism for a perfect Picnic Spot Near Pimpri Chinchwad. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
        }
      ],
      tableData: [
        ["- One Day Trip Near Pimpri Chinchwad for Family", "- One Day Picnic Spot Near Pimpri Chinchwad for Family"],
        ["- One Day Trip Near Pimpri Chinchwad for Couples", "- One Day Picnic Spot for Family Near Pimpri Chinchwad"],
        ["- Best Family Picnic Spot Near Pimpri Chinchwad", "- Family One Day Picnic Spot in Pimpri Chinchwad"],
        ["- Family Picnic Spot Near Pimpri Chinchwad", "- Family Picnic Spot in Pimpri Chinchwad"],
        ["- One Day Picnic Spot in Pimpri Chinchwad for Family", "- One Day Picnic Near Pimpri Chinchwad for Family"],
        ["- One Day Trip Near Pimpri Chinchwad in Winter with Family", "- One Day Trip Near Pimpri Chinchwad with Family in Summer"],
        ["- Pimpri Chinchwad Family Picnic Spot", "- Family One Day Trip Near Pimpri Chinchwad"],
        ["- Shinde Agri Tourism - One Day Picnic Spot Near Pimpri Chinchwad", "- Shinde Agri Tourism Family Picnic Spot Near Pimpri Chinchwad"]
      ],
      
      whychoose: [
        {
          "WhyChooseheading": "Scenic & Serene Location",
          "WhyChoosedescription": "Nestled amidst lush greenery, Shinde Agri Tourism offers a peaceful environment perfect for a relaxing getaway from the hustle and bustle of city life. It’s a beautiful place to unwind and connect with nature."
        },
        {
          "WhyChooseheading": "Family-Friendly Activities",
          "WhyChoosedescription": "From nature trails and farming activities to outdoor games, there’s something for every family member. Kids can learn about sustainable farming while adults relax in a serene setting."
        },
        {
          "WhyChooseheading": "Delicious Local Cuisine",
          "WhyChoosedescription": "Enjoy authentic Maharashtrian dishes made with fresh, organic ingredients at our in-house restaurant. It’s a food lover's paradise, offering wholesome and traditional food for the whole family."
        },
        {
          "WhyChooseheading": "Safe & Clean Environment",
          "WhyChoosedescription": "We prioritize the safety and cleanliness of our environment. Our high standards ensure that every family member enjoys a safe and hygienic outing while having fun."
        },
        {
          "WhyChooseheading": "Affordable Packages",
          "WhyChoosedescription": "Our competitive pricing ensures you get a memorable experience without breaking the bank. With packages that cater to different needs and budgets, we offer an enriching outing for families at affordable rates."
        },
        {
          "WhyChooseheading": "Easy Accessibility from Pimpri Chinchwad",
          "WhyChoosedescription": "Just a short drive from Pimpri Chinchwad, Shinde Agri Tourism is a convenient and easy-to-reach location for families looking to escape the city for a day. A quick getaway that doesn’t require much travel time."
        }
      ]
      




  }
  const faqData = [
    {
      question: "How far is the picnic spot from Pimpri Chinchwad?",
      answer: "The picnic spot is located just 30 km from Pimpri Chinchwad, making it a convenient location for a quick getaway. It’s approximately a 45-minute to 1-hour drive, ideal for a day trip."
    },
    {
      question: "What activities are available at the picnic spot?",
      answer: "At Shinde Agri Tourism, you can enjoy a variety of activities such as fruit picking, animal feeding, bullock cart rides, nature walks, and more. We also offer fun outdoor games and adventure activities like zip-lining and archery."
    },
    {
      question: "Is food provided during the picnic?",
      answer: "Yes! We offer a farm-to-table dining experience with freshly prepared meals, including organic snacks, salads, Maharashtrian delicacies, and more. You can enjoy a delicious and healthy meal as part of your picnic outing."
    },
    {
      question: "Can I bring my own food and drinks?",
      answer: "While we offer a farm-to-table dining experience, you’re welcome to bring your own picnic food if you prefer. However, we encourage you to try our organic offerings for an authentic farm experience."
    },
    {
      question: "Can we book the picnic spot for a large group?",
      answer: "Yes, we accommodate both small and large groups. Whether you're a small family or a large corporate group, our picnic spot is spacious and can be tailored to suit your group size and requirements."
    },
    {
      question: "Is the picnic spot eco-friendly?",
      answer: "Absolutely! We emphasize sustainability by using eco-friendly practices, including organic farming, rainwater harvesting, and waste management systems to create a green and environmentally conscious space."
    },
    {
      question: "How can I book the picnic spot?",
      answer: "Booking is easy! You can reach out to us via our website or phone, and let us know the date, group size, and any special requirements. We’ll ensure that your picnic day is perfectly organized."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Raghav & Family, Pimpri Chinchwad",
      text: "We had a wonderful picnic at Shinde Agri Tourism! The kids loved feeding the animals and picking fruits, while we enjoyed a relaxing time under the trees. The food was absolutely fresh and tasty, and the environment was peaceful. A perfect day out!"
    },
    {
      name: "Ms. Priya, HR Manager, Pimpri Chinchwad",
      text: "The picnic spot at Shinde Agri Tourism is ideal for group outings. Our office team had a blast with the outdoor activities and enjoying the fresh meals. The surroundings were perfect for unwinding and spending quality time with colleagues. Highly recommend it!"
    }
  ];
    







  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Picnic Spots Near Pimpri Chinchwad | Family & Couples Picnic Locations | Best Places to Visit Near Pimpri Chinchwad | Shinde Krushi Paryatan</title>
  <meta name="description" content="Explore the best picnic spots near Pimpri Chinchwad for families, couples, and school outings. Discover top tourist destinations, Ashtavinayak tour packages, wedding venues, and pre-wedding shoot locations near Pimpri Chinchwad." />
  <meta name="keywords" content="Picnic Spot Near Pimpri Chinchwad, Places to Visit in Pimpri Chinchwad for Family, One Day Picnic Spot Near Pimpri Chinchwad, Family Picnic Spot Near Pimpri Chinchwad, Tourist Places Near Pimpri Chinchwad, School Picnic Near Pimpri Chinchwad, Ashtavinayak Darshan Stay Near Pimpri Chinchwad, Cheapest Ashtavinayak Tour from Pune, Get Together Places Near Pimpri Chinchwad, Wedding Destination Near Pimpri Chinchwad, Pre-Wedding Shoot Near Pimpri Chinchwad" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/picnic-spot-near-pimpri-chinchwad" />

  {/* JSON-LD Schema for Picnic Spot Near Pimpri Chinchwad */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Picnic Spot Near Pimpri Chinchwad",
        "url": "https://shindekrushiparyatan.com/picnic-spot-near-pimpri-chinchwad",
        "description": "Looking for picnic spots near Pimpri Chinchwad? Explore family-friendly picnic locations, tourist attractions, Ashtavinayak tour options, and perfect wedding venues for events and pre-wedding shoots.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pimpri Chinchwad",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.6272",
          "longitude": "73.7994"
        },
        "image": "https://shindekrushiparyatan.com/images/picnic-spot-near-pimpri-chinchwad.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/40.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Picnicspotpimpri;