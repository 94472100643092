
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Agrotourismnearmumbai() {



  const cardData =
  {
    keyword: ' Agro Tourism Near Mumbai for Family',
    heading:  'Agro Tourism Near Mumbai for Family: Shinde Agri Tourism',
    headingDescription: 'If you’re looking for an ideal agro-tourism destination near Mumbai for a family outing, Shinde Agri Tourism offers an unforgettable experience. Located just a short drive away from Mumbai, this charming agri-tourism resort provides a perfect blend of nature, education, and fun for the whole family. With its vast open spaces, farm activities, and peaceful surroundings, Shinde Agri Tourism is a great place for families to bond, relax, and explore rural life together.',

    top: 'Top Family-Friendly Activities at Shinde Agri Tourism	',
   "topPlaces": [
  {
    "title": "Interactive Farming Experience",
    "description": "Engage the whole family in traditional farming activities like planting crops, harvesting vegetables, and milking cows. This hands-on experience gives both children and adults an appreciation for farm life and allows families to work together in a relaxed and fun environment."
  },
  {
    "title": "Nature Walks and Trekking",
    "description": "Explore the surrounding nature on peaceful nature walks. The resort offers family-friendly walking trails where you can discover local flora, spot birds, and enjoy the scenic beauty of the countryside. For a more adventurous experience, you can also try family-friendly trekking routes in the nearby hills."
  },
  {
    "title": "Outdoor Games for All Ages",
    "description": "Whether it's a game of badminton, volleyball, or cricket, there are plenty of outdoor sports and games that families can enjoy together. The open spaces at Shinde Agri Tourism are perfect for friendly competitions and fun-filled family moments."
  },
  {
    "title": "Traditional Maharashtrian Folk Performances",
    "description": "Immerse your family in the culture of Maharashtra by watching live folk performances. Traditional dances and music are an entertaining way for the family to experience local customs while enjoying the lively atmosphere around a bonfire."
  },
  {
    "title": "Bird Watching and Wildlife Spotting",
    "description": "For nature lovers, Shinde Agri Tourism offers excellent opportunities for bird watching and spotting wildlife. The resort’s serene environment attracts various bird species, and the entire family can enjoy identifying them as they roam the grounds."
  },
  {
    "title": "Farm-to-Table Dining Experience",
    "description": "After a day of exploring, treat the family to a delicious farm-to-table dining experience. The resort’s restaurant serves fresh, organic meals made from ingredients grown right on the farm. Families can enjoy a wholesome meal together while taking in the natural beauty surrounding them."
  },
  {
    "title": "Bonfire and Stargazing",
    "description": "After a fun-filled day, gather around a bonfire for a cozy family evening. Share stories, roast marshmallows, and enjoy the warmth of the fire. As night falls, enjoy stargazing in the clear skies, far from the city’s light pollution, and spot constellations as a family."
  },
  {
    "title": "Educational Workshops for Kids",
    "description": "Shinde Agri Tourism offers workshops on farming, sustainability, and the environment, making it an educational experience for the younger members of the family. Children can learn about organic farming practices, animal husbandry, and other rural life activities in a fun and interactive way."
  },
  {
    "title": "Picnic and Relaxation Areas",
    "description": "For families that prefer a more laid-back experience, the resort provides picnic spots and relaxation areas. You can spread out a blanket, enjoy homemade food, and spend quality time together amidst nature, making it a perfect setting for a family picnic."
  },
  {
    "title": "Photography Opportunities",
    "description": "With picturesque landscapes, vibrant fields, and rustic farmhouses, Shinde Agri Tourism is a great place for family photography. Capture beautiful moments as a family in the scenic surroundings and create lasting memories to look back on."
  }
],

   "services": [
  {
    "name": "Best Agro Tourism Near Mumbai",
    "description": "For best agro tourism near Mumbai, Shinde Agri Tourism is an ideal destination. Located just a few hours away from Mumbai, this agro-tourism center offers a blend of organic farming experiences, nature walks, and educational activities, making it a great escape from the city for those looking to explore rural life and agriculture."
  },
  {
    "name": "One Day Agro Tourism Near Mumbai",
    "description": "If you’re looking for one day agro tourism near Mumbai, Shinde Agri Tourism offers the perfect solution. With its close proximity to Mumbai, you can enjoy a full day of farm tours, bullock cart rides, nature walks, and hands-on agricultural activities, all while experiencing the peaceful, rural atmosphere of the farm."
  },
  {
    "name": "One Day Picnic Spot Near Mumbai in Monsoon",
    "description": "During the monsoon, Shinde Agri Tourism is a great one day picnic spot near Mumbai. The lush green landscapes, fresh air, and the opportunity to engage in outdoor activities like nature walks and farm tours make it a perfect destination to enjoy the beauty of the rainy season away from the city crowds."
  },
  {
    "name": "One Day Picnic Spot Near Mumbai in Rainy Season",
    "description": "For a one day picnic spot near Mumbai in the rainy season, Shinde Agri Tourism offers the perfect setting. The monsoon season brings the farm to life, with vibrant greenery and cool weather, making it an excellent spot for outdoor activities like nature trails, farm visits, and enjoying the beauty of nature in its full bloom."
  },
  {
    "name": "One Day Picnic Spot Near Mumbai in Summer",
    "description": "Even during the summer, Shinde Agri Tourism remains a refreshing one day picnic spot near Mumbai. You can enjoy the cool, shaded areas of the farm, take nature walks, and experience agricultural activities. The relaxed atmosphere and natural surroundings offer a perfect retreat from the summer heat."
  },
  {
    "name": "One Day Picnic Spot Near Mumbai in Winter",
    "description": "For a winter one day picnic spot near Mumbai, Shinde Agri Tourism provides a calm and peaceful retreat. The pleasant winter weather combined with activities like farm tours, nature walks, and interactive farming experiences make it an ideal destination for a cozy and enjoyable picnic near Mumbai."
  },
  {
    "name": "One Day Picnic Spot Near Mumbai for Family",
    "description": "Shinde Agri Tourism is an excellent one day picnic spot near Mumbai for family. Families can engage in fun activities such as bullock cart rides, farm tours, and organic farming workshops. The farm provides a wonderful opportunity for children to learn about agriculture and nature while enjoying a day in the countryside."
  },
  {
    "name": "One Day Picnic Resorts Near Mumbai",
    "description": "If you're looking for one day picnic resorts near Mumbai, Shinde Agri Tourism is a great option. The farm offers a variety of outdoor activities, including farm tours, nature walks, and educational experiences, all in a peaceful, rural setting just a short drive from Mumbai."
  },
  {
    "name": "Best 1 Day Picnic Spot Near Mumbai",
    "description": "The best 1 day picnic spot near Mumbai is definitely Shinde Agri Tourism. With its serene environment, educational activities, and opportunities for outdoor adventure, it’s an ideal destination for those looking for a one-day getaway close to Mumbai."
  },
  {
    "name": "Best One Day Picnic Spot in Mumbai",
    "description": "Although Shinde Agri Tourism is a bit outside Mumbai, it is one of the best one day picnic spots near Mumbai. The farm’s engaging activities, tranquil atmosphere, and focus on agro-tourism make it an unforgettable destination for a day trip."
  },
  {
    "name": "Best One Day Picnic Spot Near Mumbai",
    "description": "For the best one day picnic spot near Mumbai, Shinde Agri Tourism is a top choice. It’s located just a few hours away from Mumbai and offers a variety of activities, from farm tours to nature walks, making it an ideal spot to relax, learn, and enjoy the outdoors."
  },
  {
    "name": "Best Picnic Spot in Mumbai for 1 Day",
    "description": "The best picnic spot in Mumbai for 1 day might just be Shinde Agri Tourism. Just outside the city, this agro-tourism destination provides a peaceful and refreshing environment with engaging activities like bullock cart rides, farm visits, and organic farming workshops for all ages."
  },
  {
    "name": "Day Picnic in Mumbai",
    "description": "For a day picnic in Mumbai, Shinde Agri Tourism is an ideal location. The farm offers a peaceful escape from the hustle and bustle of the city, where you can enjoy outdoor activities, explore the farm, and learn about sustainable agriculture. It’s a perfect place for a quick, relaxing getaway."
  },
  {
    "name": "Day Picnic Around Mumbai",
    "description": "If you’re looking for a day picnic around Mumbai, Shinde Agri Tourism is a great choice. Located just a short drive from the city, it offers a variety of fun activities like farm tours, nature walks, and hands-on experiences that allow you to connect with nature."
  },
  {
    "name": "Family Picnic Places Near Mumbai",
    "description": "For family picnic places near Mumbai, Shinde Agri Tourism is a fantastic destination. The farm offers a mix of outdoor activities such as bullock cart rides, farm tours, and nature walks, making it an excellent choice for families looking for a fun and educational day out in nature."
  },
  {
    "name": "Mumbai Nearest Picnic Spot",
    "description": "Shinde Agri Tourism is one of the nearest picnic spots to Mumbai that offers a peaceful, rural experience. Just a few hours away from the city, it provides an immersive agro-tourism experience with various activities for visitors of all ages."
  },
  {
    "name": "One Day Family Picnic Near Mumbai",
    "description": "For a one day family picnic near Mumbai, Shinde Agri Tourism is an ideal choice. Families can enjoy a variety of activities, from bullock cart rides to farm tours, while learning about sustainable farming practices. It’s an engaging and fun-filled day for families looking to spend time together in nature."
  },
  {
    "name": "One Day Outing Places in Mumbai",
    "description": "While Shinde Agri Tourism is located a short distance from Mumbai, it’s one of the best one day outing places near Mumbai. The farm offers activities that cater to all ages, such as nature walks, farming experiences, and bullock cart rides, making it perfect for a quick escape from the city."
  },
  {
    "name": "One Day Picnic Destination Near Mumbai",
    "description": "For a one day picnic destination near Mumbai, Shinde Agri Tourism is the perfect spot. The farm offers a variety of outdoor activities, from exploring the farm to enjoying nature walks, making it an ideal place for a fun and educational day trip with family or friends."
  },
  {
    "name": "Shinde Agri Tourism Krushi Paryatan Kendra Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for the perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Best Agro Tourism Near Mumbai", "- One Day Agro Tourism Near Mumbai"],
    ["- One Day Picnic Spot Near Mumbai in Monsoon", "- One Day Picnic Spot Near Mumbai in Rainy Season"],
    ["- One Day Picnic Spot Near Mumbai in Summer", "- One Day Picnic Spot Near Mumbai in Winter"],
    ["- One Day Picnic Spot Near Mumbai for Family", "- One Day Picnic Resorts Near Mumbai"],
    ["- Best 1 Day Picnic Spot Near Mumbai", "- Best One Day Picnic Spot in Mumbai"],
    ["- Best One Day Picnic Spot Near Mumbai", "- Best Picnic Spot in Mumbai for 1 Day"],
    ["- Day Picnic in Mumbai", "- Day Picnic Around Mumbai"],
    ["- Family Picnic Places Near Mumbai", "- Mumbai Nearest Picnic Spot"],
    ["- One Day Family Picnic Near Mumbai", "- One Day Outing Places in Mumbai"],
    ["- One Day Picnic Destination Near Mumbai", "- Best Picnic Spots Near Mumbai"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Authentic Agro Tourism Experience",
      "WhyChoosedescription": "At Shinde Agri Tourism, you’ll experience the true essence of farming life. You and your family can explore our organic farms, interact with farm animals, and learn about sustainable farming practices. It’s an educational yet enjoyable way for kids and adults alike to understand where their food comes from."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining",
      "WhyChoosedescription": "One of the highlights of agro tourism at Shinde Agri Tourism is our farm-to-table dining experience. Enjoy freshly prepared meals made with ingredients sourced directly from our farm. From delicious traditional Maharashtrian dishes to fresh juices and organic salads, the food is wholesome, healthy, and absolutely delicious."
    },
    {
      "WhyChooseheading": "Family-Friendly Activities",
      "WhyChoosedescription": "Apart from farming-related activities, we also offer plenty of fun and engaging activities for the whole family: \n- Nature Walks: Take a guided nature walk and explore the beauty of the surrounding countryside. \n- Bird Watching: Our lush green environment is home to a variety of birds, making it a great spot for birdwatching. \n- Games and Sports: Enjoy outdoor games like cricket, badminton, and volleyball, perfect for family bonding and friendly competitions."
    },
    {
      "WhyChooseheading": "A Peaceful Natural Setting",
      "WhyChoosedescription": "Shinde Agri Tourism is located amidst lush greenery and picturesque landscapes, offering the perfect escape from the hustle and bustle of city life. Surrounded by farms, hills, and fresh air, it’s an ideal environment for relaxation, stress-relief, and quality family time."
    },
    {
      "WhyChooseheading": "Accommodation Options for Families",
      "WhyChoosedescription": "Our resort offers comfortable and family-friendly accommodations. Choose from cozy cottages or eco-friendly tents that are well-equipped to ensure a comfortable stay. Wake up to the sounds of nature, enjoy outdoor spaces for picnics, and unwind in a safe and peaceful environment."
    },
    {
      "WhyChooseheading": "Educational for Kids",
      "WhyChoosedescription": "Agro tourism is a fantastic way for children to learn about agriculture, farming, and the environment. Kids can participate in interactive activities like making compost, planting seeds, and understanding the importance of organic farming. The experience helps instill a love for nature and sustainability in young minds."
    },
    {
      "WhyChooseheading": "Close Proximity to Mumbai",
      "WhyChoosedescription": "Shinde Agri Tourism is conveniently located just 100 km from Mumbai (about 2 to 2.5 hours by car). This makes it an ideal location for a quick weekend getaway or a day trip from the city, offering families a refreshing escape without needing to travel far."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Mumbai, which takes about 2 to 2.5 hours by car. It’s an easy and convenient drive for a family weekend getaway."
    },
    {
      question: "What kind of activities can my family participate in?",
      answer: "Your family can enjoy a range of activities, including fruit and vegetable picking, milking cows, animal care, organic farming workshops, nature walks, bird watching, and outdoor games like cricket and volleyball. There’s something fun for everyone!"
    },
    {
      question: "Is this a family-friendly destination?",
      answer: "Yes! Shinde Agri Tourism is designed to be family-friendly. We offer activities for kids, parents, and even grandparents. It’s a wonderful destination to bond with your loved ones while learning about farming and enjoying nature."
    },
    {
      question: "Can my kids learn about farming during the visit?",
      answer: "Absolutely! Our agro tourism experience is educational, and kids can learn a lot about farming, animals, organic produce, and sustainability. They can engage in hands-on activities like planting seeds, harvesting crops, and learning how to take care of farm animals."
    },
    {
      question: "Do you offer meals during the agro tourism experience?",
      answer: "Yes! We offer fresh, healthy meals made from the farm’s own organic produce. Enjoy traditional Maharashtrian dishes, grilled snacks, organic salads, and fresh juices. It’s a wholesome, farm-to-table dining experience for the entire family."
    },
    {
      question: "Are there accommodation options for families?",
      answer: "Yes, we offer family-friendly accommodation, including cozy cottages and eco-friendly tents. All options are designed to ensure a comfortable stay with modern amenities, so you can relax and enjoy your time with your loved ones."
    },
    {
      question: "Can we visit for a day trip from Mumbai?",
      answer: "Yes! Shinde Agri Tourism is a perfect destination for a day trip from Mumbai. The resort is just a short 2 to 2.5-hour drive, so you can easily spend a day enjoying the activities and relaxing in nature."
    }
  ];
  
  const testimonials = [
    {
      name: "Sanya & Family, Mumbai",
      text: "We had a wonderful family trip to Shinde Agri Tourism! The kids loved picking fruits and learning about farming. The farm-to-table food was delicious, and the nature walk was so peaceful. It was a refreshing change from the city, and we’ll definitely be back!"
    },
    {
      name: "Amit & Family, Mumbai",
      text: "Shinde Agri Tourism was the perfect getaway for our family! The kids enjoyed milking cows and playing outdoor games, and we all learned so much about organic farming. It was educational, fun, and relaxing. Highly recommend it for family trips!"
    }
  ];
  




  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Agro Tourism Near Mumbai for Family | Best One Day Picnic Spots, Family Getaways & Agro Tourism Resorts Near Mumbai | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for agro tourism near Mumbai for a family outing? Discover the best one-day picnic spots, family-friendly resorts, and agro tourism destinations for a memorable family trip near Mumbai. Perfect for weekends, rainy season, and summer getaways!" />
  <meta name="keywords" content="Best agro tourism near Mumbai, one day agro tourism near Mumbai, one day picnic spot near Mumbai in monsoon, one day picnic spot near Mumbai in rainy season, one day picnic spot near Mumbai in summer, one day picnic spot near Mumbai in winter, one day picnic spot near Mumbai for family, one day picnic resorts near Mumbai, Best 1 day picnic spot near Mumbai, best one day picnic spot in Mumbai, best one day picnic spot near Mumbai, best picnic spot in Mumbai for 1 day, day picnic in Mumbai, day picnic around Mumbai, family picnic places near Mumbai, Mumbai nearest picnic spot, one day family picnic near Mumbai, one day outing places in Mumbai, One day picnic destination near Mumbai" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/agro-tourism-near-mumbai-for-family" />

  {/* JSON-LD Schema for Agro Tourism Near Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Agro Tourism Near Mumbai for Family",
        "url": "https://shindekrushiparyatan.com/agro-tourism-near-mumbai-for-family",
        "description": "Explore the best agro tourism destinations near Mumbai for family getaways. Enjoy one-day picnic spots, resorts, and agro tourism experiences perfect for family outings, weekend trips, and seasonal getaways.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/agro-tourism-near-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/31.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Agrotourismnearmumbai;