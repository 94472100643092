import React from 'react';

const GalleryItem = ({ image, title }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <div className="gallery-item mb-4 rounded overflow-hidden">
        <div className="gallery-image">
          <img src={image} alt={title} />
        </div>
        <div className="gallery-content">
          <h5 className="white text-center position-absolute bottom-0 pb-4 left-50 mb-0 w-100">
            {title}
          </h5>
          <ul>
            <li><a href={image} data-lightbox="gallery" data-title="Title"><i className="fa fa-eye"></i></a></li>
            <li><a href="#"><i className="fa fa-link"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const Gallery = () => {
  const galleryItems = [
    { image: '/images/gallery/1.jpg', },
    { image: '/images/gallery/2.jpg', },
    { image: '/images/gallery/3.jpg', },
    { image: '/images/gallery/4.jpg', },
    { image: '/images/gallery/5.jpg', },
    { image: '/images/gallery/6.jpg', },
    { image: '/images/gallery/7.jpg', },
    { image: '/images/gallery/8.jpg', },
    { image: '/images/gallery/9.jpg', },
    { image: '/images/gallery/10.jpg', },
    { image: '/images/gallery/11.jpg', },
    { image: '/images/gallery/12.jpg', },
    { image: '/images/gallery/13.jpg', },
    { image: '/images/gallery/14.jpg', },
    { image: '/images/gallery/15.jpg', },
    { image: '/images/gallery/16.jpg', },
    { image: '/images/gallery/17.jpg', },
    { image: '/images/gallery/18.jpg', },
    { image: '/images/gallery/19.jpg', },
    { image: '/images/gallery/20.jpg', },
    { image: '/images/gallery/21.jpg', },
    { image: '/images/gallery/22.jpg', },
    { image: '/images/gallery/23.jpg', },
    { image: '/images/gallery/15.jpg', },
  ];

  return (

<div>



<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
      <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3"> Gallery</h1>

          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    <div className="gallery pt-6 pb-0">
      <div className="container">
        <div className="section-title mb-6 text-center w-75 mx-auto">
          <h4 className="mb-1 theme1">Our Gallery</h4>
          <h2 className="mb-1">
            Some Beautiful <span className="theme">Snapshoots</span>
          </h2>
        </div>

        <div className="row">
          {galleryItems.map((item, index) => (
            <GalleryItem key={index} image={item.image} title={item.title} />
          ))}
        </div>
      </div>
    </div>


</div>  );
};

export default Gallery;
