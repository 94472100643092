
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Schoolpicnicsports() {



  const cardData =
  {
    keyword: ' School Picnic Spots in Mumbai',
    heading:  'School Picnic Spots in Mumbai: Shinde Agri Tourism',
    headingDescription: 'If you are looking for a school picnic spot near Mumbai that offers a mix of fun, education, and nature, Shinde Agri Tourism is the perfect destination. Located just a short drive from Mumbai, this agro-tourism site offers a variety of activities that not only engage students but also provide them with valuable learning experiences about farming, nature, and rural life. It’s an ideal place to combine outdoor fun with educational activities.',

    top: 'Top Activities at Shinde Agri Tourism	',
   "topPlaces": [
  {
    "title": "Educational Farming Activities",
    "description": "Students can experience firsthand how a farm works. Activities like planting seeds, harvesting crops, and milking cows give children a unique opportunity to learn about agriculture and the importance of sustainable farming."
  },
  {
    "title": "Interactive Nature Walks",
    "description": "Lead students on nature trails where they can explore local flora and fauna. This activity encourages an appreciation for nature and provides students with knowledge about different plant species, birds, and wildlife."
  },
  {
    "title": "Outdoor Sports and Games",
    "description": "Shinde Agri Tourism offers a variety of outdoor sports such as cricket, badminton, and volleyball, making it the perfect place for school teams to engage in friendly matches and team-building activities."
  },
  {
    "title": "Bird Watching",
    "description": "The lush greenery and natural surroundings make it an excellent spot for bird watching. Students can identify various bird species while enjoying the peaceful environment."
  },
  {
    "title": "Farm Animal Interaction",
    "description": "Students will love the chance to interact with farm animals like cows, goats, and chickens. This activity is both fun and educational, allowing children to learn about animal care and farm life."
  },
  {
    "title": "Picnic and Refreshments",
    "description": "After a day filled with activities, students can enjoy a relaxed picnic in the well-maintained areas of Shinde Agri Tourism. The resort serves farm-fresh meals that will keep the children energized and satisfied."
  }
],

     "services": [
  {
    "name": "Picnic Spots for School Students",
    "description": "Shinde Agri Tourism is a fantastic picnic spot for school students. Located near Pune, it offers educational and fun activities like farm tours, nature walks, and hands-on farming experiences, making it an ideal place for school groups to learn about agriculture and enjoy the great outdoors."
  },
  {
    "name": "Picnic Spot for School Students in Pune",
    "description": "For school picnic spots in Pune, Shinde Agri Tourism provides an immersive and educational experience. Students can engage in farming activities, take part in nature walks, and discover the joys of rural life, all while being close to Pune."
  },
  {
    "name": "School Picnic Spots in Mumbai",
    "description": "Though Shinde Agri Tourism is located just outside Mumbai, it remains one of the best school picnic spots near Mumbai. The farm offers a range of activities, including educational farm tours, nature walks, and interactive workshops, making it a great destination for school groups looking to spend a day learning about sustainable farming and enjoying outdoor activities."
  },
  {
    "name": "Picnic Spots Around Mumbai",
    "description": "For picnic spots around Mumbai, Shinde Agri Tourism is an excellent option. Located just a few hours away, the farm offers a peaceful rural setting where students and families can engage in educational farming activities, nature walks, and experience the simplicity of rural life."
  },
  {
    "name": "Picnic Spots Near Mumbai for 2 Days",
    "description": "If you're looking for picnic spots near Mumbai for 2 days, Shinde Agri Tourism offers overnight stays, making it perfect for a two-day getaway. Enjoy a variety of activities such as farm tours, organic farming workshops, bullock cart rides, and nature trails, all while staying overnight in a peaceful, rural setting."
  },
  {
    "name": "2 Days Tour Package from Mumbai",
    "description": "Shinde Agri Tourism provides an ideal 2 days tour package from Mumbai. With a combination of overnight stays and educational farm experiences, it’s a great destination for those wanting to escape the city for a couple of days. The package includes farm activities, nature walks, bullock cart rides, and a chance to connect with nature in a serene environment."
  },
  {
    "name": "College Tour Packages Near Mumbai",
    "description": "For college tour packages near Mumbai, Shinde Agri Tourism offers a unique and educational experience. College groups can take part in agro-tourism activities, including farm tours, organic farming lessons, and nature walks. It’s a great place for students to relax and learn about sustainable agriculture while enjoying the peaceful countryside."
  },
  {
    "name": "Shinde Agri Tourism Krushi Paryatan Kendra near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Picnic Spots for School Students", "- Picnic Spot for School Students in Pune"],
    ["- School Picnic Spots in Mumbai", "- Picnic Spots Around Mumbai"],
    ["- Picnic Spots Near Mumbai for 2 Days", "- 2 Days Tour Package from Mumbai"],
    ["- College Tour Packages Near Mumbai", "- "]
  ],
  
  
  
  whychoose: [
    {
      "WhyChooseheading": "Educational and Fun Agro Tourism Experience",
      "WhyChoosedescription": "At Shinde Agri Tourism, school groups can participate in hands-on farming activities that are both fun and educational. Students will get a real-world experience of farm life, including learning how crops are grown, the basics of organic farming, and understanding the lifecycle of plants. Activities like fruit and vegetable picking, milking cows, and feeding farm animals give children an interactive way to connect with nature and agriculture. This engaging, immersive experience helps students understand where their food comes from, fostering respect for the environment and promoting sustainable practices."
    },
    {
      "WhyChooseheading": "Interactive Learning with Farm Visits",
      "WhyChoosedescription": "Our farm visits provide an interactive and engaging learning experience for students. While exploring the farm, students will gain practical knowledge about the agricultural process—from planting and growing to harvesting and caring for livestock. They'll learn the importance of biodiversity, soil health, and eco-friendly farming practices. This hands-on experience allows students to observe real-life farming methods and understand the interconnectedness of nature, offering them valuable lessons in environmental stewardship, sustainability, and the importance of healthy food systems."
    },
    {
      "WhyChooseheading": "Outdoor Games and Team Activities",
      "WhyChoosedescription": "After educational sessions, students can enjoy a variety of outdoor games that not only provide fun but also promote teamwork, physical fitness, and mental agility. Sports like cricket, volleyball, and badminton can be enjoyed on the spacious grounds. Additionally, activities such as archery, obstacle courses, and nature trails add an adventurous touch, encouraging healthy competition and fostering a sense of camaraderie among students. These group activities help students develop social skills, build leadership qualities, and enhance their cooperation and problem-solving abilities—all while having a great time outdoors."
    },
    {
      "WhyChooseheading": "Safe and Secure Environment",
      "WhyChoosedescription": "Shinde Agri Tourism is dedicated to providing a safe and secure environment for school groups. Our expansive property is designed with the safety of children in mind, featuring well-marked areas for different activities to ensure supervision at all times. With dedicated staff overseeing all activities, teachers and school staff can rest assured that students will be safe while enjoying their educational trip. We prioritize the safety of students in everything we do, from well-maintained facilities to fully trained staff who are ready to respond to any situation. Our secure environment allows students to explore, learn, and have fun without worries."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining for School Groups",
      "WhyChoosedescription": "One of the highlights of the Shinde Agri Tourism experience is our farm-to-table dining. Students will have the opportunity to enjoy healthy and nutritious meals made from organic ingredients directly sourced from our farm. Our food is not only delicious but also freshly prepared, ensuring that students get the best in quality and nutrition. We offer a variety of traditional Maharashtrian dishes, organic salads, fresh fruit juices, and snacks, all made with wholesome ingredients. This not only helps students appreciate the importance of healthy eating but also teaches them about the farm-to-table process and the benefits of sustainable food systems."
    },
    {
      "WhyChooseheading": "Team-Building Activities for Students",
      "WhyChoosedescription": "Shinde Agri Tourism offers a wide range of team-building activities tailored to improve students' communication, leadership, and collaborative skills. These exercises are designed to encourage teamwork and build trust among students. Activities like trust falls, problem-solving challenges, and group tasks help students learn how to work together, resolve conflicts, and support each other in achieving common goals. These team-building experiences are not only fun but also incredibly valuable in developing essential life skills, such as leadership, decision-making, and resilience, in a relaxed and informal setting."
    },
    {
      "WhyChooseheading": "Scenic and Peaceful Location",
      "WhyChoosedescription": "Shinde Agri Tourism is set in a beautiful and tranquil natural environment, surrounded by lush green fields, hills, and fresh air. This serene setting offers students the perfect opportunity to disconnect from the distractions of everyday life and reconnect with nature. After engaging in various educational activities, students can enjoy quiet moments of reflection, take a peaceful stroll through nature trails, or relax in the open air. The calming atmosphere fosters mental relaxation, reduces stress, and provides a peaceful backdrop for both learning and recreation, making it an ideal setting for a rejuvenating school outing."
    },
    {
      "WhyChooseheading": "Easy Accessibility from Mumbai",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 100 km from Mumbai, a short 2 to 2.5-hour drive, making it the perfect destination for a school day trip. Its close proximity ensures that schools can easily organize a visit without the hassle of long travel times. This convenient location allows students to experience the joys of agro-tourism without having to travel far from the city. Whether for a one-day outing or a longer visit, Shinde Agri Tourism offers a stress-free, easily accessible location for schools looking to offer their students a memorable and educational outdoor experience."
    }
  ]
  



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located about 100 km from Mumbai, which is a 2 to 2.5-hour drive. It’s an easy destination for a day trip, perfect for school outings."
    },
    {
      question: "What activities are available for school groups?",
      answer: "We offer a variety of activities, including farm tours, fruit and vegetable picking, milking cows, animal interactions, outdoor games like cricket and volleyball, and team-building exercises designed to encourage teamwork and communication."
    },
    {
      question: "Is the resort safe for school groups?",
      answer: "Yes, we prioritize safety and ensure that school groups are well-supervised during all activities. Our resort is spacious with defined areas for different activities, and we take all necessary precautions to ensure a safe environment for students."
    },
    {
      question: "What kind of food is provided for school groups?",
      answer: "We offer healthy, farm-to-table meals made from organic produce grown on our farm. Meals include traditional Maharashtrian dishes, fresh salads, juices, and other nutritious options. We can accommodate special dietary needs upon request."
    },
    {
      question: "Are there any educational elements in the picnic experience?",
      answer: "Yes! Our agro-tourism experience is highly educational. Students will learn about organic farming, sustainable agriculture, and the importance of environmental conservation. It’s a great way to connect classroom learning with real-world experiences."
    },
    {
      question: "Can we arrange for team-building activities for students?",
      answer: "Absolutely! We offer team-building exercises that are designed to improve communication, leadership, and cooperation among students. Activities like group challenges, problem-solving tasks, and trust-building exercises are perfect for fostering teamwork."
    },
    {
      question: "How long does the picnic experience last?",
      answer: "Our school picnic packages are designed for a full day. The experience usually lasts from morning to evening, allowing ample time for activities, meals, and relaxation."
    },
    {
      question: "Can we book a picnic for a large group of students?",
      answer: "Yes! We welcome school groups of all sizes and can customize packages based on the number of students and your specific needs. We ensure that every student has an enjoyable and educational experience."
    }
  ];
  
  const testimonials = [
    {
      name: "Mrs. Neelam, Principal, Mumbai",
      text: "Our school trip to Shinde Agri Tourism was a fantastic experience for the kids! They loved picking fruits, learning about organic farming, and interacting with the animals. The outdoor games and team-building activities were a big hit. The food was healthy and delicious too. We’ll definitely be back!"
    },
    {
      name: "Mr. Amit, Teacher, Mumbai",
      text: "Shinde Agri Tourism is the perfect spot for school picnics! The kids had a wonderful time learning about farming, playing outdoor games, and enjoying the peaceful surroundings. It was both an educational and fun experience for our students. Highly recommend for school outings!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | School Picnic Spots in Mumbai | Picnic Spots for School Students, College Tour Packages & 2 Days Tour from Mumbai | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for school picnic spots in Mumbai? Explore the best picnic spots for school students, college tour packages, and 2-day tour options near Mumbai for a fun and educational outing." />
  <meta name="keywords" content="Picnic spots for school students, Picnic spot for school students in Pune, School picnic spots in Mumbai, Picnic spots around Mumbai, Picnic spots near Mumbai for 2 days, 2 days tour package from Mumbai, College tour packages near Mumbai" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/school-picnic-spots-in-mumbai" />

  {/* JSON-LD Schema for School Picnic Spots in Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - School Picnic Spots in Mumbai",
        "url": "https://shindekrushiparyatan.com/school-picnic-spots-in-mumbai",
        "description": "Discover the best school picnic spots in Mumbai, ideal for school students and college tours. Explore 2-day tour packages and picnic spots around Mumbai for an educational and fun outing.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/school-picnic-spots-in-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/34.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Schoolpicnicsports;