import React from 'react';

const Contact = () => {
  return (
   <div>


<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
      <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3"> Contact</h1>

          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>

<section className="contact-main pt-6 pb-60 " style={{backgroundColor:'#106B30'}}>
      <div className="container">
        <div className="contact-info-main mt-0">
          <div className="row">
            <div className="col-lg-10 col-offset-lg-1 mx-auto">
              <div className="contact-info bg-white">
                <div className="contact-info-title text-center mb-4 px-5">
                  <h3 className="mb-1">INFORMATION ABOUT US</h3>
                
                </div>
                <div className="contact-info-content row mb-1">
                
                  {/* Phone Number */}
                  <div className="col-lg-4 col-md-6 mb-4 borderrr">
                    <div className="info-item bg-lgrey px-4 py-5  text-center rounded">
                      <div className="info-icon mb-2">
                        <i className="fa fa-phone theme"></i>
                      </div>
                      <div className="info-content">
                        <h3>Phone Number</h3>
                        <a href="tel:+919356007002">
                     <p className="m-0 darkcolor">+91 9356007002</p>
                      </a>
                        <a href="tel:+919075990202">
                        <p className="m-0 darkcolor">+91 9075990202</p>
                        </a>

                      </div>
                    </div>
                  </div>
                  {/* Email Address */}
                  <div className="col-lg-4 col-md-12 mb-4 borderrr">
                    <div className="info-item bg-lgrey px-4 py-5 text-center rounded">
                      <div className="info-icon mb-2">
                        <i className="fa fa-envelope theme"></i>
                      </div>
                      <div className="info-content ">
                        <h3>Email </h3>
                        <p className="m-0 darkcolor">
                          <a href="booking@shindekrushiparyatan.com" className='darkcolor'>booking@shindekrushiparyatan.com</a>
                        </p>
                       
                      </div>
                    </div>
                  </div>
                    {/* Office Location */}
                    <div className="col-lg-4 col-md-6 mb-4 borderrr">
                    <div className="info-item bg-lgrey px-4 py-5   text-center rounded">
                      <div className="info-icon mb-2">
                        <i className="fa fa-map-marker-alt theme"></i>
                      </div>
                      <div className="info-content darkcolor">
                        <h3>Address</h3>
                        At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218
                      </div>
                    </div>
                  </div>

                </div>

                <div id="contact-form1" className="contact-form">
                  <div className="row">
                    {/* Google Map */}
                    <div className="col-12">
                      <div className="map rounded overflow-hiddenb rounded mb-md-4">
                        <div style={{ width: '100%' }}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.5547922308438!2d74.22163490000001!3d18.834895699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc32bb61bb9bd89%3A0xe4a14a349669cb53!2sShinde%20Agri%20Tourism%20(%20Shinde%20krushi%20paryatan%20)!5e1!3m2!1sen!2sin!4v1731325585975!5m2!1sen!2sin" width="auto" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


   </div>
  );
};

export default Contact;
