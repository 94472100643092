
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Agritourismnear() {



  const cardData =
  {
    keyword: ' Agri Tourism Near Ahmednagar',
    heading: 'Agri Tourism Near Ahmednagar: Shinde Agri Tourism',
    headingDescription: 'Looking for a serene and refreshing getaway near Ahmednagar? Shinde Agri Tourism offers an exceptional agri-tourism experience, combining the tranquility of rural life with the beauty of nature. Whether you are planning a family vacation, a corporate retreat, or a peaceful weekend escape, our farm-based activities and relaxing environment provide a perfect backdrop for creating unforgettable memories.',

    top: 'Top Features of Agri Tourism Near Ahmednagar at Shinde Agri Tourism',
    "topPlaces": [
        {
          "title": "Farm-to-Table Dining",
          "description": "Savor the freshest meals prepared with organic produce grown right on the farm. The farm-to-table experience ensures that you enjoy nutritious and flavorful meals, enhancing the authenticity of your visit. Shinde Agri Tourism’s focus on using locally sourced ingredients adds a personal touch to every dish, making your meal not only healthy but also deeply connected to the rural farm environment."
        },
        {
          "title": "Interactive Farming Activities",
          "description": "Experience the true essence of farm life with activities like milking cows, planting crops, and harvesting fresh produce. These hands-on activities provide fun and education for all ages, making your visit memorable and enriching. At Shinde Agri Tourism, you can engage directly with the farm and learn about sustainable agriculture, creating a deeper connection with the land and its natural rhythms."
        },
        {
          "title": "Nature Walks and Bird Watching",
          "description": "Explore the lush green landscapes on nature walks, and enjoy bird watching in a peaceful environment. The serene surroundings are perfect for relaxing, observing wildlife, and connecting with nature. Shinde Agri Tourism offers an ideal setting for bird lovers and nature enthusiasts, where you can spot a variety of species while enjoying the calm beauty of the surroundings."
        },
        {
          "title": "Outdoor Sports and Games",
          "description": "Get active with outdoor sports like cricket, volleyball, and badminton. The spacious areas at Shinde Agri Tourism allow you to enjoy friendly competitions and family bonding activities in a natural setting. With ample space and beautiful surroundings, it’s a great way to unwind, get some exercise, and create fun memories with your group."
        },
        {
          "title": "Bonfire and Barbecue",
          "description": "After a day filled with activities, unwind with a cozy bonfire. Enjoy a barbecue with friends and family, sharing stories and making lasting memories under the open sky. The intimate atmosphere of a bonfire at Shinde Agri Tourism allows for a relaxing evening, with the warmth of the fire complementing the delicious food and great company."
        },
        {
          "title": "Peaceful Picnic Spots",
          "description": "Shinde Agri Tourism offers well-maintained picnic areas, perfect for a relaxing day out. Spread a blanket, enjoy your meals, and take in the calm surroundings for an unforgettable picnic experience. The scenic spots, surrounded by greenery and fresh air, provide the perfect setting to unwind and enjoy quality time with loved ones."
        }
      ],


        "services": [
    {
      "name": "Best Agri Tourism Near Ahmednagar",
      "description": "If you're looking for agri tourism near Ahmednagar, Shinde Agri Tourism offers a great rural retreat with farm activities, organic farming, and a peaceful environment. Another good option is Malthan Agri Tourism, providing a variety of agro-tourism experiences such as farm tours, bullock cart rides, and nature walks."
    },
    {
      "name": "Best Agro Tourism Resort Near Pune",
      "description": "For a luxurious agro-tourism experience near Pune, Shinde Agri Tourism and Malthan Agritourism offer top-notch rural stays with a variety of activities. Resorts like The Corinthians Resort and Aamby Valley provide a blend of agro-tourism with luxury amenities, perfect for a relaxing getaway."
    },
    {
      "name": "One Day Picnic Agro Tourism Near Pune",
      "description": "For a one-day picnic near Pune, visit Shinde Agri Tourism for a day of fun farm activities, nature walks, and relaxation. Malthan Agritourism also offers day packages with bullock cart rides, organic farming experiences, and a peaceful retreat for a quick escape from the city."
    },
    {
      "name": "Ahmednagar Picnic Spot for Family",
      "description": "For a family picnic near Ahmednagar, head to Khandala Hill or Bhandardara Dam, both offering scenic views and peaceful environments. Shirdi is also an option, providing both spiritual experiences and picnic-friendly spots for families to enjoy a relaxing day."
    },
    {
      "name": "Tourist Places Near Ahmednagar Within 50 Km",
      "description": "There are many tourist spots near Ahmednagar within a 50 km radius, including Shirdi (famous for the Sai Baba Temple), Bhandardara Dam (ideal for picnics and nature lovers), Shani Shingnapur (known for its temple), and Nandur Madhmeshwar Wildlife Sanctuary (bird watching and nature tours)."
    },
    {
      "name": "One Day Picnic Spot Near Ahmednagar",
      "description": "Bhandardara Dam is a perfect one-day picnic spot near Ahmednagar, offering beautiful landscapes and serene water views. Alternatively, visit Khandala Hill for a peaceful escape, or Shirdi for both spiritual and recreational experiences."
    },
    {
      "name": "Agri Tourism Near Nashik",
      "description": "Nashik is home to some fantastic agri-tourism experiences: Sula Vineyards offers a mix of wine tourism and rural experiences, with tours of their vineyards and wine-tasting sessions. Shinde Agri Tourism near Nashik provides a serene rural experience, with farm tours, nature walks, and relaxation. Malthan Agritourism near Nashik also offers an authentic agro-tourism experience."
    },
    {
      "name": "Agri Tourism Near Mumbai",
      "description": "For agri tourism near Mumbai, head to Malshej Ghat, where you can enjoy the countryside and experience agro-tourism activities. Tungarli Lake near Lonavala offers a perfect mix of agro-tourism and adventure. Malthan Agri Tourism also offers a countryside escape from Mumbai, with various farming experiences and nature activities."
    },
    {
      "name": "Agro Tourism Near Aurangabad",
      "description": "Aurangabad has several agro-tourism options for nature lovers: Ajanta and Ellora Caves are popular tourist destinations, but nearby villages offer rural tourism experiences such as organic farming, bullock cart rides, and farm stays. Malthan Agri Tourism is also a good choice for farm activities and relaxation near Aurangabad."
    },
    {
      "name": "Adventure Park Near Aurangabad",
      "description": "For adventure activities near Aurangabad, visit Daulatabad Fort, offering some light trekking and historical exploration. Ajanta and Ellora Caves provide a combination of adventure and culture, though not specifically an 'adventure park.' Dnyanganga Wildlife Sanctuary offers nature walks and exploration."
    },
    {
      "name": "Picnic Spot Near Aliyanagar",
      "description": "If you're looking for a picnic spot near Aliyanagar, visit Khadakwasla Dam, offering beautiful lakeside views, boating, and picnic areas. Pune-Okayama Friendship Garden is also a peaceful spot for a day out in nature."
    },
    {
      "name": "Farmhouse Near Ahmednagar",
      "description": "Shinde Agri Tourism offers beautiful farmhouses near Ahmednagar, providing a rustic experience with nature walks, farm activities, and relaxation. For a more luxurious stay, you can visit resorts around Bhandardara or Khandala Hill."
    },
    {
      "name": "Tourist Places in Ahmednagar",
      "description": "Some key tourist attractions in Ahmednagar include Shirdi (Sai Baba Temple), Shani Shingnapur (famous for its temple), Bhandardara Dam (picnic spot), Khandala Hill (trekking and scenic views), and Nandur Madhmeshwar Wildlife Sanctuary (bird-watching and nature walks)."
    },
    {
      "name": "Agri Tourism Near Nashik",
      "description": "Nashik offers some of the best agro-tourism experiences: Sula Vineyards for wine lovers, while Shinde Agri Tourism and Malthan Agri Tourism provide authentic rural stays with activities like organic farming, nature walks, and traditional village experiences."
    },
    {
      "name": "Best Agro Tourism Near Nashik",
      "description": "For the best agro-tourism experiences near Nashik, Sula Vineyards stands out for wine tourism, while Shinde Agri Tourism and Malthan Agri Tourism provide authentic rural stays with farm tours, bullock cart rides, and an immersive agro-tourism experience."
    },
    {
      "name": "Shinde Agri Tourism Agri Tourism Near Ahmednagar Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Agri Tourism Near Ahmednagar experience. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],

      tableData: [
  ["- Best Agri Tourism Near Ahmednagar", "- Best Agro Tourism Resort Near Pune"],
  ["- One Day Picnic Agro Tourism Near Pune", "- Ahmednagar Picnic Spot for Family"],
  ["- Tourist Places Near Ahmednagar Within 50 km", "- One Day Picnic Spot Near Ahmednagar"],
  ["- Agri Tourism Near Nashik", "- Agri Tourism Near Mumbai"],
  ["- Agro Tourism Near Aurangabad", "- Adventure Park Near Aurangabad"],
  ["- Picnic Spot Near Aliyanagar", "- Farmhouse Near Ahmednagar"],
  ["- Tourist Places in Ahmednagar", "- Best Agro Tourism Near Nashik"],
  ["- Agri Tourism Near Nashik", "- Agri Tourism Near Nashik"]
],

whychoose: [
  {
    "WhyChooseheading": "Proximity to Ahmednagar",
    "WhyChoosedescription": "Shinde Agri Tourism is conveniently located just 60-70 km from Ahmednagar, making it easily accessible for a quick weekend getaway or even a day trip. Whether you're looking to escape for a peaceful day in nature or spend the weekend relaxing, our location offers the perfect balance of proximity and tranquility. You won’t have to travel far to enjoy a refreshing rural retreat."
  },
  {
    "WhyChooseheading": "Scenic and Peaceful Surroundings",
    "WhyChoosedescription": "Shinde Agri Tourism is set in a serene rural location, surrounded by lush greenery, open fields, and untouched nature. The tranquil environment provides a perfect escape from the noise and rush of city life, allowing guests to unwind and relax in an authentic countryside setting. It’s an ideal spot for anyone looking to reconnect with nature and enjoy the peace of rural life."
  },
  {
    "WhyChooseheading": "Authentic Farm Experiences",
    "WhyChoosedescription": "Immerse yourself in authentic farm life at Shinde Agri Tourism. Participate in fun and educational activities such as organic farming, fruit picking, milking cows, and feeding animals. These hands-on experiences offer guests a unique opportunity to learn about sustainable farming practices and connect with nature in a way that is both enjoyable and informative."
  },
  {
    "WhyChooseheading": "Outdoor Adventure Activities",
    "WhyChoosedescription": "For those seeking a bit more adventure, Shinde Agri Tourism offers a variety of outdoor activities like zip-lining, rock climbing, archery, and nature walks. These activities cater to all ages and provide a fun and exciting way to explore the natural beauty of the area. Whether you're an adrenaline junkie or someone who enjoys a peaceful hike, there’s something for everyone."
  },
  {
    "WhyChooseheading": "Farm-to-Table Dining Experience",
    "WhyChoosedescription": "At Shinde Agri Tourism, we offer a delightful farm-to-table dining experience. All meals are prepared with fresh, organic ingredients sourced directly from our farm. From traditional Maharashtrian dishes to fresh salads, juices, and snacks, each meal is crafted to give you a true taste of rural hospitality. The farm-to-table approach ensures that you’re enjoying nutritious and flavorful meals that reflect the essence of countryside living."
  },
  {
    "WhyChooseheading": "Perfect Venue for Special Occasions",
    "WhyChoosedescription": "Whether you're celebrating a family reunion, birthday, anniversary, or hosting a corporate retreat, Shinde Agri Tourism is the perfect venue for your special occasion. Our customizable packages include event planning, food, activities, and decoration services to ensure your event is a success. The tranquil setting, combined with our excellent service, will make your celebration memorable for years to come."
  },
  {
    "WhyChooseheading": "Privacy and Exclusivity",
    "WhyChoosedescription": "When you choose Shinde Agri Tourism, you gain exclusive access to the entire property. This allows you to enjoy complete privacy and freedom, whether you’re hosting a private family gathering, a corporate team-building event, or simply spending a peaceful day away from the city. Enjoy the privacy to relax, bond with loved ones, or work with colleagues in a stress-free, open environment."
  },
  {
    "WhyChooseheading": "Affordable and Flexible Packages",
    "WhyChoosedescription": "Shinde Agri Tourism offers a range of flexible and affordable packages that cater to all budgets and group sizes. Whether you’re planning a day trip, a weekend retreat, or an extended stay, our packages can be tailored to suit your specific needs. We believe in providing high-quality experiences at competitive prices, ensuring you get great value for your money while enjoying a relaxing and enriching rural getaway."
  },
  {
    "WhyChooseheading": "Eco-Friendly and Sustainable Practices",
    "WhyChoosedescription": "We are committed to sustainability at Shinde Agri Tourism. Our farm follows eco-friendly practices such as organic farming, rainwater harvesting, and efficient waste management. By supporting sustainable tourism, we ensure that your visit has a minimal environmental impact. Staying with us means you’re helping support responsible tourism practices while enjoying a beautiful, eco-conscious experience."
  }
]

      




  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Ahmednagar?",
      answer: "Shinde Agri Tourism is located about 60-70 km from Ahmednagar, making it an easily accessible destination for a weekend getaway or day trip."
    },
    {
      question: "What types of activities are available at Shinde Agri Tourism?",
      answer: "Guests can enjoy a variety of activities, including organic farming, fruit picking, milking cows, animal feeding, bullock cart rides, zip-lining, rock climbing, archery, and nature walks."
    },
    {
      question: "Can Shinde Agri Tourism be booked for special occasions or corporate retreats?",
      answer: "Yes, Shinde Agri Tourism is ideal for special occasions like birthdays, anniversaries, family gatherings, and corporate retreats. We offer customizable packages to suit your event needs, including food, activities, and event planning."
    },
    {
      question: "Is food included in the rental package?",
      answer: "Yes, Shinde Agri Tourism provides a farm-to-table dining experience, offering fresh and organic meals made from produce grown on the farm. Enjoy traditional Maharashtrian meals, fresh salads, juices, and snacks."
    },
    {
      question: "Can Shinde Agri Tourism accommodate large groups?",
      answer: "Yes, our property can comfortably accommodate both small and large groups. Whether you're visiting for a family outing, a corporate retreat, or a friends' getaway, we can cater to your group's size and requirements."
    },
    {
      question: "Is Shinde Agri Tourism eco-friendly?",
      answer: "Yes, we are dedicated to sustainability. Our farm practices include organic farming, rainwater harvesting, and waste management systems, ensuring an environmentally friendly stay."
    },
    {
      question: "How do I book a stay at Shinde Agri Tourism?",
      answer: "Booking is easy! Simply contact us through our website or phone, let us know your preferred dates, group size, and any special requests, and we’ll take care of the rest."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. and Mrs. Patil, Ahmednagar",
      text: "We had an amazing time at Shinde Agri Tourism! The kids loved picking fruits, feeding the animals, and going on a bullock cart ride. The peaceful surroundings and farm-to-table meals made it a perfect family weekend getaway."
    },
    {
      name: "Ms. Sonali, HR Manager, Pune",
      text: "Our corporate retreat at Shinde Agri Tourism was a fantastic experience. The team-building activities were engaging, and the food was fresh and delicious. We left feeling rejuvenated and more connected as a team."
    }
  ];
  






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Agri Tourism Near Ahmednagar | Best Agro Tourism Resorts & Picnic Spots Near Ahmednagar, Nashik & Aurangabad | Shinde Krushi Paryatan</title>
  <meta name="description" content="Explore the best agri tourism near Ahmednagar with Shinde Krushi Paryatan. Discover one-day picnic spots, agro tourism resorts near Pune, Nashik, and Aurangabad, ideal for family getaways and nature lovers." />
  <meta name="keywords" content="Best Agri Tourism Near Ahmednagar, Best Agro Tourism Resort Near Pune, One Day Picnic Agro Tourism Near Pune, Ahmednagar Picnic Spot for Family, Tourist Places Near Ahmednagar Within 50 km, One Day Picnic Spot Near Ahmednagar, Agri Tourism Near Nashik, Agri Tourism Near Mumbai, Agro Tourism Near Aurangabad, Adventure Park Near Aurangabad, Picnic Spot Near Aliyanagar, Farmhouse Near Ahmednagar, Tourist Places in Ahmednagar" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/agri-tourism-near-ahmednagar" />

  {/* JSON-LD Schema for Agri Tourism Near Ahmednagar */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Agri Tourism Near Ahmednagar",
        "url": "https://shindekrushiparyatan.com/agri-tourism-near-ahmednagar",
        "description": "Looking for agri tourism near Ahmednagar? Explore the best agro tourism resorts, one-day picnic spots, and family-friendly destinations near Ahmednagar, Nashik, and Aurangabad with Shinde Krushi Paryatan.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Ahmednagar",
          "addressRegion": "Maharashtra",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0976",
          "longitude": "74.7387"
        },
        "image": "https://shindekrushiparyatan.com/images/agri-tourism-near-ahmednagar.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "200"
        }
      }
    `} 
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/43.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Agritourismnear;