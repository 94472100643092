
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Teamouting() {



  const cardData =
  {
    keyword: ' Team Outing Places Near Mumbai',
    heading:  'Team Outing Places Near Mumbai: Shinde Agri Tourism',
    headingDescription: 'If you are looking for a unique team outing destination near Mumbai that combines relaxation, adventure, and team-building activities, Shinde Agri Tourism is the perfect choice. Located just a short drive from Mumbai, this agro-tourism resort offers a refreshing escape from the city"s busy pace, making it an ideal venue for corporate team outings. Whether you’re planning a fun-filled day of activities or a relaxed outing with colleagues, Shinde Agri Tourism has everything you need for a memorable experience.',

    top: 'Top Team Outing Activities at Shinde Agri Tourism	',
    "topPlaces": [
  {
    "title": "Team-Building Games and Outdoor Sports",
    "description": "Engage in various team-building exercises and outdoor sports such as cricket, volleyball, badminton, and relay races. These activities promote teamwork, cooperation, and communication, making them perfect for strengthening team dynamics in a fun and relaxed environment."
  },
  {
    "title": "Farming and Agriculture Experience",
    "description": "Get hands-on with farming activities such as milking cows, planting crops, and harvesting produce. This immersive experience provides a unique way for teams to bond while learning about sustainable agriculture and rural life."
  },
  {
    "title": "Nature Walks and Exploration",
    "description": "Take a scenic nature walk through the lush surroundings, where your team can enjoy the beauty of the outdoors. The peaceful atmosphere is perfect for unwinding and having meaningful conversations while connecting with nature."
  },
  {
    "title": "Barbecue and Bonfire Night",
    "description": "After a day of activities, enjoy a barbecue dinner followed by a bonfire. This relaxed setting provides an excellent opportunity for team members to socialize, unwind, and build stronger connections outside the office environment."
  },
  {
    "title": "Interactive Workshops",
    "description": "Organize interactive workshops that focus on skill-building, problem-solving, or leadership development. These workshops can be customized to meet your team's specific needs, combining learning with fun outdoor activities."
  },
  {
    "title": "Farm-to-Table Meals",
    "description": "Enjoy farm-fresh meals prepared with organic ingredients grown on-site. The resort offers a variety of local and traditional Maharashtrian dishes, ensuring a delicious and authentic dining experience for your team."
  }
],

    "services": [
  {
    "name": "One Day Resort in Mumbai for Couples",
    "description": "For a one day resort in Mumbai for couples, Shinde Agri Tourism is an ideal choice. Located just outside Mumbai, this agro-tourism farm offers a tranquil environment perfect for couples looking to spend quality time together. Activities like nature walks, bullock cart rides, and exploring the farm make it a peaceful and unique getaway for couples seeking a one-day escape from the city."
  },
  {
    "name": "Pet Friendly Resorts in Mumbai",
    "description": "Shinde Agri Tourism is a great pet-friendly resort near Mumbai. With its vast open spaces and rural setting, pets are often allowed to accompany their owners, making it a perfect place for a family outing with furry friends. Please confirm pet policies directly with the farm for the most accurate information."
  },
  {
    "name": "Resorts in Mumbai for One Day Picnic with Rooms",
    "description": "If you’re looking for resorts in Mumbai for one day picnic with rooms, Shinde Agri Tourism offers overnight stays, allowing you to enjoy a one-day outing while having comfortable accommodation. The farm provides a peaceful environment for a day trip or a short overnight stay."
  },
  {
    "name": "Resorts with Adventure Activities Near Mumbai",
    "description": "For resorts with adventure activities near Mumbai, Shinde Agri Tourism is a wonderful choice. The farm offers a range of activities, including nature walks, bullock cart rides, and farm visits, making it an adventure-packed destination for nature lovers. While it may not have extreme adventure sports, the experience of rural life and farm activities is a refreshing adventure on its own."
  },
  {
    "name": "One Day Resort in Mumbai",
    "description": "Shinde Agri Tourism is a great option for a one day resort in Mumbai. It provides an educational and fun-filled day trip experience with activities like organic farming workshops, bullock cart rides, and nature trails. It’s perfect for a quick getaway to experience nature, away from the hustle of the city."
  },
  {
    "name": "Best Budget Resorts Near Mumbai for Couples",
    "description": "For a best budget resort near Mumbai for couples, Shinde Agri Tourism offers an affordable yet enriching experience. Couples can enjoy a serene day surrounded by nature, partake in farm activities, and relax without breaking the bank. It’s an ideal choice for those looking to spend quality time in a natural setting on a budget."
  },
  {
    "name": "Budget-Friendly Resorts Near Mumbai",
    "description": "Shinde Agri Tourism is one of the best budget-friendly resorts near Mumbai. Offering a variety of agro-tourism experiences such as farm tours and nature walks, it provides a fulfilling experience at an affordable price, making it perfect for families, couples, or groups on a budget."
  },
  {
    "name": "Best Resort for Couples in Mumbai",
    "description": "While Shinde Agri Tourism is not a typical luxury resort, it is one of the best resorts for couples in Mumbai if you’re looking for a quiet, nature-filled escape. Couples can enjoy peaceful walks, farm visits, and the beauty of the countryside—an excellent option for those looking to connect with nature in a serene environment."
  },
  {
    "name": "Top Resorts Near Mumbai",
    "description": "For top resorts near Mumbai, Shinde Agri Tourism provides a top-quality agro-tourism experience. Located just outside Mumbai, it offers a variety of activities like farm tours, nature walks, and organic farming experiences. It’s an excellent choice for those looking for something unique and immersive in nature."
  },
  {
    "name": "Family Resorts Near Mumbai for Overnight Stay",
    "description": "For family resorts near Mumbai for overnight stay, Shinde Agri Tourism offers overnight stays where families can enjoy farm-based activities, nature walks, and a relaxed environment. The farm is perfect for families looking to disconnect from the city and enjoy quality time together in nature."
  },
  {
    "name": "Couple-Friendly Resorts in Mumbai",
    "description": "Shinde Agri Tourism is a couple-friendly resort in Mumbai. The peaceful rural setting makes it an ideal getaway for couples seeking quiet time, nature walks, and farm visits. The serene environment and activities like bullock cart rides and farm tours make it a wonderful destination for couples."
  },
  {
    "name": "Best Resorts Around Mumbai",
    "description": "For the best resorts around Mumbai, Shinde Agri Tourism offers a unique agro-tourism experience. Located just a short drive away from the city, it’s the perfect place for families, couples, or friends looking to relax and enjoy nature. Activities like farm tours, nature walks, and organic farming workshops make it a must-visit."
  },
  {
    "name": "Mumbai Resort Hotel",
    "description": "If you’re looking for a Mumbai resort hotel, Shinde Agri Tourism provides an excellent alternative with its rural charm and agro-tourism activities. While not a traditional hotel, it offers comfortable accommodations and a range of enriching activities, making it a perfect place for a peaceful retreat."
  },
  {
    "name": "Team Outing Places Near Mumbai",
    "description": "For team outing places near Mumbai, Shinde Agri Tourism is an excellent choice. The farm offers team-building activities like farm visits, nature walks, and bullock cart rides, making it a great place for corporate outings or group activities."
  },
  {
    "name": "Corporate Outing Near Mumbai",
    "description": "Shinde Agri Tourism is ideal for a corporate outing near Mumbai. With a variety of outdoor activities and nature-based experiences, it provides a refreshing change from traditional corporate events. Activities like farm tours, organic farming workshops, and nature walks can be combined with team-building exercises for a productive and fun outing."
  },
  {
    "name": "Best Places for Corporate Team Outing in Mumbai",
    "description": "For best places for corporate team outing in Mumbai, Shinde Agri Tourism offers a unique experience. The farm offers a variety of team-building activities, along with nature walks and farm tours, making it a great choice for a productive and relaxing outing."
  },
  {
    "name": "Places for Team Outing in Mumbai",
    "description": "If you're looking for places for team outing in Mumbai, Shinde Agri Tourism is an excellent option. With activities such as farm visits, bullock cart rides, and nature trails, it’s perfect for corporate groups, offering a mix of relaxation and team-building activities."
  },
  {
    "name": "Resorts Near Mumbai for Team Outing",
    "description": "For resorts near Mumbai for team outing, Shinde Agri Tourism is a great choice. It offers a rural setting with a range of activities suitable for team-building and group activities. The combination of fun outdoor activities and peaceful surroundings makes it an ideal place for corporate groups."
  },
  {
    "name": "Team Outing Near Mumbai",
    "description": "Shinde Agri Tourism provides an ideal destination for team outing near Mumbai. The farm offers outdoor activities such as farm tours, bullock cart rides, and nature walks, making it a unique and refreshing location for corporate or team outings."
  },
  {
    "name": "Shinde Agri Tourism Team Outing Places Near Mumbai Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Team Outing Places Near Mumbai. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- One Day Resort in Mumbai for Couples", "- Pet Friendly Resorts in Mumbai"],
    ["- Resorts in Mumbai for One Day Picnic with Rooms", "- Resorts with Adventure Activities Near Mumbai"],
    ["- One Day Resort in Mumbai", "- Best Budget Resorts Near Mumbai for Couples"],
    ["- Budget Friendly Resorts Near Mumbai", "- Best Resort for Couples in Mumbai"],
    ["- Top Resorts Near Mumbai", "- Family Resorts Near Mumbai for Overnight Stay"],
    ["- Couple Friendly Resorts in Mumbai", "- Best Resorts Around Mumbai"],
    ["- Mumbai Resort Hotel", "- Team Outing Places Near Mumbai"],
    ["- Corporate Outing Near Mumbai", "- Best Places for Corporate Team Outing in Mumbai"],
    ["- Places for Team Outing in Mumbai", "- Resorts Near Mumbai for Team Outing"],
    ["- Team Outing Near Mumbai", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Ideal Location Close to Mumbai",
      "WhyChoosedescription": "Shinde Agri Tourism is located just 100 km from Mumbai, approximately 2 to 2.5 hours by car, making it the perfect destination for a quick yet refreshing team outing without the hassle of long travel. It’s the ideal spot to break free from the office and immerse in nature."
    },
    {
      "WhyChooseheading": "Customized Team-Building Activities",
      "WhyChoosedescription": "We offer a variety of team-building activities designed to improve communication, collaboration, and leadership. Whether it’s problem-solving challenges, trust-building exercises, or group challenges, our activities encourage teamwork and help foster stronger relationships among colleagues. We can tailor the program based on your company’s specific goals and needs."
    },
    {
      "WhyChooseheading": "Exciting Outdoor Adventure Activities",
      "WhyChoosedescription": "For teams seeking adventure, we offer thrilling outdoor activities such as zip-lining, rock climbing, archery, and nature walks. These fun and engaging activities not only provide a break from the usual office routine but also help enhance teamwork, resilience, and leadership in a challenging environment."
    },
    {
      "WhyChooseheading": "Relax in a Scenic, Peaceful Setting",
      "WhyChoosedescription": "After a day of activities, unwind in the peaceful surroundings of our resort. The serene environment, surrounded by lush greenery and farms, creates the perfect backdrop for relaxation and reflection. Enjoy a calm evening with your team, debrief on the day’s events, and bond over a delicious meal."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining Experience",
      "WhyChoosedescription": "One of the highlights of your team outing will be our farm-to-table dining experience. Enjoy freshly prepared meals made from organic ingredients grown on our farm. Our menu includes healthy and nutritious options like traditional Maharashtrian dishes, salads, grilled snacks, and fresh juices, ensuring your team is well-fed and energized throughout the day."
    },
    {
      "WhyChooseheading": "Group-Friendly Accommodation",
      "WhyChoosedescription": "If you're planning an overnight stay, we offer comfortable, eco-friendly accommodation for your team. Choose from cozy cottages or tented stays, all equipped with modern amenities. Our accommodations are designed to provide your team with a restful environment, ensuring everyone is well-rested for the next day’s activities."
    },
    {
      "WhyChooseheading": "Perfect for Corporate Retreats and Workshops",
      "WhyChoosedescription": "If you’re looking to combine relaxation with learning, we also offer customized corporate retreat packages that include leadership workshops, communication skills training, and strategic planning sessions. Our tranquil setting provides the perfect space for brainstorming, reflection, and growth."
    },
    {
      "WhyChooseheading": "Flexible Packages for Different Group Sizes",
      "WhyChoosedescription": "Whether you’re a small team or a large corporate group, we offer flexible packages tailored to your needs. Our team outing packages include a variety of activities that can be customized based on your group size, interests, and budget."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Mumbai?",
      answer: "Shinde Agri Tourism is located about 100 km from Mumbai, approximately 2 to 2.5 hours by car. It’s an easy and quick destination for a team outing, perfect for those looking to escape the city without long travel times."
    },
    {
      question: "What team-building activities are offered at the resort?",
      answer: "We offer a wide range of team-building activities including problem-solving challenges, trust-building exercises, group games, leadership workshops, and outdoor activities like zip-lining, archery, and rock climbing. We can customize the activities to suit your team’s needs."
    },
    {
      question: "Is food included in the team outing package?",
      answer: "Yes, our farm-to-table dining experience is included. We serve fresh, healthy meals made from organic ingredients grown on our farm. Our menu features traditional Maharashtrian dishes, salads, fresh juices, and other nutritious options to keep your team energized throughout the day."
    },
    {
      question: "Can we arrange for overnight accommodation for our team?",
      answer: "Yes! We offer comfortable eco-friendly cottages and tented stays for teams looking to stay overnight. Our accommodations are designed for comfort and relaxation, and we provide modern amenities to ensure your team has a restful stay."
    },
    {
      question: "Is the resort suitable for large corporate groups?",
      answer: "Absolutely! We cater to both small and large corporate groups. We offer flexible packages that can be tailored to the size of your group and the activities you prefer. Whether you're a small team or a large corporate retreat, we can accommodate your needs."
    },
    {
      question: "What type of outdoor activities are available for teams?",
      answer: "We offer a variety of outdoor activities such as zip-lining, rock climbing, archery, nature walks, and outdoor games like cricket and volleyball. These activities are designed to promote teamwork and collaboration while providing an exciting and adventurous experience."
    },
    {
      question: "Can the team outing be customized for specific objectives or goals?",
      answer: "Yes! We can tailor the team outing experience based on your company’s specific objectives, whether it’s leadership development, communication skills, strategic planning, or team collaboration. Just let us know your goals, and we’ll create a customized package that meets your needs."
    },
    {
      question: "How do I book a team outing at Shinde Agri Tourism?",
      answer: "Booking is easy! Simply contact us through our website or by phone, and we’ll help customize your team outing experience. Let us know your group size, preferred activities, and any specific requirements, and we’ll create a personalized package for you."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Minal, Marketing Head, Mumbai",
      text: "Our team outing at Shinde Agri Tourism was an unforgettable experience! The team-building activities were engaging and fun, and we could see an improvement in communication and collaboration right away. The food was delicious, and the peaceful surroundings made it the perfect escape from the office."
    },
    {
      name: "Mr. Rajesh, Senior Manager, Mumbai",
      text: "We had a fantastic time during our corporate retreat at Shinde Agri Tourism. The outdoor activities were exciting, and the team-building challenges helped strengthen our bond as a team. The farm-to-table meals were healthy and fresh, and the whole experience was revitalizing. Highly recommended for any corporate group looking to unwind and grow together!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Team Outing Places Near Mumbai | Best Resorts for Corporate & Team Outings | Family & Couples Resorts | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for the best team outing places near Mumbai? Explore top resorts for corporate team outings, family getaways, and couples. Book one-day resorts with adventure activities, pet-friendly options, and budget-friendly stays." />
  <meta name="keywords" content="Team Outing Places Near Mumbai, Resorts Near Mumbai for Team Outing, Corporate Outing Near Mumbai, Best Places for Corporate Team Outing in Mumbai, Places for Team Outing in Mumbai, Resorts Near Mumbai for Corporate Team Outing, One Day Resort in Mumbai for Couples, Pet Friendly Resorts in Mumbai, Resorts in Mumbai for One Day Picnic with Rooms, Resorts with Adventure Activities Near Mumbai, Best Budget Resorts Near Mumbai for Couples, Budget Friendly Resorts Near Mumbai, Best Resort for Couples in Mumbai, Top Resorts Near Mumbai, Family Resorts Near Mumbai for Overnight Stay, Couple Friendly Resorts in Mumbai, Best Resorts Around Mumbai, Mumbai Resort Hotel" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/team-outing-places-near-mumbai" />

  {/* JSON-LD Schema for Team Outing Places Near Mumbai */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Team Outing Places Near Mumbai",
        "url": "https://shindekrushiparyatan.com/team-outing-places-near-mumbai",
        "description": "Explore the best team outing places near Mumbai with resorts offering adventure activities, budget-friendly stays, and options for corporate, family, and couples. Find perfect one-day picnic spots and overnight stays near Mumbai.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Mumbai",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "19.0760",
          "longitude": "72.8777"
        },
        "image": "https://shindekrushiparyatan.com/images/team-outing-places-near-mumbai.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/36.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Teamouting;