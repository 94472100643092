import React from 'react';

// Sample data for the trending packages (You can modify or fetch this data dynamically)
const tourPackages = [
  {
    id: 1,
    country: 'Play Ground',
    location: 'Adventure Activities',
    days: '1 Days Tours',
    // rating: 5,
    price: '799 rs',
    image: '/images/gallery/2.jpg',
    reviews: 12,
    paraghaph:'Challenge yourself on our Adventure Rope Course! Test your strength, balance, and agility with rope bridges, tire paths, climbing towers, and balance beams—exciting fun for all ages.',
  },
  {
    id: 2,
    country: 'Swimming Pool',
    location: 'Swimming Pool',
    days: '1 Days Tours',
    rating: 5,
    price: '799 rs',
    image: '/images/gallery/3.jpg',
    reviews: 38,
    paraghaph: 'Take a refreshing dip in our scenic swimming pool at Shinde Agri Tourism! Surrounded by greenery, it is the perfect place to relax, cool off, and enjoy nature with family and friends. Dive in for a rejuvenating experience'
  },
  {
    id: 3,
    country: 'cottages',
    location: 'cottages for stay',
    days: '1 Days Tours',
    rating: 5,
    price: '799 rs',
    image: '/images/gallery/4.jpg',
    reviews: 18,
    paraghaph: 'Enjoy the charm of our cottages, blending rustic appeal with modern comfort. A peaceful escape in nature, ideal for families, couples, and solo travelers. Unwind in a serene and memorable setting!',
  },
];

const TrendingPackages = () => {
  return (
    <section className="trending p-0">
      <div className="container">
        <div className="section-title text-center w-75 mx-auto mb-6">
          <h4 className="mb-1 theme1">Top Pick</h4>
          <h2 className="mb-1">
            Best <span className="theme">Tour Packages</span>
          </h2>

        </div>
        <div className="trend-box">
          <div className="row">
            {tourPackages.map((pkg) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mb-4 " style={{border:'1px solid green'}} key={pkg.id}>
                <div className="trend-item rounded box-shadow">
                  <div className="trend-image position-relative">
                    <img src={pkg.image} alt={pkg.location} className="" />
                    <div className="color-overlay"></div>
                  </div>
                  <div className="trend-content p-4 pt-5 position-relative">
                    <div className="trend-meta bg-theme white px-3 py-2 rounded">
                      <div className="entry-author">
                        <i className="icon-calendar"></i>
                        <span className="fw-bold"> {pkg.days}</span>
                      </div>
                    </div>
                    <h5 className="theme mb-1">
                      <i className="flaticon-location-pin"></i> {pkg.country}
                    </h5>
                    <h3 className="mb-1">
                    </h3>
                    <div className="rating-main d-flex align-items-center pb-2">
                    </div>
                    <p className="border-b pb-2 mb-2">
                      {pkg.paraghaph}
                    </p>
                    <div className="entry-meta">
                      <div className="entry-author d-flex align-items-center">
                        <p className="mb-0">
                          <span className="theme fw-bold fs-5"> {pkg.price}</span> | Per person
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingPackages;
