import React from 'react';

const Trending = () => {
  return (
    <section
      className="trending pb-0 pt-6"
      style={{ backgroundImage: 'url(images/shape-1.png)', backgroundPosition: 'center' }}
    >
      <div className="container">
        <div className="section-title mb-6 w-50 mx-auto text-center">
          <h4 className="mb-1 theme1">Top Activities</h4>
          <h2 className="mb-1">
            Explore <span className="theme">Top Activities</span>
          </h2>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 mb-4">
            <div className="trend-item1">
              <div className="trend-image position-relative rounded">
                <img src="/images/slider/1.jpg" alt="image" />
                <div className="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                  <div className="trend-content-title">
                    <h5 className="mb-0">
                      {/* <a href="tour-grid.html" className="theme1">England</a> */}
                    </h5>
                    {/* <h3 className="mb-0 white">London</h3> */}
                  </div>
                  {/* <span className="white bg-theme p-1 px-2 rounded">15 Tours</span> */}
                </div>
                <div className="color-overlay"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              {[
                { country: 'Italy', city: 'Caspian Valley', tours: 18, image: '2.jpg' },
                { country: 'Tokyo', city: 'Japan', tours: 21, image: '3.jpg' },
                { country: 'Moscow', city: 'Russia', tours: 15, image: '4.jpg' },
                { country: 'Florida', city: 'America', tours: 32, image: '5.jpg' },
              ].map((destination, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div className="trend-item1">
                    <div className="trend-image position-relative rounded">
                      <img
                        src={`/images/slider/${destination.image}`}
                        alt={`${destination.city} Image`}
                      />
                      <div className="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                        <div className="trend-content-title">
                          <h5 className="mb-0">
                            {/* <a href="" className="theme1">{destination.country}</a> */}
                          </h5>
                          {/* <h3 className="mb-0 white">{destination.city}</h3> */}
                        </div>
                        {/* <span className="white bg-theme p-1 px-2 rounded">{destination.tours} Tours</span> */}
                      </div>
                      <div className="color-overlay"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trending;
