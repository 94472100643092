
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Mangomahotsav() {



  const cardData =
  {
    keyword: ' Mango Mahotsav in Pune',
    heading:  'Mango Mahotsav in Pune: Shinde Agri Tourism',
    headingDescription: 'Shinde Agri Tourism is the perfect destination for those looking to celebrate the vibrant Mango Mahotsav near Pune. Situated amidst nature’s beauty, this agro-tourism spot offers an ideal setting to enjoy the king of fruits during the mango season. Whether you are a mango enthusiast, a family looking for a fun-filled day, or a group eager to explore the traditions of mango cultivation, Shinde Agri Tourism offers an unforgettable Mango Mahotsav experience.',

    top: 'What to Expect at Shinde Agri Tourism’s Mango Mahotsav	',
   "topPlaces": [
  {
    "title": "Mango Tasting & Variety Exploration",
    "description": "At Shinde Agri Tourism, the Mango Mahotsav offers a wide range of mango varieties, including the famous Alphonso (Hapus), Kesar, Langra, and more. Visitors can indulge in a mango-tasting experience and savor the fresh, succulent flavors of different mango varieties grown locally in the region."
  },
  {
    "title": "Mango-based Culinary Delights",
    "description": "The Mango Mahotsav at Shinde Agri Tourism isn’t just about tasting mangoes – it's also about experiencing mango-inspired dishes. From refreshing mango lassi to mouth-watering mango desserts and pickles, guests can enjoy an array of mango-based treats made from freshly harvested fruits."
  },
  {
    "title": "Interactive Mango Farming Experience",
    "description": "One of the highlights of the Mango Mahotsav at Shinde Agri Tourism is the interactive experience of learning about mango farming. Guests can visit the farm, learn about mango cultivation, see the harvesting process in action, and even participate in activities like picking mangoes or making traditional mango products."
  },
  {
    "title": "Workshops and Live Demonstrations",
    "description": "Shinde Agri Tourism hosts workshops and live demonstrations on mango farming and sustainable agricultural practices. Experts and local farmers share their knowledge about the best techniques for cultivating mangoes, as well as tips on improving the quality of mango harvests."
  },
  {
    "title": "Cultural Performances and Entertainment",
    "description": "The Mango Mahotsav at Shinde Agri Tourism is not just a celebration of mangoes but also of the rich culture of the region. Enjoy traditional music, dance performances, and folk art that add a festive touch to the event, making it a cultural celebration of Maharashtra’s agricultural traditions."
  },
  {
    "title": "Mango Pickle and Product Making Workshops",
    "description": "For those who want to take home a piece of the festival, Shinde Agri Tourism offers workshops on making mango pickles, jams, and other mango-based products. These workshops provide hands-on experience, allowing participants to create their own mango preserves, which they can take home as souvenirs."
  },
  {
    "title": "Picnic Areas and Outdoor Fun",
    "description": "Shinde Agri Tourism offers lush, green picnic areas where families and friends can relax, enjoy the festivities, and indulge in homemade mango treats. The peaceful, scenic surroundings make it an ideal spot for enjoying a day out in nature while celebrating the Mango Mahotsav."
  },
  {
    "title": "Mango Games and Contests",
    "description": "Engage in fun mango-themed games and competitions at Shinde Agri Tourism’s Mango Mahotsav. From mango-eating contests to mango trivia and arts and crafts activities, there’s something for everyone to enjoy."
  }
],

   "services": [
  {
    "name": "Amba Mahotsav at Om Agri Tourism",
    "description": "The Amba Mahotsav at Om Agri Tourism is a celebration dedicated to the king of fruits — the mango! Held annually during the peak mango season, this event allows guests to indulge in the delicious, fresh, and aromatic aamras (mango pulp). Located in the tranquil setting of Om Agri Tourism near Pune, the Amba Mahotsav offers a perfect blend of agro-tourism and cultural festivities, making it a must-visit for mango lovers. Visitors can savor freshly made aamras, enjoy local mango varieties, and experience the rustic charm of agro-tourism."
  },
  {
    "name": "Amba Mahotsav in Agri Tourism Pune",
    "description": "Celebrate the Amba Mahotsav in Agri Tourism Pune at Om Agri Tourism, where the joy of mangoes meets the beauty of rural life. The festival brings together a variety of mango-based experiences, from aamras tasting to mango picking. Enjoy the scenic views of the farm while learning about organic farming practices, and take part in hands-on activities. It's an ideal way to enjoy the mango season with your family and friends."
  },
  {
    "name": "Aamras Party Near Pune",
    "description": "If you're looking for an Aamras party near Pune, Om Agri Tourism in Koyali is the perfect destination. This agro-tourism spot hosts special events centered around mangoes, including an Aamras party where guests can enjoy fresh mango pulp, seasonal fruit picking, and more. The tranquil rural atmosphere combined with delicious food and drinks makes for an unforgettable experience during the mango season."
  },
  {
    "name": "Best Place to Enjoy Aamras Party Near Pune",
    "description": "The best place to enjoy an Aamras party near Pune is at Om Agri Tourism in Koyali. The farm hosts the famous Mango Mahotsav, offering a variety of mangoes and aamras dishes, along with the opportunity to explore the farm, interact with nature, and take part in various activities. Whether it's the rich taste of fresh mango pulp or the charming rural landscape, it’s the ultimate spot for mango enthusiasts."
  },
  {
    "name": "Aamras Party / Mango Mahotsav at Om Agri Tourism",
    "description": "The Aamras Party / Mango Mahotsav at Om Agri Tourism is a lively event that celebrates the bountiful mango harvest. Set in the picturesque village of Koyali, just outside Pune, this annual festival is a favorite for families, couples, and groups looking to indulge in mango-inspired treats and enjoy agro-tourism experiences. Savor the finest aamras, participate in mango-related activities, and relax amidst nature at this one-of-a-kind festival."
  },
  {
    "name": "Aamras Party Mango Mahotsav at Om Agri Tourism Koyali",
    "description": "Join the fun at the Aamras Party Mango Mahotsav at Om Agri Tourism Koyali. This event celebrates the mango season with a variety of fun-filled activities, including aamras tasting, mango picking, and more. Located in the scenic Koyali region, the festival gives you a chance to immerse yourself in nature, enjoy a fresh, fruity feast, and create lasting memories with friends and family. It’s the ideal destination for those looking to celebrate mangoes in a unique agro-tourism setting."
  },
  {
    "name": "Agri Tourism in Koyali",
    "description": "Agri Tourism in Koyali offers a refreshing escape from the city. At Om Agri Tourism, visitors can experience the charm of rural life through farm tours, organic farming activities, and exciting festivals like the Amba Mahotsav. Koyali is home to lush greenery and rich agricultural traditions, making it an excellent spot for agro-tourism. Whether you're interested in farming practices or enjoying seasonal fruit festivals, Koyali offers an authentic agro-tourism experience."
  },
  {
    "name": "Agro Tourism Chakan",
    "description": "Located near Pune, Agro Tourism Chakan is another beautiful spot for those looking to experience rural life and organic farming practices. The region offers a variety of agro-tourism experiences, including farm visits, educational workshops, and seasonal celebrations like Amba Mahotsav. While Om Agri Tourism in Koyali hosts the famous Mango Mahotsav, nearby areas such as Chakan also offer plenty of opportunities to explore agriculture and nature in a peaceful environment."
  },
  {
    "name": "Shinde Agri Tourism Mango Mahotsav in Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for Mango Mahotsav in Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],

tableData: [
    ["- Amba Mahotsav at Om Agri Tourism", "- Amba Mahotsav in Agri Tourism Pune"],
    ["- Aamras Party Near Pune", "- Best Place to Enjoy Aamras Party Near Pune"],
    ["- Aamras Party / Mango Mahotsav at Om Agri Tourism", "- Aamras Party Mango Mahotsav at Om Agri Tourism Koyali"],
    ["- Agri Tourism in Koyali", "- Agro Tourism Chakan"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Celebrate Mangoes in All Their Glory",
      "WhyChoosedescription": "At Shinde Agri Tourism, our Mango Mahotsav celebrates everything mangoes have to offer. From tasting different varieties to participating in mango-themed activities, you’ll get to enjoy the sweet, tangy, and rich flavors of India’s favorite fruit in a fun-filled atmosphere."
    },
    {
      "WhyChooseheading": "Taste Exotic Mango Varieties",
      "WhyChoosedescription": "Experience a wide variety of mangoes, including local favorites like Alphonso, Kesar, Langra, and Himsagar. Each variety offers a unique flavor, making it the perfect mango-tasting experience for enthusiasts and connoisseurs alike."
    },
    {
      "WhyChooseheading": "Mango-Themed Activities",
      "WhyChoosedescription": "Engage in exciting mango-themed activities such as mango picking, learning how to prepare mango dishes (like mango lassi and pickles), and participating in fun competitions like mango-eating contests and creative mango art workshops."
    },
    {
      "WhyChooseheading": "A Fun and Educational Experience",
      "WhyChoosedescription": "Learn about the different mango varieties, their cultivation, and sustainable farming practices while you enjoy farm tours and other educational experiences. Discover how mangoes are grown and harvested, making it both a fun and informative experience."
    },
    {
      "WhyChooseheading": "Family-Friendly Fun",
      "WhyChoosedescription": "The Mango Mahotsav is a family-friendly event, perfect for all ages. Children can enjoy mango-related games, while adults can indulge in mango-inspired dishes and take part in educational workshops. It’s a great way to bond with loved ones over the King of Fruits!"
    },
    {
      "WhyChooseheading": "Mango-Inspired Cuisine",
      "WhyChoosedescription": "Relish a special mango-inspired menu featuring everything from savory chutneys and curries to sweet mango desserts, ice creams, and drinks. Whether you love your mangoes sweet or savory, we’ve got something to excite your taste buds."
    },
    {
      "WhyChooseheading": "Relax and Unwind in Nature",
      "WhyChoosedescription": "While enjoying the Mango Mahotsav, take a moment to unwind and relax amidst the lush mango orchards and serene surroundings. The tranquil environment of Shinde Agri Tourism provides the perfect backdrop to enjoy the festivities at a leisurely pace."
    },
    {
      "WhyChooseheading": "Convenient Location from Pune",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is a short 2 to 2.5-hour drive, making it an easy and accessible getaway for a day trip or weekend retreat. Celebrate the mango season and enjoy nature without the long commute."
    }
  ]
    



  }

  const faqData = [
    {
      question: "When is the Mango Mahotsav held?",
      answer: "The Mango Mahotsav at Shinde Agri Tourism typically takes place during the mango season, from late April to June. The exact dates are announced each year based on the mango harvest."
    },
    {
      question: "Do I need to book in advance for the Mango Mahotsav?",
      answer: "Yes, we recommend booking your spot in advance, especially during peak mango season, to ensure availability. You can easily book your tickets online or by contacting our customer service team."
    },
    {
      question: "What kind of mango varieties will be available at the Mango Mahotsav?",
      answer: "Our Mango Mahotsav features a wide variety of mangoes, including famous varieties like Alphonso, Kesar, Langra, Himsagar, Totapuri, and more. You’ll get to taste the different flavors and textures of these regional mangoes."
    },
    {
      question: "Can we participate in mango-related activities?",
      answer: "Absolutely! The Mango Mahotsav includes a range of activities such as mango-picking, mango-eating contests, mango cooking workshops, mango art, and mango quizzes. It’s a fun and interactive way to celebrate the mango season."
    },
    {
      question: "Is the Mango Mahotsav suitable for children?",
      answer: "Yes, the Mango Mahotsav is a family-friendly event. Kids can enjoy activities like mango-related games, learning to make mango dishes, and tasting different mango varieties. It’s a perfect outing for families."
    },
    {
      question: "Are food and drinks provided during the Mango Mahotsav?",
      answer: "Yes! A special mango-themed menu will be available throughout the event, featuring delicious mango-based dishes like mango salads, mango lassi, mango desserts, and much more. You can indulge in a variety of sweet and savory mango delights."
    },
    {
      question: "Is the Mango Mahotsav held every year?",
      answer: "Yes, Shinde Agri Tourism holds the Mango Mahotsav annually, celebrating the mango harvest season. Check our website or social media pages for updates on the next Mango Mahotsav."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Siddhi & Family",
      text: "The Mango Mahotsav at Shinde Agri Tourism was a fantastic experience! We had the chance to taste so many varieties of mangoes and participate in fun activities like mango picking and the mango-eating contest. The kids loved it, and the food was absolutely delicious!"
    },
    {
      name: "Mr. Ravi & Friends",
      text: "We had an amazing time at the Mango Mahotsav! The mango varieties were delicious, and the activities were a lot of fun. The mango-themed dishes were a highlight, especially the mango lassi and mango desserts. A perfect way to celebrate the mango season!"
    }
  ];
  





  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Mango Mahotsav in Pune | Aamras Party, Amba Mahotsav at Om Agri Tourism | Enjoy Aamras Party Near Pune | Om Agri Tourism</title>
  <meta name="description" content="Celebrate Mango Mahotsav in Pune at Om Agri Tourism. Enjoy Aamras Party, Amba Mahotsav, and Agro Tourism in Koyali. Book your spot for a fun-filled day with family and friends this mango season!" />
  <meta name="keywords" content="Mango Mahotsav in Pune, Aamras Party near Pune, Best place for Aamras Party near Pune, Amba Mahotsav at Om Agri Tourism, Aamras Party Mango Mahotsav at Om Agri Tourism Koyali, Agri Tourism in Koyali, Agro Tourism Chakan, Mango Festival near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/mango-mahotsav-in-pune" />

  {/* JSON-LD Schema for Mango Mahotsav */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Mango Mahotsav in Pune",
        "url": "https://shindekrushiparyatan.com/mango-mahotsav-in-pune",
        "description": "Join us at Om Agri Tourism for Mango Mahotsav and Aamras Party near Pune. Enjoy fresh mangoes, celebrate Amba Mahotsav, and explore Agro Tourism in Koyali.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Koyali, Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/mango-mahotsav-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/27.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Mangomahotsav;