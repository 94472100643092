
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function AgriTourism() {



  const cardData =
  {
    keyword: ' Agri Tourism Near Pune',
    heading:  'Agri Tourism Near Pune: Shinde Agri Tourism',
    headingDescription: 'For those seeking an authentic rural experience near Pune, Shinde Agri Tourism offers a delightful escape into nature and traditional farming. This serene agri-tourism spot provides a unique opportunity to experience village life, enjoy outdoor activities, and relax in a peaceful environment—perfect for families, groups, or anyone looking to connect with nature.',

    top: 'Top Experiences at Shinde Agri Tourism	',
    topPlaces: [
        {
          "title": "Pre-Wedding Photoshoots in a Rustic Setting",
          "description": "Capture unforgettable moments with scenic backdrops at Shinde Agri Tourism. The picturesque green fields and traditional farmhouses make it an ideal location for pre-wedding photoshoots, adding a natural, rustic charm to your memories."
        },
        {
          "title": "Birthday Celebrations in a Natural Setting",
          "description": "Enjoy a memorable birthday celebration surrounded by the beauty of nature. Shinde Agri Tourism offers spacious, open areas and fresh air, providing a fantastic venue for family gatherings and birthday parties, complete with delicious homemade food and outdoor games."
        },
        {
          "title": "Hands-On Farming Activities",
          "description": "Dive into the world of traditional farming with interactive experiences like planting, harvesting, and animal care. This hands-on activity is both fun and educational, offering a great way for families to bond while learning about the rural lifestyle."
        },
        {
          "title": "Peaceful Nature Walks and Bird Watching",
          "description": "Take a leisurely stroll along nature trails or enjoy bird watching. With its lush landscapes and variety of bird species, Shinde Agri Tourism is perfect for those looking to relax and soak in the beauty of the natural surroundings."
        },
        {
          "title": "Outdoor Games and Picnic Areas for Family Fun",
          "description": "Whether you’re interested in outdoor games like cricket, volleyball, or simply relaxing in picnic areas, Shinde Agri Tourism provides plenty of space for everyone to enjoy. The family-friendly environment ensures that all ages have a wonderful time."
        }
      ],

"services": [
  {
    "name": "Agriculture Tourism Near Pune",
    "description": "For those interested in experiencing the rural side of Pune, Shinde Agritourism offers a great introduction to agriculture tourism. Situated just outside the city, it allows visitors to get hands-on experience with organic farming practices. Whether you’re looking to learn about sustainable agriculture, participate in farming activities, or simply enjoy the beauty of the countryside, Shinde Agritourism provides an immersive and educational experience for all ages."
  },
  {
    "name": "Agri Tourism Spots Near Pune",
    "description": "If you're looking for unique agri tourism spots near Pune, Shinde Agritourism is a fantastic choice. The farm offers a variety of activities for visitors to explore rural life, including farm tours, animal feeding, organic farming demonstrations, and fun activities like bullock cart rides. It’s a perfect destination to unwind, learn about agriculture, and spend quality time with family and friends, all while being close to Pune."
  },
  {
    "name": "Agritourism Resorts Near Pune",
    "description": "Shinde Agritourism is one of the best agritourism resorts near Pune, offering a unique rural getaway. While it’s not a conventional resort with luxury amenities, it provides a peaceful countryside retreat where guests can enjoy organic farming, animal interactions, and outdoor activities. It’s perfect for those looking to relax and connect with nature, all within a short distance from Pune."
  },
  {
    "name": "Best Agri Tourism Near Pune",
    "description": "When it comes to the best agri tourism near Pune, Shinde Agritourism stands out for its blend of education, fun, and relaxation. With a focus on organic farming, visitors can learn about sustainable agricultural practices, participate in hands-on activities, and enjoy the serene environment. Whether you’re visiting with family, friends, or as a corporate group, this agritourism destination offers a peaceful, enriching experience."
  },
  {
    "name": "Agro Tourism Near Pimpri Chinchwad",
    "description": "Located just a short drive from Pimpri Chinchwad, Shinde Agritourism is a great option for those looking to explore agro tourism near the area. Offering activities like farm tours, bullock cart rides, and animal feeding, the farm provides a fantastic rural escape where you can learn about agriculture and enjoy nature. It’s perfect for a day trip with family or friends, or even a team-building outing."
  },
  {
    "name": "Agro Tourism Near Hinjewadi",
    "description": "If you’re based near Hinjewadi and looking for a peaceful agro tourism experience, Shinde Agritourism is only a short drive away. The farm offers a wide range of activities such as organic farming demonstrations, traditional rural games, and animal interactions, making it an ideal choice for a relaxing getaway. The peaceful surroundings also make it an excellent destination for corporate groups or team-building activities."
  },
  {
    "name": "Agro Tourism Near Kharadi",
    "description": "For those near Kharadi, Shinde Agritourism offers a refreshing break from the city life. Situated close to Kharadi, this agritourism destination provides a unique rural experience where visitors can learn about organic farming, feed farm animals, and engage in outdoor games. It’s a great spot for families, groups, or even corporate outings looking to connect with nature and experience rural life firsthand."
  },
  {
    "name": "Agro Tourism Near Hadapsar",
    "description": "If you are located near Hadapsar, Shinde Agritourism is an ideal destination for agro tourism. The farm offers a blend of educational activities related to farming, along with opportunities to unwind and relax in nature. Visitors can engage in farming activities, learn about sustainable agricultural practices, and participate in traditional games, all while enjoying the natural beauty of the farm."
  },
  {
    "name": "Agro Tourism Near Pune with Swimming Pool",
    "description": "While Shinde Agritourism offers a tranquil, nature-based experience, for those specifically seeking agro tourism near Pune with swimming pool, some other resorts might cater to those preferences. However, Shinde Agritourism provides an immersive agro tourism experience with activities such as farm tours, nature walks, and educational farming activities, making it an enriching destination, even without a swimming pool. For those looking for water-based activities, there are other resorts in the area that offer such facilities."
  },
  {
    "name": "Agro Tourism with Water Park Near Pune",
    "description": "For a more recreational experience with water activities, there are agro tourism with water parks near Pune. These resorts combine agro-tourism with water park features, offering a fun, family-friendly experience. While Shinde Agritourism focuses primarily on the agricultural and rural experience, those seeking water parks may want to explore nearby resorts that blend both agro-tourism and water-based entertainment."
  },
  {
    "name": "Best Agro Tourism Near Pune",
    "description": "The best agro tourism near Pune often includes destinations like Shinde Agritourism, where guests can participate in farming activities, enjoy rural life, and relax in a peaceful environment. The farm provides a refreshing rural escape with educational tours, bullock cart rides, and organic food experiences. For those interested in learning about sustainable farming while relaxing in nature, Shinde Agritourism is one of the best choices near Pune."
  },
  {
    "name": "Agri Tourism Farm Resort in Pune",
    "description": "If you're looking for an agri tourism farm resort in Pune, Shinde Agritourism offers an enriching experience in a rural setting. While it may not be a typical resort with luxury amenities, it provides a unique opportunity to engage with agriculture, learn about organic farming, and enjoy the outdoors. It’s a fantastic destination for nature lovers, families, or even corporate groups looking for an offbeat and educational outing."
  },
  {
    "name": "Om Agro Tourism Near Pune",
    "description": "For a similar experience, Om Agro Tourism near Pune offers a great alternative for those looking to explore agro-tourism. While Shinde Agritourism focuses on farm activities and rural experiences, Om Agro Tourism also provides opportunities to learn about agriculture and participate in hands-on activities. Both destinations offer a fantastic way to connect with nature and experience farm life near Pune."
  },
  {
    "name": "Shinde Agri Tourism Agri Tourism Near Pune Contact Number",
    "description": "Escape to nature with Shinde Agri Tourism for a perfect Agri Tourism Near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
  }
],
tableData: [
    ["- Team Outing Places Near Pune", "- Resorts Near Pune for Corporate Outing"],
    ["- Best Resort for Corporate Outing Near Pune", "- Corporate Team Outing Places in Pune"],
    ["- Corporate Team Outing Places Near Pune", "- Resorts for Team Outing Near Pune"],
    ["- Team Outing Resorts Near Pune", "- Team Outing Places in Pune"],
    ["- Resorts for Team Outing Near Pune", "- Corporate Outing Near Pune"],
    ["- Resorts for Corporate Outing Near Pune", "- Corporate Day Outing Near Pune"],
    ["- Office Outing Places Near Pune", "- One Day Corporate Outing Near Pune"],
    ["- Places for Corporate Team Outing Near Pune", "- Team Outing Near Pune"],
    ["- Om Agritourism Corporate Outing Resorts Near Pune", "- Corporate Outing Resorts Near Pune"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Ideal Location for Team Building",
      "WhyChoosedescription": "Located just a short drive from Pune, Shinde Agri Tourism offers a peaceful, distraction-free environment, perfect for team-building activities and workshops. The natural setting promotes collaboration and relaxation, making it an ideal retreat for employees."
    },
    {
      "WhyChooseheading": "Tailored Corporate Packages",
      "WhyChoosedescription": "We offer customized packages designed to suit the needs of corporate groups. Whether you're planning a day trip, an extended retreat, or an off-site meeting, our team will work with you to create the perfect experience for your group."
    },
    {
      "WhyChooseheading": "Engaging Team Activities",
      "WhyChoosedescription": "From outdoor team-building games to farm tours and nature walks, our resort offers various activities that promote teamwork and communication. These activities help improve morale and foster better relationships within the team."
    },
    {
      "WhyChooseheading": "Relaxing & Comfortable Accommodation",
      "WhyChoosedescription": "Our resort offers comfortable and well-maintained accommodation options for overnight stays. Enjoy a peaceful retreat away from the city, with modern amenities and clean, spacious rooms."
    },
    {
      "WhyChooseheading": "Delicious Catering Services",
      "WhyChoosedescription": "We offer a variety of meal options, including authentic Maharashtrian cuisine, perfect for corporate groups. Our catering team can customize menus to fit your group’s preferences and dietary needs, ensuring a delightful dining experience."
    },
    {
      "WhyChooseheading": "Professional & Friendly Service",
      "WhyChoosedescription": "Our team at Shinde Agri Tourism is dedicated to making your corporate outing a success. We provide exceptional service and attention to detail to ensure your event runs smoothly, from arrival to departure."
    },
    {
      "WhyChooseheading": "Easy Accessibility",
      "WhyChoosedescription": "Located just 100 km from Pune, Shinde Agri Tourism is easily accessible, making it the perfect place for a quick and convenient corporate outing."
    }
  ]
  

  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Pune?",
      answer: "Shinde Agri Tourism is located approximately 100 km from Pune, which is about a 2 to 2.5-hour drive, making it an easy and quick getaway for a day trip."
    },
    {
      question: "What kind of activities are offered at Shinde Agri Tourism?",
      answer: "We offer a wide variety of activities, including farm tours, crop harvesting, animal feeding, nature walks, and interactive agricultural workshops. You can also enjoy hands-on farming experiences like planting and harvesting crops."
    },
    {
      question: "Is the experience suitable for children?",
      answer: "Yes! Shinde Agri Tourism is perfect for children. They can learn about farming, interact with animals, and participate in fun, educational activities. It’s a great way for kids to connect with nature."
    },
    {
      question: "Are meals included in the agri tourism package?",
      answer: "Yes! We offer fresh, organic, farm-to-table meals as part of our agri tourism experience. You can enjoy authentic local Maharashtrian dishes prepared with ingredients sourced directly from our farm."
    },
    {
      question: "Can I book a stay at Shinde Agri Tourism?",
      answer: "Yes! We offer comfortable accommodation options for guests who want to extend their stay and experience the farm life for longer. Enjoy a peaceful night in the lap of nature and wake up to the sounds of birds and fresh morning air."
    },
    {
      question: "Is transportation available to Shinde Agri Tourism?",
      answer: "Yes! We provide transportation options from Pune to Shinde Agri Tourism. You can easily book a cab or group transport through us to ensure a comfortable and hassle-free journey."
    },
    {
      question: "Can I visit Shinde Agri Tourism for a single day trip?",
      answer: "Yes! We offer flexible packages for day trips. Whether you want to visit for a few hours or spend the entire day, we’ll ensure your visit is enjoyable and memorable."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Suresh",
      text: "Shinde Agri Tourism was a great experience for our family! The kids had so much fun interacting with the animals and learning about farming. The food was fresh and delicious, and the whole experience was both educational and enjoyable."
    },
    {
      name: "Miss. Aarti",
      text: "We had a wonderful day at Shinde Agri Tourism! The farm tour was so interesting, and it was amazing to see how food is grown and harvested. The meals were delicious, and the peaceful surroundings made it the perfect escape from the city."
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Agri Tourism Near Pune | Best Agri Tourism Spots & Agro Tourism Resorts Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for agri tourism near Pune? Discover the best agri tourism spots, agro tourism resorts, and farm resorts near Pune with swimming pools, water parks, and beautiful nature. Visit Shinde Krushi Paryatan for an unforgettable agro tourism experience." />
  <meta name="keywords" content="Agri Tourism Near Pune, Agriculture Tourism Near Pune, Agri Tourism Spots Near Pune, Agro Tourism Near Pimpri Chinchwad, Agro Tourism Near Hinjewadi, Agro Tourism Near Kharadi, Agro Tourism Near Hadapsar, Best Agro Tourism Near Pune, Agri Tourism Farm Resort in Pune, Agro Tourism with Swimming Pool Near Pune, Agro Tourism with Water Park Near Pune, Best Agri Tourism Near Pune, Om Agro Tourism Near Pune" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/agri-tourism-near-pune" />

  {/* JSON-LD Schema for Review with Rating */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Agri Tourism Near Pune",
        "url": "https://shindekrushiparyatan.com/agri-tourism-near-pune",
        "description": "Explore the best agri tourism spots and agro tourism resorts near Pune. Shinde Krushi Paryatan offers farm resorts, swimming pools, and water parks for an unforgettable agro tourism experience.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/agri-tourism-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "120"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/18.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default AgriTourism;