
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Ranjangaonbhakta() {



  const cardData =
  {
    keyword: ' Ranjangaon Bhakta Niwas Online Booking',
    heading: 'Ranjangaon Bhakta Niwas Online Booking: Shinde Agri Tourism',
    headingDescription: 'Planning a visit to Ranjangaon Ganpati and looking for comfortable and convenient accommodation? Shinde Agri Tourism offers an easy and hassle-free online booking system for Ranjangaon Bhakta Niwas. Situated near the famous Ranjangaon Ganpati Temple, Bhakta Niwas provides pilgrims with a peaceful and affordable stay during their visit. With Shinde Agri Tourism, you can easily reserve your room and ensure a smooth and enjoyable stay, complete with modern amenities and a serene atmosphere.',

    top: 'Top Features of Ranjangaon Bhakta Niwas at Shinde Agri Tourism',
    "topPlaces": [
        {
          "title": "Convenient Online Booking",
          "description": "Easily book your stay at Ranjangaon Bhakta Niwas through our user-friendly online booking system. Reserve your room in just a few clicks, ensuring a hassle-free experience and peace of mind before your arrival."
        },
        {
          "title": "Comfortable Accommodation",
          "description": "The Bhakta Niwas at Shinde Agri Tourism provides simple yet comfortable rooms designed for pilgrims and travelers. The rooms are well-equipped with essential amenities to ensure a pleasant stay while you focus on your spiritual journey."
        },
        {
          "title": "Serene and Peaceful Environment",
          "description": "Located amidst nature, our Bhakta Niwas offers a peaceful and serene atmosphere, perfect for relaxation after a visit to the temple. Enjoy the fresh air and beautiful surroundings as you unwind and recharge."
        },
        {
          "title": "Proximity to Ranjangaon Ganpati Temple",
          "description": "Our Bhakta Niwas is just a short distance away from the Ranjangaon Ganpati Temple, making it a convenient choice for pilgrims. Enjoy easy access to the temple for your religious visit and other nearby attractions."
        },
        {
          "title": "Affordable and Spacious Rooms",
          "description": "We offer affordable room options that provide spacious and comfortable accommodations for individuals, families, or small groups. The Bhakta Niwas is designed to accommodate pilgrims and travelers with a peaceful and budget-friendly stay."
        },
        {
          "title": "Farm-Fresh Meals",
          "description": "Experience the authentic farm-to-table dining at Shinde Agri Tourism. Enjoy freshly prepared, healthy meals made with organic produce from our farm, enhancing your stay with wholesome food after a day of temple visits."
        },
        {
          "title": "Bonfire and Relaxation",
          "description": "End your day with a bonfire experience, where you can relax and enjoy the cozy atmosphere with your family or fellow pilgrims. Our outdoor spaces offer a perfect setting for unwinding and spending quality time together."
        }
      ],

        "services": [
    {
      "name": "Ranjangaon Ganpati Bhakta Niwas Contact Number",
      "description": "For those looking to stay at Ranjangaon Ganpati Bhakta Niwas, you can contact the accommodation directly for booking details and inquiries. The contact number for Bhakta Niwas is available at the temple complex and can be obtained via the temple administration for accommodation-related queries."
    },
    {
      "name": "Accommodation in Ranjangaon MIDC",
      "description": "For accommodation in Ranjangaon MIDC, Shinde Agri Tourism offers a unique rural stay experience with comfortable rooms and farm activities. For business travelers, Hotel Kunal and Hotel Suryakiran are also excellent options near Ranjangaon MIDC, offering basic amenities for a restful stay. These hotels are well-connected to the industrial zone, providing convenient access for corporate visitors."
    },
    {
      "name": "Accommodation in Ranjangaon Ganpati",
      "description": "If you're visiting Ranjangaon Ganpati, Shinde Agri Tourism offers a peaceful rural experience with farmhouse stays near the temple, perfect for pilgrims looking for a serene atmosphere. Other options like Hotel Shree Gajanan and Hotel Keshav Ganesh provide comfortable stays just a short distance from the Ranjangaon Ganpati Temple, making them convenient choices for pilgrims."
    },
    {
      "name": "Hotel Near Karegaon MIDC",
      "description": "For accommodations near Karegaon MIDC, Hotel Shubham and Hotel Sagar Plaza are great options offering comfort and convenience. Both hotels provide clean rooms, basic amenities, and easy access to the Karegaon MIDC, making them ideal for business travelers and visitors to the area."
    },
    {
      "name": "Best Hotel for Stay in Ashtavinayak Darshan Stay",
      "description": "For a comfortable stay during your Ashtavinayak Darshan, Shinde Agri Tourism near Ranjangaon Ganpati offers a peaceful and tranquil retreat, ideal for pilgrims looking to relax between temple visits. Hotel Shree Gajanan and Hotel Keshav Ganesh are also great choices for visitors seeking comfortable accommodations close to the Ranjangaon Ganpati Temple."
    },
    {
      "name": "Ashtavinayak Darshan Stay",
      "description": "If you are planning an Ashtavinayak Darshan, Shinde Agri Tourism provides a great place to stay between visits to the temples. Located near Ranjangaon Ganpati, it offers a peaceful environment with farm activities and a relaxing atmosphere. For those visiting multiple Ashtavinayak temples, there are also hotels like Hotel Shree Gajanan and Hotel Kunal, which offer convenient stays during the pilgrimage."
    },
    {
      "name": "Ashtavinayak Darshan from Mumbai in 2 Days",
      "description": "If you’re planning to complete the Ashtavinayak Darshan in 2 days from Mumbai, Shinde Agri Tourism provides a comfortable stop near Ranjangaon Ganpati. The itinerary includes visits to several temples, with Ranjangaon as one of the key stops. You can also stay at Hotel Shree Gajanan or Hotel Kunal while completing the darshan in this short timeframe."
    },
    {
      "name": "Mahaganpati Ranjangaon Darshan Accommodation Booking",
      "description": "For those looking for accommodation during Mahaganpati Ranjangaon Darshan, Shinde Agri Tourism offers an ideal retreat, providing farmhouse-style accommodations close to the temple. Bhakta Niwas at the temple is also available for pilgrims looking for simple and budget-friendly stays. Booking for both options can be done directly through the temple or by calling Shinde Agri Tourism for reservations."
    },
    {
      "name": "Ashtavinayak Bhakta Niwas Near Ranjangaon",
      "description": "Ashtavinayak Bhakta Niwas near Ranjangaon Ganpati offers basic, clean rooms for pilgrims visiting the temple. This facility is a budget-friendly option with close proximity to the temple for easy access during darshan. For a more peaceful experience with farm stays, Shinde Agri Tourism provides an alternative with rural accommodations that offer both relaxation and convenience."
    },
    {
      "name": "Shinde Agri Tourism Krushi Paryatan Kendra near Pune Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune experience. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],

       "tableData": [
    ["- Ranjangaon Ganpati Bhakta Niwas Contact Number", "- Accommodation in Ranjangaon MIDC"],
    ["- Accommodation in Ranjangaon Ganpati", "- Hotel Near Karegaon MIDC"],
    ["- Best Hotel for Stay in Ashtavinayak Darshan Stay", "- Ashtavinayak Darshan Stay"],
    ["- Ashtavinayak Darshan from Mumbai in 2 Days", "- Mahaganpati Ranjangaon Darshan Accommodation Booking"],
    ["- Ashtavinayak Bhakta Niwas Near Ranjangaon", "- Ranjangaon Ganpati Bhakta Niwas"]
  ],
  
  "whychoose": [
    {
      "WhyChooseheading": "Proximity to Ranjangaon Ganpati Temple",
      "WhyChoosedescription": "Shinde Agri Tourism is ideally located just 10-15 km from the Ranjangaon Ganpati Temple, making it the perfect choice for pilgrims looking for convenient accommodation close to the temple. You’ll be able to visit the temple for darshan without the hassle of long commutes, and once you’re done, retreat to a peaceful and tranquil environment. It’s the perfect blend of spirituality and serenity, all in one place."
    },
    {
      "WhyChooseheading": "Comfortable and Spacious Accommodations",
      "WhyChoosedescription": "Our rooms and cottages at Shinde Agri Tourism are thoughtfully designed for comfort, spaciousness, and relaxation. Whether you're visiting for a weekend getaway or a short stay, you can expect clean, well-maintained rooms equipped with modern amenities such as Wi-Fi, air conditioning, and comfortable furnishings. Our accommodations cater to all, whether you're traveling with family, friends, or colleagues, ensuring a peaceful and pleasant stay."
    },
    {
      "WhyChooseheading": "Tranquil and Serene Environment",
      "WhyChoosedescription": "Shinde Agri Tourism is nestled in a serene, rural setting surrounded by lush green fields and open spaces. The peaceful atmosphere offers the perfect escape from the hustle and bustle of city life. After visiting the Ranjangaon Ganpati Temple, you can unwind in this calm, nature-filled environment. Whether you're sitting on your porch enjoying a quiet evening or taking a stroll through the property, the serenity of the surroundings will leave you feeling rejuvenated."
    },
    {
      "WhyChooseheading": "Farm Activities and Adventure",
      "WhyChoosedescription": "At Shinde Agri Tourism, we offer a wide variety of fun-filled farm activities and adventures for guests of all ages. Experience authentic farm life with activities such as fruit picking, bullock cart rides, and feeding the animals. If you're seeking a bit more excitement, we also provide adventure activities like zip-lining, rock climbing, archery, and nature trails. These activities make for a fun-filled day for families, friends, and even corporate teams looking for team-building experiences."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining Experience",
      "WhyChoosedescription": "At Shinde Agri Tourism, we take pride in offering an authentic farm-to-table dining experience. All the meals served are prepared using organic ingredients sourced directly from our farm. Guests can enjoy traditional Maharashtrian dishes made with freshly harvested vegetables, fruits, and herbs, providing a wholesome and healthy meal. Whether you're enjoying a hearty breakfast, a traditional lunch, or a light snack, the flavors of our farm-grown produce are sure to satisfy your taste buds and keep you coming back for more."
    },
    {
      "WhyChooseheading": "Customizable Packages for Special Events",
      "WhyChoosedescription": "Shinde Agri Tourism offers flexible, customizable packages for a wide range of special events, whether it's a spiritual retreat, family reunion, birthday celebration, or corporate event. Our team works closely with you to tailor your experience, ensuring that all aspects—from catering to activities and décor—are planned to your exact specifications. With our customizable event packages, we make sure your occasion is stress-free, enjoyable, and memorable."
    },
    {
      "WhyChooseheading": "Complete Privacy and Exclusivity",
      "WhyChoosedescription": "When you book your stay at Shinde Agri Tourism, you’re guaranteed complete privacy. You’ll have exclusive access to the entire property, providing the perfect setting for family gatherings, private events, or corporate team-building activities. Whether you’re looking to host a bonfire evening, an outdoor barbecue, or a day of relaxation, you and your group will enjoy the full use of the space, ensuring a private and intimate experience that’s perfect for special occasions."
    },
    {
      "WhyChooseheading": "Eco-Friendly Practices",
      "WhyChoosedescription": "We are committed to sustainability at Shinde Agri Tourism. Our farm follows eco-friendly practices such as organic farming, rainwater harvesting, and effective waste management to minimize our environmental impact. We encourage guests to embrace nature and contribute to sustainable tourism by practicing responsible behavior during their stay. Whether it’s through our eco-friendly amenities or our emphasis on sustainability in every activity, we ensure that your stay is not only enjoyable but also kind to the planet."
    },
    {
      "WhyChooseheading": "Affordable and Flexible Packages",
      "WhyChoosedescription": "At Shinde Agri Tourism, we offer flexible and affordable packages to suit the needs of different guests, whether you're planning a quick weekend getaway or an extended retreat. Our pricing is transparent and competitive, with options that can be customized based on the size of your group and your preferences. We want everyone to experience the beauty and tranquility of farm life, which is why we offer cost-effective solutions for families, friends, pilgrims, and corporate teams alike. With our range of flexible packages, you can enjoy a luxurious, yet affordable rural retreat."
    }
  ]
 




  }
  const faqData = [
    {
      question: "How far is Shinde Agri Tourism from Ranjangaon Bhakta Niwas?",
      answer: "Shinde Agri Tourism is located just 10-15 km away from Ranjangaon Bhakta Niwas, making it a convenient and easy place to stay for pilgrims visiting the Ranjangaon Ganpati Temple."
    },
    {
      question: "What activities are available at Shinde Agri Tourism?",
      answer: "At Shinde Agri Tourism, you can enjoy a variety of activities, including farm experiences like fruit picking and animal feeding, as well as adventure activities such as zip-lining, rock climbing, and archery. There are also nature walks for a peaceful retreat."
    },
    {
      question: "Can we book Shinde Agri Tourism for a family gathering or special event?",
      answer: "Yes! Shinde Agri Tourism is perfect for family reunions, corporate retreats, or any special occasion. We offer customizable event packages including food, decorations, and activities to ensure your event is memorable."
    },
    {
      question: "Does the booking include food?",
      answer: "Yes, we offer a farm-to-table dining experience. Enjoy freshly prepared meals using organic ingredients grown on our farm. We offer a range of Maharashtrian dishes, salads, juices, and snacks made with the finest local produce."
    },
    {
      question: "How many people can Shinde Agri Tourism accommodate?",
      answer: "Shinde Agri Tourism can comfortably accommodate large groups, making it ideal for family gatherings, team retreats, or pilgrimages. The capacity depends on your group size and accommodation preferences."
    },
    {
      question: "Is Shinde Agri Tourism eco-friendly?",
      answer: "Yes, we focus on sustainability and green practices at Shinde Agri Tourism. Our property includes organic farming, rainwater harvesting, and waste management systems, ensuring an eco-conscious experience for all guests."
    },
    {
      question: "How do I book my stay at Shinde Agri Tourism?",
      answer: "Booking is easy! You can visit our website or contact us by phone to make your reservation. Simply provide your preferred dates, group size, and any specific requirements, and we will handle the rest to ensure a smooth and enjoyable stay."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. and Mrs. Deshmukh, Pune",
      text: "Our family stayed at Shinde Agri Tourism for a weekend trip, and it was a wonderful experience! It’s just a short drive from Ranjangaon Bhakta Niwas, and the farm activities were so much fun for the kids. The food was fresh and delicious, and the peaceful surroundings made it the perfect escape. Highly recommend it!"
    },
    {
      name: "Mr. Sanjay, Manager, Pune",
      text: "We booked Shinde Agri Tourism for our team retreat, and it was an amazing experience. The location is perfect for a spiritual retreat with the nearby Ranjangaon Ganpati Temple. The team-building activities were great, and the farm-to-table meals were absolutely delicious. Looking forward to coming back!"
    }
  ];
      







  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>

<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Ranjangaon Bhakta Niwas Online Booking | Ashtavinayak Darshan Accommodation | Best Hotels Near Ranjangaon Ganpati | Shinde Krushi Paryatan</title>
  <meta name="description" content="Book your stay at Ranjangaon Bhakta Niwas for an unforgettable Ashtavinayak Darshan experience. Find the best accommodation options near Ranjangaon Ganpati, including hotels near Karegaon MIDC, with online booking facilities." />
  <meta name="keywords" content="Ranjangaon Bhakta Niwas Online Booking, Ranjangaon Ganpati Bhakta Niwas Contact Number, Accommodation in Ranjangaon MIDC, Accommodation Near Ranjangaon Ganpati, Best Hotel for Stay in Ashtavinayak Darshan, Ashtavinayak Darshan Stay, Mahaganpati Ranjangaon Darshan Accommodation Booking, Ashtavinayak Bhakta Niwas Near Ranjangaon" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/ranjangaon-bhakta-niwas-online-booking" />

  {/* JSON-LD Schema for Ranjangaon Bhakta Niwas Online Booking */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Shinde Krushi Paryatan - Ranjangaon Bhakta Niwas Online Booking",
        "url": "https://shindekrushiparyatan.com/ranjangaon-bhakta-niwas-online-booking",
        "description": "Book your stay at Ranjangaon Bhakta Niwas for Ashtavinayak Darshan. Enjoy convenient accommodation options near Ranjangaon Ganpati and Karegaon MIDC with easy online booking.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Ranjangaon",
          "addressRegion": "Maharashtra",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.9184",
          "longitude": "74.2906"
        },
        "image": "https://shindekrushiparyatan.com/images/ranjangaon-bhakta-niwas-online-booking.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.6",
          "reviewCount": "180"
        }
      }
    `} 
  </script>
</Helmet>

      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/45.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


 
             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Ranjangaonbhakta;