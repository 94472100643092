
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import Gallery from './Smallimages';
import './smallkey.css';
import { Helmet } from 'react-helmet';

function Farmhouseonrentinpune() {



  const cardData =
  {
    keyword: ' Farm House On Rent in Pune',
    heading:  'Farm House On Rent in Pune: Shinde Agri Tourism',
    headingDescription: 'Looking for a peaceful retreat or a unique getaway near Pune? Shinde Agri Tourism offers the perfect solution with its farm house rentals. Located amidst scenic greenery, this farm house provides an ideal environment for relaxation, rejuvenation, and adventure. Whether you are looking to host a family gathering, corporate retreat, or a weekend getaway with friends, renting a farm house at Shinde Agri Tourism offers an unforgettable experience. Enjoy the tranquility of rural life while still being close to Pune city.',

    top: 'Top Features of the Farm House at Shinde Agri Tourism	',
    "topPlaces": [
  {
    "title": "Spacious Accommodation",
    "description": "The farm house is designed to accommodate groups of various sizes, with ample space for everyone to relax and enjoy. With comfortable living areas, cozy bedrooms, and a welcoming ambiance, it’s the perfect place to unwind and reconnect with nature."
  },
  {
    "title": "Farm-to-Table Dining",
    "description": "Enjoy delicious meals prepared from fresh, organic produce grown right on the farm. The farm-to-table experience ensures that you get the healthiest and most flavorful meals during your stay, making your getaway even more special."
  },
  {
    "title": "Surrounded by Nature",
    "description": "Wake up to the sights and sounds of nature with lush greenery, chirping birds, and fresh air. Whether you're sipping your morning coffee on the porch or enjoying a peaceful evening walk, the natural beauty surrounding the farm house adds to the relaxing experience."
  },
  {
    "title": "Interactive Farming Experience",
    "description": "Engage in fun and educational farm activities like milking cows, planting crops, or harvesting fresh produce. These hands-on experiences are great for families, friends, or team-building activities, allowing you to connect with nature and learn about sustainable farming practices."
  },
  {
    "title": "Outdoor Activities and Sports",
    "description": "The farm house is equipped with ample open space for outdoor sports like cricket, badminton, and volleyball. You can also enjoy peaceful walks around the property or relax in the well-maintained picnic areas, perfect for a day out in the fresh air."
  },
  {
    "title": "Bonfire and Barbecue",
    "description": "As the evening sets in, enjoy a cozy bonfire and indulge in a barbecue session with friends and family. It’s the perfect way to unwind after a day of activities and make lasting memories with your loved ones."
  },
  {
    "title": "Peaceful and Private Setting",
    "description": "The farm house offers a private, serene environment away from the hustle and bustle of the city. Whether you’re looking for a quiet weekend getaway or a special family celebration, the secluded setting provides a peaceful atmosphere for your stay."
  }
],

"services": [
    {
      "name": "Farm House for Rent Near Pune",
      "description": "If you are looking for a farm house for rent near Pune, Shinde Agri Tourism offers a beautiful, rustic farm experience that can be rented for a day or overnight stay. Located in the serene countryside, it’s perfect for those seeking a peaceful retreat away from the hustle and bustle of the city."
    },
    {
      "name": "Farm House in Pune for Rent",
      "description": "For a farm house in Pune for rent, Shinde Agri Tourism provides a welcoming rural experience, where you can enjoy the peaceful surroundings of a working farm. This is a great option for individuals or groups who want to experience rural life in a serene and relaxing environment."
    },
    {
      "name": "Farm House Near Pune on Rent",
      "description": "Looking for a farm house near Pune on rent? Shinde Agri Tourism offers beautiful farmhouses for rent that allow you to experience the tranquility of nature. Perfect for family gatherings, friends' getaways, or team outings, you can enjoy activities like farm tours, bullock cart rides, and nature walks during your stay."
    },
    {
      "name": "Farm House on Rent Pune",
      "description": "If you're interested in a farm house on rent in Pune, Shinde Agri Tourism offers a rural retreat just a short drive from the city. The farmhouse provides a peaceful environment to relax, unwind, and enjoy the beauty of the countryside."
    },
    {
      "name": "Farm House Pune Rent",
      "description": "For farm house Pune rent, Shinde Agri Tourism offers a variety of rustic accommodations ideal for groups or families. The farm is equipped with basic amenities to ensure a comfortable stay while providing an authentic agro-tourism experience."
    },
    {
      "name": "Farmhouse for Rent in Pune",
      "description": "Shinde Agri Tourism offers a farmhouse for rent in Pune for those looking for a short escape to the countryside. You can enjoy farm activities, explore nature, and unwind in a peaceful setting. It’s perfect for weekend getaways or family gatherings."
    },
    {
      "name": "Farmhouse on Rent Near Pune for Party",
      "description": "If you're looking for a farmhouse on rent near Pune for a party, Shinde Agri Tourism offers a spacious, scenic farm setting that is perfect for hosting small to medium-sized gatherings. Celebrate your special events surrounded by nature, with activities such as farm tours, nature walks, and bullock cart rides for entertainment."
    },
    {
      "name": "Farm House on Rent Pune",
      "description": "For a farm house on rent in Pune, Shinde Agri Tourism is a wonderful option. This picturesque farm offers a serene environment, perfect for those looking to host parties, family gatherings, or corporate offsites, with a range of activities available to enhance your stay."
    },
    {
      "name": "Pune Farm House Rent",
      "description": "Shinde Agri Tourism provides an affordable and comfortable Pune farm house rent option. The farm offers a rustic stay surrounded by nature, with a variety of activities to keep you engaged, making it a great getaway option for families and small groups."
    },
    {
      "name": "Farmhouse with Swimming Pool on Rent Near Pune",
      "description": "Shinde Agri Tourism offers a farmhouse with swimming pool on rent near Pune. Perfect for a relaxing weekend getaway or a fun-filled family retreat, the farm includes a swimming pool along with other activities like farm tours and bullock cart rides, all within a peaceful and natural environment."
    },
    {
      "name": "Farm House for Rent in Pune",
      "description": "For those looking for a farm house for rent in Pune, Shinde Agri Tourism offers a great option. The farm is located in a tranquil setting, offering a rural experience with comfortable accommodation and a variety of outdoor activities."
    },
    {
      "name": "Farmhouse in Pune with Swimming Pool",
      "description": "Shinde Agri Tourism provides a farmhouse in Pune with swimming pool, ideal for those who want to relax in the pool while also enjoying the rural charm of a farm stay. It’s a perfect blend of comfort and nature."
    },
    {
      "name": "Farmhouse Near Pune for Couples",
      "description": "For couples seeking a farmhouse near Pune, Shinde Agri Tourism offers a romantic and peaceful escape in the countryside. The farm offers an intimate setting, ideal for couples looking for a quiet retreat with activities like nature walks, farm tours, and organic farming experiences."
    },
    {
      "name": "Farmhouse Near Pune for Weekend Party",
      "description": "If you're planning a weekend party and looking for a farmhouse near Pune, Shinde Agri Tourism is a fantastic option. The farm provides ample space for parties, along with fun activities like bullock cart rides and nature walks to entertain your guests."
    },
    {
      "name": "Farmhouse Near Pune for One Night Stay",
      "description": "For a farmhouse near Pune for one night stay, Shinde Agri Tourism is a great choice. The farm offers an overnight retreat in a serene rural setting, allowing guests to relax, enjoy nature, and experience farm life—all just a short drive from Pune."
    },
    {
      "name": "Farm House Near Pune for One Day",
      "description": "If you’re looking for a farm house near Pune for one day, Shinde Agri Tourism is the ideal location. The farm provides a peaceful environment with a variety of activities, including farm tours and nature walks, perfect for a relaxing day trip."
    },
    {
      "name": "Budget Farmhouse Near Pune",
      "description": "For a budget farmhouse near Pune, Shinde Agri Tourism offers an affordable farm stay option that provides great value for money. With a variety of activities and comfortable accommodation, it’s perfect for those on a budget looking to escape the city for a relaxing retreat."
    },
    {
      "name": "Lakeside Farmhouse Near Pune",
      "description": "While Shinde Agri Tourism is not lakeside, it does offer a peaceful rural retreat near Pune, with scenic views of the countryside, making it an ideal location for relaxation and enjoying nature."
    },
    {
      "name": "Farmhouse Near Pune for Weekend",
      "description": "If you're looking for a farmhouse near Pune for a weekend, Shinde Agri Tourism is a great option. Located just outside Pune, the farm offers a relaxing weekend experience with activities like farm tours, organic farming workshops, and bullock cart rides."
    },
    {
      "name": "Shinde Agri Tourism Krushi Paryatan Kendra near Pune Contact Number",
      "description": "Escape to nature with Shinde Agri Tourism for a perfect Krushi Paryatan Kendra near Pune. Contact us at +91 9356007002 to book your day of relaxation and adventure at our serene countryside resort. Enjoy a day filled with scenic views, authentic rural experiences, and top-notch hospitality. We ensure a peaceful and rejuvenating experience for all our guests, making your one-day getaway truly special."
    }
  ],

  tableData: [
    ["- Farm House for Rent Near Pune", "- Farm House in Pune for Rent"],
    ["- Farm House Near Pune on Rent", "- Farm House on Rent Pune"],
    ["- Farm House Pune Rent", "- Farmhouse for Rent in Pune"],
    ["- Farmhouse on Rent Near Pune for Party", "- Farmhouse on Rent Pune"],
    ["- Pune Farm House Rent", "- Farmhouse with Swimming Pool on Rent Near Pune"],
    ["- Farm House for Rent in Pune", "- Farmhouse in Pune with Swimming Pool"],
    ["- Farmhouse Near Pune for Couple", "- Farmhouse Near Pune for Weekend Party"],
    ["- Farmhouse Near Pune for One Night Stay", "- Farm House Near Pune for One Day"],
    ["- Budget Farmhouse Near Pune", "- Lakeside Farmhouse Near Pune"],
    ["- Farmhouse Near Pune for Weekend", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Proximity to Pune",
      "WhyChoosedescription": "Located just 40 km from Pune, Shinde Agri Tourism is easily accessible, making it an ideal choice for a quick weekend retreat or family getaway. The short drive ensures that you spend more time relaxing and enjoying the serene surroundings, rather than being stuck in traffic."
    },
    {
      "WhyChooseheading": "Spacious and Comfortable Farmhouse",
      "WhyChoosedescription": "Our farmhouse is spacious, well-maintained, and designed to offer you the ultimate comfort. Whether you’re hosting a family gathering, a friend’s reunion, or a small corporate retreat, our farmhouse offers ample space, cozy rooms, and modern amenities to make your stay comfortable and enjoyable."
    },
    {
      "WhyChooseheading": "Serene and Peaceful Environment",
      "WhyChoosedescription": "Surrounded by lush greenery, open fields, and a tranquil atmosphere, our farmhouse provides the perfect escape from the hustle and bustle of city life. It’s a peaceful haven where you can relax, unwind, and reconnect with nature. The quiet surroundings allow for a refreshing retreat away from the stress of daily life."
    },
    {
      "WhyChooseheading": "Farm and Outdoor Activities",
      "WhyChoosedescription": "At Shinde Agri Tourism, you can experience the joys of farm life. Enjoy fruit picking, explore the organic vegetable gardens, and participate in hands-on activities like animal feeding, bullock cart rides, and nature walks. For adventure seekers, we offer activities like zip-lining, rock climbing, and archery for an exciting day out."
    },
    {
      "WhyChooseheading": "Farm-to-Table Dining",
      "WhyChoosedescription": "One of the highlights of staying at our farmhouse is the farm-to-table dining experience. Enjoy freshly prepared meals made from organic ingredients grown right on our farm. From Maharashtrian specialties to fresh salads, juices, and grilled snacks, we provide delicious and nutritious meals for your entire group."
    },
    {
      "WhyChooseheading": "Ideal for Special Occasions",
      "WhyChoosedescription": "Whether you’re planning a birthday party, anniversary celebration, family reunion, or a small corporate retreat, our farmhouse is the perfect venue. We can help you customize the experience based on your occasion, providing everything from decorations to food and activities to make your event special."
    },
    {
      "WhyChooseheading": "Privacy and Exclusivity",
      "WhyChoosedescription": "Renting our farmhouse gives you complete privacy, ensuring a peaceful and intimate environment for your group. Whether you want to relax in the garden, have a bonfire, or enjoy a barbecue, you’ll have the entire property to yourself to create lasting memories."
    },
    {
      "WhyChooseheading": "Affordable and Flexible Packages",
      "WhyChoosedescription": "We offer affordable rental packages for both short-term and long-term stays. Whether you need the farmhouse for a day trip, a weekend stay, or an extended retreat, our packages are flexible and can be tailored to suit your group size and requirements."
    },
    {
      "WhyChooseheading": "Eco-Friendly Amenities",
      "WhyChoosedescription": "At Shinde Agri Tourism, we are committed to sustainability. Our farmhouse features eco-friendly facilities, including rainwater harvesting, organic farming, and waste management systems. It’s the perfect place for those looking for a green and eco-conscious getaway."
    }
  ]
    



  }

  const faqData = [
    {
      question: "How far is the farmhouse from Pune?",
      answer: "The farmhouse is located just 40 km from Pune, approximately 1 to 1.5 hours by car. It’s an easy and convenient drive, making it perfect for a weekend getaway or a day trip."
    },
    {
      question: "What types of activities are available at the farmhouse?",
      answer: "Our farmhouse offers a wide range of activities including fruit picking, organic farming, animal feeding, nature walks, bullock cart rides, and adventure activities like zip-lining, rock climbing, and archery. There’s something for everyone!"
    },
    {
      question: "Can we rent the farmhouse for a family gathering or special occasion?",
      answer: "Yes! Our farmhouse is ideal for family gatherings, birthday parties, anniversary celebrations, and small corporate retreats. We offer customizable packages to cater to your event’s needs, including food, activities, and decorations."
    },
    {
      question: "Is food included in the farmhouse rental?",
      answer: "Yes, we offer a farm-to-table dining experience, where you can enjoy fresh, delicious meals made from organic ingredients grown on our farm. We provide a variety of dishes, including Maharashtrian cuisine, salads, juices, and grilled snacks."
    },
    {
      question: "How many people can the farmhouse accommodate?",
      answer: "Our farmhouse is spacious and can comfortably accommodate large groups. It’s ideal for family reunions, group outings, or corporate retreats. The number of guests the farmhouse can accommodate will depend on the type of setup and rooms you require."
    },
    {
      question: "Are the farmhouse facilities eco-friendly?",
      answer: "Yes, our farmhouse is designed with sustainability in mind. We use eco-friendly facilities, such as rainwater harvesting, organic farming, and waste management systems, to minimize our environmental impact."
    },
    {
      question: "Can I book the farmhouse for a corporate retreat?",
      answer: "Absolutely! Our farmhouse is a great place for corporate retreats, team-building activities, or small conferences. We can provide a mix of relaxation, team-building exercises, and workshops to suit your corporate needs."
    },
    {
      question: "How do I book the farmhouse?",
      answer: "Booking is easy! You can contact us through our website or by phone. Simply let us know the dates, group size, and any special requirements, and we will take care of the rest to ensure you have an unforgettable stay at our farmhouse."
    }
  ];
  
  const testimonials = [
    {
      name: "Miss. Aarti & Family, Pune",
      text: "We rented the farmhouse for a family gathering, and it was an unforgettable experience! The peaceful surroundings, delicious food, and farm activities made it the perfect place to relax and bond with loved ones. The kids had a great time feeding the animals and picking fruits. Highly recommend it!"
    },
    {
      name: "Mr. Vikas, HR Manager, Pune",
      text: "Shinde Agri Tourism was the perfect place for our team retreat. The farmhouse provided a peaceful and private setting for us to unwind and connect as a team. The team-building activities were fun and engaging, and the farm-to-table meals were absolutely delicious. We'll definitely return for our next corporate outing!"
    }
  ];
      






  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9356007002",
      "+91 9075990202",

    ],
    email: "booking@shindekrushiparyatan.com"
  };


  const Images = [
    {
      place: "/images/keyword/14.jpg",
      text: "One Day Trip Near Pune",
    },
    {
      place: "/images/keyword/15.jpg",
      text: "Picnic Spot Near Pune",
    },
    {
      place: "/images/keyword/16.jpg",
      text: "Picnic Destination Near Pune",
    },
    {
      place: "/images/keyword/17.jpg",
      text: "Corporate Outing Resorts Near Pune",
    },
    {
      place: "/images/keyword/18.jpg",
      text: "Agri Tourism Near Pune",
    },
    {
      place: "/images/keyword/19.jpg",
      text: "Destination Wedding Near Pune",
    },

    {
      place: "/images/keyword/20.jpg",
      text: "Hurda Party Places Near Pune ",
    },
    {
      place: "/images/keyword/21.jpg",
      text: "Water Park Near Pune",
    },
    {
      place: "/images/keyword/22.jpg",
      text: "School Picnic Spots Near Pune for Students",
    },
    {
      place: "/images/keyword/23.jpg",
      text: "One Day Adventure Resorts Near Pune",
    },

    {
      place: "/images/keyword/24.jpg",
      text: "Resort Near Pune",
    },
    {
      place: "/images/keyword/25.jpg",
      text: "Forest Resorts Near Pune",
    },
    {
      place: "/images/keyword/27.jpg",
      text: "Mango Mahotsav in Pune",
    },

    {
      place: "/images/keyword/28.jpg",
      text: "Krushi Paryatan Kendra near Pune",
    },

    {
      place: "/images/keyword/29.jpg",
      text: "Camping Places Near Pune ",
    },
    {
      place: "/images/keyword/30.jpg",
      text: "Activities Resort Near Pune",
    },
    {
      place: "/images/keyword/31.jpg",
      text: "Agro Tourism Near Mumbai for Family",
    },
    {
      place: "/images/keyword/32.jpg",
      text: "One Day Picnic Spot From Mumbai",
    },
    {
      place: "/images/keyword/33.jpg",
      text: "One Day Picnic Packages Near Mumbai",
    },
    {
      place: "/images/keyword/34.jpg",
      text: "School Picnic Spots in Mumbai",
    },
    {
      place: "/images/keyword/35.jpg",
      text: "Resort Near Mumbai",
    },
    {
      place: "/images/keyword/37.jpg",
      text: "Corporate Outing Near Mumbai",
    },
    {
      place: "/images/keyword/38.jpg",
      text: "Picnic Spot Near Nashik",
    },
    {
      place: "/images/keyword/39.jpg",
      text: "Farm House On Rent in Pune",
    },
  ];


  const images = [
    { src: "/images/gallery/1.jpg", alt: "Image 1" },
    { src: "/images/gallery/2.jpg", alt: "Image 1" },
    { src: "/images/gallery/3.jpg", alt: "Image 1" },
    { src: "/images/gallery/4.jpg", alt: "Image 1" },
    { src: "/images/gallery/5.jpg", alt: "Image 1" },
    { src: "/images/gallery/6.jpg", alt: "Image 1" },
    { src: "/images/gallery/7.jpg", alt: "Image 1" },
    { src: "/images/gallery/8.jpg", alt: "Image 1" },
    { src: "/images/gallery/9.jpg", alt: "Image 1" },
  ];




  return (
    <div>
<Helmet>
  <title>Contact: +91 9356007002 | Shinde Krushi Paryatan | Farm House on Rent in Pune | Budget & Luxury Farmhouses for Parties, Weekends & Stay | Farm House Rentals Near Pune | Shinde Krushi Paryatan</title>
  <meta name="description" content="Looking for a farm house on rent near Pune? Explore a wide range of farmhouses for parties, weekend getaways, and family stays. Rent budget and luxury farmhouses with swimming pools, lakeside locations, and perfect spots for one day or weekend parties." />
  <meta name="keywords" content="Farm House On Rent in Pune, Farm House for Rent Near Pune, Farm House in Pune for Rent, Farm House Near Pune on Rent, Farm House On Rent Pune, Pune Farm House Rent, Farmhouse for Rent in Pune, Farmhouse On Rent Near Pune for Party, Farmhouse with Swimming Pool on Rent Near Pune, Farm House for Rent in Pune, Farmhouse in Pune with Swimming Pool, Farmhouse Near Pune for Couples, Farmhouse Near Pune for Weekend Party, Farmhouse Near Pune for One Night Stay, Farm House Near Pune for One Day, Budget Farmhouse Near Pune, Lakeside Farmhouse Near Pune, Farmhouse Near Pune for Weekend" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://shindekrushiparyatan.com/farm-house-on-rent-in-pune" />

  {/* JSON-LD Schema for Farm House on Rent in Pune */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Shinde Krushi Paryatan - Farm House on Rent in Pune",
        "url": "https://shindekrushiparyatan.com/farm-house-on-rent-in-pune",
        "description": "Find a variety of farmhouses for rent near Pune. Choose from budget to luxury options for family stays, weekend parties, and more. Farmhouses with swimming pools, lakeside views, and more.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Pune",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "18.5204",
          "longitude": "73.8567"
        },
        "image": "https://shindekrushiparyatan.com/images/farm-house-on-rent-pune.jpg",
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-18:00",
        "telephone": "+91-9356007002",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "85"
        }
      }
    `}
  </script>
</Helmet>


      <section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
        <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 className="mb-3 text-white">{cardData.keyword}</h2>

            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>


      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-8 ">
              <img src='/images/keyword/39.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#106b30', // green color
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className=' leadd'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>


              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>


              <div>

              </div>


            </div>

            <div className='col-12 col-md-4' >


              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #106b30',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#106b30' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9356007002" className="d-block  text-white">+91 9356007002</a>
                        <a href="tel:+91 90759 90202" className="d-block text-white">+91 90759 90202</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shindekrushiparyatan.com" className=" text-white d-block">
                          booking@shindekrushiparyatan.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#106b30' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Shinde Krushi Paryatan:<br />
                            At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='darkcolorr'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              



             <Gallery images={images} ></Gallery>
              <table className="table table-responsive custom-table my-4">
                <thead>
                  <tr>
                    {cardData?.tableHeaders?.map((header, index) => (
                      <th key={index} className="custom-th">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cardData?.tableData?.map((row, rowIndex) => (
                    <tr className="custom-row" key={rowIndex}>
                      {row?.map((cell, cellIndex) => (
                        <td key={cellIndex} className="custom-td">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>


              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Shinde Agri Tourism</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>


              <div className="contact-box Borderr mt-5">
                <h3>{contactData.heading}</h3>
                <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                <div className="contact-details">
                  <p><strong className='darkcolor'>Mobile No:</strong></p>
                  <ul>
                    {contactData.contactNumbers.map((number, index) => (
                      <li key={index}>
                        <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <p><strong className='darkcolor'>Email Id: </strong>
                    <a href={`mailto:${contactData.email}`} className="contact-link">
                      {contactData.email}
                    </a>
                  </p>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Farmhouseonrentinpune;