import React from 'react';

const ContactButtons = () => {
  return (
    <>
    
    <div class="phone-float">
                <a href="tel:+919356007002">
                <i class="bi bi-telephone-fill"></i><span>Call Us
                        <br/><small>+91 9356007002</small></span>
                </a>
            </div>

            <div class="whats-float">
                <a href="https://wa.me/+919356007002?text=Hello%20Shinde%20Agri%20Tourism%20Team,%20I%20want%20to%20inquire%20about%20the%20services%20offered%20at%20Shinde%20Agri%20Tourism.%20I%20am%20interested%20in%20one%20day%20picnic%20spots,%20corporate%20outing%20resorts,%20agri%20tourism,%20destination%20weddings,%20hurda%20party%20places,%20and%20other%20activities.%20Please%20send%20me%20more%20details%20or%20call%20me.%20Thank%20you!" 
                    target="_blank">
                    <span>WhatsApp<br/><small style={{ textAlign: 'right' }}>+91 9356007002</small></span><i
                        class="bi bi-whatsapp"></i>
                </a>
              
            </div>

    
    </>
  );
};

export default ContactButtons;

