import React, { useState, useEffect } from 'react';

const AboutUsSection = () => {
  // State for each counter
  const [yearsExperience, setYearsExperience] = useState(0);
  const [tourPackages, setTourPackages] = useState(0);
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [awardWinning, setAwardWinning] = useState(0);

  // Animation function to increment the counter values
  const animateCounter = (target, setter) => {
    let count = 0;
    const interval = setInterval(() => {
      if (count < target) {
        count += Math.ceil(target / 100); // Adjust the increment to control the speed
        setter(count);
      } else {
        clearInterval(interval);
      }
    }, 30); // Interval time in ms (controls the speed of animation)
  };

  // useEffect to start the counter animation on component mount
  useEffect(() => {
    animateCounter(5, setYearsExperience); // 20 years experience
    animateCounter(530, setTourPackages); // 530 tour packages
    animateCounter(850, setHappyCustomers); // 850 happy customers
    animateCounter(320, setAwardWinning); // 320 awards
  }, []);

  return (
    <section
      className="about-us pt-6"
      style={{
        backgroundImage: 'url(images/background_pattern.png)',
        backgroundPosition: 'bottom right',
      }}
    >
      <div className="container">
        <div className="about-image-box">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-6 ps-4">
              <div className="about-content text-center text-lg-start">
                <h4 className="theme d-inline-block mb-0">Get To Know Us</h4>
                <h2 className="border-b mb-2 pb-1">
                  Explore All Tour of the world with us.
                </h2>
                <p className="border-b mb-2 pb-2">
                Welcome to Shinde Agri Tourism, a unique retreat that brings you closer to nature and offers an authentic farm experience. Nestled in the serene countryside, our agro-tourism destination allows you to escape the hustle and bustle of city life while immersing yourself in the peaceful surroundings of lush green fields and sustainable farming practices.
                  <br />
                  <br />
                  At Shinde Agri Tourism, we believe in promoting eco-tourism and connecting our guests with the beauty and tranquility of rural life. Our farm is a blend of tradition and modernity, where you can engage in hands-on farming activities, enjoy farm-fresh meals, and experience the charm of rural living. Whether you're looking for a relaxing weekend getaway, an educational farm tour, or a place to host corporate events or celebrations, we offer something for everyone.

                </p>
                {/* <div className="about-listing">
                  <ul className="d-flex justify-content-between">
                    <li><i className="icon-location-pin theme"></i> Tour Guide</li>
                    <li><i className="icon-briefcase theme"></i> Friendly Price</li>
                    <li><i className="icon-folder theme"></i> Reliable Tour Package</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 mb-4 pe-4">
              <div className="about-image" style={{ animation: 'none', background: 'transparent' }}>
                <img src="/images/about2.png" alt="" />
              </div>
            </div>




          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
